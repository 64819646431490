import React, { useEffect, useState } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import {
  businessTypesData,
  changeLanguages,
  getLanguages,
} from '../../redux/business/action'
import classNames from 'classnames'
import style from '../../assets/scss/additional/dropDownItem.module.scss'
import { useHistory, useLocation } from 'react-router-dom'

import en from '../../assets/img/en.png'
import ro from '../../assets/img/ro.png'
import it from '../../assets/img/language/it.png'
import es from '../../assets/img/language/es.png'
import fr from '../../assets/img/language/fr.png'
import pt from '../../assets/img/flags/PT.png'
import de from '../../assets/img/flags/DE.png'
import tr from '../../assets/img/flags/TR.png'
import gr from '../../assets/img/flags/GR.png'
import bg from '../../assets/img/flags/BG.png'
import ru from '../../assets/img/flags/RU.png'
// import hr from "../../assets/img/flags/hr.png";
import ae from '../../assets/img/flags/AE.png'
import IN from '../../assets/img/flags/IN.png'

import { useTranslation } from 'react-i18next'
export default function LanguageDropDown() {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const business = useSelector(({ business }) => business)
  const activeLanguage = localStorage.getItem('i18nextLng')

  const [state, setState] = useState({
    isOpen: false,
    dropdownOpen: false,
    color: 'transparent',
    width: '',
  })
  const [businessState, setBusinessState] = useState({
    dropdownOpen: false,
    isOpen: false,
  })
  const [languageStatus, setLanguageStatus] = useState('')
  const toggle = () => {
    if (state.isOpen) {
      setState({
        color: 'transparent',
      })
    } else {
      setState({
        color: 'white',
      })
    }
    setState({
      isOpen: !state.isOpen,
    })
  }
  const dropdownToggle = (e) => {
    setState({
      dropdownOpen: !state.dropdownOpen,
    })
  }
  const changeLanguage = (lng) => (event) => {
    activeLanguageFlag(lng)
    dispatch(changeLanguages(lng))
    i18n.changeLanguage(lng)
    // toggle()
    localStorage.setItem('i18nextLng', lng)
    setState({
      isOpen: !state.isOpen,
    })
  }
  const activeLanguageFlag = (lang) => {
    switch (lang) {
      case 'ro':
        setLanguageStatus(ro)
        break
      case 'en':
        setLanguageStatus(en)
        break
      case 'it':
        setLanguageStatus(it)
        break
      case 'es':
        setLanguageStatus(es)
        break
      case 'fr':
        setLanguageStatus(fr)
        break
      case 'pt':
        setLanguageStatus(pt)
        break
      case 'de':
        setLanguageStatus(de)
        break
      case 'tr':
        setLanguageStatus(tr)
        break
      case 'gr':
        setLanguageStatus(gr)
        break
      case 'bg':
        setLanguageStatus(bg)
        break
      case 'ru':
        setLanguageStatus(ru)
        break
      // case "hr":
      // setLanguageStatus(hr);
      // break;
      case 'ae':
        setLanguageStatus(ae)
        break
      case 'in':
        setLanguageStatus(IN)
        break
      default:
        setLanguageStatus(en)
        break
    }
  }

  const displayFlag = (lang) => {
    switch (lang) {
      case 'ro':
        return <img src={ro} alt="ro" />
      case 'en':
        return <img src={en} alt="en" />
      case 'it':
        return <img src={it} alt="it" />

      case 'es':
        return <img src={es} alt="es" />
      case 'fr':
        return <img src={fr} alt="fr" />
      case 'pt':
        return <img src={pt} alt="pt" />
      case 'de':
        return <img src={de} alt="de" />
      case 'tr':
        return <img src={tr} alt="tr" />
      case 'gr':
        return <img src={gr} alt="gr" />
      case 'bg':
        return <img src={bg} alt="bg" />
      case 'ru':
        return <img src={ru} alt="ru" />
      // case "hr":
      //   return <img src={hr} alt="hr" />;
      case 'ae':
        return <img src={ae} alt="ae" />
      case 'in':
        return <img src={IN} alt="in" />

      default:
        break
    }
  }
  useEffect(() => {
    dispatch(getLanguages(true))
  }, [])
  useEffect(() => {
    dispatch(businessTypesData())
  }, [location.pathname])
  useEffect(() => {
    activeLanguageFlag(activeLanguage)
    i18n.changeLanguage(activeLanguage)
  }, [])

  const colorName =
    location.pathname != '/login' && location.pathname != '/register'
      ? '#ffffff'
      : '#000000'
  return (
    <Dropdown
      className="lng-img-mobile img-drop-width"
      nav
      isOpen={state.dropdownOpen}
      toggle={(e) => dropdownToggle(e)}
      style={{
        borderRadius: "6px",
        backgroundColor: colorName,
      }}
    >
      <DropdownToggle color="primary" caret nav>
        <img src={languageStatus} />
      </DropdownToggle>
      <DropdownMenu
        style={{ backgroundColor: '#ffffff' }}
        className={`nav-bar ${classNames(style.iconMenu)}`}
        right
      >
        {business.languagesData &&
          business.languagesData.map(({ name }, i) => {
            if (name == activeLanguage || activeLanguage.includes(name)) return
            return (
              <div key={i}>
                <DropdownItem
                  tag="a"
                  // key={i}
                  style={{ backgroundColor: '#ffffff' }}
                  className="cursor"
                  onClick={changeLanguage(name)}
                >
                  {/* <img src={lang} /> */}
                  {displayFlag(name)}
                </DropdownItem>
              </div>
            )
          })}
      </DropdownMenu>
    </Dropdown>
  )
}

import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";
import bgImage from "../../assets/img/bg.jpg";
import { Redirect, useHistory } from "react-router-dom";
import { businessTypes } from "../../redux/business/action";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../assets/css/custom.scss";
import beachImage from "../../assets/img/beach.jpg";

var title = [
  {
    id: 1,
    titleName: "beach",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
  {
    id: 2,
    titleName: "pool",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
  {
    id: 3,
    titleName: "restaurant",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
  {
    id: 4,
    titleName: "bar",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
  {
    id: 5,
    titleName: "club",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
];

const AdminWizard = (props) => {
  const verifyLocation = props.location.pathname;
  let history = useHistory();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const [businessData, setBusinessData] = useState([]);
  const [businessType, setBusinessType] = useState({
    beach: 1,
    pool: 1,
    restaurant: 1,
    bar: 1,
    club: 1,
  });
  const [lockStatus, setLockStatus] = useState(true);
  const [currentLocation, setCurrentLocation] = useState({
    defaultBeach: 0,
    defaultPool: 0,
    defaultRestaurant: 0,
    defaultBar: 0,
    defaultClub: 0,
  });
  useEffect(() => {
    let arr = [...title];
    if (business.businessLists) {
      arr = arr.map((item) => {
        const businessesOfThisType = business.businessLists.filter(
          (business) => business.type === item.titleName
        );
        return {
          ...item,
          value: item.value.map((value) => ({
            ...value,
            status: value.name < businessesOfThisType?.length,
          })),
        };
      });

      if (business.businessLists) {
        let countBeach = [];
        let countPool = [];
        let countRestaurant = [];
        let countBar = [];
        let countClub = [];
        business.businessLists.forEach((items, k) => {
          if (items.type === "beach") {
            countBeach.push(items.id);
          }
          if (items.type === "pool") {
            countPool.push(items.id);
          }
          if (items.type === "restaurant") {
            countRestaurant.push(items.id);
          }
          if (items.type === "bar") {
            countBar.push(items.id);
          }
          if (items.type === "club") {
            countClub.push(items.id);
          }
        });

        setBusinessType({
          ...businessType,
          beach: countBeach.length,
          pool: countPool.length,
          restaurant: countRestaurant.length,
          bar: countBar.length,
          club: countClub.length,
        });

        setCurrentLocation({
          defaultBeach: countBeach.length,
          defaultPool: countPool.length,
          defaultRestaurant: countRestaurant.length,
          defaultBar: countBar.length,
          defaultClub: countClub.length,
        });
      }
      setBusinessData(arr);
    } else {
      setBusinessData(arr);
    }
  }, [business]);

  const dispatch = useDispatch();

  const lockButtonStatus = (name, value) => {
    let status = true;
    switch (name) {
      case "beach":
        status = value === currentLocation.defaultBeach;
        break;
      case "pool":
        status = value === currentLocation.defaultPool;
        break;
      case "restaurant":
        status = value === currentLocation.defaultRestaurant;
        break;
      case "bar":
        status = value === currentLocation.defaultBar;
        break;
      case "club":
        status = value === currentLocation.defaultClub;
        break;
      default: {
        break;
      }
    }
    return status;
  };

  const handleChange = (name) => (event) => {
    let status = lockButtonStatus(name, event.target.value * 1);
    setLockStatus(status);
    setBusinessType({ ...businessType, [name]: event.target.value * 1 });

    switch (name) {
      case "beach": {
        setCurrentLocation({
          ...currentLocation,
          defaultBeach: event.target.value * 1,
        });
        break;
      }
      case "pool": {
        setCurrentLocation({
          ...currentLocation,
          defaultPool: event.target.value * 1,
        });
        break;
      }
      case "restaurant": {
        setCurrentLocation({
          ...currentLocation,
          defaultRestaurant: event.target.value * 1,
        });
        break;
      }
      case "bar": {
        setCurrentLocation({
          ...currentLocation,
          defaultBar: event.target.value * 1,
        });
        break;
      }
      case "club": {
        setCurrentLocation({
          ...currentLocation,
          defaultClub: event.target.value * 1,
        });
        break;
      }
      default: {
        return 0;
      }
    }
  };

  const next = () => {
    dispatch(businessTypes(businessType, history));
  };

  const _getInputValue = (businessType) => {
    switch (businessType) {
      case "beach": {
        return currentLocation.defaultBeach;
      }
      case "pool": {
        return currentLocation.defaultPool;
      }
      case "restaurant": {
        return currentLocation.defaultRestaurant;
      }
      case "bar": {
        return currentLocation.defaultBar;
      }
      case "club": {
        return currentLocation.defaultClub;
      }
      default: {
        return 0;
      }
    }
  };

  const _renderBusinessData = () => {
    return businessData.map((list, i) => {
      return (
        <div className="form-row px-4" key={i}>
          <FormGroup className="col-md-9">
            <p
              className={
                verifyLocation === "/wizard"
                  ? "pt-2 text-white"
                  : "pt-2 text-black"
              }
            >
              {t(list.titleName)}
            </p>
          </FormGroup>
          <FormGroup className="col-md-3 admin-wizard">
            <Input
              value={_getInputValue(list.titleName)}
              type="select"
              name="select"
              id="inputState"
              className={"text-black"}
              onChange={handleChange(`${list.titleName}`)}
            >
              {list.value.map((item) => {
                return (
                  <option
                    className={item.status ? "light-gray" : "text-dark"}
                    disabled={!!item.status}
                    key={item.name}
                  >
                    {item.name}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
        </div>
      );
    });
  };

  return (
    <>
      {business.accessToken ? (
        <>
          <div
            className="content admin-container"
            style={{
              backgroundImage: `url(${beachImage})`,
            }}
          >
            <Row className="d-flex justify-content-center">
              <Col md="5 admin-form-container">
                <div className="mb-5">
                  <h4
                    className={
                      verifyLocation === "/wizard"
                        ? "text-center mb-5 text-white px-4 pt-5"
                        : "text-center mb-5 text-black px-4 pt-5"
                    }
                  >
                    {t("businessLocationType")}
                  </h4>
                </div>
                <Form>{_renderBusinessData()}</Form>
                <div className="d-flex justify-content-center px-4">
                  <Button
                    style={{
                      background: "#7557f9",
                      width: "100%",
                      fontWeight: "bold",
                    }}
                    color="success"
                    onClick={next}
                    disabled={lockStatus}
                  >
                    {t("Confirm")}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          />
        </>
      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};

export default AdminWizard;

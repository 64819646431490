import React, { Fragment, useEffect, useState } from "react";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Plans from "../Pages/adminPage/Plans";
import Plans2 from "../Pages/adminPage/Plans2";

const PlanPage = () => {
  const dispatch = useDispatch();
  const business = useSelector(({ business }) => business);
  const [businessValue, setBusinessValue] = useState([]);

  useEffect(() => {}, [business.filterBusinessList]);

  return (
    <Fragment>
      {
        // business.filterBusinessList.type=='beach' || business.filterBusinessList.type=='pool' ?(
        //   <Plans></Plans>
        // ):(
        //   <Plans2></Plans2>
        // )
      }
      <Plans2 />
    </Fragment>
  );
};

export default PlanPage;

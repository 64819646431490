import {
  rejectPromiseAction,
  resolvePromiseAction,
} from "@adobe/redux-saga-promise";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  actionTypes,
  businessTypes,
  businessTypesData,
  createBsuinessProfile,
  getBusinessListGetById,
  getBusinessProfileById,
  loginFromAdminAction,
  saveBusinessName,
} from "./action";
import API from "./api";

const api = new API();
const loginRequest = (business) => {
  return api.loginRequests(business.loginValue);
};
const existBusinessRequest = (value) => {
  return api.existBusinessRequests(value);
};

function* login({ history, resolve, reject }) {
  try {
    const business = yield select((store) => store.business);
    const result1 = yield call(loginRequest, business);
    if (result1.data?.LoginClient?.token) {
      const { token } = result1.data.LoginClient;
      const { id, super_admin } = result1.data.LoginClient.result;
      yield put({
        type: actionTypes.RESULT_LOGIN_DATA,
        superAdmin: parseInt(super_admin) === 1,
        value: token,
        id: id,
      });
      if (parseInt(super_admin) !== 1) {
        const result2 = yield call(existBusinessRequest, id);
        if (result2.data) {
          if (result2.data.businessCount === 0) {
            history.push("/admin/wizard");
          } else {
            history.push("/admin/business");
          }
        }
      } else {
        history.push("/super_admin");
      }
    } else {
      reject(result1.message);
      yield put({
        type: actionTypes.RESULT_LOGIN_FAIL_DATA,
        value: false,
        message: result1.message,
      });
      yield delay(3000);
      yield put({ type: actionTypes.RESULT_DELAY_NOTIFICATION });
    }
    resolve();
  } catch (e) { }
}

function* loginFromAdmin(action) {
  try {
    const { history, loginData } = action.payload;
    const business = yield select((store) => store.business);
    //const result1 = yield call(loginRequest, business);
    const { token } = loginData;
    const { id, super_admin } = loginData.user;
    yield put({
      type: actionTypes.RESULT_ADMIN_LOGIN_DATA,
      superAdmin: parseInt(super_admin) === 1,
      value: token,
      id: id,
    });
    yield call(resolvePromiseAction, action, null);
  } catch (e) { }
}

///////////////////
const getCounryRequest = () => {
  return api.getCounryRequests();
};

function* getCounry() {
  try {
    const result = yield call(getCounryRequest);
    if (result.data) {
      yield put({
        type: actionTypes.RESULT_COUNTRY_DATA,
        value: result.data.country,
      });
    }
  } catch (e) { }
}

const registerRequest = (value) => {
  return api.registerRequests(value);
};

function* register({ history }) {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(registerRequest, business.registerValue);
    if (result) {
      if (result.data) {
        yield put({
          type: actionTypes.RESULT_REGISTER_SUCCESS_DATA,
          value: true,
          message: "Please wait until your registration is approved.",
        });
        yield delay(3000);
        yield put({
          type: actionTypes.RESULT_DELAY_NOTIFICATION,
        });
        history.push("/login");
      } else {
        let messageRegister = result.message.split(".");
        let messageValue = "";
        if (messageRegister[3] === "PHONE") {
          messageValue = "Phone numbers should not be duplicated.";
        } else {
          messageValue = "Email should not be duplicated.";
        }
        yield put({
          type: actionTypes.RESULT_REGISTER_FAIL_DATA,
          value: true,
          message: messageValue,
        });
        yield delay(3000);
        yield put({
          type: actionTypes.RESULT_DELAY_NOTIFICATION,
        });
      }
    }
  } catch (e) { }
}

////////////////////////
const businessTypesRequest = (value, type) => {
  return api.businessTypesRequests(value, type);
};

function* businessTypesAction(action) {
  try {
    // debugger;

    const { value, name, history, business_type } = action.payload;
    yield put({
      type: actionTypes.SET_BUSINESS_TYPE,
      value: value,
    });
    yield put({
      type: actionTypes.SET_LOCATION_NAME,
      value: name,
    });

    const business = yield select((store) => store.business);

    const result = yield call(businessTypesRequest, business, business_type);
    if (result) {
      yield put({
        type: actionTypes.RESULT_BUSINESS_DATA,
        value: result.data.SaveBusinessTypes.businesses,
      });
      history.push("/admin/wizard");
      yield call(resolvePromiseAction, action, result);
    }
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

//////////////////////////////
const getBusinessTypeDataRequest = (value) => {
  return api.getBusinessTypeDataRequests(value);
};

function* getBusinessTypeData(action) {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(getBusinessTypeDataRequest, business);
    if (result) {
      yield put({
        type: actionTypes.RESULT_BUSINESS_DATA,
        value: result.data.business,
      });
      yield put({
        type: actionTypes.FILTER_BUSINESS_LIST_ACTION,
        value: business?.filterBusinessList
          ? business?.filterBusinessList
          : result.data.business[0],
      });
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) { }
}
function* getBusinessProfileGetById(action) {
  const id = action.payload;
  try {
    const business = yield select((store) => store.business);
    const result = yield call(getBusinessTypeDataRequest, business);

    if (result) {
      yield put({
        type: actionTypes.RESULT_BUSINESS_DATA,
        value: result.data.business,
      });

      const filterData = result.data.business.find(
        (ele) => Number(ele.id) === Number(id)
      );

      yield put({
        type: actionTypes.FILTER_BUSINESS_LIST_ACTION,
        value: filterData ? filterData : business?.filterBusinessList,
      });
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) { }
}
function* getBusinessList(action) {
  const id = action.payload;

  const business = yield select((store) => store.business);
  const filterData = business?.businessLists.filter((ele) => ele.id === id);
  if (filterData) {
    yield put({
      type: actionTypes.FILTER_BUSINESS_LIST_ACTION,
      value: filterData[0],
    });
  }
  yield call(resolvePromiseAction, action, filterData);
}

///////////////////////////////
const createBusinessActionRequest = (values) => {
  return api.createBusinessActionRequests(values);
};

function* createBusinessAction(action) {
  try {
    const values = action.payload;
    const result = yield call(createBusinessActionRequest, values);
    if (result) {
      if (result.message) {
        values.reject(result.message);
      } else {
        yield put({
          type: actionTypes.RESULT_CREATE_BUSINESS_ACTION,
          value: result.data.businessUpdate,
        });
        const business = yield select((store) => store.business);
        const resultData = yield call(getBusinessTypeDataRequest, business);
        if (resultData) {
          yield put({
            type: actionTypes.RESULT_BUSINESS_DATA,
            value: resultData.data.business,
          });
        }
      }
      yield call(resolvePromiseAction, action, result);
    }
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

///////////////////////////////
const saveBusinessNameActionRequest = (values) => {
  return api.saveBusinessName(values);
};

function* saveBusinessNameAction(action) {
  try {
    const values = action.payload;
    const result = yield call(saveBusinessNameActionRequest, values);
    if (result) {
      if (result.message) {
        values.reject(result.message);
      } else {
        yield put({
          type: actionTypes.RESULT_SAVE_BUSINESS_NAME,
          value: result.data.businessUpdate.location_name,
        });
      }
      yield call(resolvePromiseAction, action, result);
    }
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

///////////////////////////////////
const getCityListActionRequest = (value) => {
  return api.getCityListActionRequests(value);
};

function* getCityListAction(id) {
  try {
    const result = yield call(getCityListActionRequest, id);
    if (result) {
      yield put({
        type: actionTypes.RESULT_GET_CITY_LIST,
        value: result.data.city,
      });
    }
  } catch (e) { }
}


//////////////////////////////////
const getSmsCountActionRequest = () => {
  return api.getSmsDataActionRequest();
};

function* getSmsCountAction() {
  try {
    const result = yield call(getSmsCountActionRequest);
    if (result) {
      yield put({
        type: actionTypes.GET_SMS_COUNT_DATA,
        value: null,
      });
    }
  } catch (e) { }
}


///////////////////////////
const savePlanActionsRequest = (value, couponId, id, price) => {
  return api.savePlanActionsRequests(value, couponId, id, price);
};

function* savePlanActions(value, couponId, id, price, resolve, reject) {
  try {
    const result = yield call(
      savePlanActionsRequest,
      value,
      couponId,
      id,
      price
    );
    if (result.message) {
      value.reject(result.message);
    } else {
      value.resolve("success");
    }
  } catch (e) { }
}

//////////////////////////
const couponValidRequest = (value, business_id, validateOnly) => {
  return api.couponValidRequests(value, business_id, validateOnly);
};

function* couponValid({ value, business_id, validateOnly, resolve, reject }) {
  try {
    const result = yield call(
      couponValidRequest,
      value,
      business_id,
      validateOnly
    );
    if (result.message) {
      reject(result.message);
    } else {
      resolve(result);
    }
  } catch (e) { }
}

////////////////////////////
const getPlanInformationActionRequest = (business_id) => {
  return api.getPlanInformationActionRequests(business_id);
};

function* getPlanInformationAction(business_id) {
  try {
    const result = yield call(getPlanInformationActionRequest, business_id);
    if (result.message) {
    } else {
      yield put({
        type: actionTypes.RESULT_GET_COUPON_BY_BUSINESSID,
        value: result.data.validated_coupons,
      });
    }
  } catch (e) { }
}

////////////////////////////
const getPlanDataActionRequest = (business_id) => {
  return api.getPlanDataActionRequests(business_id);
};
const getCouponListRequest = (couponId) => {
  return api.getCouponListRequests(couponId);
};

function* getPlanDataAction({ business_id, resolve, reject }) {
  try {
    const result = yield call(getPlanDataActionRequest, business_id);
    if (result.message) {
    } else {
      reject(result.data);
      yield put({
        type: actionTypes.RESULT_GET_PLAN,
        value: result.data.plan_changes,
      });
      if (result.data.plan_changes.length > 0) {
        let couponId = result.data.plan_changes[0].plan_config.couponIds;
        const result1 = yield call(getCouponListRequest, couponId);
        if (result1.message) {
        } else {
          yield put({
            type: actionTypes.RESULT_ALL_COUPONS,
            value: result1.data.all_coupons,
          });
          resolve(result.data.plan_changes, result1.data.all_coupons);
        }
      } else {
        yield put({
          type: actionTypes.RESULT_ALL_COUPONS,
          value: [],
        });
      }
    }
  } catch (e) { }
}

////////////////////////////
const changeStatusActionRequest = (businessId, status) => {
  return api.changeStatusActionRequests(businessId, status);
};

function* changeStatusAction({ businessId, status }) {
  try {
    const result = yield call(changeStatusActionRequest, businessId, status);
  } catch (e) { }
}

////////////////////////////
const removeBusinessActionRequest = (id) => {
  return api.removeBusinessActionRequests(id);
};

function* removeBusiness({ id }) {
  try {
    const result = yield call(removeBusinessActionRequest, id);
  } catch (e) { }
}

// get Languages
const getLanguagesDataRequest = () => {
  return api.getLanguagesDataActionRequests();
};

function* geLanguageseData(action) {
  try {
    // const business = yield select((store) => store.business);

    const result = yield call(getLanguagesDataRequest);

    if (result && result.data) {
      let filterData = result.data.language.filter(
        (lang) => lang.active == true
      );
      yield put({
        type: actionTypes.RESULT_GET_LANGUAGES,
        value: filterData,
      });
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) { }
}
export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.LOGIN_ACTION, login),
    takeLatest(actionTypes.GET_COUNTRY_ACTION, getCounry),
    takeLatest(actionTypes.REGISTER_ACTION, register),
    takeLatest(businessTypes, businessTypesAction),
    takeLatest(businessTypesData, getBusinessTypeData),
    takeLatest(getBusinessListGetById, getBusinessList),
    takeLatest(getBusinessProfileById, getBusinessProfileGetById),


    takeLatest(createBsuinessProfile, createBusinessAction),
    takeLatest(actionTypes.GET_CITY_LIST, getCityListAction),
    takeLatest(actionTypes.SAVE_PLAN_ACTION, savePlanActions),
    takeLatest(actionTypes.CUOPON_CODE_VALID, couponValid),
    takeLatest(actionTypes.GET_PLAN_INFORMATION, getPlanInformationAction),
    takeLatest(actionTypes.GET_PLAN_DATA, getPlanDataAction),
    takeLatest(actionTypes.CHANGE_PUBLISH_STATUS, changeStatusAction),
    takeLatest(actionTypes.REMOVE_BUSINESS, removeBusiness),
    takeLatest(actionTypes.GET_LANGUAGES, geLanguageseData),

    takeLatest(saveBusinessName, saveBusinessNameAction),
    takeLatest(loginFromAdminAction, loginFromAdmin),

    takeLatest(actionTypes.GET_SMS_COUNT_DATA, getSmsCountAction),

  ]);
}

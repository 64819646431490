import { actionTypes } from "./action";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const reducer = persistReducer(
  {
    storage,
    key: "business",
    whitelist: [
      "accessToken",
      "countryData",
      "businessLists",
      "userId",
      "language",
      "businessValues",
      "filterBusinessList",
      "businessName",
      "planDataById",
      "allCoupons",
      "superAdmin",
      "languagesData",
    ],
  },
  (state = initState, action) => {
    const newState = { ...state };

    switch (action.type) {
      case actionTypes.INITIAL_STATE:
        return { ...state };
      case actionTypes.LOGIN_VALUE_ACTION:
        return { ...state, loginValue: action.value };
      case actionTypes.RESULT_LOGIN_DATA:
        return {
          ...state,
          accessToken: action.value,
          loginStatus: false,
          userId: action.id,
          superAdmin: action.superAdmin,
        };
      case actionTypes.RESULT_ADMIN_LOGIN_DATA:
        return {
          ...state,
          accessToken: action.value,
          loginStatus: false,
          userId: action.id,
          superAdmin: action.superAdmin,
          filterBusinessList: "",
        };

      case actionTypes.REGISTER_VALUE_ACTION:
        return { ...state, registerValue: action.value };
      case actionTypes.RESULT_COUNTRY_DATA:
        return { ...state, countryData: action.value };
      case actionTypes.RESULT_LOGIN_FAIL_DATA:
        return { ...state, loginStatus: true, loginMessage: action.message };
      case actionTypes.RESULT_REGISTER_FAIL_DATA:
        return {
          ...state,
          loginStatus: action.value,
          registerMessage: action.message,
        };

      case actionTypes.RESULT_REGISTER_SUCCESS_DATA:
        return {
          ...state,
          loginStatus: action.value,
          registerMessage: action.message,
        };
      case actionTypes.SET_BUSINESS_TYPE:
        return { ...state, businessType: action.value };
      // location name set
      case actionTypes.SET_LOCATION_NAME:
        return { ...state, location_name: action.value };
      case actionTypes.RESULT_BUSINESS_DATA:
        return { ...state, businessLists: action.value };
      case actionTypes.CHANGE_LANGUAGE:
        return { ...state, language: action.value };
      case actionTypes.LOGOUT_ACTION:
        return { ...state, accessToken: "" };
      case actionTypes.FILTER_BUSINESS_LIST_ACTION: {
        return {
          ...state,
          filterBusinessList: action.value,
          businessData: action.value,
        };
      }
      case actionTypes.RESULT_CREATE_BUSINESS_ACTION:
        return { ...state, filterBusinessList: action.value };
      case actionTypes.RESULT_SAVE_BUSINESS_NAME:
        return {
          ...state,
          filterBusinessList: {
            ...state.filterBusinessList,
            location_name: action.value,
          },
        };
      case actionTypes.CREATE_BUSINESS_PROFILE:
        return { ...state, businessValues: action.value };
      case actionTypes.RESULT_GET_CITY_LIST:
        return { ...state, cityLists: action.value };
      case actionTypes.BUSINESS_NAME:
        return { ...state, businessName: action.value };
      case actionTypes.RESULT_VALID_CUOPON:
        return { ...state, businessName: action.value };
      case actionTypes.RESULT_GET_COUPON_BY_BUSINESSID:
        return { ...state, planData: action.value };
      case actionTypes.RESULT_GET_PLAN:
        return { ...state, planDataById: action.value };
      // get all langauges
      case actionTypes.RESULT_GET_LANGUAGES:
        return { ...state, languagesData: action.value };

      case actionTypes.RESULT_ALL_COUPONS:
        return { ...state, allCoupons: action.value };
      case actionTypes.RESULT_DELAY_NOTIFICATION:
        return { ...state, loginStatus: false };
      case actionTypes.LOCATION_NAME_UPDATE:
        return { ...state, locationNameState: action.value };
      case "BUSINESS_DOCUMENT_UPLOADED": {
        const { type, filename } = action.payload;

        newState.businessLists = newState.businessLists.map((business) => {
          if (business.id !== newState.filterBusinessList.id) {
            return business;
          }

          return {
            ...business,
            id_card_file_name:
              type === "idCard" ? filename : business.id_card_file_name,
            additional_document_file_name:
              type === "additionalDocument"
                ? filename
                : business.additional_document_file_name,
            identification_file_name:
              type === "companyIdentification"
                ? filename
                : business.identification_file_name,
          };
        });
        return newState;
      }
      case "CREATE_STRIPE_ACCOUNT": {
        newState.savingStripeAccount = true;
        return newState;
      }
      case "CREATE_STRIPE_ACCOUNT_FULFILLED": {
        const { acceptedToS } = action.payload;
        newState.savingStripeAccount = false;
        newState.stripeToSRequired = true;
        if (acceptedToS) {
          newState.acceptedToS = true;
        }
        return newState;
      }
      case "CREATE_STRIPE_ACCOUNT_REJECTED": {
        newState.savingStripeAccount = false;
        newState.stripeToSRequired = false;
        return newState;
      }
      case "GET_STRIPE_TOS_STATUS": {
        newState.stripeToSRequired = false;
        return newState;
      }
      case "GET_STRIPE_TOS_STATUS_FULFILLED": {
        const { acceptedToS, stripeToSRequired } = action.payload;
        newState.stripeToSRequired = stripeToSRequired;
        newState.acceptedToS = acceptedToS;
        return newState;
      }
      case "GET_STRIPE_TOS_STATUS_REJECTED": {
        newState.stripeToSRequired = true;
        return newState;
      }
      case "AGREE_STRIPE_TOS_FULFILLED": {
        newState.stripeToSRequired = false;
        newState.acceptedToS = true;
        return newState;
      }
      case "AGREE_STRIPE_TOS": {
        newState.acceptedToS = true;
        return newState;
      }
      case "AGREE_STRIPE_TOS_REJECTED": {
        newState.acceptedToS = false;
        return newState;
      }
      case actionTypes.REMOVE_BUSINESS: {
        return {
          ...state,
          businessLists: state.businessLists.filter((item) => {
            return item.id != action.id;
          }),
        };
      }
      default:
        return state;
    }
  }
);

export const initState = {
  initials: null,
  superAdmin: false,
  loginValue: "",
  accessToken: "",
  registerValue: "",
  countryData: "",
  loginStatus: false,
  userId: "",
  registerState: "true",
  loginMessage: "",
  businessType: "",
  businessLists: "",
  language: "en",
  filterBusinessList: "",
  businessValues: "",
  cityLists: [],
  businessData: "",
  businessName: "",
  planData: "",
  planDataById: [],
  allCoupons: [],
  registerMessage: "",
  locationNameState: "",
  savingStripeAccount: false,
  acceptedToS: false,
  stripeToSRequired: false,
  location_name: null,
};

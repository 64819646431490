import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import axios from "axios";
import { setContext } from "@apollo/client/link/context";
import { GRAPHQL_URL } from "../../utils/constants.js";
import { calls } from "../../utils/calls.js";
//import swal from "sweetalert";
import { createNotification } from "../../utils/utils";

const link = new HttpLink({
  uri: `${GRAPHQL_URL}`,
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const authLink = setContext(async (_, { headers }) => {
  let token;
  let superAdminSessionToken = localStorage.getItem("SHADOWED_USER_TOKEN");
  let localState = localStorage.getItem("persist:business");
  if (localState && typeof JSON.parse(localState) === "object") {
    localState = JSON.parse(localState);
    token = JSON.parse(localState.accessToken);

    return {
      headers: {
        ...headers,
        authorization: `${superAdminSessionToken !== null && superAdminSessionToken.length > 0
          ? superAdminSessionToken
          : token
          }`,
      },
    };
  }
});
const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions,
});

export default class API {
  loginRequests(value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation login{
						LoginClient(input: {email:"${value.email}"
							password:"${value.password}"}){
							token
							result{
								super_admin
								id
							}
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Login",
            message: "Successfully Logged in !",
          });
          //swal("Successfully Logged in !");
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  getCounryRequests() {
    try {
      let data = client
        .mutate({
          mutation: gql`
            query countries {
              country(where: {}) {
                id
                name
              }
            }
          `,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  existBusinessRequests(value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query businessCount{
						businessCount(where: {
							client_id:"${value}"
						})
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  registerRequests(value) {
    let arr = [];
    if (value.interested_in.length > 0) {
      value.interested_in.forEach((item) => {
        arr.push(`{
          value:"${item.value}"
          label:"${item.label}"
      }`);
      });
    }
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation register{
						RegisterClient(input: {
              terms_condition_version:"${value.terms_condition_version}"
              terms_condition_accepted_date:"${value.terms_condition_accepted_date}"
							first_name:"${value.firstName}"
							last_name:"${value.lastName}"
							email:"${value.email}"
							phone:"${value.phoneNumber}"
							password:"${value.password}"
							country_id:${value.country}
							position:"${value.position}"
							interested_in:[${arr.join("")}]
						}){
							id
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Register",
            message:
              "Your account has been succesfully registered. You will receive a confirmation e-mail after we review your data",
          });
          //swal("Your account has been succesfully registered. You will receive a confirmation e-mail after we review your data");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Register",
            message: "Not registered",
          });
          //swal("Not registered");
          return err;
        });
      return data;
    } catch (err) { }
  }

  businessTypesRequests(value, type) {
    let location_name = JSON.stringify(value.location_name); //stringify the location name
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation saveBusinessTypes{
						SaveBusinessTypes(input: {
							beach:${value.businessType.beach}
							pool:${value.businessType.pool}
							restaurant:${value.businessType.restaurant}
							bar:${value.businessType.bar}
							club:${value.businessType.club}
							location_name:${location_name}
              business_type :  "${type}"
						}){
							businesses{
								id,
								number,
								name,
								image,
								description,
								status,
								type
								representative_first_name,
								representative_last_name,
								representative_birth_date,
								representative_phone,
								representative_email,
								zipcode,
								address,
								reg_com_number,
								capital_social,
								bank_name,
								bank_account,
								bank_routing_number,
								bank_account_holder_name,
								vat,
								vat_number,
								cui_number,
								id_card_file_name,
								identification_file_name,
								additional_document_file_name,
								country_id
								city_id
								location_name
								client_id
								stripe_account_id
								stripe_approved
							}
						}
					}
				`,
        })
        .then((data) => {
          //createNotification({type:'success',title:'Update', message:'Successfully updated '});
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          //createNotification({type:'danger',title:'Update', message:'Not updated'});
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  getBusinessTypeDataRequests(value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query businesslIST{
						business(where: {
							client_id:${value.userId}
						}
							order:"location_name"
						)
						{
							id,
							number,
							name,
							image,
							description,
							status,
							type,
							representative_first_name,
							representative_last_name,
							representative_birth_date,
							representative_phone,
							representative_email,
							zipcode,
							address,
							reg_com_number,
							capital_social,
							bank_name,
							bank_account,
							bank_routing_number,
							bank_account_holder_name,
							vat,
							vat_number,
							cui_number,
							id_card_file_name,
							identification_file_name,
							additional_document_file_name,
							country_id
							city_id
							country{
								name
							}
							city{
								name
							}
							currency
							location_name
							slug
							stripe_account_id
							stripe_approved

						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  saveBusinessName(value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation businessUpdate{
						businessUpdate(business:{
							id:${value.id}
							location_name:"${value.location_name}"
						})
						{
							id
							type,
							name,
							location_name
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  createBusinessActionRequests(value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation businessUpdate{
						businessUpdate(business:{
							id:${value.id}
							name:"${value.value.name}"
							location_name:"${value.value.location_name}"
							description:"${value.value.description}"
							representative_first_name:"${value.value.representative_first_name}"
							representative_last_name:"${value.value.representative_last_name}"
							representative_birth_date:"${value.countryCityValue.birthdatData}"
							representative_phone:"${value.value.representative_phone}"
							representative_email:"${value.value.representative_email}"
							zipcode:"${value.value.zipcode}"
							address:"${value.value.address}"
							reg_com_number:"${value.value.reg_com_number}"
							capital_social:"${value.value.capital_social}"
							cui_number:"${value.value.cui_number}"
							vat:${value.value.vat}
							vat_number:"${value.value.vat_number}"
							bank_name:"${value.value.bank_name}"
							bank_account:"${value.value.bank_account}"
							bank_routing_number:"${value.value.bank_routing_number}"
							bank_account_holder_name:"${value.value.bank_account_holder_name}",
							city_id:${value.value.city_id}
							currency:"${value.value.currency}"
							country_id:${value.value.country_id},
							id_card_file_name : "${value.value.id_card_file_name}"
							additional_document_file_name : "${value.value.additional_document_file_name}"
							identification_file_name : "${value.value.identification_file_name}"
						})
						{
							id
							type,
							name,
							description,
							representative_first_name,
							representative_last_name,
							representative_birth_date,
							representative_phone,
							representative_email,
							zipcode,
							address,
							city_id,
							status,
							reg_com_number,
							capital_social,
							cui_number,
							vat,
							vat_number,
							bank_name,
							bank_account,
							bank_routing_number,
							bank_account_holder_name
							city_id
							country_id
							currency
							location_name
							representative_birth_date
							country{
								name
							}
							city{
								name
							}
							slug
							stripe_account_id
							stripe_approved
						}
					}
				`,
        })
        .then((data) => {
          if (value.callback) {
            value.callback();
          }
          createNotification({
            type: "success",
            title: "Business Update",
            message: "Business updated successfully!",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Business Update",
            message: "Business Not updated",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  removeBusinessActionRequests(value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation DeleteBusinessTypes{
						DeleteBusinessTypes(
							input:{
								id:${value}
							}
						){
							businesses { id }
						}
					}
				`,
        })
        .then((data) => {
          if (value.callback) {
            value.callback();
          }
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  getSmsDataActionRequest(id) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query Business {
            business(where: {id:10}){
              id
              active_plan
          }
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }

  }

  getCityListActionRequests(value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query getCityList{
						city(
							where:{country_id:${value.value}})
						{
							id
							name
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  savePlanActionsRequests(value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation plan_changesCreate{
						plan_changesCreate(plan_changes: {
							business_id:${value.business_id}
							plan_config:{type:"${value.value.plan}",subType:"${value.value.subPlan}",allow_print:${value.value.printDirectly},couponIds:[${value.couponId}],price:${value.price}}
							status:"active"
						}){
							id
							plan_config
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Planned change",
            message: "Planeed updated successfully!",
          });
          //swal("Successfully updated !");

          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Planned change",
            message: err.message,
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  couponValidRequests(value, business_id, validateOnly) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation businessCouponCreate{
						CreateBusinessCoupon(
							input:{
								business_id:${business_id}
								code:"${value}"
								validate_only: ${validateOnly ? true : false}
							}
						){
							id
							coupon_id
							name
							value
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  getPlanInformationActionRequests(business_id) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query validated_coupons{
						validated_coupons(
							where:{business_id:${business_id.business_id}}){
							id
							coupon_id
							coupon
							value
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  getPlanDataActionRequests(business_id) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query plan_changes{
						plan_changes(
							where:{
								business_id:${business_id}
							},
							order: "reverse:created_at"
						){
							plan_config
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  getCouponListRequests(couponId) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query all_coupons{
						all_coupons(
							where:{id:[${couponId}]}){
							coupon
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  changeStatusActionRequests(businessId, status) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation businessUpdate{
						businessUpdate(business:{
							id:${businessId}
							status:"${status}"
						}){
							id
							status
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Status Update",
            message: "Status updated successfully!",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Status Update",
            message: "Status Not updated",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  sendUserAcceptedEmail(businessId) {
    axios(calls.sendUserAcceptedEmail(businessId)).catch(() => { });
  }

  sendUserRejectedEmail(businessId) {
    axios(calls.sendUserRejectedEmail(businessId)).catch(() => { });
  }

  getLanguagesDataActionRequests() {
    try {
      let data = client
        .mutate({
          mutation: gql`
            query language {
              language(where: {}) {
                id
                name
                long_name
                active
              }
            }
          `,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }
}

//

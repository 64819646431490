import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createFacility,
  deleteFacility,
  saveBusinessSettings,
} from "../../../../redux/option/action";
import { startLoading, stopLoading } from "../../../../redux/loader/action";

const ExecutiveChef = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [executiveChef, setExecutiveChef] = useState("");
  const [chefCheck, setChefCheck] = useState(false);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if (
      option.businessSettings.length > 0 &&
      option.businessSettings[0].executive_chef
    ) {
      setExecutiveChef(option.businessSettings[0].executive_chef);
      setChefCheck(true);
    } else {
      setExecutiveChef("");
      setChefCheck(false);
    }
  }, [option.businessSettings]);

  const handleChange = (event) => {
    if (event.target.checked === false) {
      setChefCheck(false);
      setExecutiveChef("");
      handleClick("false")
    } else {
      setChefCheck(true);
    }
  };

  const updateStateValue = (event) => {
    setDisableSave(false);
    if (!event.target.value) {
      setDisableSave(true);
    }
    setExecutiveChef(event.target.value);
  };

  const handleClick = (checkboxStatus) => {
    setTimeout(() => {
      let mutation = `{
        id : ${option.businessSettings[0].id}
        executive_chef: "${checkboxStatus && checkboxStatus == 'false' ? '' : executiveChef}"
      }`;
      dispatch(startLoading());
      dispatch(saveBusinessSettings(mutation)).then(data => {
        dispatch(stopLoading());
        setDisableSave(true);
      }).catch(e => {
        dispatch(stopLoading());
      });
    }, 100);
  };
  return (
    <React.Fragment>
      <Card className="options-card">
        <Row className="p-3">
          <Col md="10">
            <h5 className="option-title-main">{t("executive_chef")}</h5>
            <p className="option-title-sub mb-3">{t('executive_chef_description')}</p>
          </Col>
          <Col md="2">
            <Switch
              checked={chefCheck}
              name="exChef"
              onChange={handleChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Col>

          {chefCheck ? (
            <Col md="10">
              <div>
                <InputGroup className="mb-3 btn-input-group">
                  <FormControl
                    type="text"
                    id="phone"
                    name="phone"
                    onChange={updateStateValue}
                    value={executiveChef}
                    placeholder={t("executive_name")}
                  />
                  <InputGroup.Append>
                    <Button variant="primary" onClick={handleClick} disabled={disableSave}>
                      {t("Save")}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </Col>
          ) : null}
        </Row>
      </Card>
      <div className="card-divider-line"></div>
    </React.Fragment>
  );
};
export default ExecutiveChef;

/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { PureComponent } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
// react plugin for creating notifications
import NotificationAlert from 'react-notification-alert';

// core components
import AdminNavbar from '../components/Navbars/AdminNavbar.js';
import Sidebar from '../components/Sidebar/Sidebar.js';

import routes from '../routes.js';
import PanelHeader from '../components/PanelHeader/PanelHeader.js';

var ps;

class SuperAdmin extends PureComponent {
	state = {
		sidebarMini: true,
		backgroundColor: 'blue',
	};
	notificationAlert = React.createRef();
	mainPanel = React.createRef();

	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			document.documentElement.className += ' perfect-scrollbar-on';
			document.documentElement.classList.remove('perfect-scrollbar-off');
			ps = new PerfectScrollbar(this.mainPanel.current);
		}
	}

	componentDidUpdate(e) {
		if (e.history.action === 'PUSH') {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.mainPanel.current.scrollTop = 0;
		}
	}

	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps.destroy();
			document.documentElement.className += ' perfect-scrollbar-off';
			document.documentElement.classList.remove('perfect-scrollbar-on');
		}
	}

	minimizeSidebar = () => {
		var message = 'Sidebar mini ';
		if (document.body.classList.contains('sidebar-mini')) {
			this.setState({ sidebarMini: false });
			message += 'deactivated...';
		} else {
			this.setState({ sidebarMini: true });
			message += 'activated...';
		}
		document.body.classList.toggle('sidebar-mini');
		var options = {};
		options = {
			place: 'tr',
			message: message,
			type: 'info',
			icon: 'now-ui-icons ui-1_bell-53',
			autoDismiss: 7,
		};
		// this.notificationAlert.current.notificationAlert(options);
	};
	handleColorClick = (color) => {
		this.setState({ backgroundColor: color });
	};
	getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return this.getRoutes(prop.views);
			}
			if (prop.layout === '/super_admin') {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = this.getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (
					window.location.pathname.indexOf(
						routes[i].layout + routes[i].path
					) !== -1
				) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};

	render() {
		return (
			<div className="wrapper">
				<NotificationAlert ref={this.notificationAlert} />

				<Sidebar
					{...this.props}
					routes={routes.filter(route => route.layout === '/super_admin')}
					minimizeSidebar={this.minimizeSidebar}
					backgroundColor={this.state.backgroundColor}
				/>
				<AdminNavbar
					{...this.props}
					brandText={this.getActiveRoute(routes)}
				/>
				<div className="main-panel" ref={this.mainPanel}>
					<PanelHeader size="sm" />
					<Switch>
						{this.getRoutes(routes)}
						<Redirect from="/super_admin" to="/super_admin/locations" />
					</Switch>
				</div>

			</div>
		);
	}
}

export default SuperAdmin;

import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar-multiday';
import { /*Button,*/ CardBody, } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveClosedDay } from '../../../../redux/grid/action';

const CloseDayComponent = ({ businessId }) => {
	const dispatch = useDispatch();
	const grid = useSelector(({ grid }) => grid);
	useEffect(() => {
		if (grid.getActivityPeriod.length > 0) {
			let data = grid.getActivityPeriod[0].closed_days;
			if (data?.length > 0) {
				let arr = [];
				data.map((item, index) => {
					arr.push(item.closed_day);
				});
				const sortArr = arr.sort((a, b) => new Date(a) - new Date(b));
				setDays(sortArr);
			}
		} else {
			setDays([]);
		}
	}, [grid.getActivityPeriod]);
	const { t } = useTranslation();
	const [days, setDays] = useState([]);
	let sortArr = [];
	const reactToChange = (ob) => {
		if (ob.selected.length > 0) {
			let arr = [];
			ob.selected.map((item, index) => {
				arr.push(item);
			});
			sortArr = arr.sort((a, b) => new Date(a) - new Date(b));
			setDays(sortArr);
		} else {
			sortArr = [];
			setDays([]);

		}
		dispatch(saveClosedDay(businessId, sortArr));
	};
	// const closedDayAction = () => {
	// 	dispatch(saveClosedDay(businessId, days));
	// };
	return (
		<CardBody className="p-0">
			<div className="row">
				<div className="col-md-10 mx-auto">
					<Calendar
						isMultiple={true}
						selected={days}
						onChange={reactToChange}
						DayComponent={<PositionDay />}
					/>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-md-12">
					<div className="d-flex justify-content-start flex-wrap" style={{ gap: "10px" }}>
						{days.length > 0 && days.map((item, index) => {
							return (
								<div key={index} className="top-calendar">
									<p>{item.split('T')[0]}</p>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			{

				/* days.length > 0 && (
					<div className="row">
						<div className="col-md-12">
							<div className="d-flex justify-content-end">
								<Button
									style={ { background: '#609' } }
									onClick={ closedDayAction }
									// disabled={lockButton}
									color="success">
									{ t('Save') }
								</Button>
							</div>
						</div>
					</div>
				) */

			}
		</CardBody>
	);
};

const PositionDay = props => {
	const onClick = (e) => {
		if (props.isInThePast) {
			e.stopPropagation()
		}
	}
	return (
		<div
			onClick={onClick}
			style={getInline(props)}
			className={getStyle(props)}
			disabled={props.isInThePast}
		>
			{props.label}
		</div>
	);
};

const getStyle = function ({ date, isSelected }) {
	return `${isSelected ? 'o_selected-current-channel-day' : ''} ${date.type}-day`
};

const getInline = ({ isToday, isInThePast }) => {
	const styleObj = {};
	if (isToday) {
		styleObj["background"] = '#6844f98c';
		styleObj["color"] = '#fff';
		styleObj["fontWeight"] = 900;
	} else if (isInThePast) {
		styleObj["background"] = '#e5e5e5';
	}
	return styleObj;
};

export default CloseDayComponent;
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const Invoice = ({ invoice }, ref) => {
    const [total, setTotal] = useState({});
    const { t } = useTranslation();
    useEffect(() => {
        let totalData = {};
        if(invoice?.price_data) {
            invoice.price_data.forEach(price => {
                totalData.priceTotal = parseFloat(totalData.priceTotal || 0) + parseFloat(price.price);
                totalData.vatTotal = parseFloat(totalData.vatTotal || 0) + ((parseFloat(price.total_price) - parseFloat(price.price)));
                totalData.grandTotal = parseFloat(totalData.grandTotal || 0) + parseFloat(price.total_price)
            })
        }
        setTotal(totalData)
    }, [invoice])
    return (
        <div className='invoice-page' ref={ref}>
            <div className="main-page">
                <div className="sub-page">
                    <table width="100%" height="100%" style={{ fontFamily: '"Poppins", sans-serif', borderSpacing: 0, margin: '0 auto', padding: '10px' }}>
                        <tbody><tr>
                            <td>
                                <table style={{ width: '100%', borderSpacing: 0 }}>
                                    <tbody><tr>
                                        <td width="60%">
                                            <a href="#">
                                                <img src="/logobun.png" width="64px" alt="Logo" />
                                            </a>
                                        </td>
                                        <td>
                                            <p style={{ margin: '7px 0px 0px 0px', padding: '0px', color: '#6A6A6A', fontSize: '12px' }}>{t("invoice_number")}</p>
                                            <p style={{ margin: '7px 0px 0px 0px', padding: '0px', color: '#6A6A6A', fontSize: '12px' }}>{t("issue_date")}</p>
                                        </td>
                                        <td>
                                            <h4 style={{ fontSize: '20px', fontWeight: 500, color: '#6744F7', margin: '0px', padding: '0px', textAlign: 'right' }}>{invoice?.invoice_id}</h4>
                                            <p style={{ color: '#000000', fontSize: '12px', margin: '0px', padding: '0px', textAlign: 'right' }}>{moment(invoice?.created_at).format("DD-MMM-YYYY")}</p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                            <tr>
                                <td>
                                    <table width="100%" style={{
                                        borderSpacing: 0,
                                        marginTop: "20px"
                                    }}>
                                        <tr>
                                            <td width="60%">
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    fontWeight: 600
                                                }}>{t("to_whom")}</p>
                                                <h5 style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#6744F7",
                                                    fontSize: "14px",
                                                    fontWeight: 500,
                                                    textTransform: "uppercase"
                                                }}>{invoice?.customer_first_name} {invoice?.customer_last_name}</h5>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                }}
                                                >
                                                    {invoice?.customer_city} {invoice?.customer_country}
                                                </p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#6A6A6A"
                                                }}>&nbsp;</p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#6A6A6A"
                                                }}>&nbsp;</p>
                                            </td>
                                            <td width="20%">
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                }}>{t("made_by")}</p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    height: "20px"
                                                }}>{t("Name")}:</p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    height: "20px"
                                                }}>CIF:</p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    height: "20px"
                                                }}>RC:</p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    height: "20px"
                                                }}>{t("address")}:</p>
                                            </td>
                                            <td width="30%">
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    height: "20px"
                                                }}>&nbsp;</p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    height: "20px",
                                                    fontWeight: 700
                                                }}>{invoice?.business_name}</p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    height: "20px",
                                                    fontWeight: 700
                                                }}>{invoice?.cui_number}</p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    height: "20px",
                                                    fontWeight: 700
                                                }}>{invoice?.register_com_number}</p>
                                                <p style={{
                                                    margin: "10px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#000000",
                                                    fontSize: "12px",
                                                    height: "20px",
                                                    fontWeight: 700
                                                }}>{invoice?.business_address}</p>
                                            </td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table width="100%" style={{ borderSpacing: 0 }}>
                                        <tbody><tr>
                                            <td>
                                                <p style={{ margin: '20px 0px 10px 0px', padding: '0px', color: '#000000', fontSize: '12px' }}>{t("vat")}: <span>{invoice?.vat}%</span>
                                                </p>
                                                <p style={{ margin: '20px 0px 10px 0px', padding: '0px', color: '#000000', fontSize: '12px' }}>{t("currency")}: <span>{invoice?.currency}</span>
                                                </p>
                                            </td>
                                        </tr>
                                        </tbody></table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table width="100%" style={{ borderSpacing: 0 }}>
                                        <tbody><tr>
                                            <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("title")}</th>
                                            <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("mu")}</th>
                                            <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("qty")}</th>
                                            <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("date")}</th>
                                            <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("price_per_unit")}</th>
                                            <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("price")}</th>
                                            <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("vat_table")}</th>
                                            <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("total_price")}</th>
                                        </tr>
                                            {
                                                invoice?.price_data &&
                                                invoice?.price_data.map(price =>
                                                (
                                                    <tr key={price.day}>
                                                        <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{t(price.title)}</td>
                                                        <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.mu}</td>
                                                        <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.qty}</td>
                                                        <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.day}</td>
                                                        <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.price_per_unit}</td>
                                                        <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.price}</td>
                                                        <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{((parseFloat(price.total_price) - parseFloat(price.price))).toFixed(2)}</td>
                                                        <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.total_price}</td>
                                                    </tr>
                                                )
                                                )

                                            }
                                            <tr>
                                                <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>{t("reservation_id")} : {invoice?.reservation_unique_id}</th>
                                                <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>&nbsp;</th>
                                                <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>&nbsp;</th>
                                                <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>&nbsp;</th>
                                                <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>&nbsp;</th>
                                                <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>{parseFloat(total.priceTotal).toFixed(2)}</th>
                                                <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>{parseFloat(total.vatTotal).toFixed(2)}</th>
                                                <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>{parseFloat(total.grandTotal).toFixed(2)}</th>
                                            </tr>

                                        </tbody></table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table width="100%" style={{ borderSpacing: 0 }}>
                                        <tr>
                                            <td>
                                                <p style={{
                                                    margin: "90px 0px 0px 0px",
                                                    padding: "0px",
                                                    color: "#6A6A6A",
                                                    fontSize: "11px"
                                                }}>Factura este valabilă fără semnătură și ștampilă, conform art. 319 alin. 29 din legea 227/2015.</p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </tbody></table>
                </div>
            </div>
        </div>
    );
};
export default forwardRef(Invoice);
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
} from 'reactstrap'
import LanguageDropDown from '../LangaugeDropDown'
import classNames from 'classnames'
import style from '../../assets/scss/additional/dropDownItem.module.scss'
import { BsBoxArrowRight } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import en from '../../assets/img/en.png'
import ro from '../../assets/img/ro.png'
import nowLogo from '../../assets/img/tabo-logo.png'
import { useTranslation } from 'react-i18next'
import { changeLanguages } from '../../redux/business/action'

const businessTypeData = [
  { id: 1, businessType: 'Beach', count: 1 },
  { id: 2, businessType: 'Beach', count: 2 },
  { id: 3, businessType: 'Beach', count: 3 },
  { id: 4, businessType: 'Pool', count: 1 },
  { id: 5, businessType: 'Pool', count: 2 },
  { id: 6, businessType: 'Pool', count: 3 },
  { id: 7, businessType: 'Restaurant', count: 1 },
  { id: 8, businessType: 'Restaurant', count: 2 },
  { id: 9, businessType: 'Restaurant', count: 3 },
  { id: 10, businessType: 'Club', count: 1 },
  { id: 11, businessType: 'Club', count: 2 },
  { id: 12, businessType: 'Add', count: '' },
]

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

const PageNavbar = (props) => {
  const size = useWindowSize()
  const dispatch = useDispatch()
  const business = useSelector(({ business }) => business)
  const { t, i18n } = useTranslation()

  const [state, setState] = useState({
    dropdownOpen: false,
    isOpen: false,
  })
  const toggle = () => {
    setState({
      isOpen: !state.isOpen,
    })
  }
  const [businessState, setBusinessState] = useState({
    dropdownOpen: false,
    isOpen: false,
  })
  const dropdownToggle = (e) => {
    setState({
      dropdownOpen: !state.dropdownOpen,
    })
  }
  const businessdropdownToggle = (e) => {
    setBusinessState({
      dropdownOpen: !businessState.dropdownOpen,
    })
  }

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? 'active' : ''
  }
  const businessAction = () => {
    if (size.width < 960) {
      setState({
        isOpen: !state.isOpen,
      })
    }
  }
  const history = useHistory()
  const logout = () => {
    localStorage.removeItem('persist:business')
    sessionStorage.clear()
    window.history.replaceState(null, null, '/login')
    history.push('/login')
    window.location.reload()
  }
  let location = useLocation()
  return (
    <Navbar
      expand="lg"
      className={
        state.isOpen
          ? 'bg-white navbar-absolute'
          : 'navbar-transparent navbar-absolute'
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            {location.pathname != '/login' && location.pathname != '/register' && (
              <NavbarToggler onClick={toggle}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </NavbarToggler>
            )}
            {/*  {(location.pathname == '/login' ||
              location.pathname == '/register') && (
              <div style={{ width: '50px' }}>
                <img src={nowLogo} alt="smart-beach-logo" />
              </div>
            )} */}
          </div>
          <Nav id="lang-mob" className="ml-auto nav-link-lang-mob">
            <div className="login">
              <LanguageDropDown className="dropdown-menu" />
            </div>
          </Nav>
        </div>

        <Collapse isOpen={state.isOpen} navbar className="visible">
          {location.pathname == '/wizard' && location.pathname == '/business' && (
            <Button style={{ background: '#609' }} color="light">
              Logo
            </Button>
          )}
          <Nav className="ml-auto" navbar>
            {/* {props.location.pathname !== '/admin/wizard' &&
              location.pathname != '/wizard' &&
              location.pathname != '/business' && (
                <React.Fragment>
                  {location.pathname != '/register' && (
                    <NavItem
                      className={activeRoute('/register')}
                      onClick={businessAction}
                    >
                      <Link to="/register" className="nav-link">
                        <i className="now-ui-icons tech_mobile" />{' '}
                        {t('register')}
                      </Link>
                    </NavItem>
                  )}
                  {location.pathname != '/login' && (
                    <NavItem
                      className={activeRoute('/  ')}
                      onClick={businessAction}
                    >
                      <Link to="/login" className="nav-link">
                        <i className="now-ui-icons users_circle-08" />{' '}
                        {t('login')}
                      </Link>
                    </NavItem>
                  )}
                </React.Fragment>
              )} */}

            {location.pathname == '/business' && (
              <Dropdown
                nav
                isOpen={businessState.dropdownOpen}
                toggle={(e) => businessdropdownToggle(e)}
              >
                <DropdownToggle caret nav>
                  Business Type
                </DropdownToggle>
                <DropdownMenu className={classNames(style.iconMenu)} right>
                  {businessTypeData.map((item, index) => {
                    return (
                      <DropdownItem tag="a" key={index}>
                        {item.businessType} {item.count}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            )}
            <LanguageDropDown />
            {location.pathname == '/wizard' && (
              <NavItem className="cursor">
                <div onClick={logout} className="nav-link">
                  <BsBoxArrowRight className="text-white font-size-20  users_single-02" />
                  <p>
                    <span className="d-lg-none d-md-block">Logout</span>
                  </p>
                </div>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default PageNavbar

let GRAPHQL_URL = "";
let REST_API_URL = "";
let FRONTEND_URL = "";

// let GRAPHQL_URL = 'https://dev-api.tabo.io/tabo/graphql';
// let REST_API_URL = 'https://dev-api.tabo.io/tabo/api';
// let FRONTEND_URL = 'https://dev.tabo.io';

// let GRAPHQL_URL = 'https://api.tabo.io/tabo/graphql';
// let REST_API_URL = 'https://api.tabo.io/tabo/api';
// let	FRONTEND_URL = 'https://tabo.io';
if (process.env.REACT_APP_NODE_ENV === "grid") {
  GRAPHQL_URL = "https://grid-api.tabo.io/tabo/graphql";
  REST_API_URL = "https://grid-api.tabo.io/tabo/api";
  FRONTEND_URL = "https://grid-api.tabo.io";
} else if (process.env.REACT_APP_NODE_ENV == "development") {
  GRAPHQL_URL = "https://dev-api.tabo.io/tabo/graphql";
  REST_API_URL = "https://dev-api.tabo.io/tabo/api";
  FRONTEND_URL = "https://dev-grid.tabo.io";
} else if (process.env.REACT_APP_NODE_ENV == "production") {
  GRAPHQL_URL = "https://api.tabo.io/tabo/graphql";
  REST_API_URL = "https://api.tabo.io/tabo/api";
  FRONTEND_URL = "https://tabo.io";
} else {
  GRAPHQL_URL = "http://localhost:4000/tabo/graphql";
  REST_API_URL = "http://localhost:4000/tabo/api";
  FRONTEND_URL = "http://localhost:3000";
}

// const testingProduction = true;
//
// if (testingProduction) {
// 	GRAPHQL_URL = 'https://api.tabo.io/tabo/graphql';
// 	REST_API_URL = 'https://api.tabo.io/tabo/api';
// 	FRONTEND_URL = 'https://tabo.io';
// }

module.exports = {
  GRAPHQL_URL,
  REST_API_URL,
  FRONTEND_URL,
};

import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input,
} from "reactstrap";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { saveBeachLocation } from "../../../../redux/option/action";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { startLoading, stopLoading } from "../../../../redux/loader/action";
import pencilIcon from '../../../../assets/img/pencil.svg'
const RegularMap = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{
      lat: Number(props.latValue),
      lng: Number(props.lngValue),
    }}
    defaultOptions={{
      // scrollwheel: true,
      mapTypeControl: false,
      fullscreenControl: true,
      streetViewControl: false,
    }}
    center={{ lat: Number(props.latValue), lng: Number(props.lngValue) }}
  >
    <Marker
      position={{ lat: Number(props.latValue), lng: Number(props.lngValue) }}
      onDragEnd={(e) => {
        props.handleDragEnd(e);
      }}
      draggable={true}
    />
  </GoogleMap>
));

const PhotoExtraSunbed = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const googleKey = process.env.REACT_APP_GOOGLE_API_KEY;
  // const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [mapPosition, setMapPosition] = useState({
    lat: 45.943161,
    lng: 24.966761,
  });
  const [value, setValue] = useState(null);
  const [addressValue, setAddressValue] = useState("");
  const [addressEdit, setAddressEdit] = useState("");
  const [disableSave, setDisableSave] = useState(true)
  useEffect(() => {
    if (option.businessSettings.length > 0) {
      if (
        Number(option.businessSettings[0].latitude) == 0 &&
        Number(option.businessSettings[0].longitude) == 0
      ) {
        getGeolocation();
      } else {
        setMapPosition({
          lat: option.businessSettings[0].latitude,
          lng: option.businessSettings[0].longitude,
        });
        setAddressValue(
          option.businessSettings[0].address
            ? option.businessSettings[0].address
            : ""
        );
      }
    } else {
      getGeolocation();
    }
  }, [option.businessSettings]);
  useEffect(() => {
    if (value && googleKey) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${value.label}&key=${googleKey}&region=US&language=ar`
      ) // B
        .then((response) => response.json()) // C
        .then((result) => {
          setMapPosition(result.results[0].geometry.location);
        })
        .catch((e) => { });
    }
  }, [value, googleKey]);
  const getGeolocation = () => {
    fetch("https://extreme-ip-lookup.com/json/")
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          setMapPosition({ lat: response.lat, lng: response.lon });
        }
      })
      .catch((data, status) => { });
  };
  const mapDragEnd = (event) => {
    const position = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setMapPosition(position);
    dispatch(startLoading());
    getAddressFromLatLng(position)
      .then((data) => {
        setAddressValue(data.formatted_address);
        setDisableSave(false);
        dispatch(stopLoading());
      })
      .catch((e) => {
        dispatch(stopLoading());
      });
  };

  const handleAddressChange = (event) => {
    setDisableSave(false);
    if (!event.target.value) {
      setDisableSave(true);
    }
    setAddressValue(event.target.value);
  };

  const handleEditAddress = (event) => {
    setAddressEdit(true)
  }
  const saveBeachLocationAction = (event) => {
    dispatch(startLoading());
    getAddressFromLatLng(mapPosition).then((addressData) => {
      getTimezoneFromLatLng(mapPosition).then(timezoneData => {
        dispatch(
          saveBeachLocation(
            mapPosition,
            addressData.country,
            addressData.city,
            addressValue,
            timezoneData.timeZoneId
          )
        )
          .then((data) => {
            dispatch(stopLoading());
            setDisableSave(true);
          })
          .catch((e) => {
            dispatch(stopLoading());
          });
      })
    });
  };

  const getAddressFromLatLng = (mapPosition) => {
    return new Promise((resolve, reject) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${mapPosition.lat},${mapPosition.lng}&key=${googleKey}&region=US&language=en`
      ) // B
        .then((response) => response.json()) // C
        .then((result) => {
          if (result) {
            let splitedLetter = result.results[0].formatted_address.split(",");
            var country = result.results[0].formatted_address
              .split(",")
            [splitedLetter.length - 1].replace(/[0-9]/g, "")
              .trim();
            var city = result.results[0].formatted_address
              .split(",")
            [splitedLetter.length - 2].replace(/[0-9]/g, "")
              .trim();

            resolve({
              city: city,
              country: country,
              formatted_address: result.results[0].formatted_address,
            });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };


  const getTimezoneFromLatLng = (mapPosition) => {
    return new Promise((resolve, reject) => {
      const timestamp = (Math.round((new Date().getTime()) / 1000));
      fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${mapPosition.lat},${mapPosition.lng}&timestamp=${timestamp}&key=${googleKey}&region=US&language=en`
      ) // B
        .then((response) => response.json()) // C
        .then((result) => {
          resolve(result)
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  return (
    <Fragment>
      <Card className="card-user">
        <CardHeader className="px-4">
          <h5 className="title mb-0 text-center">
            {/* {t(business.businessName)} */}
            {t("Location")}
          </h5>
        </CardHeader>
        <CardBody>
          <Row className="justify-content-center">
            <Col md={4}>
              <div className="py-2">
                <GooglePlacesAutocomplete
                  apiKey={googleKey}
                  selectProps={{
                    placeholder: `${t("Search_place")}`,
                    value,
                    onChange: setValue,
                  }}
                  className="google-search-bar"
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={4} lg={4}>
              <p className="light-gray bold mb-4 search-info">
                {t("Select_beach_location")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={12}>
              <RegularMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `480px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                latValue={mapPosition.lat}
                lngValue={mapPosition.lng}
                handleDragEnd={mapDragEnd}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {addressEdit ? (
                <Input
                  className="t-textarea text-center"
                  cols="80"
                  placeholder="Please write Here"
                  rows="2"
                  type="textarea"
                  name="rule"
                  value={addressValue}
                  onChange={handleAddressChange}
                  onBlur={() => { setAddressEdit(false) }}
                  autoFocus
                />
              ) : (
                <div className="address-edit">
                  {addressValue}
                  <img src={pencilIcon} alt="react-logo" onClick={handleEditAddress} />
                </div>
              )}
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <Button className="save-btn" disabled={disableSave} onClick={saveBeachLocationAction}>
              {t("Save")}
            </Button>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default PhotoExtraSunbed;

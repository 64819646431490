import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const FeeInvoice = ({ invoice }, ref) => {
    //const [total, setTotal] = useState({});
    const { t } = useTranslation();
    // useEffect(() => {
    //     let totalData = {};
    //     invoice.price_data.forEach(price => {
    //         totalData.priceTotal = parseFloat(totalData.priceTotal || 0) + parseFloat(price.price);
    //         totalData.vatTotal = parseFloat(totalData.vatTotal || 0) + ((parseFloat(price.total_price) - parseFloat(price.price)));
    //         totalData.grandTotal = parseFloat(totalData.grandTotal || 0) + parseFloat(price.total_price)
    //     })
    //     setTotal(totalData)
    // }, [invoice])
    return (
                <div className='invoice-page' ref={ref}>
                    <div className="main-page">
                        <div className="sub-page" style={{padding:"0 1cm 1cm 1cm"}}>


                            <table width="100%" style={{ borderSpacing: 0 }}>
                                <tbody><tr>
                                    <td>
                                        <p style={{ margin: '0px 0px 20px 0px', padding: '0px', color: '#6744F7', fontSize: '20px', textAlign: 'center', fontWeight: 600 }}>{t("invoice")}
                                        </p>

                                    </td>
                                </tr>
                                </tbody></table>

                            <table width="100%" height="100%" style={{ fontFamily: '"Poppins", sans-serif', borderSpacing: 0, margin: '0 auto', padding: '0px' }}>
                                <tbody><tr>
                                    <td>
                                        <table style={{ width: '100%', borderSpacing: 0 }}>
                                            <tbody><tr>
                                                <td width="60%">
                                                    <a href="#">
                                                        <img src="/logobun.png" width="64px" alt="Logo" />
                                                    </a>
                                                </td>
                                                <td>
                                                    <p style={{ padding: '0px', color: '#6A6A6A', fontSize: '12px' }}>{t("invoice_number")}</p>
                                                    <p style={{ padding: '0px', color: '#6A6A6A', fontSize: '12px' }}>{t("issue_date")}</p>
                                                    <p style={{ padding: '0px', color: '#6A6A6A', fontSize: '12px' }}>{t("due_to")}</p>
                                                </td>
                                                <td>
                                                    <p style={{ fontSize: '12px', fontWeight: 500, color: '#6744F7', margin: '0px', padding: '0px', textAlign: 'right' }}>{invoice?.invoice_number}</p>
                                                    <p style={{ fontSize: '12px', fontWeight: 500, color: '#000000', margin: '0px', padding: '0px', textAlign: 'right' }}>{moment(invoice.created_at).format("DD-MMM-YYYY")}</p>
                                                    <p style={{ fontSize: '12px', fontWeight: 500, color: '#6744F7', margin: '0px', padding: '0px', textAlign: 'right' }}>{t("already_paid")}</p>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style={{
                                                borderSpacing: 0,
                                                marginTop: "20px"
                                            }}>
                                                <tr>
                                                    <td width="60%">
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            fontWeight: 600
                                                        }}>{t("to_whom")}</p>
                                                        <h5 style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#6744F7",
                                                            fontSize: "14px",
                                                            fontWeight: 500,
                                                            textTransform: "uppercase"
                                                        }}>{invoice.business_name}</h5>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                        }}
                                                        >
                                                            CIF: {invoice.business_cui}
                                                        </p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                        }}
                                                        >
                                                            RC: {invoice.business_regist_number}
                                                        </p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                        }}
                                                        >
                                                            {t("Address")}: {invoice.business_address}
                                                        </p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                        }}
                                                        >
                                                            {t("City")} & {t("Country")}: {invoice.business_city} / {invoice.business_country}
                                                        </p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                        }}
                                                        >
                                                            {t("Email")}: {invoice.business_email}
                                                        </p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#6A6A6A"
                                                        }}>&nbsp;</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#6A6A6A"
                                                        }}>&nbsp;</p>
                                                    </td>
                                                    <td width="40%">
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            fontWeight: 600
                                                        }}>{t("made_by")}</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            height: "20px"
                                                        }}>{invoice.platform_name}:</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            height: "20px"
                                                        }}>CIF: {invoice.platform_cui}</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            height: "20px"
                                                        }}>RC: {invoice.platform_regist_number}</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            height: "20px"
                                                        }}>{t("Address")}: {invoice.platform_address}</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            height: "20px"
                                                        }}>{t("City")} & {t("Country")}: {invoice.platform_city} / {invoice.platform_country}</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            height: "20px"
                                                        }}>EUID: {invoice.platform_regist_number}</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            height: "20px"
                                                        }}>{t("Email")}: {invoice.platform_email}</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            height: "20px"
                                                        }}>{t("Bank_details")}: TRANSILVANIA CASA DE CULTURA</p>
                                                        <p style={{
                                                            margin: "0px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#000000",
                                                            fontSize: "12px",
                                                            height: "20px"
                                                        }}>{invoice.platform_iban}</p>
                                                    </td>
                                                </tr>
                                            </table>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style={{ borderSpacing: 0 }}>
                                                <tbody><tr>
                                                    <td>
                                                        <p style={{ margin: '20px 0px 0px 0px', padding: '0px', fontWeight: 500, color: '#000000', fontSize: '12px' }}>{t("vat")}: <span>{invoice.platform_vat}%</span>
                                                        </p>
                                                        <p style={{ margin: '0px 0px 0px 0px', padding: '0px', fontWeight: 500, color: '#000000', fontSize: '12px' }}>{t("currency")}: <span>{invoice.currency}</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                </tbody></table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style={{ borderSpacing: 0 }}>
                                                <tbody><tr>
                                                    <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("title")}</th>
                                                    <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("mu")}</th>
                                                    <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("qty")}</th>
                                                    <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("date")}</th>
                                                    <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("price_per_unit")}</th>
                                                    <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("price")}</th>
                                                    <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("vat_table")}</th>
                                                    <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 600, textAlign: 'left', backgroundColor: '#F8F8F8' }}>{t("total_price")}</th>
                                                </tr>
                                                    {

                                                        invoice.description.map(price =>
                                                        (
                                                            <tr key={price.title}>
                                                                <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{t(price.title)}</td>
                                                                <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.mu}</td>
                                                                <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.qty}</td>
                                                                <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{moment(invoice.created_at).format("DD-MMM-YYYY")}</td>
                                                                <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.price_per_unit}</td>
                                                                <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.price}</td>
                                                                <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{((parseFloat(price.total_price) - parseFloat(price.price))).toFixed(2)}</td>
                                                                <td style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', borderBottom: '1px solid #F0F0F0' }}>{price.total_price}</td>
                                                            </tr>
                                                        )
                                                        )
                                                    }
                                                    <tr>
                                                        <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>&nbsp;</th>
                                                        <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>&nbsp;</th>
                                                        <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>&nbsp;</th>
                                                        <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>&nbsp;</th>
                                                        <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>&nbsp;</th>
                                                        <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>{parseFloat(invoice.total).toFixed(2)}</th>
                                                        <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>{parseFloat(invoice.total_vat).toFixed(2)}</th>
                                                        <th style={{ margin: '10px 0px 0px 0px', padding: '12px 6px', color: '#000000', fontSize: '12px', fontWeight: 400, textAlign: 'left', fontWeight: 600 }}>{parseFloat(invoice.full_total).toFixed(2)}</th>
                                                    </tr>

                                                </tbody></table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" style={{ borderSpacing: 0 }}>
                                                <tr>
                                                    <td>
                                                        <p style={{
                                                            margin: "90px 0px 0px 0px",
                                                            padding: "0px",
                                                            color: "#6A6A6A",
                                                            fontSize: "11px"
                                                        }}>{t("text_invoice")}</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody></table>
                        </div>
                    </div>
                </div>
    );
};
export default forwardRef(FeeInvoice);
import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GRAPHQL_URL } from "../../utils/constants.js";
import { createNotification } from "../../utils/utils.js";
//import swal from "sweetalert";

const link = new HttpLink({
  uri: `${GRAPHQL_URL}`,
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const authLink = setContext(async (_, { headers }) => {
  let token;
  let localState = localStorage.getItem("persist:business");
  let superAdminSessionToken = localStorage.getItem("SHADOWED_USER_TOKEN");

  if (localState && typeof JSON.parse(localState) === "object") {
    localState = JSON.parse(localState);
    token = localState.accessToken;
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${superAdminSessionToken && superAdminSessionToken.length > 0
          ? superAdminSessionToken
          : token
          }`,
      },
    };
  }
});
const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions,
});
export default class API {
  getAllEmployeesRequests(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_users{
						business_user(where:{
							business_id:${business?.filterBusinessList?.id}
						}){
							id
							name
							password
							verified
							role
							color
							unlock_code
							updated_at
              email
              user_assign_permissions{
              permission_id
              }
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  getPermissionsList() {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query permission{
						permissions(where:{
							type:"user"
						}){
							id
							permission_code
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) {
      return err;
    }
  }

  createActionsRequests(employeeData, business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_userCreate{
						business_userCreate(business_user:{
							name:"${employeeData.name}"
							verified:false
							unlock_code:${employeeData.unlock_code}
							role:"${employeeData.role}"
              email:"${employeeData.email}"
							business_id:${business?.filterBusinessList?.id}
							color:"blue"
						}){
							id
							name
							verified
							password
							role
							color
							unlock_code
						}
					}
				`,
        })
        .then((data) => {
          createNotification({ type: 'success', title: 'Update', message: 'Successfully updated !' });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({ type: 'danger', title: 'Update', message: 'Not updated' });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateActionsRequests(employeeData) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_userUpdate{
						business_userUpdate(business_user:{
							id:${employeeData.id}
							name:"${employeeData.name}"
							unlock_code:${employeeData.unlock_code}
							role:"${employeeData.role}"
              email:"${employeeData.email}"
							color:"blue"
						}){
							id
							name
							password
							verified
							password
							role
							color
							unlock_code
						}
					}
				`,
        })
        .then((data) => {
          createNotification({ type: 'success', title: 'Update', message: 'Successfully updated !' });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({ type: 'danger', title: 'Update', message: 'Not updated !' });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  deleteActionsRequests(id) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_userDelete{
						business_userDelete(id:${id})
					}
				`,
        })
        .then((data) => {
          createNotification({ type: 'success', title: 'Update', message: 'Successfully updated !' });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({ type: 'danger', title: 'Update', message: 'Not updated !' });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  employeeEmailValidationActionRequests(name, value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_user{
						business_user(where:{
							email:"${value}"
						}){
							unlock_code
              email
							id
							role
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }


  employeeNameValidationActionRequests(name, value) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_user{
						business_user(where:{
							name:"${value}"
						}){
							unlock_code
							name
							id
							role
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  employeeCodeValidationActionRequests(name, value, business_id) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_user{
						business_user(where:{
							unlock_code:"${value}"
              business_id : ${business_id}
						}){
							unlock_code
							name
							id
							role
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }
}

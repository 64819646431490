import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PriceRangeComponent from "./PriceRangeComponent";

import {
  isData,
  getPrice,
  modalAction,
  resetPeriod,
  deletePricePlanInput,
} from "../../../../redux/price/action";
import { createNotification } from "../../../../utils/utils";
import { startLoading, stopLoading } from "../../../../redux/loader/action";

const PriceDayComponenet = ({
  startDate,
  endDate,
  currency,
  filterData,
  cancelActionss,
  agreeActionss,
  savePricePlanAction,
  seletedSeat,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const grid = useSelector(({ grid }) => grid);
  const price = useSelector(({ price }) => price);
  const business = useSelector(({ business }) => business);
  const [range, setRange] = useState([]);
  const [count, setCount] = useState(1);
  const [lockStatus, setLockStatus] = useState(false);
  const [redAlertStatus, setRedAlertStatus] = useState(false);
  const [resetStatus, setResetStatus] = useState(false);

  const errorData = !price.allPricesAreSet && price?.reasons && price?.reasons.length ? price.reasons.find(r => r.element_type.id == seletedSeat.id) : {};

  const allowPlusButton = (allPriceData, priceEndData) => {
    const sortArray = allPriceData.sort(
      (a, b) => new Date(a.end_date) - new Date(b.end_date)
    );
    const endDate = sortArray[sortArray.length - 1].end_date;
    let endDateValue = new Date(endDate).getTime();
    let currentEndDateValue = new Date(priceEndData).getTime();
    if (endDateValue <= currentEndDateValue) {
      setRedAlertStatus(true);
      return true;
    }
    setRedAlertStatus(false);
    return false;
  };

  useEffect(() => {
    if (price.allPrice.length > 0) {
      if (filterData.length > 0) {
        const allowStauts = allowPlusButton(filterData, grid.endDate);
        dispatch(isData(true));
        let arrs = [];
        arrs = filterData.filter(
          (ele) => ele.seat_types_id == price.elementType.id
        );
        let rangeDatas = [];
        arrs.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        arrs.map((item, index) => {
          let arr = getPriceArray(item.price);
          let repeatPriceArr = getPriceArray(item.price);
          repeatPriceArr.pop();
          let values = Object.values(item.price);
          const resultStatus = arr.every(
            (val, i, arr) => !val.status || val.price === arr[0].price
          );
          rangeDatas.push({
            id: item.id,
            startDate: item.start_date,
            endDate: item.end_date,
            minusStatus: arrs.length > 1 && index != 0,
            plusStatus: arrs.length - 1 == index && allowStauts ? true : false,
            equalPrice: resultStatus,
            equalValue: resultStatus ? item.price.sunday : "",
            priceData: arr,
            status: item.status,
          });
          arr = [];
        });
        rangeDatas.forEach((range) => {
          rangeDatas = checkPlusButtonStatus(range, rangeDatas);
        });
        setRange(rangeDatas);
      } else {
        // const allowStauts = allowPlusButton(
        // 	price.allPrice,
        // 	grid.endDate
        // );
        // dispatch(isData(false));
        // let arr = [];
        // arr = price.allPrice;
        // arr.sort(
        // 	(a, b) => new Date(a.start_date) - new Date(b.start_date)
        // );
        // let a = null;
        // let data = [];
        // let rangeDatas = [];
        // arr.map((item, index) => {
        // 	if (item.start_date !== a) {
        // 		a = item.start_date;
        // 		data.push(item);
        // 	}
        // });
        // data.map((item, index) => {
        // 	let arr = getPriceArray();
        // 	let rangeItem = {
        // 		id: item.id,
        // 		startDate: item.start_date,
        // 		endDate: item.end_date,
        // 		minusStatus: false,
        // 		plusStatus:
        // 			data.length - 1 == index && allowStauts
        // 				? true
        // 				: false,
        // 		equalPrice: false,
        // 		equalValue: "",
        // 		priceData: arr,
        // 		status: item.status
        // 	}
        // 	rangeDatas.push();
        // 	arr = [];
        // });
        // rangeDatas.forEach(range => {
        // 	rangeDatas = checkPlusButtonStatus(range, rangeDatas)
        // });
        // setRange(rangeDatas);
        setRange([
          {
            id: `i${count}`,
            startDate: grid.startDate,
            endDate: grid.endDate,
            plusStatus: true,
            minusStatus: false,
            equalPrice: false,
            equalValue: "",
            priceData: getPriceArray(),
          },
        ]);
      }
    } else {
      setRange([
        {
          id: `i${count}`,
          startDate: grid.startDate,
          endDate: grid.endDate,
          plusStatus: true,
          minusStatus: false,
          equalPrice: false,
          equalValue: "",
          priceData: getPriceArray(),
        },
      ]);
    }
  }, [grid.startDate, grid.endDate, filterData]);

  const getPriceArray = (price) => {
    return [
      {
        id: 1,
        name: "Monday",
        price: price && price.monday ? price.monday : "",
        status: getDayStatus("mon"),
      },
      {
        id: 2,
        name: "Tuesday",
        price: price && price.monday ? price.tuesday : "",
        status: getDayStatus("tue"),
      },
      {
        id: 3,
        name: "Wednesday",
        price: price && price.monday ? price.wednesday : "",
        status: getDayStatus("wed"),
      },
      {
        id: 4,
        name: "Thursday",
        price: price && price.monday ? price.thursday : "",
        status: getDayStatus("thu"),
      },
      {
        id: 5,
        name: "Friday",
        price: price && price.monday ? price.friday : "",
        status: getDayStatus("fri"),
      },
      {
        id: 6,
        name: "Saturday",
        price: price && price.monday ? price.saturday : "",
        status: getDayStatus("sat"),
      },
      {
        id: 7,
        name: "Sunday",
        price: price && price.monday ? price.sunday : "",
        status: getDayStatus("sun"),
      },
    ];
  };
  const getDayStatus = (day) => {
    return grid.weekHourData[0][day] == "Open";
  };
  const nextDate = (dateValue) => {
    let date = new Date(dateValue).getTime() + 1000 * 3600 * 24;
    let result1 = new Date(date).getDate();
    let result2 = new Date(date).getMonth() + 1;
    let result3 = new Date(date).getFullYear();
    return (
      `${result2 > 9 ? result2 : `0${result2}`}` +
      "/" +
      `${result1 > 9 ? result1 : `0${result1}`}` +
      "/" +
      `${result3}`
    );
  };
  const previousDate = (dateValue) => {
    // let result = moment(dateValue).add(1, 'd').format('MM/DD/YYYY');
    let date = new Date(dateValue).getTime() - 1000 * 3600 * 24;
    let result1 = new Date(date).getDate();
    let result2 = new Date(date).getMonth() + 1;
    let result3 = new Date(date).getFullYear();
    return (
      `${result2 > 9 ? result2 : `0${result2}`}` +
      "/" +
      `${result1 > 9 ? result1 : `0${result1}`}` +
      "/" +
      `${result3}`
    );
  };
  const addPeriod = (componentId) => {
    if (redAlertStatus) {
      setResetStatus(true);
    }
    let rangeIndex = range[range.length - 1];
    setCount(count + 1);
    let dateValue = range[range.length - 1].endDate;
    const dates = nextDate(dateValue);
    let arr = [...range];
    arr[arr.length - 1]["plusStatus"] = false;
    setLockStatus(false);
    if (componentId == range[range.length - 1].id) {
      setRange([
        ...arr,
        {
          id: `i${count + 1}`,
          startDate: dates,
          endDate: grid.endDate,
          equalPrice: false,
          equalValue: "",
          plusStatus: true,
          minusStatus: true,
          priceData: getPriceArray(),
        },
      ]);
    } else {
      range.map((list, index) => {
        if (list.id === componentId) {
          rangeIndex = index;
        }
      });
      let arrs = [...range];
      arrs[rangeIndex]["plusStatus"] = false;
      setLockStatus(false);
      let nextDates = nextDate(arr[rangeIndex].endDate);
      let previousDates = previousDate(arr[rangeIndex + 1].startDate);
      arrs.splice(rangeIndex + 1, 0, {
        id: `i${count + 1}`,
        startDate: nextDates,
        endDate: previousDates,
        equalPrice: false,
        equalValue: "",
        plusStatus: false,
        minusStatus: true,
        priceData: getPriceArray(),
      });
      setRange(arrs);
    }
  };
  const changePriceHandle = (name, value, id) => {
    let arr = [...range];
    arr.map((list) => {
      if (list.id === id) {
        list.priceData.map((listData) => {
          if (listData.name === name) {
            listData.price = value;
          }
        });
      }
    });
    setRange(arr);
  };
  const checkPlusButtonStatus = (item, rangeDatas) => {
    changeDateHandle(item.startDate, item.id, "start", rangeDatas);
    return changeDateHandle(item.endDate, item.id, "end", rangeDatas);
  };
  const changeDateHandle = (date, id, type, rangeDatas = null) => {
    let arr = [];
    if (!rangeDatas) {
      arr = [...range];
    } else {
      arr = rangeDatas;
    }

    arr.map((list, index) => {
      if (list.id === id) {
        if (type === "start") {
          let endDateValue = new Date(
            arr[index > 0 ? index - 1 : index].endDate
          ).getTime();
          let currentDate = new Date(date).getTime();
          if (currentDate - endDateValue > 1000 * 3600 * 24) {
            arr[index - 1]["plusStatus"] = true;
            setLockStatus(true);
            arr[index]["startDate"] = date;
          } else {
            arr[index > 0 ? index - 1 : index]["plusStatus"] = false;
            setLockStatus(false);
            // arr[index]["startDate"] = date;
          }
          list.startDate = date;
        }
        if (type === "end") {
          const nextItem = arr[index + 1];
          //if (nextItem) {
          let startDate = nextItem
            ? new Date(arr[index + 1].startDate).getTime()
            : new Date(endDate).getTime();
          let currentDate = new Date(date).getTime();
          if (startDate - currentDate > 1000 * 3600 * 24) {
            arr[index]["endDate"] = date;
            arr[index]["plusStatus"] = true;
            setLockStatus(true);
          } else {
            arr[index]["endDate"] = date;
            arr[index]["plusStatus"] = false;
            setLockStatus(true);
          }
          list.endDate = date;
          //}
        }
      }
    });
    if (!rangeDatas) {
      setRange(arr);
    }
    return arr;
  };
  const changeCheckEqualHandle = (status, id) => {
    let arr = [...range];
    arr.map((list) => {
      if (list.id === id) {
        list.equalPrice = status;
      }
    });
    setRange(arr);
  };
  const changeEqualPriceHandle = (value, id) => {
    let arr = [...range];
    arr.map((list) => {
      if (list.id === id) {
        list.equalValue = value;
        list.priceData[0]["price"] = list.priceData[0]["status"] ? value : "";
        list.priceData[1]["price"] = list.priceData[1]["status"] ? value : "";
        list.priceData[2]["price"] = list.priceData[2]["status"] ? value : "";
        list.priceData[3]["price"] = list.priceData[3]["status"] ? value : "";
        list.priceData[4]["price"] = list.priceData[4]["status"] ? value : "";
        list.priceData[5]["price"] = list.priceData[5]["status"] ? value : "";
        list.priceData[6]["price"] = list.priceData[6]["status"] ? value : "";
      }
    });
    setRange(arr);
  };
  const savePricePlan = () => {
    let hasMissingPrice = false;
    const newRangeData = range.map(p => ({
      ...p,
      priceData: p.priceData.map(pd => {
        const price = Number(pd?.price);
        const pData = { ...pd, price, invalid: false };
        if (!hasMissingPrice && price <= 0) hasMissingPrice = true;
        if (price <= 0) pData["invalid"] = true;
        return pData;
      }),
    }));

    if (hasMissingPrice) {
      setRange(newRangeData);
      return null;
    }
    savePricePlanAction(newRangeData);
    if (resetStatus) {
      dispatch(resetPeriod(false));
    }
  };
  const deletePeriodHandle = async (id) => {
    try {
      dispatch(startLoading());
      const res = await dispatch(deletePricePlanInput(id));
      const { result: responseResult } = res?.data?.DeleteCustomPrice ?? {};
      if (responseResult == "ok") {
        await dispatch(getPrice());
        let arr = [...range];
        arr.map((item, index) => {
          if (item.id == id) {
            arr[index - 1]["plusStatus"] = true;
            setLockStatus(true);
          }
        });
        const result = arr.filter((ele) => ele.id != id);
        setRange(result);
        createNotification({ type: 'success', title: 'Period Remove', message: 'Period removed successfully!' });
      } else {
        createNotification({ type: 'success', title: 'Period Remove', message: 'Period not removed!' });
      }
    } catch (error) {
      createNotification({ type: 'danger', title: 'Period Remove', message: 'Period not removed!' });
    } finally {
      dispatch(stopLoading());
    }
  };
  const toggleModalDemo = () => {
    dispatch(modalAction(!price.modalStatus));
  };
  const agreeAction = () => {
    if (resetStatus) {
      dispatch(resetPeriod(false));
    }
    agreeActionss(range);
    // dispatch(agreeActions(true))
  };
  const cancelAction = () => {
    cancelActionss();
    // dispatch(cancelActions(false))
  };
  return (
    <React.Fragment>
      <>
        {
          !isEmpty(errorData) ? (
            <div className="d-flex align-items-center justify-content-center text-center mb-3">
              <i className="now-ui-icons travel_info mr-2" />
              <p className="error-tip">
                {!!errorData?.description ? errorData?.description.replace(/_/g, " ") : ""}
              </p>
            </div>
          ) : null
        }
        <p className="light-gray bold my-3 text-center">{`${t(
          "Your season start at"
        )} ${startDate}${t("Ends at")} ${endDate} ${t("set_period")}`}</p>
      </>
      <Row className="mx-auto">
        <Col md={12}>
          {/* {price.isBed || price.isSunbed ? ( */}
          <React.Fragment>
            {range &&
              range.map((item, index) => {
                return (
                  <PriceRangeComponent
                    key={item.id}
                    item={item}
                    lastDate={
                      index > 0 ? range[index - 1].endDate : grid.startDate
                    }
                    changePriceValue={changePriceHandle}
                    changeDate={changeDateHandle}
                    changeCheckEqual={changeCheckEqualHandle}
                    changeEqualPrice={changeEqualPriceHandle}
                    addNewPeriod={addPeriod}
                    deletePeriod={deletePeriodHandle}
                    currency={currency}
                    rangeData={range}
                  />
                );
              })}
            <Row>
              <Col md={8} className="mx-auto">
                <div
                  className="d-flex justify-content-center"
                  style={{ paddingTop: "20px" }}
                >
                  <Button
                    style={{ minWidth: "100px" }}
                    color="success"
                    onClick={savePricePlan}
                  >
                    {t("Save")}
                  </Button>
                </div>
              </Col>
            </Row>
          </React.Fragment>
          {/* ) : (
						<Alert color='danger'>
							{t(
								"This section empty because you didnt associate the elements from grid with it."
							)}
						</Alert>
					)} */}
        </Col>
      </Row>
      <Modal isOpen={price.modalStatus} toggle={toggleModalDemo}>
        <ModalBody>
          <h5 className="title text-center">{t("Do you like to save")}</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={agreeAction}>
            {t("Yes")}
          </Button>
          <Button color="danger" onClick={cancelAction}>
            {t("No")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
export default PriceDayComponenet;

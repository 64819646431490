import { createPromiseAction } from "@adobe/redux-saga-promise";
import axios from "axios";
import { calls } from "../../utils/calls.js";
import { createError } from "../../utils/utils.js";
//import swal from "sweetalert";

export const actionTypes = {
  GET_STRUCTURE: "GET_STRUCTURE",
  RESULT_GET_STRUCTURE_ELEMENT: "RESULT_GET_STRUCTURE_ELEMENT",
  CREATE_PRICE_PLAN: "CREATE_PRICE_PLAN",
  GET_PRICE: "GET_PRICE",
  RESULT_GET_PRICE: "RESULT_GET_PRICE",
  SAVED_STATUS: "SAVED_STATUS",
  RANGE_DATA: "RANGE_DATA",
  ELEMENT_TYPES_ACTION: "ELEMENT_TYPES_ACTION",
  IS_SUNBED_ACTION: "IS_SUNBED_ACTION",
  IS_BED_ACTION: "IS_BED_ACTION",
  IS_DATA: "IS_DATA",
  IS_SAME_PRICE: "IS_SAME_PRICE",
  ASYNC_PRICE: "ASYNC_PRICE",
  DELETE_RESET_ACTION: "DELETE_RESET_ACTION",
  RESET_PERIOD: "RESET_PERIOD",
  MODAL_ACTION: "MODAL_ACTION",
  CANCEL_ACTION: "CANCEL_ACTION",
  TEMP_ELEMENT_TYPE: "TEMP_ELEMENT_TYPE",
  TEMP_ZONE_NAME: "TEMP_ZONE_NAME",
  CURRENT_ZONE_NAME: "CURRENT_ZONE_NAME",
  ASYNC_CREATE: "ASYNC_CREATE",
  DELETE_PRICE: "DELETE_PRICE",
  DELETE_PRICE_PLAN_INPUT: "DELETE_PRICE_PLAN_INPUT",
};

export function initialize() {
  const init = {};
  return { type: actionTypes.INITIAL_STATE, init };
}

export function getStructure(id) {
  return { type: actionTypes.GET_STRUCTURE, id };
}

export function createPricePlan(elementType, priceData) {
  return { type: actionTypes.CREATE_PRICE_PLAN, elementType, priceData };
}

export const getPrice = createPromiseAction(actionTypes.GET_PRICE, () => ({}));

export const deletePrice = createPromiseAction(
  actionTypes.DELETE_PRICE,
  (id, business_id) => ({ id, business_id })
);

export const deletePricePlanInput = createPromiseAction(
  actionTypes.DELETE_PRICE_PLAN_INPUT,
  (period_id) => ({ period_id })
);

export function savedStatus(value) {
  return { type: actionTypes.SAVED_STATUS, value };
}

export function elementTypesAction(value) {
  return { type: actionTypes.ELEMENT_TYPES_ACTION, value };
}

export function isSunbedAction(value) {
  return { type: actionTypes.IS_SUNBED_ACTION, value };
}

export function isBedAction(value) {
  return { type: actionTypes.IS_BED_ACTION, value };
}

export function isData(value) {
  return { type: actionTypes.IS_DATA, value };
}

export function isSamePrice(value) {
  return { type: actionTypes.IS_SAME_PRICE, value };
}

export function ayncGetPrice(resolve) {
  return { type: actionTypes.ASYNC_PRICE, resolve };
}

export function deleteAction() {
  return { type: actionTypes.DELETE_RESET_ACTION };
}

export function resetPeriod(value) {
  return { type: actionTypes.RESET_PERIOD, value };
}

export function modalAction(value) {
  return { type: actionTypes.MODAL_ACTION, value };
}

export function cancelActions(value) {
  return { type: actionTypes.CANCEL_ACTION, value };
}

export function tempElementType(value) {
  return { type: actionTypes.TEMP_ELEMENT_TYPE, value };
}

export function tempZoneName(value, valueId) {
  return { type: actionTypes.TEMP_ZONE_NAME, value, valueId };
}

export function currentZoneName(value) {
  return { type: actionTypes.CURRENT_ZONE_NAME, value };
}

export function ayncCreatePricePlan(seat_type, priceData, resolve) {
  return { type: actionTypes.ASYNC_CREATE, seat_type, priceData, resolve };
}

// ---- PAT Work ----

/**
 * Checks whether to display the bar that tells the user they need to set prices for periods
 * @param businessId {String}
 * @param dispatch {Function}
 * @returns {Function}
 */
export const checkIfAllPricesAreSet = (businessId, dispatch) => {
  dispatch({ type: "CHECK_IF_ALL_PRICES_ARE_SET" });
  return axios(calls.checkIfAllPricesAreSet(businessId))
    .then((res) => {
      dispatch({
        type: "CHECK_IF_ALL_PRICES_ARE_SET_FULFILLED",
        payload: {
          allPricesAreSet: res.data.data.allPricesAreSet,
          reasons: res.data.data.reasons,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: "CHECK_IF_ALL_PRICES_ARE_SET_REJECTED",
        payload: {
          error: createError(err),
        },
      });
    });
};

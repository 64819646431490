import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createFacility,
  deleteFacility,
  saveBusinessSettings,
} from "../../../../redux/option/action";
import { startLoading, stopLoading } from "../../../../redux/loader/action";

const Cuisine = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const updateTimeout = useRef(0);
  const defaultCuisine = [
    {
      id: 1,
      status: true,
      name: "French Cuisine",
    },
    {
      id: 2,
      status: true,
      name: "Chinese Cuisine",
    },
    {
      id: 3,
      status: true,
      name: "Japanese Food",
    },
    {
      id: 4,
      status: true,
      name: "Italian Food",
    },
    {
      id: 5,
      status: true,
      name: "Greek Food",
    },
    {
      id: 6,
      status: true,
      name: "Spanish Food",
    },
    {
      id: 7,
      status: true,
      name: "Mediterranean Cuisine",
    },
    {
      id: 8,
      status: true,
      name: "Lebanese Cuisine",
    },
    {
      id: 9,
      status: true,
      name: "Moroccan Cuisine",
    },
    {
      id: 10,
      status: true,
      name: "Turkish Cuisine",
    },
    {
      id: 11,
      status: true,
      name: "Thai Cuisine",
    },
    {
      id: 12,
      status: true,
      name: "Indian Cuisine",
    },
    {
      id: 13,
      status: true,
      name: "Cajun Food",
    },
    {
      id: 14,
      status: true,
      name: "Mexican Cuisine",
    },
    {
      id: 15,
      status: true,
      name: "Fine dining",
    },
    {
      id: 16,
      status: true,
      name: "Fusion",
    },
    {
      id: 17,
      status: true,
      name: "Libanes",
    },
    {
      id: 18,
      status: true,
      name: "Sea food",
    },
    {
      id: 19,
      status: true,
      name: "Raw Vegan",
    },
    {
      id: 20,
      status: true,
      name: "Vegetarian",
    }, {
      id: 21,
      status: true,
      name: "Sushi",
    },
    {
      id: 22,
      status: true,
      name: "Romanian Cuisine",
    },
    {
      id: 23,
      status: true,
      name: "International Cuisine",
    },

  ];
  const [cuisines, setCuisines] = useState([...defaultCuisine]);

  useEffect(() => {
    updateOptions()
  }, [option.businessSettings]);

  const updateOptions = () => {
    if (
      option.businessSettings.length > 0 &&
      option.businessSettings[0].cuisine &&
      option.businessSettings[0].cuisine.data.length > 0
    ) {
      let arr = [...cuisines];
      cuisines.map((item, index) => {
        let selected = false;
        option.businessSettings[0].cuisine.data.map((list, i) => {
          if (item.id === list.id) {
            selected = true;
          }
        });
        if (selected) {
          arr[index]["selected"] = true;
        } else {
          arr[index]["selected"] = false;
        }
      });
      setCuisines(arr);
    } else {
      setCuisines([...defaultCuisine]);
    }
  };

  const updateStatus = (id, selected) => {
    if (option.businessSettings.length > 0) {
      let arr = [...cuisines];
      cuisines.map((item, index) => {
        if (item.id == id) {
          arr[index].selected = selected;
        }
      });
      setCuisines(arr);
    }
  };

  const handleCuisine = (id, state) => (event) => {
    updateStatus(id, state);
    if (updateTimeout.current) clearTimeout(updateTimeout.current);
    updateTimeout.current = setTimeout(() => {
      dispatch(startLoading())
      let selectedCuisines = [];
      cuisines.map((item, index) => {
        if (item.selected) {
          selectedCuisines.push(item);
        }
      });
      let data = JSON.stringify({ data: selectedCuisines }).replace(
        /"/g,
        '\\"'
      );
      let mutation = `{
        id : ${option.businessSettings[0].id}
        cuisine: "${data}"
      }`;
      dispatch(saveBusinessSettings(mutation)).then(data => {
        dispatch(stopLoading())
      }).catch(e => {
        dispatch(stopLoading())
      });
    }, 1500);
  };
  return (
    <React.Fragment>
      <Card className="options-card p-3 pt-3">
        <Row >
          <Col md="10">
            <h5 className="option-title-main">{t("cuisine")}</h5>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="d-flex flex-wrap pb-3" style={{ gap: "10px" }}>
              {cuisines.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    style={{ textAlign: "center" }}
                  >
                    {item.selected ? (
                      <Button
                        color="primary"
                        className="btn-facility"
                        onClick={handleCuisine(item.id, false)}
                      >
                        {t(item.name)}
                      </Button>
                    ) : (
                      <Button
                        outline
                        color="secondary"
                        className="btn-facility"
                        onClick={handleCuisine(item.id, true)}
                      >
                        {t(item.name)}
                      </Button>
                    )}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Card>
      <div className="card-divider-line"></div>
    </React.Fragment>
  );
};
export { Cuisine };

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Switch from '@material-ui/core/Switch';
import {
  saveExtraSunbedPhoto,
} from "../../../../redux/option/action";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from "../../../../redux/loader/action";

const CovidCertificate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const option = useSelector(({ option }) => option);
  const [certiRequired, setCertiRequired] = useState(false);

  useEffect(() => {
    if (option.businessSettings.length > 0) {
      setCertiRequired(option.businessSettings[0].covid_certificate);
    } else {
      setCertiRequired(false);
    }
  }, [option.businessSettings]);

  const handleCerti = (value) => (event) => {
    let issetCerti = value === "Yes" ? true : false;
    setCertiRequired(issetCerti);
    const issetPhoto =
      option.businessSettings.length.length > 0
        ? option.businessSettings[0].photo_required
        : false;
    const extraSunbed =
      option.businessSettings.length.length > 0
        ? option.businessSettings[0].extra_sunbeds
        : 0;
    const umbrellaRequired =
      option.businessSettings.length.length > 0
        ? option.businessSettings[0].umbrella_requrired
        : false;

    dispatch(startLoading());
    dispatch(
      saveExtraSunbedPhoto(
        issetPhoto,
        extraSunbed,
        umbrellaRequired,
        issetCerti
      )
    )
      .then((data) => {
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };


  const handleCerti2 = (event) => {
    let issetCerti = event.target.checked;
    setCertiRequired(issetCerti);
    const issetPhoto =
      option.businessSettings.length.length > 0
        ? option.businessSettings[0].photo_required
        : false;
    const extraSunbed =
      option.businessSettings.length.length > 0
        ? option.businessSettings[0].extra_sunbeds
        : 0;
    const umbrellaRequired =
      option.businessSettings.length.length > 0
        ? option.businessSettings[0].umbrella_requrired
        : false;

    dispatch(startLoading());
    dispatch(
      saveExtraSunbedPhoto(
        issetPhoto,
        extraSunbed,
        umbrellaRequired,
        issetCerti
      )
    )
      .then((data) => {
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };

  return (
    <>
      <Card className="options-card">
        <Row className="p-3">
          <Col md="10">
            <h5 className="option-title-main">{t("covid_certi_check")}</h5>
            <p className="option-title-sub">{t('covid_certi_description')}</p>
          </Col>
          <Col md="2">
            <Switch
              checked={certiRequired}
              name="covidCert"
              onChange={handleCerti2}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Col>
        </Row>
      </Card>

      <div className="card-divider-line"></div>
    </>
  );
};

export default CovidCertificate;

import { Store } from 'react-notifications-component';
/**
 * Creates an error avoiding app crashes in case of Network Errors
 * @param err {Object}
 * @param customMessage {String}
 * @returns {String}
 */
export const createError = (err, customMessage = null) => {
	let message = 'Something went wrong. Please contact technical support. Code 500';
	if (err && (err?.statusText || err?.message || err?.error || err?.errors)) {
		message = err?.message || err?.error || err?.statusText || customMessage;
	}

	if (process.env.NODE_ENV === 'development') {
	}

	return message;
};


/* Creates an notification
* @param err {Object}
* @param customMessage {String}
*/
export const createNotification = (data) => {
	Store.addNotification({
		title: data.title,
		message: data.message,
		type: data.type,
		insert: "top",
		container: "top-right",
		animationIn: ["animate__animated", "animate__fadeIn"],
		animationOut: ["animate__animated", "animate__fadeOut"],
		dismiss: {
			duration: (data.type == 'danger') ? 2500 : 1500,
			onScreen: true
		}
	});
};
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "../../../components/ReactTable/ReactTable";
import { smsLogsHeader } from "../../../description/sms.description";
import { getSmsLogs } from "../../../redux/sms/action";

const SmsLogs = () => {
  const { smsLogs } = useSelector(({ sms }) => sms);
  const { businessData } = useSelector(({ business }) => business);
  const dispatch = useDispatch();
  const { id } = businessData;

  useEffect(() => {
    if (businessData) {
      dispatch(getSmsLogs(id));
    }
  }, [businessData]);

  const renderSmsLogsData = () => {
    return (
      <ReactTable
        data={smsLogs}
        showGeneralSearch
        isSearchable={false}
        // showOnMobileView={true}
        columns={smsLogsHeader}
        isMsgDisplay={true}
        isPrint={false}
        hasLastKeySortable={true}
        // hasPagination={false}
      />
    );
  };
  return (
    <div className="content bg-white">
      <div className="max-w-[1300px] mx-auto px-5">
        <h4 className="font-semibold my-4">SMS logs</h4>
        <div className="overflow-y-auto pb-4 smsLogs-table">
          {renderSmsLogsData()}
        </div>
      </div>
    </div>
  );
};

export default SmsLogs;

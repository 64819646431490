import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createFacility,
  deleteFacility,
  saveBusinessSettings,
} from "../../../../redux/option/action";
import { startLoading, stopLoading } from "../../../../redux/loader/action";

const Website = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [webisite, setWebisite] = useState("");
  const [webisiteCheck, setwebisiteCheck] = useState(false);
  const [disableSave, setdisableSave] = useState(true)

  useEffect(() => {
    if (
      option.businessSettings.length > 0 &&
      option.businessSettings[0].website
    ) {
      setWebisite(option.businessSettings[0].website);
      setwebisiteCheck(true);
    } else {
      setWebisite("");
      setwebisiteCheck(false);
    }
  }, [option.businessSettings]);

  const updateStateValue = (event) => {
    setdisableSave(false);
    if (!event.target.value) {
      setdisableSave(true);
    }
    setWebisite(event.target.value);
  };
  const handleWebsite = (event) => {
    let mutation = `{
      id : ${option.businessSettings[0].id}
      website: "${webisite}"
    }`;
    dispatch(startLoading())
    dispatch(saveBusinessSettings(mutation)).then(data => {
      dispatch(stopLoading())
      setdisableSave(true);
    }).catch(e => {
      dispatch(stopLoading())
    })
  };
  return (
    <React.Fragment>
      <Card className="options-card">
        <Row className="p-3">
          <Col md="10">
            <h5 className="option-title-main">{t('website_header')}</h5>
            <p className="option-title-sub mb-3">{t('website_header_description')}</p>
          </Col>
          <Col md="10">
            <div>
              <InputGroup className="mb-3 btn-input-group">
                <FormControl
                  type="text"
                  id="phone"
                  name="phone"
                  onChange={updateStateValue}
                  value={webisite}
                  placeholder={t("Website URL")}
                />
                <InputGroup.Append>
                  <Button variant="primary" onClick={handleWebsite} disabled={disableSave}>
                    {t("Save")}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </Card>

      <div className="card-divider-line"></div>
    </React.Fragment>
  );
};
export { Website };

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { BsBell } from 'react-icons/bs';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export default function Notifications() {
  const { t } = useTranslation();
  const history = useHistory();
  // const grid = useSelector(({ grid }) => grid);
  const price = useSelector(({ price }) => price);
  const business = useSelector(({ business }) => business);

  const [notifications, setNotifications] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCriticalDot, setShowCriticalDot] = useState(false);

  useEffect(() => {
    if (!price.allPricesAreSet && (business.filterBusinessList?.type == 'beach' || business.filterBusinessList?.type == 'pool')) {
      const notif = {
        isCritical: true,
        title: t("Action_Required"),
        onClick: () => history.push("/admin/price"),
        description: t("You have unset prices for some periods. Please define them"),
      }
      setNotifications([notif]);
    }
  }, [price]);

  useEffect(() => {
    if (notifications.length > 0 && !showCriticalDot) {
      if (notifications.some(n => n?.isCritical ?? false)) {
        setShowCriticalDot(true);
      }
    } else if (showCriticalDot) {
      if (notifications.length <= 0 || (notifications.length > 0 && notifications.every(n => !(n?.isCritical)))) {
        setShowCriticalDot(false);
      }
    }
  }, [notifications, showCriticalDot]);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const onNotificationPress = (n) => { };

  if (notifications.length == 0) {
    return null;
  }

  return (
    <Dropdown
      nav
      toggle={toggle}
      isOpen={dropdownOpen}
      style={{ backgroundColor: "#fff", borderRadius: "6px" }}
    >
      <DropdownToggle nav caret={false}>
        <div style={{ position: "relative" }}>
          <BsBell className="text-black font-size-20" />
          {showCriticalDot ? <div className='critical-dot' /> : null}
        </div>
      </DropdownToggle>
      <DropdownMenu right style={{ backgroundColor: '#ffffff' }}>
        {
          notifications.map((n, i) => (
            <DropdownItem
              key={`${i}_${n?.title ?? ""}`}
              onClick={() => n?.onClick() ?? onNotificationPress(n)}
            >
              <div>
                <p className='notification-title'>{n.title}</p>
              </div>
              <p className='notification-description'>
                {n.description}
              </p>
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  )
}

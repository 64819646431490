import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input,
  Alert
} from "reactstrap";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { saveBeachLocation } from "../../../../redux/option/action";
import { useTranslation } from "react-i18next";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { startLoading, stopLoading } from "../../../../redux/loader/action";
import pencilIcon from '../../../../assets/img/pencil.svg'
const RegularMap = withGoogleMap((props) => (
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{
      lat: Number(props.latValue),
      lng: Number(props.lngValue),
    }}
    defaultOptions={{
      // scrollwheel: true,
      mapTypeControl: false,
      fullscreenControl: true,
      streetViewControl: false,
    }}
    center={{ lat: Number(props.latValue), lng: Number(props.lngValue) }}
  >
    <Marker
      position={{ lat: Number(props.latValue), lng: Number(props.lngValue) }}
      onDragEnd={(e) => {
        props.handleDragEnd(e);
      }}
      draggable={true}
    />
  </GoogleMap>
));

const PhotoExtraSunbed = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const googleKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [mapPosition, setMapPosition] = useState({
    lat: 45.943161,
    lng: 24.966761,
  });
  const [value, setValue] = useState(null);
  const [addressValue, setAddressValue] = useState("");
  const [addressEdit, setAddressEdit] = useState("");
  const [disableSave, setDisableSave] = useState(true)
  useEffect(() => {
    if (option.businessSettings.length > 0) {
      if (
        Number(option.businessSettings[0].latitude) == 0 &&
        Number(option.businessSettings[0].longitude) == 0
      ) {
        getGeolocation();
      } else {
        setMapPosition({
          lat: option.businessSettings[0].latitude,
          lng: option.businessSettings[0].longitude,
        });
        setAddressValue(
          option.businessSettings[0].address
            ? option.businessSettings[0].address
            : ""
        );
      }
    } else {
      getGeolocation();
    }
  }, [option.businessSettings]);
  useEffect(() => {
    if (value) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${value.label}&key=${googleKey}&region=US&language=ar`
      ) // B
        .then((response) => response.json()) // C
        .then((result) => {
          setMapPosition(result.results[0].geometry.location);
        })
        .catch((e) => { });
    }
  }, [value]);
  const getGeolocation = () => {
    fetch("http://ip-api.com/json?fields=lat,lon")
      .then((res) => res.json())
      .then((response) => {
        if (response) {
          const position = { lat: response.lat, lng: response.lon };
          setMapPosition(position);
          getAddressFromLatLng(position).then((data) => {
            setAddressValue(data.formatted_address);
            setDisableSave(false);
          }).catch((e) => { });
        }
      })
      .catch((data, status) => { });
  };
  const mapDragEnd = (event) => {
    const position = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setMapPosition(position);
    dispatch(startLoading());
    getAddressFromLatLng(position)
      .then((data) => {
        setAddressValue(data.formatted_address);
        setDisableSave(false);
        dispatch(stopLoading());
      })
      .catch((e) => {
        dispatch(stopLoading());
      });
  };

  const handleAddressChange = (event) => {
    setDisableSave(false);
    if (!event.target.value) {
      setDisableSave(true);
    }
    setAddressValue(event.target.value);
  };

  const handleEditAddress = (event) => {
    setAddressEdit(true)
  }
  const saveBeachLocationAction = (event) => {
    dispatch(startLoading());
    getAddressFromLatLng(mapPosition).then((addressData) => {
      getTimezoneFromLatLng(mapPosition).then(timezoneData => {
        dispatch(
          saveBeachLocation(
            mapPosition,
            addressData.country,
            addressData.city,
            addressValue,
            timezoneData.timeZoneId
          )
        )
          .then((data) => {
            dispatch(stopLoading());
            setDisableSave(true);
          })
          .catch((e) => {
            dispatch(stopLoading());
          });
      })
    });
  };

  const getAddressFromLatLng = (mapPosition) => {
    return new Promise((resolve, reject) => {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${mapPosition.lat},${mapPosition.lng}&key=${googleKey}&region=US&language=en`
      ) // B
        .then((response) => response.json()) // C
        .then((result) => {
          if (result) {
            let splitedLetter = result.results[0].formatted_address.split(",");
            var country = result.results[0].formatted_address
              .split(",")
            [splitedLetter.length - 1].replace(/[0-9]/g, "")
              .trim();
            var city = result.results[0].formatted_address
              .split(",")
            [splitedLetter.length - 2].replace(/[0-9]/g, "")
              .trim();

            resolve({
              city: city,
              country: country,
              formatted_address: result.results[0].formatted_address,
            });
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };


  const getTimezoneFromLatLng = (mapPosition) => {
    return new Promise((resolve, reject) => {
      const timestamp = (Math.round((new Date().getTime()) / 1000));
      fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${mapPosition.lat},${mapPosition.lng}&timestamp=${timestamp}&key=${googleKey}&region=US&language=en`
      ) // B
        .then((response) => response.json()) // C
        .then((result) => {
          resolve(result)
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  return (
    <Fragment>
      <Card className="options-card pb-3 pt-3">
        <Row className="p-3">
          <Col md="12">
            <Row className="mb-3">
              <Col sm="12" md="5">
                <div className="d-flex flex-sm-column flex-md-row align-items-baseline" style={{ gap: "12px" }}>
                  <h5 className="option-title-main">{t('Location')}</h5>
                  <div className="w-100">
                    <GooglePlacesAutocomplete
                      apiKey={googleKey}
                      selectProps={{
                        placeholder: `${t("Search_place")}`,
                        value,
                        onChange: setValue,
                        className: "google-search-bar"
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col><p className="option-title-sub mb-4">{t('Select_beach_location')}</p></Col>
            </Row>
            <Row>
              <Col>
                <div className="google-map-wrapper">
                  <div className="google-map">
                    <RegularMap
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `380px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      latValue={mapPosition.lat}
                      lngValue={mapPosition.lng}
                      handleDragEnd={mapDragEnd}
                    />
                  </div>
                  <div className="google-map-details">
                    <div className="map-address-wrapper">
                      <div className="text-right">
                        <img src={pencilIcon} alt="react-logo" onClick={handleEditAddress} />
                      </div>
                      <div className="map-address-title">{t("Selected Address")}</div>

                      {addressEdit ? (
                        <div className="map-address-input">
                          <Input
                            className="t-textarea text-center"
                            cols="80"
                            placeholder="Please write Here"
                            rows="5"
                            type="textarea"
                            name="rule"
                            value={addressValue}
                            onChange={handleAddressChange}
                            onBlur={() => { setAddressEdit(false) }}
                            autoFocus
                          />
                        </div>
                      ) : (<div className="map-address-desc">{addressValue}</div>)}
                      <div className="map-addrerss-btn">
                        <Button className="save-btn-primary w-100" disabled={disableSave} onClick={saveBeachLocationAction}>
                          {t("Save")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

        </Row>
      </Card>
    </Fragment>
  );
};
export default PhotoExtraSunbed;

import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Row, Card } from "reactstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import bgImage from "../../assets/img/bg.jpg";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { businessTypes } from "../../redux/business/action";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../assets/css/custom.scss";
import beachImage from "../../assets/img/beach.jpg";
import { CheckCircle, Cancel, Close, WarningRounded } from "@material-ui/icons";
import Radio from "@material-ui/core/Radio";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  businessName,
  businessTypesData,
  changeLanguages,
  getBusinessListGetById,
  saveBusinessName,
  loginFromAdminAction,
  businessTypesRequests,
} from "../../redux/business/action";
import {
  getAllElement,
  getElements,
  getZones,
} from "../../redux/element/action";
import { getActivityPeriod, getHourWork } from "../../redux/grid/action";
import { InputGroup, FormControl } from "react-bootstrap";
import { startLoading, stopLoading } from "../../redux/loader/action.js";
import { createNotification } from "../../utils/utils";
import axios from "axios";
import { REST_API_URL } from "../../utils/constants.js";
import { BsClockFill } from "react-icons/bs";
import { checkIfAllPricesAreSet, getPrice } from "../../redux/price/action";
import { getExtraSunbedPhoto } from "../../redux/option/action";
var title = [
  {
    id: 1,
    titleName: "beach",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
  {
    id: 2,
    titleName: "pool",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
  {
    id: 3,
    titleName: "restaurant",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
  {
    id: 4,
    titleName: "bar",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
  {
    id: 5,
    titleName: "club",
    value: [
      { name: 0, label: 0, status: false },
      { name: 1, label: 1, status: false },
      { name: 2, label: 2, status: false },
      { name: 3, label: 3, status: false },
      { name: 4, label: 4, status: false },
      { name: 5, label: 5, status: false },
      { name: 6, label: 6, status: false },
      { name: 7, label: 7, status: false },
      { name: 8, label: 8, status: false },
      { name: 9, label: 9, status: false },
      { name: 10, label: 10, status: false },
    ],
  },
];

const AdminWizard = (props) => {
  const verifyLocation = props.location.pathname;
  let history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const [businessData, setBusinessData] = useState([]);
  const [isfirstBusiness, setIsfirstBusiness] = useState(false);
  const [businessType, setBusinessType] = useState({
    beach: 1,
    pool: 1,
    restaurant: 1,
    bar: 1,
    club: 1,
  });
  const [lockStatus, setLockStatus] = useState(true);
  const [businessList, setBusinessList] = useState([]);
  const [statusAddMore, setStatusAddMore] = useState(false);
  const [businessDeleted, setBusinessDeleted] = useState(0);
  const [newBussinessList, setNewBusinessList] = useState({
    type: '',
    location_name: "",
  });
  const [initialBusinesType, setInitialBusinessType] = useState({
    beach: 0,
    pool: 0,
    restaurant: 0,
    bar: 0,
    club: 0,
  });
  const [currentLocation, setCurrentLocation] = useState({
    defaultBeach: 0,
    defaultPool: 0,
    defaultRestaurant: 0,
    defaultBar: 0,
    defaultClub: 0,
  });

  useEffect(() => {
    let arr = [...title];
    if (business.businessLists) {
      arr = arr.map((item) => {
        const businessesOfThisType = business?.businessLists.filter(
          (business) => business.type === item.titleName
        );
        return {
          ...item,
          value: item.value.map((value) => ({
            ...value,
            status: value.name < businessesOfThisType?.length,
          })),
        };
      });

      if (business.businessLists) {
        let countBeach = [];
        let countPool = [];
        let countRestaurant = [];
        let countBar = [];
        let countClub = [];
        business.businessLists.map((items, k) => {
          if (items.type === "beach") {
            countBeach.push(items.id);
          }
          if (items.type === "pool") {
            countPool.push(items.id);
          }
          if (items.type === "restaurant") {
            countRestaurant.push(items.id);
          }
          if (items.type === "bar") {
            countBar.push(items.id);
          }
          if (items.type === "club") {
            countClub.push(items.id);
          }
        });

        setBusinessType({
          ...businessType,
          beach: countBeach.length,
          pool: countPool.length,
          restaurant: countRestaurant.length,
          bar: countBar.length,
          club: countClub.length,
        });

        setCurrentLocation({
          defaultBeach: countBeach.length,
          defaultPool: countPool.length,
          defaultRestaurant: countRestaurant.length,
          defaultBar: countBar.length,
          defaultClub: countClub.length,
        });

        setInitialBusinessType({
          beach: countBeach.length,
          pool: countPool.length,
          restaurant: countRestaurant.length,
          bar: countBar.length,
          club: countClub.length,
        });
      }
      setBusinessData(arr);
    } else {
      setBusinessData(arr);
    }
  }, [business, businessDeleted]);

  useEffect(() => {
    let newBusinessList = [];

    if (business.businessLists && business.businessLists.length > 0) {
      newBusinessList = business?.businessLists.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });
    }
    if (business.businessLists) {
      if (newBusinessList.length <= 0) {
        setIsfirstBusiness(true);
      }
      setBusinessList(newBusinessList);
    }
  }, [business, businessDeleted]);

  useEffect(() => {
    if (business.businessLists && business.businessLists.length > 0 && businessList.length && isfirstBusiness) {
      setIsfirstBusiness(false);
      handleSelectedBusiness(businessList[0].id, businessList[0].location_name).then(() => {
        window.location.href = `/admin/business/${businessList[0].id}`;
        // window.location.href = "/admin/settings"
      })
    }
  }, [business, businessList])

  const dispatch = useDispatch();

  const lockButtonStatus = (name, value) => {
    let status = true;
    switch (name) {
      case "beach":
        status = value === currentLocation.defaultBeach;
        break;
      case "pool":
        status = value === currentLocation.defaultPool;
        break;
      case "restaurant":
        status = value === currentLocation.defaultRestaurant;
        break;
      case "bar":
        status = value === currentLocation.defaultBar;
        break;
      case "club":
        status = value === currentLocation.defaultClub;
        break;
      default: {
        break;
      }
    }
    return status;
  };

  const handleChange = (name) => (event) => {
    let status = lockButtonStatus(name, event.target.value * 1);
    setLockStatus(status);
    setBusinessType({ ...businessType, [name]: event.target.value * 1 });

    switch (name) {
      case "beach": {
        setCurrentLocation({
          ...currentLocation,
          defaultBeach: event.target.value * 1,
        });
        break;
      }
      case "pool": {
        setCurrentLocation({
          ...currentLocation,
          defaultPool: event.target.value * 1,
        });
        break;
      }
      case "restaurant": {
        setCurrentLocation({
          ...currentLocation,
          defaultRestaurant: event.target.value * 1,
        });
        break;
      }
      case "bar": {
        setCurrentLocation({
          ...currentLocation,
          defaultBar: event.target.value * 1,
        });
        break;
      }
      case "club": {
        setCurrentLocation({
          ...currentLocation,
          defaultClub: event.target.value * 1,
        });
        break;
      }
      default: {
        return 0;
      }
    }
  };

  const next = () => {
    dispatch(businessTypes(businessType, "demo Value", history));
  };

  const _getInputValue = (businessType) => {
    switch (businessType) {
      case "beach": {
        return currentLocation.defaultBeach;
      }
      case "pool": {
        return currentLocation.defaultPool;
      }
      case "restaurant": {
        return currentLocation.defaultRestaurant;
      }
      case "bar": {
        return currentLocation.defaultBar;
      }
      case "club": {
        return currentLocation.defaultClub;
      }
      default: {
        return 0;
      }
    }
  };

  // const _renderBusinessData = () => {
  //   return businessData.map((list, i) => {
  //     return (
  //       <div className="form-row px-4" key={i}>
  //         <FormGroup className="col-md-9">
  //           <p
  //             className={
  //               verifyLocation === "/wizard"
  //                 ? "pt-2 text-white"
  //                 : "pt-2 text-black"
  //             }
  //           >
  //             {t(`${list.titleName}`)}
  //           </p>
  //         </FormGroup>
  //         <FormGroup className="col-md-3 admin-wizard">
  //           <Input
  //             value={_getInputValue(list.titleName)}
  //             type="select"
  //             name="select"
  //             id="inputState"
  //             className={"text-black"}
  //             onChange={handleChange(`${list.titleName}`)}
  //           >
  //             {list.value.map((item) => {
  //               return (
  //                 <option
  //                   className={item.status ? "light-gray" : "text-dark"}
  //                   disabled={!!item.status}
  //                   key={item.name}
  //                 >
  //                   {item.name}
  //                 </option>
  //               );
  //             })}
  //           </Input>
  //         </FormGroup>
  //       </div>
  //     );
  //   });
  // };

  const handleBusinessType = (id) => (event) => {
    let name = event.target.value;
    let index = businessList.findIndex((item) => item.id == id);
    businessList[index].type = event.target.value;
    setBusinessList([...businessList]);

    switch (name) {
      case "beach": {
        setBusinessType({
          ...initialBusinesType,
          beach: currentLocation.defaultBeach + 1,
        });
        break;
      }
      case "pool": {
        setBusinessType({
          ...initialBusinesType,
          pool: currentLocation.defaultPool + 1,
        });
        break;
      }
      case "restaurant": {
        setBusinessType({
          ...initialBusinesType,
          restaurant: currentLocation.defaultRestaurant + 1,
        });
        break;
      }
      case "bar": {
        setBusinessType({
          ...initialBusinesType,
          bar: currentLocation.defaultBar + 1,
        });
        break;
      }
      case "club": {
        setBusinessType({
          ...initialBusinesType,
          club: currentLocation.defaultClub + 1,
        });
        break;
      }
      default: {
        return 0;
      }
    }
  };

  const handleInputValue = (id) => (event) => {
    let index = businessList.findIndex((item) => item.id == id);
    if (event.target.value === "") {
      businessList[index].selected = true;
    } else {
      businessList[index].selected = false;
    }
    businessList[index].location_name = event.target.value;
    setBusinessList([...businessList]);
  };
  const handleSelectedBusiness = (id, location_name) => {
    return new Promise((resolve, reject) => {
      const path = location.pathname;
      dispatch(getBusinessListGetById(id));
      dispatch(businessName(location_name));

      setTimeout(() => {
        dispatch(getElements());
      }, 100);
      new Promise((resolve) => {
        dispatch(getHourWork(resolve));
      });
      dispatch(getPrice()).then(() => {
        dispatch(getElements());
        dispatch(getAllElement());
        dispatch(getZones());
        dispatch(getActivityPeriod()).then((result) => {
          setTimeout(() => {
            dispatch(stopLoading(), 2000);
          });
        });
        dispatch(getExtraSunbedPhoto());
        resolve()
      }).catch(e => {
        reject()
      });
      checkIfAllPricesAreSet(id, dispatch);
      dispatch(businessTypesData());
    })
  };

  const addBusiness = () => {
    setStatusAddMore(true);
    let businesses = [...businessList];
    businesses.push({
      location_name: "",
      type: "",
      status: "inactive",
      selected: true,
    });

    setBusinessList(businesses);
  };

  const handleSaveBusinessName = (id, location_name) => {
    dispatch(startLoading());
    dispatch(
      saveBusinessName({
        location_name: location_name,
        id: id,
      })
    )
      .then((data) => {
        dispatch(stopLoading());
        createNotification({
          type: "success",
          title: "Update",
          message: "Successfully updated name",
        });
      })
      .catch((e) => {
        dispatch(stopLoading());
        createNotification({
          type: "danger",
          title: "Update",
          message: "Error while update name",
        });
      });
  };

  const handleSaveBusinessNew = (id, location_name, type) => (event) => {
    if (id) {
      handleSaveBusinessName(id, location_name);
    } else {
      let newList = [...businessList, { type, location_name }];
      const value = newList[newList.length - 1]?.location_name;
      dispatch(businessTypes(businessType, value, history, type));
      setStatusAddMore(false);
    }
  };

  const businessTypesList = Object.keys(businessType);

  const deleteBusinessRequest = (id) => {
    axios
      .delete(`${REST_API_URL}/business/${id}`)
      .then((response) => {
        if (response) {
        }
      })
      .catch((error) => { });
    //setBusinessDeleted(businessDeleted + 1);
  };

  const handleBusinessDelete = (id) => (event) => {
    deleteBusinessRequest(id);
  };

  return (
    <>
      {business.accessToken ? (
        <>
          <div
            className="admin-wizard-container"
            style={{
              backgroundColor: "rgb(247, 247, 247)",
            }}
          >
            <div className="admin-wizard-container-inner">
              <Row>
                <Col md="10" lg="7" className="mx-auto">
                  <Card
                    className="admin-wizard-card p-4"
                    style={{ marginTop: "6rem" }}
                  >
                    <Row>
                      <Col md="12" className="text-center">
                        <h5 className="option-title-main mb-4">
                          {t("admin-wizard-title")}
                        </h5>
                      </Col>
                    </Row>
                    {businessList.map((item, index) => {
                      return (
                        <div className="business-wizard-row">
                          <div className="business-value d-flex">
                            <div>
                              <Radio
                                checked={business?.businessData?.id == item.id}
                                name="selected-business"
                                onChange={() => {
                                  handleSelectedBusiness(
                                    item.id,
                                    item.location_name,
                                    item.type
                                  )
                                }}
                                color="default"
                                inputProps={{ "aria-label": "A" }}
                                disabled={item.id ? false : true}
                              />
                            </div>
                            <div className="business-type">
                              <FormGroup>
                                <Input
                                  value={item.type}
                                  type="select"
                                  name="select"
                                  id="inputState"
                                  className={"text-black"}
                                  onChange={handleBusinessType(
                                    item.id,
                                    item.type
                                  )}
                                  disabled={item.id ? true : false}
                                  style={{ width: "124px" }}
                                //onChange={handleChangeNew(`${item.type}`)}
                                >
                                  <option value="" disabled selected hidden>{t("select type")}</option>
                                  {businessTypesList.map((item) => {
                                    return (
                                      <option value={item} key={item}>
                                        {t(item)}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </FormGroup>
                            </div>
                          </div>
                          <div style={{ display: "contents" }}>
                            <InputGroup className="mb-3 btn-input-group wizard-page">
                              <FormControl
                                type="text"
                                name="business-name"
                                onChange={handleInputValue(item.id)}
                                value={item?.location_name}
                              />
                              <InputGroup.Append>
                                <Button
                                  variant="primary"
                                  onClick={handleSaveBusinessNew(
                                    item.id,
                                    item.location_name,
                                    item.type
                                  )}
                                  disabled={item.selected}
                                //disabled={disableSave}
                                >
                                  {t(`Save`)}
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </div>

                          <div className="business-controls">
                            <div>
                              <span className="business-status pr-2">
                                {item.status == "active" && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        {t("status_active")}
                                      </Tooltip>
                                    }
                                  >
                                    <CheckCircle style={{ color: "green" }} />
                                  </OverlayTrigger>
                                )}
                                {item.status == "inactive" && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        {t("status_pending")}
                                      </Tooltip>
                                    }
                                  >
                                    {/* <Cancel style={{ color: "orange" }} /> */}
                                    <BsClockFill
                                      style={{
                                        fontSize: "20px",
                                        color: "orange",
                                      }}
                                    />
                                  </OverlayTrigger>
                                )}
                                {item.status == "delete_pending" && (
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        {t("status_delete_pending")}
                                      </Tooltip>
                                    }
                                  >
                                    <Cancel style={{ color: "red" }} />
                                  </OverlayTrigger>
                                )}
                              </span>
                            </div>
                            <div>
                              <span className="business-status">
                                <Button
                                  className="btn-delete-business"
                                  onClick={handleBusinessDelete(item.id)}
                                  disabled={
                                    (item.id ? false : true) ||
                                    item.status === "delete_pending"
                                  }
                                >
                                  <Close style={{ color: "grey" }} />
                                </Button>
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {!business.businessData && (
                      <div>
                        <div className="business-wizard-row">
                          <div className="business-value d-flex">

                            {
                              (businessList && businessList.length > 0) && (
                                <div>
                                  <Radio
                                    // checked={business?.businessData?.id == item?.id}
                                    name="selected-business"
                                    onChange={() => { }}
                                    color="default"
                                    inputProps={{ "aria-label": "A" }}
                                  // disabled={item?.id ? false : true}
                                  />
                                </div>
                              )
                            }


                            <div className="business-type">
                              <FormGroup>
                                <Input
                                  // value={item.type}
                                  type="select"
                                  name="select"
                                  id="inputState"
                                  className={"text-black"}
                                  onChange={(e) => setNewBusinessList({
                                    ...newBussinessList,
                                    type: e.target.value
                                  })}
                                  // disabled={item?.id ? true : false}
                                  style={{ width: "124px" }}
                                //onChange={handleChangeNew(`${item.type}`)}
                                >
                                  <option value="" disabled selected hidden>{t("select type")}</option>
                                  {businessTypesList.map((item) => {
                                    return (
                                      <option value={item} key={item}>
                                        {t(item)}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </FormGroup>
                            </div>
                          </div>
                          <div style={{ display: "contents" }}>
                            <InputGroup className="mb-3 btn-input-group wizard-page">
                              <FormControl
                                type="text"
                                name="business-name"
                                onChange={e => setNewBusinessList({ ...newBussinessList, location_name: e.target.value })}
                              />
                              <InputGroup.Append>
                                <Button
                                  variant="primary"
                                  onClick={handleSaveBusinessNew(
                                    undefined,
                                    newBussinessList.location_name,
                                    newBussinessList.type,
                                  )}
                                // disabled={item?.selected}
                                // disabled={disableSave}
                                >
                                  {t(`Save`)}
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </div>
                          {
                            (businessList && businessList.length > 0) && (
                              <div className="business-controls">

                                <div>
                                  <span className="business-status">
                                    <Button
                                      className="btn-delete-business"
                                      // onClick={handleBusinessDelete(item.id)}
                                      disabled={false}
                                    >
                                      <Close style={{ color: "grey" }} />
                                    </Button>
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                    {
                      (businessList && businessList.length > 0) && (
                        <Row>
                          <Col md="12 mt-2">
                            <div className="d-flex justify-content-between mt-1">
                              <Button
                                className="save-btn-blue btn-primary"
                                onClick={addBusiness}
                                disabled={statusAddMore}
                              >
                                {t("Add More")}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      )
                    }

                  </Card>
                </Col>
              </Row>
            </div>
          </div>

          <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          />
        </>
      ) : (
        <Redirect to="/login" />
      )}
    </>
  )
};

export default AdminWizard;

import moment from "moment";

export const historyHeader = [
  { Header: "Amount", accessor: "amount", Cell: (row) => `${row.value}€` },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: (row) => `${moment(row.value).format("DD/MM/YYYY")}`,
  },
];

export const smsLogsHeader = [
  {
    Header: "Reservation ID",
    accessor: "reservation_restaurant_id",
  },
  { Header: "Status", accessor: "sms_status" },
  { Header: "SMS Cost", accessor: "sms_price" },
  { Header: "Phone No.", accessor: "phone" },
];

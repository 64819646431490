import React, { useEffect, useState, memo } from "react";
import { Card, Col, Row } from "reactstrap";
import Switch from "@material-ui/core/Switch";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { startLoading, stopLoading } from "../../../../redux/loader/action";
import { saveAutoAccepted } from "../../../../redux/option/action";
import { createNotification } from "../../../../utils/utils";

const AutoAccept = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const option = useSelector(({ option }) => option);
  const [autoAccepted, setAutoAccepted] = useState(false);

  useEffect(() => {
    setAutoAccepted(option.businessSettings[0]?.auto_accept ?? false);
  }, [option.businessSettings]);

  const handleAutoAcceptCall = async (event) => {
    let accepted = event.target.checked;
    dispatch(startLoading());
    try {
      await dispatch(saveAutoAccepted(accepted));
      setAutoAccepted(accepted);
      createNotification({
        type: "success",
        title: "Update",
        message: "Successfully updated!",
      });
    } catch (error) {
      createNotification({
        type: "danger",
        title: "Update",
        message: "Not updated!",
      });
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Card className="options-card">
            <Row className="p-3">
              <Col md="10">
                <h5 className="option-title-main">{t("Auto_Accept")}</h5>
                <p className="option-title-sub">
                  {t("Auto_Accept_Msg")}
                </p>
                <p className="option-title-sub">
                  <span style={{ color: "#1d1d1d" }}><b>{t("Additional_Info")}: </b></span>{t("Additional_Info_Msg")}
                </p>
              </Col>
              <Col md="2">
                <Switch
                  color="primary"
                  name="AutoAccepted"
                  checked={autoAccepted}
                  onChange={handleAutoAcceptCall}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <div className="card-divider-line"></div>
    </React.Fragment>
  );
};
export default memo(AutoAccept);

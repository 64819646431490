import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Button,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap'
import classNames from 'classnames'
import LanguageDropDown from '../LangaugeDropDown'
import { BsArrowBarRight, BsPerson } from 'react-icons/bs'
import './AdminNavbar.modules.scss'
import style from '../../assets/scss/additional/dropDownItem.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  businessName,
  businessTypesData,
  changeLanguages,
  getLanguages,
  getBusinessListGetById,
  loginFromAdminAction,
} from '../../redux/business/action'
import en from '../../assets/img/en.png'
import ro from '../../assets/img/ro.png'
import it from '../../assets/img/language/it.png'
import es from '../../assets/img/language/es.png'
import fr from '../../assets/img/language/fr.png'
import pt from '../../assets/img/flags/PT.png'
import de from '../../assets/img/flags/DE.png'
import tr from '../../assets/img/flags/TR.png'
import gr from '../../assets/img/flags/GR.png'
import bg from '../../assets/img/flags/BG.png'
import ru from '../../assets/img/flags/RU.png'
// import hr from "../../assets/img/flags/hr.png";
import ae from '../../assets/img/flags/AE.png'
import IN from '../../assets/img/flags/IN.png'

import { useTranslation } from 'react-i18next'
import { getElements } from '../../redux/element/action'
import { REST_API_URL } from '../../utils/constants'
import { AiFillCaretDown } from 'react-icons/ai'
import Notifications from '../Notifications/Notifications'
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}

const AdminNavbar = (props, ref) => {
  const { t, i18n } = useTranslation()
  const business = useSelector(({ business }) => business)

  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const [state, setState] = useState({
    isOpen: false,
    dropdownOpen: false,
    color: 'transparent',
    width: '',
  })
  const [businessState, setBusinessState] = useState({
    dropdownOpen: false,
    isOpen: false,
  })
  const businessdropdownToggle = (e) => {
    setBusinessState({
      dropdownOpen: !businessState.dropdownOpen,
    })
  }
  const sidebarToggle = React.createRef()
  const toggle = () => {
    if (state.isOpen) {
      setState({
        color: 'transparent',
      })
    } else {
      setState({
        color: 'white',
      })
    }
    setState({
      isOpen: !state.isOpen,
    })
  }
  const dropdownToggle = (e) => {
    setState({
      dropdownOpen: !state.dropdownOpen,
    })
  }
  const openSidebar = () => {
    document.documentElement.classList.toggle('nav-open')
    sidebarToggle.current.classList.toggle('toggled')
  }
  const updateColor = () => {
    if (window.innerWidth < 993 && state.isOpen) {
      setState({
        color: 'white',
      })
    } else {
      setState({
        color: 'transparent',
      })
    }
  }

  const stripeAccountStatusEvent = (business_id) => {
    const source = new EventSource(
      `${REST_API_URL}/stripe/${business_id}/account-status`,
    )

    source.addEventListener('open', () => {
      console.log('SSE opened!')
    })

    source.addEventListener('message', (e) => {
      console.log(e.data)
    })

    source.addEventListener('error', (e) => {
      console.error('Error: ', e)
    })

    return () => {
      source.close()
    }
  }
  useEffect(() => {
    dispatch(businessTypesData())
  }, [location.pathname])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    if (
      window.innerWidth < 993 &&
      history.location.pathname !== location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
      sidebarToggle.current.classList.toggle('toggled')
    }
  }, [])

  // useEffect(() => {
  // 	if (business.filterBusinessList.stripe_account_id && !business.filterBusinessList.stripe_approved) {
  // 		stripeAccountStatusEvent(business.filterBusinessList.id)
  // 	}
  // }, [business.filterBusinessList.stripe_account_id, business.filterBusinessList.stripe_approved])

  useEffect(() => {
    dispatch(businessTypesData())
  }, [location.pathname])

  const bussiness = () => {
    history.push(`/admin/business/${business.filterBusinessList.id}`)
  }

  const handleResize = () => {
    setState({
      width: 45,
    })
  }

  const logout = () => {
    localStorage.removeItem('persist:business')
    sessionStorage.clear()
    window.history.replaceState(null, null, '/login')
    history.push('/login')
    window.location.reload()
  }
  const stopShadowingUser = () => {
    let adminData = JSON.parse(localStorage.getItem('admin_data'))

    dispatch(
      loginFromAdminAction({
        history: history,
        loginData: {
          token: adminData.accessToken,
          user: {
            id: adminData.userId,
            super_admin: 1,
          },
        },
      }),
    ).then((data) => {
      setTimeout(() => {
        let business = JSON.parse(localStorage.getItem('persist:business'))
        business.accessToken = '"' + adminData.accessToken + '"'
        business.userId = adminData.userId
        business.super_admin = 1
        localStorage.setItem('persist:business', JSON.stringify(business))
        localStorage.removeItem('SHADOWED_USER_TOKEN')
        localStorage.removeItem('admin_data')
        history.replace('/super_admin/locations')
      }, 1000)
    })
  }
  const [shadowedUserToken, setShadowedUserToken] = useState(
    localStorage.getItem('SHADOWED_USER_TOKEN'),
  )

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        window.location.href.indexOf('full-screen-maps') !== -1
          ? 'white'
          : state.color
      }
      expand="lg"
      className={
        window.location.href.indexOf('full-screen-maps') !== -1
          ? 'navbar-absolute '
          : 'navbar-absolute ' +
          (state.color === 'transparent' ? 'navbar-grey ' : 'navbar-grey')
      }
    >
      <Container fluid className="flex-end-item" style={{ gap: '10px' }}>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={ref}
              className="navbar-toggler"
              onClick={() => props.openSidebar()}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
        </div>
        <div className="top-header-nav centered-nav">
          <Button
            onClick={() => history.push(`/admin/wizard`)}
            className="nav-business-btn"
          >
            {business?.businessData?.location_name}
            <AiFillCaretDown
              style={{ fontSize: '10px', paddingLeft: '2px', width: '18px' }}
            />
          </Button>
          {/* {business.businessLists.length > 0 && (
						<UncontrolledDropdown>
							<Dropdown
								nav
								isOpen={businessState.dropdownOpen}
								toggle={(e) => businessdropdownToggle(e)}
								className="businessTypes dropdown-width"
							>
								<DropdownToggle caret nav>
									{business?.filterBusinessList?.location_name
										? business?.filterBusinessList?.location_name
										: business?.businessLists?.[0]?.location_name}
								</DropdownToggle>
								<DropdownMenu className={classNames(style.iconMenu)} right>
									{business?.businessLists?.map((item, index) => {
										return (
											<DropdownItem
												tag="a"
												style={{ cursor: 'pointer' }}
												onClick={() => {
													const path = location.pathname;
													dispatch(getBusinessListGetById(item.id));
													dispatch(businessName(item.location_name));
													setTimeout(() => {
														dispatch(getElements());
													}, 100)
													if (path.split('/')[2] === 'business') {
														history.push(`/admin/business/${item.id}`);
													}
													if (path.split('/')[2] === 'plan') {
														history.push(`/admin/plan`);
													}
													if (path.split('/')[2] === 'gridPlan') {
														history.push(`/admin/gridPlan`);
													}
													if (path.split('/')[2] === 'beachMap') {
														history.push(`/admin/beachMap`);
													}
													if (path.split('/')[2] === 'price') {
														if (item.type == 'beach') {
															setTimeout(() => {
																window.location.reload();
															}, 100)
														} else {
															history.push(`/admin/gridPlan`);
														}
													}
												}}
												key={index}
											>
												{item.location_name}
											</DropdownItem>
										);
									})}
									<DropdownItem
										tag="a"
										style={{ cursor: 'pointer' }}
										onClick={() => history.push('/admin/wizard')}
									>
										<span className="fa fa-plus"></span> {t('Add More')}
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</UncontrolledDropdown>
					)} */}
        </div>
        <div className="top-header-nav">
          <Nav className="admin">
            <Notifications />
          </Nav>
        </div>
        <div className="top-header-nav">
          <Nav className="admin">
            <LanguageDropDown />
          </Nav>
          <NavbarToggler className="three-dot" onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
        </div>
        <Collapse
          isOpen={state.isOpen}
          navbar
          className="justify-content-end profile-drop profile-logout visible"
          style={{ gap: '10px' }}
        >
          <NavItem className="cursor">
            <div onClick={bussiness} className="nav-link nav-item-profile">
              <BsPerson className="text-black font-size-20  users_circle-08" />
              <p>
                <span className="d-lg-none d-md-block">Profile</span>
              </p>
            </div>
          </NavItem>
          <NavItem className="cursor">
            <div onClick={logout} className="nav-link nav-item-profile">
              <BsArrowBarRight className="text-black font-size-20  users_single-02" />
              <p>
                <span className="d-lg-none d-md-block">Logout</span>
              </p>
            </div>
          </NavItem>
        </Collapse>
        {shadowedUserToken && shadowedUserToken.length > 0 ? (
          <Button
            className="text-left"
            color="success"
            id="tooltip26024663"
            size="md"
            type="button"
            onClick={stopShadowingUser}
          >
            Stop shadowing
          </Button>
        ) : null}
      </Container>
    </Navbar>
  )
}

AdminNavbar.defaultProps = {
  brandText: 'Default Brand Text',
}

AdminNavbar.propTypes = {
  // string for the page name
  brandText: PropTypes.string,
}

export default React.forwardRef(AdminNavbar)

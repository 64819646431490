import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  Card,
} from 'reactstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BeachImageUpload from '../../../../components/CustomUpload/BeachImageUpload';

import { businessGalleries, getBusinessGallery } from '../../../../redux/option/action';
import { createNotification } from '../../../../utils/utils';
import { REST_API_URL } from '../../../../utils/constants';

import Loading from '../../../../assets/img/loading.gif';

const PhotoExtraSunbed = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [count, setCount] = useState(1);
  const [beachImageData, setBeachImageData] = useState([]);
  const [imageFile, setImageFile] = useState([]);
  const [updateId, setUpdateId] = useState(0);

  const imageSaveStatus = useRef("create");

  useEffect(() => {
    dispatch(getBusinessGallery());
  }, [business.filterBusinessList, dispatch]);

  useEffect(() => {
    let arr = [];
    if (option.businessGallery.length > 0) {
      option.businessGallery.map((item, index) => {
        arr.push({ file: '', previewUrl: item.url, id: item.id });
      });
      setBeachImageData([...arr]);
    } else {
      setBeachImageData([]);
    }
  }, [option.businessGallery]);
  const imageUpload = (file, previewUrl) => {
    setImageFile(file);
    setCount(count + 1);
    if (imageSaveStatus == 'create') {
      setBeachImageData([
        ...beachImageData,
        { file: [file], previewUrl: [previewUrl], id: 'i' + (count + 1) },
      ]);
    } else {
      let arr = [...beachImageData];
      arr.map((item, index) => {
        if (item.id == updateId) {
          arr[index]['previewUrl'] = Loading;
        }
      });
      setBeachImageData(arr);
    }
  };
  useEffect(() => {
    (async () => {
      if (imageFile.name) {
        const formData = new FormData();
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        formData.append('myImage', imageFile);
        formData.append('business_id', business?.filterBusinessList?.id);

        try {
          let response = undefined;
          if (imageSaveStatus.current == 'update') {
            formData.append('id', updateId);
            response = await axios.put(`${REST_API_URL}/beach-photo/upload`, formData, config);
          } else if (imageSaveStatus.current == 'create') {
            response = await axios.post(`${REST_API_URL}/beach-photo/upload`, formData, config);
          }
          if (response && response?.data && response?.data.length > 0) {
            createNotification({ type: 'success', title: 'Update', message: 'Updated Successfully !' });
            dispatch(businessGalleries(response.data));
          }
        } catch (error) { }
      }
    })();
  }, [business.filterBusinessList.id, imageFile, imageSaveStatus, updateId]);

  const imageDeleteAction = async (id) => {
    const filterResult = beachImageData.filter((ele) => ele.id != id);
    if (filterResult.length > 0) {
      setBeachImageData(filterResult);
    }
    try {
      const response = await axios({
        method: 'delete',
        url: `${REST_API_URL}/beach-photo/upload`,
        data: {
          deleteId: `${id}`, // This is the body part
        },
      });
      createNotification({ type: 'success', title: 'Update', message: 'Updated Successfully !' });
      if (response) {
        dispatch(getBusinessGallery());
      }
    } catch (error) { }
  };

  const imageUpdateAction = (id) => {
    imageSaveStatus.current = 'update';
    setUpdateId(id);
  };

  return (
    <React.Fragment>
      <div className="card-divider-line"></div>
      <Card className="options-card pb-3 pt-3">
        <Row className="p-3">
          <Col>
            <div className="d-flex flex-sm-column flex-md-row align-items-center" style={{ gap: "12px" }}>
              <h5 className="option-title-main mb-0">{t('Photo')}</h5>
              {beachImageData.length < 15 && (
                <div className='select-image'>
                  <BeachImageUpload beachImageUpload={imageUpload} imageUrl={''} uploadNewImage />
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="p-3">
          {beachImageData.length > 0 && (
            <React.Fragment>
              {beachImageData.map((item, index) => {
                return (
                  <div className="px-3" key={index}>
                    <BeachImageUpload
                      beachImageUpload={imageUpload}
                      imageUrl={item.previewUrl}
                      imageId={item.id}
                      imageDelete={imageDeleteAction}
                      imageUpdate={imageUpdateAction}
                    />
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </Row>
      </Card>
    </React.Fragment>
  );
};
export default PhotoExtraSunbed;

import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { saveContactCall } from "../../../../redux/option/action";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from "../../../../redux/loader/action";
import { createNotification } from "../../../../utils/utils";

const ContactInformation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const option = useSelector(({ option }) => option);
  const [contact_phone, setContactPhone] = useState("");
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if (option.businessSettings.length > 0) {
      setContactPhone(
        option.businessSettings[0].contact_phone
          ? option.businessSettings[0].contact_phone
          : ""
      );
    } else {
      setContactPhone("");
    }
  }, [option.businessSettings]);

  const updateStateValue = (event) => {
    setDisableSave(false);
    if (!event.target.value) {
      setDisableSave(true);
    }
    setContactPhone(event.target.value);
  };
  const saveContactPhone = (event) => {
    if (!contact_phone) {
      createNotification({
        type: "danger",
        title: t("Invalid contact"),
        message: t("Please provide valid phone number"),
      });
      return false;
    }

    dispatch(startLoading());
    dispatch(saveContactCall(contact_phone))
      .then((data) => {
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };

  return (
    <>
      <Card className="options-card" style={{ paddingBottom: "7px" }}>
        <Row className="p-3">
          <Col md="10">
            <h5 className="option-title-main">{t("contact")}</h5>
            <p className="option-title-sub mb-3">{t("contact_description")}</p>
          </Col>
          <Col md="10">
            <div>
              <InputGroup className="mb-3 btn-input-group">
                <FormControl
                  type="text"
                  id="phone"
                  name="phone"
                  onChange={updateStateValue}
                  value={contact_phone}
                  placeholder={t("phone_number")}
                />
                <InputGroup.Append>
                  <Button
                    variant="primary"
                    onClick={saveContactPhone}
                    disabled={disableSave}
                  >
                    {t("Save")}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </Card>

      <div className="card-divider-line"></div>
    </>
  );
};

export default ContactInformation;

import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    UncontrolledDropdown,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import style from '../../../../assets/scss/additional/dropDownItem.module.scss';
import { googleTranslate } from './googleTranslate.js';
import { useDispatch, useSelector } from 'react-redux';
import {
    getRule,
    languageFlagStatus,
    saveMainLanguage,
    saveRuleValue,
    translatedLanguage
} from '../../../../redux/option/action';

const Rules = () => {
    const dispatch = useDispatch();
    const business = useSelector(({ business }) => business);
    const option = useSelector(({ option }) => option);
    const [state, setState] = useState({
        modalDemo: false,
        modalDemoRule: false
    });
    const { t } = useTranslation();
    const [initialLanguageFlag, setInitialLanguageFlag] = useState('en');
    const [languageStatus, setLanguageStatus] = useState('en');
    const [ruleValue, setRuleValue] = useState('');
    const [savedStatus, setSavedStatus] = useState(true);
    const [allowTranslateStatus, setAllowTranslateStatus] = useState(false);
    const [isTranslated, setIsTranslated] = useState(false);
    const [saveLockStatus, setSaveLockStatus] = useState(true);
    useEffect(() => {
        dispatch(getRule());
    }, [business?.filterBusinessList]);
    useEffect(() => {
        setSavedStatus(true);
        setSaveLockStatus(true);
        setLanguageStatus(option.currentIng);
        setAllowTranslateStatus(option.mainLang ? true : false);
        let ruleVal = (option.currentIng === 'en' ? option.en : option.currentIng === 'el' ? option.el : option.currentIng === 'es' ? option.es : option.currentIng === 'it' ? option.it : option.currentIng === 'fr' ? option.fr : option.ro).replace(/\\n/gm, "\n");
        ruleVal.replace(/"/g, "'");
        setRuleValue(ruleVal);
    }, [option.currentIng]);
    const selectedLangValue = (selectedLang) => {
        return option[selectedLang];
        // if (selectedLang === 'en') {
        //     return option.en;
        // }
        // if (selectedLang === 'es') {
        //     return option.es;
        // }
        // if (selectedLang === 'el') {
        //     return option.el;
        // }
        // if (selectedLang === 'fr') {
        //     return option.fr;
        // }
        // if (selectedLang === 'ro') {
        //     return option.ro;
        // }
        // if (selectedLang === 'it') {
        //     return option.it;
        // }
    };
    const translatedLanguages = (selectedLang) => {
        if (selectedLang === 'en') {
            return 'enGoogleTranslated';
        }
        if (selectedLang === 'es') {
            return 'esGoogleTranslated';
        }
        if (selectedLang === 'el') {
            return 'elGoogleTranslated';
        }
        if (selectedLang === 'fr') {
            return 'frGoogleTranslated';
        }
        if (selectedLang === 'ro') {
            return 'roGoogleTranslated';
        }
        if (selectedLang === 'it') {
            return 'itGoogleTranslated';
        }
    };
    const changeLanguage = (lng) => (event) => {
        setInitialLanguageFlag(lng);
        setSaveLockStatus(false);
        if (option.en.length === 0 && option.es.length === 0 && option.el.length === 0 && option.it.length === 0 && option.ro.length === 0 && option.fr.length === 0 && ruleValue.length === 0) {
            setLanguageStatus(lng);
            dispatch(languageFlagStatus(lng));
        } else {
            if (savedStatus) {
                if (option.mainLang === lng) {
                    setLanguageStatus(lng);
                    dispatch(languageFlagStatus(lng));
                } else {
                    if (allowTranslateStatus) {
                        setState({ ...state, modalDemo: true });
                        setLanguageStatus(lng);
                        dispatch(languageFlagStatus(lng));
                    }
                }
            } else {
                setState({ ...state, modalDemoRule: true });
            }
        }
    };
    const translateAction = (event) => {
        googleTranslate.translate(selectedLangValue(option.mainLang).replace(/\\n/gm, "<br>").replace(/(\<\/?)script/g, "$1noscript"), languageStatus, function (err, translation) {
            setRuleValue(translation.translatedText.replace(/(<br>)/gm, '\n'));
            setIsTranslated(true);
            setSaveLockStatus(false);
        });
    };
    const toggleModalDemo = () => {
        setState({ ...state, modalDemo: !state.modalDemo });
    };
    const toggleModalDemoRule = () => {
        setState({ ...state, modalDemoRule: !state.modalDemoRule });
    };
    const handleChangeRule = (event) => {
        setIsTranslated(false);
        const selectedResult = selectedLangValue(languageStatus);
        if (selectedResult === event.target.value) {
            setSavedStatus(true);
            setSaveLockStatus(true);
        } else {
            setSaveLockStatus(false);
            setSavedStatus(false);
        }
        setRuleValue(event.target.value.replace(/"/g, "'"));
    };
    const cancelLangAction = () => {
        setState({ ...state, modalDemoRule: !state.modalDemoRule });
        setSavedStatus(true);
        dispatch(languageFlagStatus(initialLanguageFlag));
    };
    const saveLangAction = () => {
        if (isTranslated) {
            const result = translatedLanguages(languageStatus);
            dispatch(translatedLanguage(result, 'true'));
            setIsTranslated(false);
        } else {
            const result = translatedLanguages(languageStatus);
            dispatch(translatedLanguage(result, 'false'));
        }
        if (option.mainLang.length === 0) {
            dispatch(saveMainLanguage(languageStatus));
        }
        dispatch(saveRuleValue(languageStatus, ruleValue));
        setSavedStatus(true);
        setState({ ...state, modalDemoRule: !state.modalDemoRule });
        setAllowTranslateStatus(true);
        setSaveLockStatus(true);
        // setTimeout(() => {
        //   dispatch(languageFlagStatus(initialLanguageFlag))
        // }, 300)
    };
    const allowTranslateAction = () => {
        translateAction();
        setSavedStatus(false);
        setState({ ...state, modalDemo: !state.modalDemo });
    };
    const unAllowTranslateAction = () => {
        setState({ ...state, modalDemo: !state.modalDemo });
    };
    const saveRuleAction = () => {
        if (isTranslated) {
            const result = translatedLanguages(languageStatus);
            dispatch(translatedLanguage(result, 'true'));
            setIsTranslated(false);
        } else {
            const result = translatedLanguages(languageStatus);
            dispatch(translatedLanguage(result, 'false'));
        }
        if (option.mainLang.length === 0) {
            dispatch(saveMainLanguage(languageStatus));
        }
        dispatch(saveRuleValue(languageStatus, ruleValue.replace(/"/g, "'").replace(/(\r\n|\n|\r)/gm, '\\n')));
        setSavedStatus(true);
        setAllowTranslateStatus(true);
        setSaveLockStatus(true);
    };

    return (
        <React.Fragment>
            <Card className="options-card">
                <Row className="p-3 align-items-baseline">
                    <Col md="3">
                        <h5 className="option-title-main">{t('Rules')}</h5>
                    </Col>
                    <Col md="7">
                        <div className='d-flex language-dropdown align-items-center justify-content-end' style={{ gap: "14px" }}>
                            <p className='option-label'>{t('Language')}</p>
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    color="secondary"
                                    caret
                                >
                                    <img src={`/language/${languageStatus}.png`} />
                                </DropdownToggle>
                                <DropdownMenu className={classNames(style.iconMenu)}>
                                    {flagArray.data.map((list, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                {list.name != languageStatus && (
                                                    <DropdownItem tag="a" className="cursor"
                                                        onClick={changeLanguage(`${list.name}`)}><img
                                                            src={`/language/${list.name}.png`} /></DropdownItem>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    </Col>
                </Row>
                <div >
                    {(languageStatus === 'en' && option.enGoogleTranslated === 'true') && (
                        <p className="text-info bold mb-3 px-3">{t('Text translated by google translate')}</p>
                    )}
                    {(languageStatus === 'es' && option.esGoogleTranslated === 'true') && (
                        <p className="text-info bold mb-3 px-3">{t('Text translated by google translate')}</p>
                    )}
                    {(languageStatus === 'el' && option.elGoogleTranslated === 'true') && (
                        <p className="text-info bold mb-3 px-3">{t('Text translated by google translate')}</p>
                    )}
                    {(languageStatus === 'it' && option.itGoogleTranslated === 'true') && (
                        <p className="text-info bold mb-3 px-3">{t('Text translated by google translate')}</p>
                    )}
                    {(languageStatus === 'fr' && option.frGoogleTranslated === 'true') && (
                        <p className="text-info bold mb-3 px-3">{t('Text translated by google translate')}</p>
                    )}
                    {(languageStatus === 'ro' && option.roGoogleTranslated === 'true') && (
                        <p className="text-info bold mb-3 px-3">{t('Text translated by google translate')}</p>
                    )}
                </div>
                <Row className="px-3 pb-3">
                    <Col md="10">
                        <FormGroup className="text-box">
                            <Input
                                placeholder={t("rules_box_palceholder")}
                                name="rule"
                                type="textarea"
                                rows={ruleValue.split("\n").length > 5 ? 10 : 5}
                                value={ruleValue}
                                onChange={handleChangeRule}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <div className="d-flex justify-content-start">
                            <Button className="save-btn-secondary" disabled={saveLockStatus}
                                onClick={saveRuleAction}>{t('Save')}</Button>
                        </div>
                    </Col>
                </Row>

                <Modal isOpen={state.modalDemo} toggle={toggleModalDemo}>
                    <ModalBody>
                        <h5 className="title text-center">
                            {`Do you like to translate the text from ${option.mainLang === 'en' ? 'English' : option.mainLang === 'es' ? 'Spanish' : option.mainLang === 'el' ? 'Greek' : option.mainLang === 'it' ? 'Italian' : option.mainLang === 'fr' ? 'French' : 'Romanian'}`}
                        </h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={allowTranslateAction}>
                            {t('Yes')}
                        </Button>
                        <Button color="danger" onClick={unAllowTranslateAction}>
                            {t('No')}
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={state.modalDemoRule} toggle={toggleModalDemoRule}>
                    <ModalBody>
                        <h5 className="title text-center">
                            {`Text in ${languageStatus === 'en' ? 'English' : languageStatus === 'es' ? 'Spanish' : languageStatus === 'el' ? 'Greek' : languageStatus === 'it' ? 'Italian' : languageStatus === 'fr' ? 'French' : 'Romanian'} was not saved. Do u want to save it ?`}
                        </h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="success" onClick={saveLangAction}>
                            {t('Yes')}
                        </Button>
                        <Button color="danger" onClick={cancelLangAction}>
                            {t('No')}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Card>

            <div className="card-divider-line"></div>
        </React.Fragment>
    );
};
export default Rules;
const flagArray = {
    data: [
        {
            name: 'en',
        },
        {
            name: 'ro'
        },
        {
            name: 'fr'
        },
        {
            name: 'es'
        },
        {
            name: 'it'
        },
        {
            name: 'el'
        }
    ]
};

import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import PhotoExtraSunbed from "./OptionComponent/PhotoExtraSunbed";
import PhotoRequired from "./OptionsComponents/PhotoRerquired";
import BeachLocation from "./OptionsComponents/BeachLocation";
import { Facilities, Facilities2 } from "./OptionsComponents/Facilities";
import BeachPhotos from "./OptionsComponents/BeachPhotos";
import GuranteeReservation from "./OptionsComponents/GuranteeReservation.js";
import EstimatedTime from "./OptionsComponents/EstimatedTime";
import Rules from "./OptionsComponents/Rules";
import { useSelector } from "react-redux";
import beachImage from "../../../assets/img/beach.jpg";
import restaurantImage from "../../../assets/img/restaurant.jpg";
import MustArriveBy from "./OptionsComponents/MustArriveBy";
import CovidCertificate from "./OptionsComponents/CovidCertificate";
import ContactInformation from "./OptionsComponents/ContactInformation";
import { Cuisine } from "./OptionsComponents/cuisine";
import { Website } from "./OptionsComponents/Website";
import ExecutiveChef from "./OptionsComponents/ExecutiveChef.js";
import { Cost } from "./OptionsComponents/Cost";
import AlertHeader from "../../../components/PanelHeader/AlertHeader";
import GuestLimit from "./OptionsComponents/GuestLimit";
import briefcaseIcon from "../../../assets/img/briefcaseIcon.svg";

const Options = () => {
  const business = useSelector(({ business }) => business);

  React.useEffect(() => {}, [business]);
  return (
    <Fragment>
      <AlertHeader />
      <div
        className={`content grid-planner-container options-container`}
        style={{
          backgroundColor: "#ffffff",
          display: "block",
        }}
      >
        {/* <div className="content options-container">
          <Row className="grid-planner-row">
            <Col md="12">
              <div className="page-heading">
                <img src={briefcaseIcon} />
                <h5>Options</h5>
              </div>
            </Col>
            <Col md="12" className="mx-auto">
              <Row
                className={
                  business.filterBusinessList.type == "club"
                    ? "club-cards club-height"
                    : "club-cards"
                }
              >
                <Col md={6} className="half-sunbad">
                  {(business.filterBusinessList.type == "restaurant" ||
                    business.filterBusinessList.type == "bar" ||
                    business.filterBusinessList.type == "club") && (
                    <GuranteeReservation />
                    )
                  }
                  <PhotoRequired />

                  {(business.filterBusinessList.type == "restaurant" ||
                    business.filterBusinessList.type == "bar" ||
                    business.filterBusinessList.type == "club") && (
                      <ExecutiveChef />
                    )
                  }

                  <CovidCertificate />

                  {(business.filterBusinessList.type == "restaurant" ||
                    business.filterBusinessList.type == "bar") && (
                      <EstimatedTime />
                    )
                  }

                  <Cost />



                </Col>

                <Col md="6">

                  <ContactInformation />
                  {(business.filterBusinessList.type == "restaurant" ||
                    business.filterBusinessList.type == "bar" ||
                    business.filterBusinessList.type == "club") && (
                      <>
                        <Website />
                        <GuestLimit />
                      </>
                    )
                  }

                  <Rules />

                  {business.filterBusinessList.type == "beach" ||
                    business.filterBusinessList.type == "pool" ? (
                    <Facilities />
                  ) : (
                    <Facilities2 />
                  )}
                </Col>
              </Row>

              {(business.filterBusinessList.type == "beach" ||
                business.filterBusinessList.type == "pool" ||
                business.filterBusinessList.type == "club") && (
                  <MustArriveBy />
                )
              }

              <BeachPhotos />

              {(business.filterBusinessList.type == "restaurant" ||
                business.filterBusinessList.type == "bar" ||
                business.filterBusinessList.type == "club") && (
                  <Cuisine />
                )
              }

              <BeachLocation />

            </Col>
          </Row>
        </div> */}
      </div>
    </Fragment>
  );
};
export default Options;

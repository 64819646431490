import { rejectPromiseAction, resolvePromiseAction } from '@adobe/redux-saga-promise';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { 
	actionTypes,
	getPlans,
	getActivePlan, 
	inactiveOldPlan,
	setSelectedPlan,
} from './action';
import API from './api';

const api = new API();

const getPlansRequest = () => {
	return api.getPlans();
};

function* getPlansAction(action) {
	try {
		const result = yield call(getPlansRequest);
		if (result.data) {
			yield put({
				type: actionTypes.SET_PLANS,
				value: result.data.plans,
			});
		}
		yield call(resolvePromiseAction, action, result);
	} catch (e) {
		yield call(rejectPromiseAction, action, e);
	}
}

const getActivePlanRequest = (businessId) => {
	return api.getActivePlan(businessId);
};

function* getActivePlansAction(action) {
	try {
		const { businessId } = action.payload;
		const result = yield call(getActivePlanRequest, businessId);
		if (result.data) {
			yield put({
				type: actionTypes.SET_ACTIVE_PLAN,
				value: result.data.plan_changes[0],
			});
		}
		yield call(resolvePromiseAction, action, result);
	} catch (e) {
		yield call(rejectPromiseAction, action, e);
	}
}

const inActivePlanRequest = (id) => {
	return api.inactiveOldPlan(id);
};

function* inActivePlansAction(action) {
	try {
		const { id } = action.payload;
		const result = yield call(inActivePlanRequest, id);
		yield call(resolvePromiseAction, action, result);
	} catch (e) {
		yield call(rejectPromiseAction, action, e);
	}
}

const createPlanChangeRequest = (plan) => {
	return api.createPlanChanges(plan);
};

function* createPlanChangeAction(action) {
	try {
		const { plan } = action.payload;
		const result = yield call(createPlanChangeRequest, plan);
		 if (result.data) {
			yield call(resolvePromiseAction, action, result);
		 }
		 yield call(rejectPromiseAction, action, result);
	} catch (e) {
		yield call(rejectPromiseAction, action, e);
	}
}


export default function* rootSaga() {
	yield all([
		takeLatest(getPlans, getPlansAction),
		takeLatest(getActivePlan, getActivePlansAction),
		takeLatest(inactiveOldPlan, inActivePlansAction),
		takeLatest(setSelectedPlan, createPlanChangeAction),	
	]);
}

import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GRAPHQL_URL } from "../../utils/constants.js";
import { createNotification } from "../../utils/utils.js";
//import swal from "sweetalert";

const link = new HttpLink({
  uri: `${GRAPHQL_URL}`,
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const authLink = setContext(async (_, { headers }) => {
  let token;
  let localState = localStorage.getItem("persist:business");
  let superAdminSessionToken = localStorage.getItem("SHADOWED_USER_TOKEN");

  if (localState && typeof JSON.parse(localState) === "object") {
    localState = JSON.parse(localState);
    token = localState.accessToken;
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${superAdminSessionToken !== null && superAdminSessionToken.length > 0
          ? superAdminSessionToken
          : token
          }`,
      },
    };
  }
});
const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions,
});
export default class API {
  getAllElementsRequests() {
    try {
      let data = client
        .mutate({
          mutation: gql`
            query element {
              element(order: "id") {
                type
                id
                structure
                width
                height
                static
                resizable 
                seat_type {
                   id
                }
              }
            }
          `,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  createElementInformationRequests(business, element, zone_id) {
    let arr = [];
    element.elementInformation.map((item, index) => {
      let zoomValue = '';
      let scale = '';
      if (business.filterBusinessList.type == "beach" || business.filterBusinessList.type == "pool") {
        zoomValue = ` zoomRate:${element.zoomRate}
        zoomAreaValue:${element.zoomAreaValue ? element.zoomAreaValue : 0.8}
        displayValue:${element.displayValue ? element.displayValue : 1}
        `
      } else {
        scale = `is_extra_element:${(item.is_extra_element) ? true : false}
        is_resizable:${(item.is_resizable) ? true : false}
        scaleX:${(item.scaleX) ? item.scaleX : 1}
        scaleY:${(item.scaleY) ? item.scaleY : 1}`
      }

      arr.push(`{
        id: "${item.id}"
        position: {
          x: ${item.x / element.zoomRate}
          y: ${item.y / element.zoomRate}
          number: "${(item.number) ? item.number : ''}"
          ${zoomValue}
          ${scale}
        }
        rotate_angle:${item.r}
        element_id: ${item.elementId}
        business_id: ${business?.filterBusinessList?.id}
        table_number:"${item.number ? item.number : ''}"
        row_number:${item.row_number ? item.row_number : null}
        unique_id:${index}
        is_vip: false
        zone_id : ${item.zone_id ? item.zone_id : null}
        seat_types_id : ${item.seat_types_id ? item.seat_types_id : null}
      }`);
    });

    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation CreateGrid{
						CreateGrid(input:{
							moved:"${element.lastMoved1 ? element.lastMoved1 : ""}"
							moved1:"${element.lastMoved2 ? element.lastMoved2 : ""}"
							list:[${arr.join("")}]
							businessId:${business?.filterBusinessList?.id}
              zoneId : ${zone_id ? zone_id : null}
						}){
							businesse_elements{
								id
								position
								rotate_angle
								is_vip
								element_id
								zone_id
							}
							moved
							moved1
						}
					}
				`,
        })
        .then((data) => {
          createNotification({ type: 'success', title: 'Business element', message: 'Business element saved successfully!' });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({ type: 'danger', title: 'Business element', message: err.message });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  getBusinessElementsRequest(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_element{
						business_element(
							where:{business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }}){
							id
							position
							rotate_angle
							is_vip
							element_id
							business_id
							business{
								location
							}
              element {
                seat_type {
                  id
                }
              }
							zone_id
              row_number
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateSeatNumberRequests(business, element, currentId, number, row_number) {
    let filterResult = element.resultElements.filter(
      (ele) => ele.id == currentId
    );
    try {
      let zoomValue = '', scale = '';
      if (business.filterBusinessList.type == "beach" || business.filterBusinessList.type == "pool") {
        zoomValue = ` zoomRate:${element.zoomRate}
        zoomAreaValue:${element.zoomAreaValue ? element.zoomAreaValue : 0.8}
        displayValue:${element.displayValue ? element.displayValue : 1}
        `
      } else {
        scale = `is_extra_element:${(filterResult[0].position.is_extra_element) ? true : false}
        is_resizable:${(filterResult[0].position.is_resizable) ? true : false}
        scaleX:${(filterResult[0].position.scaleX) ? filterResult[0].position.scaleX : 1}
        scaleY:${(filterResult[0].position.scaleY) ? filterResult[0].position.scaleY : 1}`
      }

      let data = client
        .mutate({
          mutation: gql`
					mutation business_elementUpdate{
						business_elementUpdate(
							business_element:{
								id:${currentId}
                position: {
                  x: ${filterResult[0].position.x}
                  y: ${filterResult[0].position.y}
                  number: "${number ? number : ''}"
                  ${zoomValue}
                  ${scale}
                }
								table_number:"${number ? number : ''}"
                row_number : ${row_number ? row_number : null}
							}
						){
							id
							position
						}
					}
				`,
        })
        .then((data) => {
          createNotification({ type: 'success', title: 'Update sheet number', message: 'Sheet number updated successfully!' });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({ type: 'danger', title: 'Update sheet number', message: 'Sheet number not updated' });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  getZonesActionRequests(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query zone{
						zone(
							where:{business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }}){
							id
							config
							name
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  createSetZonesRequests(element) {
    let arr = [];
    element.dotsData.map((item, index) => {
      arr.push(`{
        id: ${item.id}
        zone_id:${item.zone_id}
      }`);
    });
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation MassSaveZones{
						MassSaveZones(input:{
							list:[${arr.join("")}],
						}){
							result
						}
					}
				`,
        })
        .then((data) => {
          createNotification({ type: 'success', title: 'Mass Zones', message: 'Mass zones saved Successfully!' });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({ type: 'danger', title: 'Mass Zones', message: 'Mass Zones Not updated' });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }
}

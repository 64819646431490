import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionTypes } from "./action";

export const reducer = persistReducer(
  {
    storage,
    key: "sms",
  },
  (state = initState, action) => {
    switch (action.type) {
      case actionTypes.INITIAL_STATE:
        return { ...state };
      case actionTypes.SET_SELECTED_AMOUNT:
        return { ...state, selected_amount: action.amount };
      case actionTypes.SET_CHANGE_AMOUNT:
        return { ...state, amount: action.amount };
      case actionTypes.SET_IS_AUTORECHARGE:
        return { ...state, isAutoRecharge: action.isAutoRecharge };
      case actionTypes.SET_TOPUP_HISTORY:
        return { ...state, topupHistory: action.history };
      case actionTypes.SET_SMS_BALANCE:
        return { ...state, smsBalance: action.balance };
      case actionTypes.SET_SMS_LOGS:
        return { ...state, smsLogs: action.payload?.sms_logs };
      default:
        return state;
    }
  }
);

export const initState = {
  selected_amount: "",
  amount: "",
  isAutoRecharge: false,
  topupHistory: [],
  smsBalance: {},
  smsLogs: [],
};

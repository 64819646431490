import React, { useState, useEffect, Fragment } from "react";
import "../../../../assets/css/general.css";
import { Row, Col, Input, FormGroup, Label } from "reactstrap";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Switch from "@material-ui/core/Switch";

const GridComponent = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    selectData: props.props.status || "Open",
    from: props.props.from || "",
    to: props.props.to || "",
    break_from: props.props.break_from || "",
    break_to: props.props.break_to || "",
  });
  const [checkStatus, setCheckStatus] = useState(false);

  useEffect(() => {
    if (props.props.break_from && props.props.break_to) {
      setCheckStatus(true);
    } else {
      setCheckStatus(false);
    }
  }, [props.props]);

  const statusSelect = (event) => {
    if (event.target.checked === true) {
      setData({ ...data, selectData: "Open" });
      props.parentMethod(props.props.id, "status", "Open");
    } else {
      setData({ ...data, selectData: "Close" });
      props.parentMethod(props.props.id, "status", "Close");
    }
  };

  const checkChange = (event) => {
    props.changeCheck(event.target.checked, props.props.id);
    setCheckStatus(event.target.checked);
  };
  const handleChangeFrom = (date) => {
    let dateTime = moment(date);
    if (dateTime.isValid()) {
      let value = dateTime.format("HH:mm");
      setData({ ...data, from: value });
      props.parentMethod(props.props.id, "from", value);
    }
  };
  const handleChangeTo = (date) => {
    let dateTime = moment(date);
    if (dateTime.isValid()) {
      let value = dateTime.format("HH:mm");
      setData({ ...data, to: value });
      props.parentMethod(props.props.id, "to", value);
    }
  };
  const handleChangeFromBreak = (date) => {
    let dateTime = moment(date);
    if (dateTime.isValid()) {
      let value = dateTime.format("HH:mm");
      if (
        moment(`${data.from}`, "HH:mm").isBefore(moment(`${value}`, "HH:mm"))
      ) {
        setData({ ...data, break_from: value });
        props.parentMethod(props.props.id, "break_from", value);
      }
    }
  };
  const handleChangeToBreak = (date) => {
    let dateTime = moment(date);
    if (dateTime.isValid()) {
      let value = dateTime.format("HH:mm");
      let toValue = moment(`${data.to}`, "HH:mm");
      let fromValue = moment(`${data.from}`, "HH:mm");
      let valueMo = moment(`${value}`, "HH:mm");
      let breakFrom = moment(`${data.break_from}`, "HH:mm");
      if (fromValue.isAfter(toValue)) {
        toValue.add(24, "h");
      }

      if (valueMo.isBefore(fromValue)) {
        valueMo.add(24, "h");
      }

      if (
        toValue.isAfter(valueMo) &&
        fromValue.isBefore(valueMo) &&
        valueMo.isAfter(breakFrom)
      ) {
        setData({ ...data, break_to: value });
        props.parentMethod(props.props.id, "break_to", value);
      }
    }
  };
  const handleFocuseFromBreak = () => {
    if (!data.break_from) {
      setData({ ...data, break_from: "00:00" });
    }
  };
  const handleFocuseToBreak = () => {
    if (!data.break_to) {
      setData({ ...data, break_to: "00:00" });
    }
  };

  return (
    <>
      <div className="d-flex mb-2 working-day-row">
        <div className="work-day-names">
          <div
            className="work-day-short"
            data-abbr={props.props.name.substring(0, 3)}
          >
            <span className="bold">{t(props.props.name)}</span>
          </div>
        </div>
        <div className="d-flex work-day-hours">
          <div className="day-toggler">
            <Switch
              checked={data.selectData === "Open" ? true : false}
              name="day-open-close"
              onChange={statusSelect}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            {/* <Input
              type="select"
              name="select"
              value={data.selectData}
              onChange={statusSelect}
              id="exampleSelect1"
              className="open-close-select"
              style={{ width: "72px" }}
            >
              <option value="Open">{t("Open")}</option>
              <option value="Close">{t("Close")}</option>
            </Input> */}
          </div>

          <div>
            <Datetime
              dateFormat={false}
              value={data.selectData === "Open" ? data.from : ""}
              timeFormat={"HH:mm"}
              inputProps={{
                placeholder:
                  data.selectData === "Open"
                    ? data.from
                      ? data.from
                      : `${t("From")}`
                    : "",
                readOnly: true,
                className: "react-datetime-input working-time-date mr-1",
                disabled: data.selectData === "Open" ? false : true,
              }}
              timeConstraints={{
                minutes: { min: 0, max: 60, step: 30 },
              }}
              onChange={handleChangeFrom}
            />
          </div>
          <div>
            <Datetime
              dateFormat={false}
              value={data.selectData === "Open" ? data.to : ""}
              onChange={handleChangeTo}
              timeFormat={"HH:mm"}
              timeConstraints={{
                minutes: { min: 0, max: 60, step: 30 },
              }}
              inputProps={{
                placeholder:
                  data.selectData === "Open"
                    ? data.to
                      ? data.to
                      : `${t("to")}`
                    : "",
                readOnly: true,
                className: "react-datetime-input working-time-date",
                disabled: data.selectData === "Open" ? false : true,
              }}
            />
          </div>
        </div>
        {props.type === "beach" ||
        props.type === "pool" ||
        props.type === "club" ? null : (
          <div className="d-flex work-day-break">
            <div className="day-toggler">
              <Switch
                checked={data.selectData === "Open" && checkStatus}
                name="day-break"
                onChange={checkChange}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            {(props.type === "restaurant" || props.type === "bar") && (
              <Fragment>
                <div>
                  <Datetime
                    dateFormat={false}
                    value={
                      data.selectData === "Open" && checkStatus
                        ? data.break_from
                        : ""
                    }
                    inputProps={{
                      placeholder:
                        data.selectData === "Open" && checkStatus
                          ? data.break_from
                            ? data.break_from
                            : `${t("From")}`
                          : "",
                      //placeholder: data.break_from ? data.break_from : `${t("From")}`,
                      readOnly: true,
                      className: "react-datetime-input working-time-date mr-1",
                      disabled:
                        data.selectData === "Open" && checkStatus
                          ? false
                          : true,
                    }}
                    timeConstraints={{
                      minutes: { min: 0, max: 60, step: 30 },
                    }}
                    onChange={handleChangeFromBreak}
                    timeFormat={"HH:mm"}
                    //onFocus={ handleFocuseFromBreak }
                  />
                </div>

                <div>
                  <Datetime
                    dateFormat={false}
                    value={
                      data.selectData === "Open" && checkStatus
                        ? data.break_to
                        : ""
                    }
                    onChange={handleChangeToBreak}
                    //onFocus={ handleFocuseToBreak }
                    timeConstraints={{
                      minutes: { min: 0, max: 60, step: 30 },
                    }}
                    inputProps={{
                      placeholder:
                        data.selectData === "Open" && checkStatus
                          ? data.break_to
                            ? data.break_to
                            : `${t("to")}`
                          : "",
                      readOnly: true,
                      className: "react-datetime-input working-time-date",
                      disabled:
                        data.selectData === "Open" && checkStatus
                          ? false
                          : true,
                    }}
                    timeFormat={"HH:mm"}
                    popperPlacement="bottom"
                    popperModifiers={{
                      flip: {
                        behavior: ["bottom"], // don't allow it to flip to be above
                      },
                      preventOverflow: {
                        enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                      },
                      hide: {
                        enabled: false, // turn off since needs preventOverflow to be enabled
                      },
                    }}
                  />
                </div>
              </Fragment>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default GridComponent;

import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import * as gridAction from "./action";
import API from "./api";
import { actionTypes as optionAction } from "../option/action";
import {
  rejectPromiseAction,
  resolvePromiseAction,
} from "@adobe/redux-saga-promise";
import { isBoolean } from "lodash";
const api = new API();

///////////////////
const activityDatePeriodRequest = (value, businessId) => {
  return api.activityDatePeriodRequests(value, businessId);
};

function* activityDatePeriod({ value, callback }) {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(
      activityDatePeriodRequest,
      value,
      business?.filterBusinessList?.id
    );
    if (callback) {
      callback();
    }
  } catch (e) { }
}

const getActivityPeriodRequest = (businessId) => {
  return api.getActivityPeriodRequests(businessId);
};

function* getActivityPeriodSaga(action) {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(
      getActivityPeriodRequest,
      business?.filterBusinessList?.id
        ? business.filterBusinessList.id
        : business.filterBusinessList
    );
    if (result?.data) {
      if (result.data.business_year.length > 0) {
        yield put({
          type: gridAction.actionTypes.RESULT_GET_BUSINESS_DATA,
          value: result.data.business_year,
          startDate: result.data.business_year[0].start,
          endDate: result.data.business_year[0].end,
        });
      } else {
        yield put({
          type: gridAction.actionTypes.RESULT_FAIL_GET_BUSINESS_DATA,
        });
      }
      yield call(resolvePromiseAction, action, result);
    }
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const saveWorkHourActionRequest = (businessId, gridValue, showNotification = true) => {
  return api.saveWorkHourActionRequests(businessId, gridValue);
};

function* saveWorkHourAction(action) {
  try {
    const showNotification = isBoolean(action.payload) ? action.payload : true;
    const business = yield select((store) => store.business);
    const grid = yield select((store) => store.grid);
    const result = yield call(
      saveWorkHourActionRequest,
      business?.filterBusinessList?.id,
      grid.workHourValue,
      showNotification
    );
    if (result.data) {
    }
  } catch (e) { }
}

/////////////////////////////
const getWorkHour = (businessId) => {
  return api.getWorkHourRequests(businessId);
};

function* getHourWork({ resolve }) {
  try {
    const business = yield select((store) => store.business);
    const grid = yield select((store) => store.grid);
    const result = yield call(getWorkHour, business.filterBusinessList.id);

    if (result.data) {
      resolve(result.data.business_week);
      yield put({
        type: gridAction.actionTypes.RESULT_GET_WEEK_HOUR,
        value: result.data.business_week,
      });
    }
  } catch (e) { }
}

const updateActivityDatePeriodRequest = (value, businessId, id, grid) => {
  return api.updateActivityDatePeriodRequests(value, businessId, id, grid);
};

function* updateActivityDatePeriod({ value, callback }) {
  try {
    const business = yield select((store) => store.business);
    const grid = yield select((store) => store.grid);
    // const result = yield call(updateActivityDatePeriodRequest, value, business?.filterBusinessList?.id, grid.getActivityPeriod[0].id, grid);
    if (callback) {
      callback();
    }
  } catch (e) { }
}

const updateWorkHourActionRequest = (businessId, gridValue, id, showNotification = true) => {
  return api.updateWorkHourActionRequests(businessId, gridValue, id, showNotification);
};

function* updateWorkHourAction(action) {
  try {
    const showNotification = isBoolean(action.payload) ? action.payload : true;
    const business = yield select((store) => store.business);
    const grid = yield select((store) => store.grid);
    const result = yield call(
      updateWorkHourActionRequest,
      business?.filterBusinessList?.id,
      grid.workHourValue,
      grid.weekHourData[0].id,
      showNotification
    );
    const result1 = yield call(getWorkHour, business.filterBusinessList.id);

    if (result1.data) {
      yield put({
        type: gridAction.actionTypes.RESULT_GET_WEEK_HOUR,
        value: result1.data.business_week,
      });
    }
    if (result.data) {
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const getZoneNamesRequest = (business, grid) => {
  return api.getZoneNamesRequests(business, grid);
};

function* getZoneNames() {
  try {
    const business = yield select((store) => store.business);
    const grid = yield select((store) => store.grid);
    const result = yield call(getZoneNamesRequest, business, grid);
    if (result.data) {
      // resolve(result.data.custom_query[0].result)
      yield put({
        type: gridAction.actionTypes.RESULT_GET_ZONE_NAME,
        zone1: result.data.custom_query[0].result[0].name,
        zone2:
          result.data.custom_query[0].result.length > 1
            ? result.data.custom_query[0].result[1].name
            : "",
        zone3:
          result.data.custom_query[0].result.length > 2
            ? result.data.custom_query[0].result[2].name
            : "",
        zone4:
          result.data.custom_query[0].result.length > 3
            ? result.data.custom_query[0].result[3].name
            : "",
      });
    }
  } catch (e) { }
}

/////////////////////////
const saveZoneNamesRequest = (value, business, grid, elements) => {
  return api.saveZoneNamesRequests(value, business, grid, elements);
};

function* saveZoneNames(action) {
  try {
    const { value } = action.payload;
    const business = yield select((store) => store.business);
    const element = yield select((store) => store.element);
    const grid = yield select((store) => store.grid);
    const result = yield call(
      saveZoneNamesRequest,
      value,
      business,
      grid,
      element.resultElements
    );
    if (result.data) {
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const getZoneActionRequest = (business) => {
  return api.getZoneActionRequests(business);
};

function* getZoneAction() {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(getZoneActionRequest, business);
    if (result.data) {
      if (result.data.zone.length > 0) {
        const zone1Result = result.data.zone.filter((ele) =>
          ele.config ? ele.config.slug === "zone1" : false
        );
        const zone2Result = result.data.zone.filter((ele) =>
          ele.config ? ele.config.slug === "zone2" : false
        );
        const zone3Result = result.data.zone.filter((ele) =>
          ele.config ? ele.config.slug === "zone3" : false
        );
        const zone4Result = result.data.zone.filter((ele) =>
          ele.config ? ele.config.slug === "zone4" : false
        );
        const vipResult = result.data.zone.filter((ele) =>
          ele.config ? ele.config.slug === "VIP" : false
        );
        yield put({
          type: gridAction.actionTypes.RESULT_GET_ZONE,
          zones: result.data.zone,
          id1: zone1Result.length > 0 ? zone1Result[0].id : "",
          id2: zone2Result.length > 0 ? zone2Result[0].id : "",
          id3: zone3Result.length > 0 ? zone3Result[0].id : "",
          id4: zone4Result.length > 0 ? zone4Result[0].id : "",
          idVIP: vipResult.length > 0 ? vipResult[0].id : "",
          zone1: zone1Result.length > 0 ? zone1Result[0].name : "",
          zone2: zone2Result.length > 0 ? zone2Result[0].name : "",
          zone3: zone3Result.length > 0 ? zone3Result[0].name : "",
          zone4: zone4Result.length > 0 ? zone4Result[0].name : "",
          hasVipZone: vipResult.length > 0 ? true : false,
          count:
            vipResult.length > 0
              ? result.data.zone.length - 1
              : result.data.zone.length,
          length:
            vipResult.length > 0
              ? result.data.zone.length - 1
              : result.data.zone.length,
        });
        const business = yield select((store) => store.business);
        const grid = yield select((store) => store.grid);
        const result1 = yield call(getZoneNamesRequest, business, grid);
        if (result1.data) {
          yield put({
            type: gridAction.actionTypes.RESULT_GET_ZONE_NAME,
            zone1: result1.data.custom_query[0].result[0].name,
            zone2:
              result1.data.custom_query[0].result.length > 1
                ? result1.data.custom_query[0].result[1].name
                : "",
            zone3:
              result1.data.custom_query[0].result.length > 2
                ? result1.data.custom_query[0].result[2].name
                : "",
            zone4:
              result1.data.custom_query[0].result.length > 3
                ? result1.data.custom_query[0].result[3].name
                : "",
          });
        }
      } else {
        yield put({
          type: gridAction.actionTypes.RESULT_GET_ZONE,
          zones: [],
          id1: "",
          id2: "",
          id3: "",
          id4: "",
          idVIP: "",
          zone1: "",
          zone2: "",
          zone3: "",
          zone4: "",
          hasVipZone: false,
          count: 1,
          length: 1,
        });
      }
    } else {
      yield put({
        type: gridAction.actionTypes.RESULT_GET_ZONE,
        zones: [],
      });
    }
  } catch (e) {
  }
}

const createZoneActionRequest = (business, zones) => {
  return api.createZoneActionRequests(business, zones);
};

function* createZones(action) {
  try {
    const { zones } = action.payload;
    const business = yield select((store) => store.business);
    const result = yield call(createZoneActionRequest, business, zones);
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const deleteZoneRequest = (id) => {
  return api.deleteZoneRequest(id);
};

function* deleteZone(action) {
  const { id } = action.payload;
  try {
    const result = yield call(deleteZoneRequest, id);
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const updateZoneNamesRequest = (value, business, grid) => {
  return api.updateZoneNamesRequests(value, business, grid);
};

function* updateZoneNames(action) {
  try {
    const { value } = action.payload;
    const business = yield select((store) => store.business);
    const grid = yield select((store) => store.grid);
    const result = yield call(updateZoneNamesRequest, value, business, grid);
    if (result.data) {
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const saveClosedDaySagaRequest = (businessId, days) => {
  return api.saveClosedDaySagaRequests(businessId, days);
};

function* saveClosedDaySaga({ businessId, days }) {
  try {
    const result = yield call(saveClosedDaySagaRequest, businessId, days);
    if (result.data) {
    }
  } catch (e) { }
}

const saveTimeLineRequest = (timeArrs, businessId, timeLineData, showNotification = true) => {
  return api.saveTimeLineRequests(timeArrs, businessId, timeLineData, showNotification);
};

function* saveTimeLineSaga(action) {
  try {
    const { timeArrs, businessId, showNotification = true } = action.payload;
    const grid = yield select((store) => store.grid);
    const result = yield call(
      saveTimeLineRequest,
      timeArrs,
      businessId,
      grid.timeLineData,
      showNotification,
    );
    if (result.data) {
    }
    yield call(resolvePromiseAction, action, result);
    //return result;
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const getTimeLineRequest = (businessId) => {
  return api.getTimeLineRequests(businessId);
};

function* getTimeLineSaga() {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(
      getTimeLineRequest,
      business?.filterBusinessList?.id
        ? business.filterBusinessList.id
        : business.filterBusinessList
    );
    if (result.data) {
      yield put({
        type: gridAction.actionTypes.RESULT_GET_TIME_LINE,
        timeLineData: result.data.time_line,
      });
    }
  } catch (e) { }
}

const getTemporaryRequest = (id, value) => {
  return api.getTemporaryRequests(id, value);
};
const createBusinessSettingTemporary_closedRequest = (id, value) => {
  return api.createBusinessSettingTemporary_closedRequests(id, value);
};

function* getTemporary({ value }) {
  try {
    const businessSetting = yield select(
      (store) => store.option.businessSettings[0]
    );
    const business = yield select((store) => store.business);
    let result = {};
    if (businessSetting) {
      result = yield call(getTemporaryRequest, businessSetting.id, value);
    } else {
      result = yield call(
        createBusinessSettingTemporary_closedRequest,
        business?.filterBusinessList?.id,
        value
      );
    }

    yield put({ type: optionAction.GET_BEACH_LOCATION });

    if (result.data) {
      yield put({
        type: gridAction.actionTypes.RESULT_GET_TIME_LINE,
        timeLineData: result.data.time_line,
      });
    }
  } catch (e) { }
}

const gridSaveDataRequest = (value, businessId, showNotification = true) => {
  return api.gridSaveDataRequests(value, businessId, showNotification);
};

function* gridSaveDataSaga(action) {
  try {
    const { value, callback, showNotification = true } = action.payload;
    const business = yield select((store) => store.business);
    const result = yield call(
      gridSaveDataRequest,
      value,
      business?.filterBusinessList?.id,
      showNotification
    );

    if (result.data) {
      yield put({
        type: gridAction.actionTypes.RESULT_GET_TIME_LINE,
        timeLineData: result.data.time_line,
      });
    }
    if (callback) {
      callback();
    }
    yield call(resolvePromiseAction, action, {});
    //return;
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(gridAction.actionTypes.ACTIVITY_DATE_PERIOD, activityDatePeriod),
    takeLatest(
      gridAction.actionTypes.UPDATE_ACTIVITY_DATE_PERIOD,
      updateActivityDatePeriod
    ),
    takeLatest(gridAction.getActivityPeriod, getActivityPeriodSaga),
    takeLatest(gridAction.saveWorkHourAction, saveWorkHourAction),
    takeLatest(gridAction.actionTypes.GET_HOUR_WORK, getHourWork),
    takeLatest(gridAction.updateWorkHourAction, updateWorkHourAction),
    takeLatest(gridAction.actionTypes.GET_ZONE_NAME, getZoneNames),
    takeLatest(gridAction.saveZoneAction, saveZoneNames),
    takeLatest(gridAction.updateZoneAction, updateZoneNames),
    takeLatest(gridAction.actionTypes.GET_ZONE, getZoneAction),
    takeLatest(gridAction.actionTypes.SAVE_CLOSED_DAYS, saveClosedDaySaga),
    takeLatest(gridAction.saveTimeLine, saveTimeLineSaga),
    takeLatest(gridAction.actionTypes.GET_TIME_LINE, getTimeLineSaga),
    takeLatest(gridAction.actionTypes.TEMPORARY_CLOSED, getTemporary),
    takeLatest(gridAction.gridSaveDataAction, gridSaveDataSaga),
    takeLatest(gridAction.createZonesAction, createZones),
    takeLatest(gridAction.deleteZoneAction, deleteZone),
  ]);
}

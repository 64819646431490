import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import Switch from "@material-ui/core/Switch";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getExtraSunbedPhoto,
  saveTowelIncluded,
} from "../../../../redux/option/action";
import { startLoading, stopLoading } from "../../../../redux/loader/action";

const PhotoRequired = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [towel_included, settowel_included] = useState(false);

  useEffect(() => {
    dispatch(getExtraSunbedPhoto());
  }, [business.filterBusinessList, dispatch]);
  useEffect(() => {
    settowel_included(option.businessSettings[0]?.towel_included);
  }, [option.businessSettings]);

  const handleWaiterCall = (event) => {
    let towelIncluded = event.target.checked;
    settowel_included(towelIncluded);
    dispatch(startLoading());
    dispatch(saveTowelIncluded(towelIncluded))
      .then((data) => {
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Card className="options-card">
            <Row className="p-3">
              <Col md="10">
                <h5 className="option-title-main">{t("Towel Included")}</h5>
                <p className="option-title-sub">
                  {t("Towel_required_description")}
                </p>
              </Col>
              <Col md="2">
                <Switch
                  checked={towel_included}
                  name="towel_included"
                  onChange={handleWaiterCall}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default PhotoRequired;

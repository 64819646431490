import React, { useState, useEffect, useRef } from "react";
import {
  Rect,
  Image,
  Layer,
  Stage,
  Transformer,
} from "react-konva";
import {
  Row,
  Col,
  Modal,
  Input,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Konva from "konva";
import useImage from "use-image";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import SweetAlert from "react-bootstrap-sweetalert";

import { startLoading, stopLoading } from "../../../../redux/loader/action";
import { updateSeatNumber } from "../../../../redux/element/action";

const SetNumber = ({ zoomRate, zoomAreaValue, selectedZone }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const business = useSelector(({ business }) => business);
  const element = useSelector(({ element }) => element);
  const [elements, setElements] = useState([]);
  const [modalStatus, setModalStatus] = useState(false);
  const [seatNumber, setSeatNumber] = useState("0");
  const [row_number, setRow_number] = useState();

  const [currentId, setCurrentId] = useState(0);
  const stageRef = useRef();
  const layerRef = useRef();
  const trRef = useRef();
  ////////////////////////////////
  const oldPos = useRef(null);
  const selectionRectRef = useRef();
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });
  useEffect(() => {
    if (element.resultElements.length > 0) {
      let filterZoneData = element.resultElements;
      let arr = [];
      const devine =
        business.filterBusinessList.type === "beach" ||
          business.filterBusinessList.type === "pool"
          ? 2
          : 1;
      const appliedZoomRate =
        business.filterBusinessList.type === "beach" ||
          business.filterBusinessList.type === "pool"
          ? zoomRate
          : 1;
      if (filterZoneData.length > 0 && element.allElement.length > 0) {
        filterZoneData.map((item, index) => {
          let filterWidthHeight = element.allElement.filter(
            (ele) => ele.id === item.element_id
          );
          if (filterWidthHeight.length > 0) {
            filterZoneData[index]["width"] =
              filterWidthHeight[0].width * appliedZoomRate;
            filterZoneData[index]["height"] =
              filterWidthHeight[0].height * appliedZoomRate;
            arr.push({
              id: `${item.id}`,
              elementId: item.element_id,
              h: (filterWidthHeight[0].height / devine) * appliedZoomRate,
              w: (filterWidthHeight[0].width / devine) * appliedZoomRate,
              number: item.position.number,
              row_number: item.row_number,
              src: `/element/${item.element_id}.png`,
              x: item.position.x * zoomRate,
              y: item.position.y * zoomRate,
              r: item.rotate_angle ? item.rotate_angle : 0,
              scaleX: item.position.scaleX,
              scaleY: item.position.scaleY,
              zone_id: item.zone_id,
            });
          }
        });
      }
      if (
        business.filterBusinessList.type === "restaurant" ||
        business.filterBusinessList.type === "bar" ||
        business.filterBusinessList.type === "club"
      ) {
        arr = arr.filter((el) => {
          return selectedZone && el.zone_id === selectedZone.id;
        });
      }
      setElements(arr);
    }
  }, [business.filterBusinessList, element.resultElements, element.allElement]);

  useEffect(() => {
    if (elements.length > 0) {
      if (layerRef && layerRef.current) {
        layerRef.current.find(`.text`).destroy();
        layerRef.current.find(`.backgroundReact`).destroy();
        elements.map((item, index) => {
          let fontSize = 15,
            fontX = 8,
            fontY = 15;
          if (
            business.filterBusinessList.type === "beach" ||
            business.filterBusinessList.type === "pool"
          ) {
            fontSize = 20;
          } else {
            if (item.w < 60) {
              fontSize = 10;
            }
          }

          let width1 = item.w * Math.cos((item.r * Math.PI) / 180);
          let width2 = item.h * Math.sin((item.r * Math.PI) / 180);
          let height1 = item.w * Math.sin((item.r * Math.PI) / 180);
          let height2 = item.h * Math.cos((item.r * Math.PI) / 180);
          const textWidth = item.number
            ? item.number.toString().length +
            (item.row_number ? item.row_number.toString().length : 0)
            : 1;
          const rectWidth = (textWidth < 2 ? 2 : textWidth) * 9 * zoomRate;
          const rectX = item.x * zoomRate;
          const finalRectX =
            item.w > rectWidth
              ? rectX + ((item.w - rectWidth) * zoomRate) / 2.2
              : rectX - ((rectWidth - item.w) * zoomRate) / 0.6;
          var rect2 = new Konva.Rect({
            x: finalRectX, // (item.x + (Math.round(width1) - Math.round(width2)) / 1.70) * zoomRate - (Math.round(width1 / 8) + ((item.number ? item.number.toString().length + (item.row_number ? item.number.length + 1 : 0) : 1) * 2)),
            y:
              (item.y +
                Math.round(height2) +
                (Math.round(height1) - Math.round(height2)) /
                (business.filterBusinessList.type === "restaurant"
                  ? 10
                  : 3.5)) *
              zoomRate -
              13,
            width: rectWidth,
            height: 20 * zoomRate,
            fill: "transparent",
            name: "backgroundReact",
          });
          // ${item.row_number ? item.row_number + "-" : ""}
          // row number we will use later
          const finalTextX = finalRectX + (textWidth < 2 ? 4 : textWidth);
          var text = new Konva.Text({
            x:
              business.filterBusinessList.type === "beach" ||
                business.filterBusinessList.type === "pull"
                ? finalTextX - 10
                : (item.x + (Math.round(width1) - Math.round(width2)) / 1.85) *
                zoomRate -
                (Math.round(width1 / 15) +
                  (item.number ? item.number.toString().length : 1) * 2),
            y:
              (item.y +
                Math.round(height2) +
                (Math.round(height1) - Math.round(height2)) /
                (business.filterBusinessList.type === "restaurant" ? 3 : 3)) *
              zoomRate -
              (business.filterBusinessList.type === "beach" ||
                business.filterBusinessList.type === "pull"
                ? 10
                : Math.round(height2 / 3)),
            text: `${item.number ? item.number : ""}`,
            fontSize: fontSize * zoomRate,
            fontStyle: "bold",
            // fontFamily: "Roboto",
            fill: "#000",
            stroke: "#29A9E5",
            name: "text",
            strokeEnabled: false,
            strokeWidth: 5,
            padding: 2,
            sceneFunc: function (context, shape) {
              context.fillStyle = 'rgb(255,255,255)';
              context.fillRect(0, 0, shape.width(), shape.height());
              (shape)._sceneFunc(context);
            },
          });

          if (
            business.filterBusinessList.type === "restaurant" ||
            business.filterBusinessList.type === "bar" ||
            business.filterBusinessList.type === "club"
          ) {
            layerRef.current.add(text);
          } else {
            if (item.row_number || item.number) {
              layerRef.current.add(rect2);
            }
            layerRef.current.add(text);
          }
        });
        layerRef.current.draw();
      }
    }
  }, [business.filterBusinessList, elements, zoomRate]);
  const updateSelectionRect = () => {
    const node = selectionRectRef.current;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(0, 161, 255, 0)",
    });
    node.getLayer().batchDraw();
  };
  const onMouseDown = (e) => {
    const isElement = e.target.findAncestor(".elements-container");
    const isTransformer = e.target.findAncestor("Transformer");
    if (isElement || isTransformer) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.visible = true;
    selection.current.x1 = pos.x;
    selection.current.y1 = pos.y;
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };
  const onMouseMove = (e) => {
    if (!selection.current.visible) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };
  const onMouseUp = () => {
    oldPos.current = null;
    if (!selection.current.visible) {
      return;
    }
    const selBox = selectionRectRef.current.getClientRect();
    const elements = [];
    layerRef.current.find(".rectangle").forEach((elementNode) => {
      const elBox = elementNode.getClientRect();
      if (Konva.Util.haveIntersection(selBox, elBox)) {
        elements.push(elementNode);
      }
    });
    selection.current.visible = false;
    Konva.listenClickTap = false;
    updateSelectionRect();
    if (trRef.current) {
      trRef.current.nodes(elements);
      if (trRef && trRef.current) {
        if (trRef.current._nodes.length > 0) {
          let attrs = trRef.current._nodes[0].attrs;
          setSeatNumber(attrs.number);
          setRow_number(attrs.row_number);
          setCurrentId(attrs.id);
          trRef.current.nodes([]);
          setModalStatus(true);
        }
      }
    }
  };
  const handleChangeSeat = (event) => {
    // if (event.target.value.length < 5) {
    setSeatNumber(event.target.value);
    // }
  };

  const handleChangeRow = (event) => {
    setRow_number(event.target.value);
  };

  const agreeSave = (event) => {
    dispatch(startLoading());
    return new Promise((resolve, reject) => {
      dispatch(updateSeatNumber(resolve, currentId, seatNumber, row_number));
    })
      .then((success) => {
        if (success) {
          setModalStatus(false);
        }
        dispatch(stopLoading());
      })
      .catch((errors) => {
        dispatch(stopLoading());
      });
  };
  const toggleModalDemoRule = () => {
    setModalStatus(!modalStatus);
  };
  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <div
            className="create-element-style custom-shadow-class"
            style={{ background: selectedZone ? selectedZone.color : "#fff" }}
          >
            <Stage
              width={window.innerWidth * zoomAreaValue}
              height={980 * zoomAreaValue}
              ref={stageRef}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
              onMouseMove={onMouseMove}
            // onTouchStart={checkDeselect}
            >
              <Layer ref={layerRef}>
                {elements.map((element, index) => {
                  return (
                    <SvgImage
                      key={index}
                      element={element}
                      zoomRate={zoomRate}
                    />
                  );
                })}
                <Transformer
                  borderDash={[4, 3]}
                  name="transformer"
                  anchorCornerRadius={5}
                  anchorStrokeWidth={15}
                  borderStrokeWidth={1}
                  padding={16}
                  keepRatio={false}
                  resizeEnabled={false}
                  rotateEnabled={false}
                  enabledAnchors={[]}
                  anchorFill="#29A9E5"
                  rotationSnaps={[0, 90, 180, 270]}
                  rotateAnchorOffset={0}
                  ref={trRef}
                />
                <Rect fill="rgba(0,0,255,0)" ref={selectionRectRef} />
              </Layer>
            </Stage>
          </div>
        </Col>
      </Row>
      {modalStatus && (
        <Modal
          autoFocus={false}
          isOpen={modalStatus}
          toggle={toggleModalDemoRule}
        >
          <ModalBody>
            <div className="d-flex">
              {(business.filterBusinessList.type === "beach" ||
                business.filterBusinessList.type === "pool") && (
                  <div style={{ marginRight: "20px", width: "100%" }}>
                    <h5 className="bold text-center">{t("row Number")}</h5>
                    <Input
                      type="number"
                      name="row_number"
                      autoFocus={true}
                      value={row_number}
                      onChange={handleChangeRow}
                      placeholder={t("row Number")}
                    />
                  </div>
                )}
              <div style={{ width: "100%" }}>
                <h5 className="bold text-center">{t("Edit Seat Number")}</h5>
                <Input
                  type="text"
                  autoFocus={true}
                  name="seatNumber"
                  value={seatNumber}
                  onChange={handleChangeSeat}
                  placeholder={t("Edit Seat Number")}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      agreeSave(e);
                    }
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button className="save-btn-secondary" onClick={agreeSave}>
              {t("Save")}
            </Button>
            <Button color="danger" onClick={toggleModalDemoRule}>
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Modal>
        // <SweetAlert
        //   input
        //   showCancel
        //   style={{ display: "block", marginTop: "-100px" }}
        //   title="Edit Seat Number"
        //   onConfirm={handleSuccess}
        //   value={seatNumber}
        //   onCancel={hideAlert}
        //   confirmBtnBsStyle="info"
        //   cancelBtnBsStyle="danger"
        // />
      )}
    </React.Fragment>
  );
};

export default SetNumber;

const SvgImage = ({ element, zoomRate }) => {
  const [img] = useImage(element.src);
  return (
    <Image
      image={img}
      x={element.x * zoomRate}
      y={element.y * zoomRate}
      id={element.id}
      name="rectangle"
      width={element.w * zoomRate}
      height={element.h * zoomRate}
      scaleX={element.scaleX}
      scaleY={element.scaleY}
      rotation={element.r}
      number={element.number}
      row_number={element.row_number}
    />
  );
};

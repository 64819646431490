import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import rootReducer from './rootredux';
import rootSaga from './rootSaga';
import { promiseMiddleware } from '@adobe/redux-saga-promise'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const persistConfig = {
//   key: 'SmartBeach',
//   storage,
//   whitelist: [
//     "auth",
//   ],
// }

export default () => {
	const sagaMiddleware = createSagaMiddleware();
	const store = createStore(
		rootReducer,
		composeEnhancers(applyMiddleware(promiseMiddleware,sagaMiddleware))
	);

	const persistor = persistStore(store);

	store.sagaTask = sagaMiddleware.run(rootSaga);
	return { store, persistor };
};
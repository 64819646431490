/*eslint-disable*/
import React from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
} from "react-table";
import {
  Row,
  Col,
  Input,
  Container,
  FormGroup,
} from "reactstrap";
import JsPDF from "jspdf";
import moment from "moment";
import Select from "react-select";
import classnames from "classnames";
import matchSorter from "match-sorter";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import VirtualizedSelect from "react-virtualized-select";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

import "jspdf-autotable";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <FormGroup>
      <Input
        placeholder={`Search ${count} records...`}
        type="text"
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table(props) {
  const tableId = "data-table-id";
  const { columns, data, initialState, showGeneralSearch = false, isSearchable = true, showOnMobileView = false, hasPagination = true, isMsgDisplay = false, isPrint = true, hasLastKeySortable = false} = props;
  const [tableData, setTableData] = React.useState(data);
  const [numberOfRows, setNumberOfRows] = React.useState({
    value: initialState?.pageSize || 50,
    label: `${initialState?.pageSize || 50} rows`,
  });
  const [pageSelect, handlePageSelect] = React.useState({
    value: 0,
    label: "Page 1",
  });
  const [search, setSearch] = React.useState("");
  const searchTimeout = React.useRef(0);
  const { t } = useTranslation();

  React.useEffect(() => {
    setTableData(data);
  }, [data]);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    pageOptions,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data: tableData,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: initialState ? initialState : { pageSize: 50, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination,
  );
  
  const exportPdf = () => {
    if (rows.length > 0) {
      const displayData = rows.map(p => {
        let newData = [];
        for (let index = 0; index < columns.length; index++) {
          const { data_field } = columns[index];
          if (data_field) {
            newData.push(p?.original[`${data_field}`])
          }
        }
        return newData;
      });
      const headers = columns.filter(c => c.accessor !== "actions").map(c => c.Header);
      const doc = new JsPDF();
      doc.autoTable({
        head: [headers],
        body: displayData,
      });
      window.open(URL.createObjectURL(doc.output("blob")));
    }
  };
  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () { });
  let numberOfRowsData = [10, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500];

  const onSearch = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    if (searchTimeout.current) clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      let newData = [...data];
      if (searchValue) {
        newData = newData.filter(td => {
          const { alias = "", birth_date = "", city = {}, email = "", phone_number = "" } = td;
          return (alias && alias.toLowerCase().includes(searchValue.toLowerCase()))
            || (birth_date && birth_date.toLowerCase().includes(searchValue.toLowerCase()))
            || (city && city?.name && city?.name.toLowerCase().includes(searchValue.toLowerCase()))
            || (email && email.toLowerCase().includes(searchValue.toLowerCase()))
            || (phone_number && phone_number.toLowerCase().includes(searchValue.toLowerCase()));
        });
      }
      setTableData(newData);
    }, 300);
  };

  const getDaysDifferenceFromCurrentDay = (date) => {
    const oDate = new Date(date);
    const day = oDate.getDate();
    const month = oDate.getMonth();
    const today = new Date(moment().format("DD MMM YYYY"));
    const bday = new Date(today.getFullYear(), month, day);
    if (today.getTime() > bday.getTime()) {
      bday.setFullYear(bday.getFullYear() + 1);
    }
    const diff = bday.getTime() - today.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days;
  }

  const getColors = (date) => {
    if (date) {
      const days = getDaysDifferenceFromCurrentDay(date);
      const isSameDay = days === 0;
      const isIn1STWeek = days > 0 && days <= 7;
      const isIn2NDWeek = days > 7 && days <= 14;
      const isIn3RDWeek = days > 14 && days <= 21;
      if (isSameDay || isIn1STWeek) return { backgroundColor: "rgba(255, 0, 0, 0.20)" };
      if (isIn2NDWeek) return { backgroundColor: "rgba(255, 102, 0, 0.20)" };
      if (isIn3RDWeek) return { backgroundColor: "rgba(255, 255, 0, 0.20)" };
    }
    return {};
  }

  return (
    <>
      <div className={`Desktop-React-Table ReactTable -striped -highlight primary-pagination`}>
       {hasPagination && <div className="pagination-top">
          <div className="-pagination">
            <Container fluid>
              <Row noGutters>
                <Col md="6" sm="6" xs="12">
                  <Row>
                 {isSearchable &&
                    <Col xl="10" sm="9" xs="10" className="px-0">
                      <div className="-search">
                        <FormGroup>
                          <Input
                            type="text"
                            value={search}
                            onChange={onSearch}
                            placeholder={`Search for alias, phone number, email, city...`}
                          />
                        </FormGroup>
                      </div>
                    </Col>}
                   {isPrint && <Col xl="2" sm="3" xs="2">
                      <button
                        type="button"
                        className="-btn"
                        onClick={() => exportPdf("pdf", true)}
                      >
                        Print
                      </button>
                    </Col>}
                  </Row>
                </Col>
                <Col md="1" sm="1" xs="12" className="px-0">
                  <button
                    type="button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className="-btn"
                  >
                    Previous
                  </button>
                </Col>
                <Col md="4" sm="4" xs="12" className="px-0">
                  <Row className="justify-content-center">
                    <Col md="6" sm="6" xs="12" className="pr-0">
                      <Select
                        className="react-select primary react-table-select hide-clear"
                        classNamePrefix="react-select"
                        name="pageSelect"
                        value={pageSelect}
                        onChange={(value) => {
                          gotoPage(value.value);
                          handlePageSelect(value);
                        }}
                        options={pageSelectData.map((prop, key) => {
                          return {
                            value: key,
                            label: "Page " + (key + 1),
                          };
                        })}
                        placeholder="Choose Page"
                      />
                    </Col>
                    <Col md="6" sm="6" xs="12">
                      <Select
                        className="react-select primary react-table-select hide-clear"
                        classNamePrefix="react-select"
                        name="numberOfRows"
                        value={numberOfRows}
                        onChange={(value) => {
                          setPageSize(value.value);
                          setNumberOfRows(value);
                        }}
                        options={numberOfRowsData.map((prop) => {
                          return {
                            value: prop,
                            label: prop + " rows",
                          };
                        })}
                        placeholder="Choose Rows"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md="1" sm="1" xs="12" className="px-0">
                  <button
                    type="button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className="-btn"
                  >
                    Next
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>}
        {!showOnMobileView && <div className="Mobile-React-Table .ReactTable .-pagination">
        <Row style={{ marginLeft: 0, marginRight: 0 }}>
        {isSearchable && <Col md={9} sm={9} xs={9} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="-search">
              <FormGroup>
                <Input
                  type="text"
                  value={search}
                  onChange={onSearch}
                  placeholder={`Search for alias, phone number, email, city...`}
                />
              </FormGroup>
            </div>
          </Col>}
          {isPrint && <Col md={3} sm={3} xs={3} style={{ paddingRight: 0 }}>
            <Button
              variant="primary"
              className="export-btn"
              onClick={() => exportPdf("pdf", true)}
            >
              Print
            </Button>
          </Col>}
          <Col md={5} sm={5} xs={5} style={{ paddingLeft: 0 }}>
            <VirtualizedSelect
              className="react-select primary react-table-select hide-clear"
              name="numberOfRows"
              value={numberOfRows}
              onChange={(value) => {
                setPageSize(value.value);
                setNumberOfRows(value);
              }}
              options={numberOfRowsData.map((prop) => {
                return {
                  value: prop,
                  label: prop + " rows",
                };
              })}
              placeholder="Choose Rows"
            />
          </Col>
          <Col md={7} sm={7} xs={7} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="table-page-container">
              <button
                type="button"
                className="-btn"
                style={{ marginRight: 5 }}
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
              >
                <ArrowBack fontSize="medium" />
              </button>
              <VirtualizedSelect
                className="select-page hide-clear"
                name="pageSelect"
                value={pageSelect}
                onChange={(value) => {
                  if (value) {
                    gotoPage(value.value);
                    handlePageSelect(value);
                  }
                }}
                options={pageSelectData.map((prop, key) => {
                  return {
                    value: key,
                    label: "Page " + (key + 1),
                  };
                })}
                placeholder="Choose Page"
              />
              <button
                type="button"
                style={{ marginLeft: 5 }}
                onClick={() => nextPage()}
                disabled={!canNextPage}
                className="-btn"
              >
                <ArrowForward fontSize="medium" />
              </button>
            </div>
          </Col>
        </Row>
        <div>
          {
            page.map((row) => {
              const { id: rowId, original = {} } = row;
              const {
                actions,
                city = {},
                alias = "",
                email = "",
                avg_rate = "",
                birth_date = "",
                phone_number = "",
                total_reservation = "",
              } = original;
              const { name: cityName = "" } = city || {};
              return (
                <div key={rowId} className="user-card" style={getColors(birth_date)}>
                  {phone_number ? (
                    <div>
                      <label>{t('Phone')}: {'\u00A0'}{'\u00A0'}</label>{phone_number}
                    </div>
                  ) : null}
                  {alias ? (
                    <div>
                      <label>{t('Name')}: {'\u00A0'}{'\u00A0'}</label>{alias}
                    </div>
                  ) : null}
                  {avg_rate ? (
                    <div>
                      <label>{t('AvgRate')}: {'\u00A0'}{'\u00A0'}</label>{avg_rate}
                    </div>
                  ) : null}
                  {total_reservation ? (
                    <div>
                      <label>{t('Reservations')}: {'\u00A0'}{'\u00A0'}</label>{total_reservation}
                    </div>
                  ) : null}
                  {email ? (
                    <div>
                      <label>{t('Email')}: {'\u00A0'}{'\u00A0'}</label>{email}
                    </div>
                  ) : null}
                  {birth_date ? (
                    <div>
                      <label>{t('Birthday')}: {'\u00A0'}{'\u00A0'}</label>{birth_date}
                    </div>
                  ) : null}
                  {cityName ? (
                    <div>
                      <label>{t('City')}: {'\u00A0'}{'\u00A0'}</label>{cityName}
                    </div>
                  ) : null}
                  <div className="action-btns">
                    {actions}
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>}
      <table {...getTableProps()} id={tableId} className="rt-table">
        <thead className="rt-thead -header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {headerGroup.headers.map((column, key) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={classnames("rt-th rt-resizable-header", {
                    "-cursor-pointer": headerGroup.headers.length - 1 !== key || hasLastKeySortable,
                    "-sort-asc": column.isSorted && !column.isSortedDesc,
                    "-sort-desc": column.isSorted && column.isSortedDesc,
                  })}
                >
                  <div className="rt-resizable-header-content">
                    {column.render("Header")}
                  </div>
                  {/* Render the columns filter UI */}
                  {!showGeneralSearch ? (
                    <div>
                      {headerGroup.headers.length - 1 === key 
                        ? null
                        : column.canFilter
                          ? column.render("Filter")
                          : null}
                    </div>
                  ) : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="rt-tbody">
        {data?.length ? 
          page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={classnames(
                  "rt-tr",
                  { " -odd": i % 2 === 0 },
                  { " -even": i % 2 === 1 }
                )}
                style={getColors(row?.original?.birth_date)}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="rt-td">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          }): isMsgDisplay && "No data found"}
        </tbody>
      </table>
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;

import React, { useEffect, useState } from "react";
import axios from "axios";
import FeeInvoice from "../Components/FeeInvoice.js";
import InvoiceModal from "../Components/InvoiceModal.js";
import { calls } from "../../utils/calls.js";

const FeeInvoicePage = ({ match }) => {
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const invoiceNumber = match?.params?.invoiceNumber;
  useEffect(() => {
    if (invoiceNumber) {
      getInvoice(invoiceNumber)
    }
  }, [invoiceNumber]);
  const getInvoice = (invoiceNumber) => {
    axios(calls.getFeeInvoice(invoiceNumber))
      .then((result) => {
        setSelectedInvoice(result.data)
      })
      .catch(() => { });
  };

  const handleCloseEvent = () => {
    window.location.href = "/login"
  }

  return (
    <>
      <div className="content" style={{ marginTop: "2rem" }}>
        {
          selectedInvoice && (
            <InvoiceModal showInvoice={true} invoice={selectedInvoice} setShowInvoiceState={handleCloseEvent} invoices={[]} mutliple={false} InvoiceComponent={FeeInvoice}></InvoiceModal>
          )
        }
      </div>
    </>
  );
}

export default FeeInvoicePage;

import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationRO from "./locales/ro/translation.json";
import translationIT from "./locales/it/translation.json";
import translationES from "./locales/es/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationPT from "./locales/pt/translation.json";
import translationDE from "./locales/de/translation.json";
import translationTR from "./locales/tr/translation.json";
import translationGR from "./locales/gr/translation.json";
import translationBG from "./locales/bg/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationAE from "./locales/ae/translation.json";
import translationIN from "./locales/in/translation.json";
import translationHR from "./locales/hr/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ro: {
    translation: translationRO,
  },
  it: {
    translation: translationIT,
  },
  hr: {
    translation: translationHR,
  },

  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  pt: {
    translation: translationPT,
  },

  de: {
    translation: translationDE,
  },
  tr: {
    translation: translationTR,
  },
  gr: {
    translation: translationGR,
  },

  bg: {
    translation: translationBG,
  },
  ru: {
    translation: translationRU,
  },
  ae: {
    translation: translationAE,
  },
  in: {
    translation: translationIN,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,

    resources,
    // lng: "en",
    saveMissing: true,
    missingKeyHandler: (ng, ns, key, fallbackValue) => {
      // console.error(`Trans Key not found ---->${key}`, fallbackValue);
    },
    // fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      wait: true,
    },
  });

export default i18n;

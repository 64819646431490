import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createFacility,
  deleteFacility,
  saveBusinessSettings,
} from "../../../../redux/option/action";
import { startLoading, stopLoading } from "../../../../redux/loader/action";
import axios from "axios";
import { calls } from "../../../../utils/calls";
import { createNotification } from "../../../../utils/utils";

const BusinessLogo = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [logo, setLogo] = useState("");
  const [check, setCheck] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const fileInput = useRef();

  useEffect(() => {
    if (
      option.businessSettings && option.businessSettings.length > 0
    ) {
      setLogo(option.businessSettings[0].logo);
      setCheck(option.businessSettings[0].allowed_logo_landing_page);
    }
  }, [option.businessSettings]);

  const handleChange = (event) => {
    setCheck(event.target.checked);
    let mutation = `{
      id : ${option.businessSettings[0].id}
      allowed_logo_landing_page: ${event.target.checked}
    }`;
    dispatch(startLoading());
    dispatch(saveBusinessSettings(mutation)).then(data => {
      dispatch(stopLoading());
      setDisableSave(true);
    }).catch(e => {
      dispatch(stopLoading());
    });
  };

  const updateStateValue = (event) => {
    setDisableSave(false);
    if (!event.target.value) {
      setDisableSave(true);
    }
    setCheck(event.target.value);
  };

  const handleClick = () => {
    setTimeout(() => {

    }, 100);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    let data = new FormData();
    data.append('businessLogo', file);
    data.append('business_id', business.filterBusinessList.id);
    dispatch(startLoading())
    axios(calls.uploadLogo(business.accessToke, data)).then((result) => {
      setLogo(result.data.data.path);
    }).catch((e) => {
      createNotification({
        type: "danger",
        title: t("Guest"),
        message: e.response?.data?.error || e.message,
      });
    }).finally(() => {
      dispatch(stopLoading())
    })
  };

  const handleRemove = () => (event) => {
    axios(calls.removeLogo(business.accessToke, business.filterBusinessList.id)).then((result) => {
      setLogo("")
    }).catch((e) => {
      createNotification({
        type: "danger",
        title: t("Guest"),
        message: e.response?.data?.error || e.message,
      });
    }).finally(() => {
      dispatch(stopLoading())
    })
  };

  return (
    <React.Fragment>
      <div className="card-divider-line"></div>
      <Card className="options-card">
        <Row className="p-3">
          <Col md="10">
            <h5 className="option-title-main">{t("Logo")}</h5>
            <p className="option-title-sub mb-3">{t('logo description')}</p>
            <div>
              <div className="fileinput text-center">
                <input
                  type="file"
                  onChange={handleImageChange}
                  ref={fileInput}
                  id={"logo"}
                />

                {logo ? (
                  <div className="business_logo_style">
                    <img src={logo} alt="Business Logo" />
                  </div>
                ) : (
                  <label for="logo" className={'thumbnail img-circle'}>
                    <img src={`/image_placeholder.jpg`} alt="..." height="100px" />
                  </label>
                )}
                <div>
                  {logo ? (
                    <div className="btn btn-danger">
                      <span
                        onClick={handleRemove()}
                      >
                        {t('Remove')}
                      </span>
                    </div>
                  ) : (
                    <span className="change" onClick={handleClick('selectImage')}>
                      {`${t('Select image')}`}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      {/* <div className="card-divider-line"></div>
      <Card className="options-card">
        <Row className="p-3">
          <Col md="10">
            <h5 className="option-title-main">{t("Show logo on landing page")}</h5>
            <p className="option-title-sub mb-3">{t('Show logo on landing page')}</p>
          </Col>
          <Col md="2">
            <Switch
              checked={check}
              name="exChef"
              onChange={handleChange}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Col>
        </Row>
      </Card> */}
    </React.Fragment>
  );
};
export default BusinessLogo;

import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from "reactstrap";
import Fuse from "fuse.js";
import axios from "axios";
import { connect } from "react-redux";
import { calls } from "../../../utils/calls.js";
import Invoice from "../../Components/Invoice.js";
import InvoiceModal from "../../Components/InvoiceModal.js";
import moment from "moment";
import { ExportJsonCsv } from 'react-export-json-csv';
import Datetime from "react-datetime";

class Clients extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      clients: [],
      showInvoice: false,
      selectedInvoice: null,
      centered: true,
      filteredClients: [],
      isMultiple: false,
      headers: [{
        key: 'id',
        name: 'ID',
      }],
      exportData: [],
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month')
    };

    this.searchOptions = {
      keys: ["name", "city", "status"],
      shouldSort: true,
    };
  }

  componentDidMount() {
    this.getAllClients();
  }

  componentDidUpdate(prevProps) {

  }

  getAllClients = () => {
    const { business } = this.props;
    axios(calls.getAllClients(business.accessToken))
      .then((result) => {
        result.toJSON()
      })
      .then(
        (result)=>console.log(result)
      )
      .catch(() => { });
  };

  openInvoice = (invoice) => {
    this.setState({
      showInvoice: true,
      selectedInvoice: invoice,
      isMultiple: false
    })
  }

  setShowInvoiceState = () => {
    this.setState({
      showInvoice: false,
      selectedInvoice: null
    })
  }

  onInvoiceSearchChange = (event) => {
    const { clients } = this.state;
    let searchTerm = event.target.value;

    let fuse = new Fuse(clients, {
      keys: ["location_name", "invoice_id", "reservation_unique_id", "customer.email"],
      shouldSort: true,
      minMatchCharLength: 1,
      findAllMatches: true,
      threshold: 0.2,
      distance: 50,
    });
    let filteredClients = fuse
      .search(searchTerm)
      .map((result) => result.item);
    if (searchTerm.length === 0) {
      filteredClients = clients;
    }
    filteredClients = filteredClients.sort((a, b) => {
      let dateA = new Date(a.created_at);
      let dateB = new Date(b.created_at);
      return dateB - dateA;
    });
    this.setState({ filteredClients: filteredClients, searchTerm });
  };

  handlePrintAll = () => {
    this.setState({
      showInvoice: true,
      selectedInvoice: null,
      isMultiple: true
    })
  }

  exportDataFromInvoice = () => {
    return [{
      id: 1
    }];
  }

  changeStartDate = (date) => {
    this.setState({
      startDate: date
    })
  }

  changeEndDate = (date) => {
    this.setState({
      endDate: date
    })
  }

  render() {
    const { searchTerm, clients, filteredClients, showInvoice, selectedInvoice, isMultiple, headers, startDate, endDate } = this.state;
    return (
      <>
        <div className="content" style={{ marginTop: "2rem" }}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader className="px-4">
                  {/* <Row>
                    <Col xs={'auto'}><h5 className="title">All Clients</h5></Col>
                    <Col xs={'auto'}>
                      <Datetime
                        name="start"
                        value={startDate}
                        timeFormat={false}
                        inputProps={{
                          placeholder: startDate ? startDate : "Start Date",
                        }}
                        onChange={this.changeStartDate}
                        closeOnSelect
                      />

                    </Col>
                    <Col xs={'auto'}>
                      <Datetime
                        name="end"
                        value={endDate}
                        timeFormat={false}
                        inputProps={{
                          placeholder: startDate ? startDate : "End Date",
                        }}
                        onChange={this.changeEndDate}
                        closeOnSelect
                      />
                    </Col>
                    <Col xs={'auto'}>
                      <Button color="primary" onClick={this.getAllClients}>Get Invoice!</Button>
                    </Col>
                  // </Row> */}
                  {/* // <Button color="primary" onClick={this.handlePrintAll}>Print this out!</Button> */}
                  {/* // <ExportJsonCsv headers={headers} items={this.exportDataFromInvoice()}>Export</ExportJsonCsv> */}
                </CardHeader>
                <CardBody>
                  {/* <div className="d-flex justify-content-start">
                    <input
                      value={searchTerm}
                      onChange={this.onInvoiceSearchChange}
                      placeholder={"Search for a location"}
                    />
                  </div> */}
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Select All</th>
                        <th className="text-left">First Name</th>
                        <th className="text-left">Last Name</th>
                        <th className="text-left">Email</th>
                        <th className="text-center">Language</th>
                        <th className="text-center">Last Version Accepted</th>
                        <th className="text-center">Send</th>
                        {/* <th className="text-center">Created</th>
                        <th className="text-left">Total Price</th>
                        <th className="text-right">Actions</th> */}
                      </tr>
                    </thead>
                    {filteredClients.length > 0 ? (
                      <tbody>
                        {filteredClients.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-left">{item.id}</td>
                              <td className="text-left">
                                {item.invoice_id}
                              </td>
                              <td className="text-center">{item.beach_reservation_id || item.restaurant_reservation_id}</td>
                              <td className="text-center">{item.reservation_unique_id}</td>
                              <td className="text-center">{item.location_name}</td>
                              <td className="text-center">{item.customer_first_name} {item.customer_last_name}</td>
                              <td className="text-center">{item.customer ? item.customer.email : ""}</td>
                              <td className="text-center">{moment(item.created_at).format("DD-MMM-YYYY")}</td>
                              <td className="text-center">{item.total_price.toFixed(2)} ({item.currency})</td>
                              <td className="d-flex flex-column justify-content-center, align-items-end d-md-block text-md-right ">
                                <Button
                                  className="btns-mr-5 mb-1 mb-md-0"
                                  color="success"
                                  id="tooltip26024663"
                                  size="sm"
                                  type="button"
                                  onClick={() => this.openInvoice(item)}
                                >
                                  Go in
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="bold text-center">
                          <td colSpan="6">There is no data to display.</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <InvoiceModal showInvoice={showInvoice} invoice={selectedInvoice} setShowInvoiceState={this.setShowInvoiceState} clients={clients} mutliple={isMultiple} InvoiceComponent={Invoice}></InvoiceModal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  business: store.business,
});

export default connect(mapStateToProps)(Clients);

import React, { useEffect, useRef, useState } from "react";
import { Nav, NavItem, NavLink, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Col from "reactstrap/lib/Col";
import {
  deletePrice,
  elementTypesAction,
  getPrice,
  isBedAction,
  isSunbedAction,
  modalAction,
  tempElementType,
} from "../../../../redux/price/action";

import sunbedActive from "../../../../assets/img/priceplanner/sunbed_active.png";
import sunbed from "../../../../assets/img/priceplanner/sunbed.png";
import bedActive from "../../../../assets/img/priceplanner/bed_active.png";
import bed from "../../../../assets/img/priceplanner/bed.png";
import AddtionalActive from "../../../../assets/img/priceplanner/additional_active.png";
import addtional from "../../../../assets/img/priceplanner/additional.png";
import { useTranslation } from 'react-i18next';
import { startLoading, stopLoading } from "../../../../redux/loader/action";
import { isEmpty } from "lodash";

const ElementType = ({ filterType, availableSeats, selectedSeats, fetchingData = false }) => {
  const dispatch = useDispatch();
  const option = useSelector(({ option }) => option);
  const { t } = useTranslation();
  const price = useSelector(({ price }) => price);
  const [isSunbed, setIsSunbed] = useState(false);
  const [isBed, setIsBed] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState({})
  const [seatList, setSeatList] = useState([])
  const business = useSelector(({ business }) => business);
  const [state, setState] = useState({
    openedCollapses: ["collapseOne"],
    hTabs: "ht1",
    vTabs: "vt1",
    vTabsIcons: "vti1",
    pageSubcategories: "ps1",
  });

  const timeout = useRef(0);

  useEffect(() => {
    const seatIds = seatList.map(s => s?.id ?? "");
    if (!price?.elementType && seatList.length > 0 && isEmpty(selectedSeat)) {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        elementSelectAction(seatList[0]);
      }, 1000);
    } else if (seatIds.length && !seatIds.includes(selectedSeat?.id)) {
      elementSelectAction(seatList[0]);
    }
  }, [price.elementType, seatList.length, selectedSeat])

  useEffect(() => {
    let seats = [];
    selectedSeats.forEach(seat => {
      seats.push({
        id: seat.id,
        name: seat.name,
        is_orphan: availableSeats.filter(as => { return as.id === seat.id }).length === 0
      })
    });

    availableSeats.forEach(el => {
      if (seats.filter(s => { return s.id == el.id }).length <= 0) {
        seats.push({
          id: el.id,
          name: el.name,
          is_orphan: false
        })
      }
    })
    seats = seats.sort((a, b) => a.id > b.id ? 1 : -1);
    setSeatList(seats);
  }, [availableSeats, selectedSeats])

  useEffect(() => {
    if (price.elementType) {
      setSelectedSeat(price.elementType)
    }
  }, [price.elementType])
  const elementSelectAction = (seat) => {
    if (!seat) {
      return false;
    }
    dispatch(tempElementType(seat));
    if (!price.isSaved) {
      dispatch(modalAction(true));
    } else {
      setSelectedSeat(seat)
      setState({ hTabs: seat.name });
      dispatch(elementTypesAction(seat));
      filterType(seat);
    }
  };

  const deletePriceClick = (id) => {
    dispatch(startLoading());
    dispatch(deletePrice(id, business.filterBusinessList.id)).then(() => {
      dispatch(elementTypesAction(''));
      dispatch(getPrice()).then(() => {
        dispatch(stopLoading());
      });
    }).catch(e => {
      dispatch(stopLoading());
    })
  }
  return (
    <Row>
      <Col>
        <div className='d-flex justify-content-center py-3 flex-wrap'>
          <Nav className='nav-pills-info'>
            <Row>
              {
                seatList.map(seat => {
                  const hasError = !price.allPricesAreSet && price?.reasons && price?.reasons.length && price.reasons.some(r => r.element_type.id == seat.id);
                  return (
                    <Col key={seat.id} xs={"auto"} style={{ "padding": "0 5px" }}>
                      <NavItem style={{ 'minWidth': '66px' }}>
                        <NavLink
                          className={`${seat.id === selectedSeat.id ? "active" : ""} cursor price-item-nav`}
                          style={{ textAlignLast: "center" }}
                        >
                          {
                            hasError ? (
                              <div className={`error-animated-box ${seat.id == selectedSeat.id ? "active-err" : ""}`} />
                            ) : null
                          }
                          <div onClick={() => { elementSelectAction(seat) }}>
                            <img
                              src={`/priceplanner/${seat.name}.png`}
                              style={{ width: "50px" }}
                            />
                            <div
                              style={{
                                color: hasError ? "red" : "black",
                                marginTop: "10px",
                              }}
                            >
                              {t(seat.name)}
                            </div>
                          </div>
                          {!fetchingData && seat.is_orphan ? (
                            <button type="button" class="btn-link btn-remove-price" onClick={() => deletePriceClick(seat.id)}><i class="fa fa-times-circle"></i></button>
                          ) : null}

                        </NavLink>
                      </NavItem>
                    </Col>
                  )
                })
              }
            </Row>
          </Nav>
        </div>
      </Col>
    </Row>
  );
};
export default ElementType;

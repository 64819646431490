/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from 'react';
// import { Alert } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const PanelHeader = (props) => {
  let history = useHistory();
  const business = useSelector(({ business }) => business);
  const price = useSelector(({ price }) => price);
  // const option = useSelector(({ option }) => option);
  // const [showBankAlert, setShowBankAlert] = useState(false);
  const { t } = useTranslation();
  const movingRouter = () => {
    history.push('/admin/price');
  };

  // const movingProfileRouter = () => {
  //   history.push('/admin/business/' + business.filterBusinessList.id);
  // };

  // useEffect(() => {
  //   if (option.businessSettings.length > 0 && option.businessSettings[0].guaranteed_reservation) {
  //     if (!business.filterBusinessList.bank_name
  //       || !business.filterBusinessList.bank_account
  //       || !business.filterBusinessList.bank_routing_number
  //       || !business.filterBusinessList.bank_account_holder_name) {
  //       setShowBankAlert(true);
  //     }
  //   }
  // }, [option.businessSettings, business.filterBusinessList]);

  return (
    <div style={{ position: "relative" }}>
      <div
        className={`panel-header ${props.size !== undefined ? 'panel-header-' + props.size : ""} ${(props?.className ?? "")}`}
        style={{ background: "border-box" }}
      >
        {props.content}
      </div>
      {(business.superAdmin != 1 && !price.allPricesAreSet && (business.filterBusinessList?.type == 'beach' || business.filterBusinessList?.type == 'pool')) ? (
        <div className="text-center reset-period2 cursor" onClick={movingRouter}>
          {t("You have unset prices for some periods. Please define them")}
        </div>
      ) : null}

      {/* { showBankAlert && (
				<Alert color="danger" className="text-center reset-period cursor" onClick={ movingProfileRouter }>
					{t('bankAlert')}
				</Alert>
			) } */}
    </div>
  );
};

PanelHeader.defaultProps = {
  size: undefined,
  content: null,
};

PanelHeader.propTypes = {
  // size of the panel header
  size: PropTypes.oneOf(['sm', 'lg', undefined]),
  // content
  content: PropTypes.node,
};

export default PanelHeader;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  saveMoneySelect,
} from "../../../../redux/option/action";

const Cost = () => {
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [optionStatus, setOptionStatus] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    if (option.businessSettings.length > 0) {
      setOptionStatus(option.businessSettings[0].money_selected);
    } else {
      setOptionStatus("");
    }
  }, [business, option.businessSettings]);

  const handleOptionChange = (name) => (event) => {
    setOptionStatus(name);
    dispatch(saveMoneySelect(name));
  };
  return (
    <>
      <Card className="options-card p-3">
        <Row >
          <Col md="10">
            <h5 className="option-title-main">{t("menu costs")}</h5>
            <p className="option-title-sub">{t('menu_costs_description')}</p>
          </Col>
        </Row>

        <Row>
          <Col md="12 pb-3">
            <div className="d-flex flex-wrap" style={{ gap: '12px' }}>
              <FormGroup check className="form-check-button">
                <Label check for="cheap">
                  <Input
                    checked={optionStatus == "$" ? true : false}
                    id="cheap"
                    name="cheap"
                    type="radio"
                    onChange={handleOptionChange("$")}
                  />
                  <span className="form-button">$ <span className="form-check-sign" /></span>
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-button">
                <Label check for="moderate">
                  <Input
                    checked={optionStatus == "$$" ? true : false}
                    id="moderate"
                    name="moderate"
                    type="radio"
                    onChange={handleOptionChange("$$")}
                  />
                  <span className="form-button">$$ <span className="form-check-sign" /></span>
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-button">
                <Label check for="expensive">
                  <Input
                    checked={optionStatus == "$$$" ? true : false}
                    id="expensive"
                    name="expensive"
                    type="radio"
                    onChange={handleOptionChange("$$$")}
                  />
                  <span className="form-button">$$$ <span className="form-check-sign" /></span>
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-button">
                <Label check for="expensive">
                  <Input
                    checked={optionStatus == "$$$$" ? true : false}
                    id="expensive"
                    name="expensive"
                    type="radio"
                    onChange={handleOptionChange("$$$$")}
                  />
                  <span className="form-button">$$$$ <span className="form-check-sign" /></span>
                </Label>
              </FormGroup>
            </div>
          </Col>
        </Row>

      </Card>

    </>
  );
};
export { Cost };

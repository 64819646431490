import { createPromiseAction } from "@adobe/redux-saga-promise";
import axios from "axios";
import { calls } from "../../utils/calls.js";
import { createError } from "../../utils/utils.js";

export const actionTypes = {
  ACTIVITY_DATE_PERIOD: "ACTIVITY_DATE_PERIOD",
  GET_ACTIVITY_PERIOD: "GET_ACTIVITY_PERIOD",
  RESULT_GET_BUSINESS_DATA: "RESULT_GET_BUSINESS_DATA",
  WORK_HOUR_VALUE: "WORK_HOUR_VALUE",
  SAVE_WORK_HOUR: "SAVE_WORK_HOUR",
  RESULT_GET_WEEK_HOUR: "RESULT_GET_WEEK_HOUR",
  GET_HOUR_WORK: "GET_HOUR_WORK",
  UPDATE_ACTIVITY_DATE_PERIOD: "UPDATE_ACTIVITY_DATE_PERIOD",
  UPDATE_WORK_HOUR: "UPDATE_WORK_HOUR",
  START_DATES: "START_DATES",
  END_DATES: "END_DATES",
  RESULT_FAIL_GET_BUSINESS_DATA: "RESULT_FAIL_GET_BUSINESS_DATA",
  APPLY_SAME_SCHEDULE: "APPLY_SAME_SCHEDULE",
  APPLY_WORK_TIME_FROM: "APPLY_WORK_TIME_FROM",
  APPLY_TO_TIME_FROM: "APPLY_TO_TIME_FROM",
  APPLY_MUST_ARRIVE_BY: "APPLY_MUST_ARRIVE_BY",
  GET_ZONE_NAME: "GET_ZONE_NAME",
  RESULT_GET_ZONE_NAME: "RESULT_GET_ZONE_NAME",
  HAS_VIP_ZONE: "HAS_VIP_ZONE",
  SAVE_ZONE_NAME: "SAVE_ZONE_NAME",
  SAVE_COUNT: "SAVE_COUNT",
  GET_ZONE: "GET_ZONE",
  RESULT_GET_ZONE: "RESULT_GET_ZONE",
  CHANGE_VALUE_ZONE: "CHANGE_VALUE_ZONE",
  UPDATE_ZONE: "UPDATE_ZONE",
  SAVE_CLOSED_DAYS: "SAVE_CLOSED_DAYS",
  APPLY_WORK_TIME_FROM_BREAK: "APPLY_WORK_TIME_FROM_BREAK",
  APPLY_TO_TIME_FROM_BREAK: "APPLY_TO_TIME_FROM_BREAK",
  SAVE_TIME_LINE: "SAVE_TIME_LINE",
  GET_TIME_LINE: "GET_TIME_LINE",
  RESULT_GET_TIME_LINE: "RESULT_GET_TIME_LINE",
  TEMPORARY_CLOSED: "TEMPORARY_CLOSED",
  GRID_DATA_SAVE: "GRID_DATA_SAVE",
  CREATE_ZONES: "CREATE_ZONES",
  DELETE_ZONE: "DELETE_ZONE",
};

export function initialize() {
  const init = {};
  return { type: actionTypes.INITIAL_STATE, init };
}

export function activityDatePeriod(value, callback, businessId) {
  return {
    type: actionTypes.ACTIVITY_DATE_PERIOD,
    value,
    businessId,
    callback,
  };
}

export function temporaryClosedChange(value) {
  return { type: actionTypes.TEMPORARY_CLOSED, value };
}

// export function getActivityPeriod(reject) {
// 	return { type: actionTypes.GET_ACTIVITY_PERIOD, reject };
// }

export const getActivityPeriod = createPromiseAction(
  actionTypes.GET_ACTIVITY_PERIOD
);
//export const workHourValue = createPromiseAction(actionTypes.WORK_HOUR_VALUE, (value)=>({value}))
export function workHourValue(value) {
  return { type: actionTypes.WORK_HOUR_VALUE, value };
}

export const saveWorkHourAction = createPromiseAction(
  actionTypes.SAVE_WORK_HOUR
);

export function getHourWork(resolve) {
  return { type: actionTypes.GET_HOUR_WORK, resolve };
}

export function updateActivityDatePeriod(value, callback) {
  return { type: actionTypes.UPDATE_ACTIVITY_DATE_PERIOD, value, callback };
}

export const updateWorkHourAction = createPromiseAction(
  actionTypes.UPDATE_WORK_HOUR
);
// export function updateWorkHourAction() {
// 	return { type: actionTypes.UPDATE_WORK_HOUR };
// }

export function startDates(value) {
  return { type: actionTypes.START_DATES, value };
}

export function endDates(value) {
  return { type: actionTypes.END_DATES, value };
}

export function apllySameSchedule(check, from, to) {
  return { type: actionTypes.APPLY_SAME_SCHEDULE, check, from, to };
}

export function applyWorkTimeFrom(from) {
  return { type: actionTypes.APPLY_WORK_TIME_FROM, from };
}

export function applyWorkTimeTo(to) {
  return { type: actionTypes.APPLY_TO_TIME_FROM, to };
}

export function applyMustArriveByAction(mustArriveBy) {
  return { type: actionTypes.APPLY_MUST_ARRIVE_BY, mustArriveBy };
}

export function applyWorkTimeFromBreak(from_break) {
  return { type: actionTypes.APPLY_WORK_TIME_FROM_BREAK, from_break };
}

export function applyWorkTimeToBreak(to_break) {
  return { type: actionTypes.APPLY_TO_TIME_FROM_BREAK, to_break };
}

export function getZoneName() {
  return { type: actionTypes.GET_ZONE_NAME };
}

export function hasVipZone(value) {
  return { type: actionTypes.HAS_VIP_ZONE, value };
}

export const saveZoneAction = createPromiseAction(
  actionTypes.SAVE_ZONE_NAME,
  (value) => ({ value })
);
// export function saveZoneAction(value) {
// 	return { type: actionTypes.SAVE_ZONE_NAME, value };
// }

export function saveCount(value) {
  return { type: actionTypes.SAVE_COUNT, value };
}

export function getZone() {
  return { type: actionTypes.GET_ZONE };
}

export function changeValueZone(name, value) {
  return { type: actionTypes.CHANGE_VALUE_ZONE, name, value };
}

export const updateZoneAction = createPromiseAction(
  actionTypes.UPDATE_ZONE,
  (value) => ({ value })
);
// export function updateZoneAction(value) {
// 	return { type: actionTypes.UPDATE_ZONE, value };
// }

export function saveClosedDay(businessId, days) {
  return { type: actionTypes.SAVE_CLOSED_DAYS, businessId, days };
}

export const saveTimeLine = createPromiseAction(
  actionTypes.SAVE_TIME_LINE,
  (timeArrs, businessId, showNotification = true) => ({ timeArrs, businessId, showNotification })
);
// export function saveTimeLine(timeArrs, businessId) {
// 	return { type: actionTypes.SAVE_TIME_LINE, timeArrs, businessId };
// }

export function getTimeLineAction() {
  return { type: actionTypes.GET_TIME_LINE };
}

export const gridSaveDataAction = createPromiseAction(
  actionTypes.GRID_DATA_SAVE,
  (value, callback, showNotification = true) => ({ value, callback, showNotification })
);

// export function gridSaveDataAction(value, callback) {
// 	return createPromiseAction(actionTypes.GRID_DATA_SAVE);
// 	return { type: actionTypes.GRID_DATA_SAVE, value, callback };
// }

export const createZonesAction = createPromiseAction(
  actionTypes.CREATE_ZONES,
  (zones) => ({ zones })
);

export const deleteZoneAction = createPromiseAction(
  actionTypes.DELETE_ZONE,
  (id) => ({ id })
);

// ---- PAT Work ----

/**
 * Update the activity period for a business
 * @param businessId {String}
 * @param data {Object}
 * @param dispatch {Function}
 * @returns {Function}
 */
export const updateActivityDatePeriodPromise = (businessId, data, dispatch) => {
  dispatch({ type: "UPDATE_ACTIVITY_PERIOD" });
  return axios(calls.updateActivityDatePeriod(businessId, data))
    .then(() => {
      dispatch({
        type: "UPDATE_ACTIVITY_PERIOD_FULFILLED",
        payload: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: "UPDATE_ACTIVITY_PERIOD_REJECTED",
        payload: {
          error: createError(err),
        },
      });
    });
};

/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React , { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
// used for making the prop types of this component
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const AlertHeader = (props) => {
	let history = useHistory();
	const option = useSelector(({ option }) => option);
	const business = useSelector(({ business }) => business);
	const [showBankAlert, setShowBankAlert] = useState(false);
	const { t } = useTranslation();

	const movingProfileRouter = () => {
		history.push('/admin/business/'+business.filterBusinessList.id);
	};
	
	useEffect(() => {
		if(option.businessSettings && option.businessSettings.length > 0 && option.businessSettings[0].guaranteed_reservation){
			if(!business.filterBusinessList.bank_name 
				|| !business.filterBusinessList.bank_account
				|| !business.filterBusinessList.bank_routing_number
				|| !business.filterBusinessList.bank_account_holder_name){
					setShowBankAlert(true);
				}else{
					setShowBankAlert(false);
				}
		}else{
			setShowBankAlert(false);
		}
	}, [option.businessSettings,business.filterBusinessList]);
	
	return (
		<>
			{ showBankAlert && (
				<Alert color="danger" className="text-center bank-alert cursor" onClick={ movingProfileRouter }>
					{t('bankAlert')}
				</Alert>
			) }
		</>
	);
};

AlertHeader.defaultProps = {
	size: undefined,
	content: null,
};

AlertHeader.propTypes = {
	// size of the panel header
	size: PropTypes.oneOf(['sm', 'lg', undefined]),
	// content
	content: PropTypes.node,
};

export default AlertHeader;

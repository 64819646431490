import { Button as MuiButton, ButtonGroup } from "@material-ui/core";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import { Button, Col, Input, Row } from "reactstrap";
import { startLoading, stopLoading } from "../../../redux/loader/action";
import {
  setChangeAmount,
  setIsAutoRecharge,
  setSelectedAmount,
  setSmsbalance,
} from "../../../redux/sms/action";
import { calls } from "../../../utils/calls";
import { createNotification } from "../../../utils/utils";
import { SplitForm, StripePayment } from "./CheckoutForm";
//import BNR from 'bnr-exchange-history';
import moment from "moment";

const Sms = () => {
  const { businessData, userId } = useSelector(({ business }) => business);
  const { smsBalance } = useSelector(({ sms }) => sms);
  const [exchangeRate, setExchangeRate] = useState();
  const [amount, setAmount] = useState("");
  const dispatch = useDispatch();

  const { id, name, cui_number, reg_com_number, address, country, city } =
    businessData;
  const { amount_remaining } = smsBalance;

  //const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  useEffect(() => {
    if (businessData) {
      getSmsBalance();
      getExchangeRate();
    }
  }, [businessData]);

  const handleRecharge = (amount) => {
    setAmount(amount);
    dispatch(setSelectedAmount(amount));
  };

  const handleChange = (e) => {
    setAmount(e.target.value);
    dispatch(setChangeAmount(e.target.value));
  };

  const handleAutoRecharge = (e) => {
    const { checked } = e.target;
    dispatch(setIsAutoRecharge(checked));
  };

  const businessDitails = {
    name,
    cui_number,
    reg_com_number,
    address,
    city: city?.name,
    country: country?.name,
  };

  const balanceDetail = {
    "Remaining Amount": amount_remaining,
  };

  const getSmsBalance = async () => {
    try {
      const res = await axios(calls.getSmsBalance({ business_id: id }, userId));
      if (res) {
        let data;
        data = Object.fromEntries(
          Object.entries(res?.data)?.filter((e) => e.business_id === id)
        );
        dispatch(setSmsbalance(data));
      }
    } catch (error) {
    }
  };

  const getExchangeRate = async () => {
    try {
      const res = await axios(calls.getExchangeRate(moment().subtract(1, 'days').format("DD/MM/YYY")));
      if (res) {
        setExchangeRate(res.data)
      }
    } catch (error) {
    }
  };

  const handlePay = async (payload) => {
    try {
      if (payload.error) {
        createNotification({
          type: "danger",
          title: "Invalid card",
          message: payload.error.message,
        });
      } else {
        dispatch(startLoading());
        const res = await axios(
          calls?.purchaseSMSPlan(
            {
              token: { ...payload.token },
              currency: businessData.country_id == 181 ? "RON" : "EUR",
              type: 'sms_credits',
              business_id: id,
              amount: amount,
              card_id: payload.card_id,
              exchange_rate: businessData.country_id == 181 ? exchangeRate.EUR.rate : 1,
            },
            userId
          )
        );
        if (res) {
          createNotification({
            type: "success",
            title: "Purchase sms",
            message: "Thanks you for purchase sms plans"
          });
          setAmount("");
        }
      }

    } catch (error) {
      createNotification({
        type: "danger",
        title: "Invalid request",
        message: error.message,
      });
    }
    dispatch(stopLoading());
  };

  return (
    <Fragment>
      {
        (businessData && exchangeRate) && (
          <div className="content bg-white">
            <div className="max-w-[1300px] mx-auto px-5">
              <h4 className="font-semibold mb-3">Payment</h4>
              <div className="border rounded-xl p-4 mb-4">
                <Row>
                  <Col md={6}>
                    <p className="font-semibold mb-4">Amount to add</p>
                    <div className="2xs:flex items-center mb-4">
                      <label className="2xs:mb-0">Choose amount to recharge:</label>
                      <ButtonGroup
                        size="small"
                        aria-label="small button group"
                        className="2xs:ml-2 xs:ml-0 w-full 2xs:w-auto"
                      >
                        {["10", "20", "50", "100"]?.map((amount, index) => {
                          return (
                            <MuiButton
                              key={index}
                              onClick={() => {
                                handleRecharge(amount);
                              }}
                            >
                              {amount}€
                            </MuiButton>
                          );
                        })}
                      </ButtonGroup>
                    </div>
                    <div className="2xs:flex mb-4">
                      <label className="mb-0 mt-2">
                        <span className="text-danger">*</span> Amount:
                      </label>
                      <div>
                        <div className="flex">
                          <Input
                            type="number"
                            className="rounded-lg !rounded-r-none ml-2 hide-arrow"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={amount}
                          />
                          <div className="bg-gray-100 rounded-r-lg px-3 py-1.5 border-t border-r border-b border-solid">
                            €
                          </div>
                        </div>
                        {amount < 10 && amount.length ? (
                          <div className="ml-2 text-danger">
                            Amount cannot be less then 10
                          </div>
                        ) : null}
                      </div>

                    </div>
                    {
                      businessData.country_id == 181 && (
                        <>
                          <div className="2xs:flex mb-4" style={{ "alignItems": "flex-end" }}>
                            <label className="mb-0 mt-2">
                              <span className="text-danger">*</span> Exchange Rate:&nbsp;
                            </label>
                            <div className="color-red" style={{ "fontWeight": "bold" }}>
                              1Euro = {exchangeRate.EUR.rate} Lei
                            </div>
                          </div>
                          <div className="2xs:flex mb-4" style={{ "alignItems": "flex-end" }}>
                            <label className="mb-0 mt-2">
                              <span className="text-danger">*</span> Amount To Pay:&nbsp;
                            </label>
                            <div className="color-red" style={{ "fontWeight": "bold" }}>
                              {(amount * exchangeRate.EUR.rate).toFixed(2)} RON
                            </div>
                          </div>
                        </>
                      )
                    }
                    <div>
                      <div className="custom-control custom-checkbox mr-sm-2">
                        <Input
                          type="checkbox"
                          name="check"
                          className="custom-control-input"
                          id="payment"
                          onClick={(e) => handleAutoRecharge(e)}
                        />
                        <label
                          className="custom-control-label before:top-[2px] after:top-[2px]"
                          for="payment"
                        >
                          Save payment detail for auto recharge
                        </label>
                      </div>
                    </div>
                    <StripePayment handlePay={handlePay} isDisabled={!amount}></StripePayment>

                  </Col>
                  <Col md={6}>
                    <div className="md:mt-0 xs:mt-4">
                      <label className="font-semibold mb-4">Business Details</label>
                      {Object.values(businessDitails)?.map((data, index) => {
                        return <p key={index}>{data || ""}</p>;
                      })}
                    </div>
                  </Col>
                </Row>
                <div className="text-right">
                  {/* <Button
                className="capitalize btn-round !rounded-xl"
                onClick={() => handlePay()}
                disabled={amount < 10 || !amount?.length}
              >
                pay
              </Button> */}
                </div>
              </div>
              {Object.values(smsBalance).length ? (
                <div className="2xs:flex justify-between items-center p-4 border border-solid border-grey-200 rounded-xl">
                  <p className="font-semibold 2xs:mb-0 xs:mb-2">Balance</p>
                  {Object.entries(balanceDetail)?.map(([k, v], index) => {
                    return (
                      <p key={index}>
                        {k} : {v}€
                      </p>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        )
      }
    </Fragment>
  );
};

export default Sms;

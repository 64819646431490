import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  PlanPriceForBeach,
  PlanPriceForClub,
  PlanPriceForPool,
  PlanPriceForRestaurant,
} from "../description/PlanPriceData";

const PlanPriceContainer = () => {
  const [planList, setPlanList] = useState([]);
  const { filterBusinessList } = useSelector((state) => state?.business);
  useEffect(() => {
    switch (filterBusinessList?.type) {
      case "beach":
        setPlanList(PlanPriceForBeach);
        break;
      case "pool":
        setPlanList(PlanPriceForPool);
        break;
      case "restaurant":
        setPlanList(PlanPriceForRestaurant);
        break;
      case "club":
      case "bar":
        setPlanList(PlanPriceForClub);
        break;
      default:
        break;
    }
  }, [filterBusinessList]);
  return { planList };
};

export default PlanPriceContainer;

import { all } from 'redux-saga/effects';
import business from '../redux/business/saga';
import grid from '../redux/grid/saga';
import element from '../redux/element/saga';
import employee from '../redux/employee/saga';
import option from '../redux/option/saga';
import price from '../redux/price/saga';
import plan from '../redux/plan/saga';
import sms from '../redux/sms/saga';

export default function* rootSaga() {
	yield all([
		business(),
		grid(),
		element(),
		employee(),
		option(),
		price(),
		plan(),
		sms()
	]);
}

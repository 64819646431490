import React, { useEffect, useState } from "react";
import { Col, FormFeedback, FormGroup, Input, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { savedStatus } from "../../../../redux/price/action";
import { useTranslation } from "react-i18next";

const PriceEveryDayComponent = ({
  name,
  currency,
  priceValue,
  valueData = "",
  openStatus,
  invalid = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const price = useSelector(({ price }) => price);
  const [priceValues, setPriceValues] = useState(valueData);
  const [status, setStatus] = useState(true);
  useEffect(() => {
    if (!price.isData1) {
      setPriceValues("");
    }
  }, [price.isData, price.elementType]);
  useEffect(() => {
    if (price.isData) {
      setPriceValues(valueData);
    }
  }, [price.isData, valueData, price.elementType]);

  const priceValueChange = (name) => (event) => {
    if (event.target.value != "e") {
      setStatus(false);
      dispatch(savedStatus(false));
      priceValue(name, event.target.value);
      setPriceValues(event.target.value);
    }
  };
  return (
    <Row className="my-3">
      <Col md={4} xs={4}>
        <p className="mb-0 bold pt-2" style={{ textTransform: "capitalize" }}>
          {t(name)}
        </p>
      </Col>
      <Col md={4} xs={4}>
        <FormGroup>
          <Input
            name="price"
            id="example"
            type="number"
            invalid={invalid}
            value={priceValues}
            disabled={!openStatus}
            placeholder={t("Price...")}
            onChange={priceValueChange(name)}
          />
          {invalid ? <FormFeedback>{t("Require_Price")}</FormFeedback> : null}
        </FormGroup>
      </Col>
      <Col md={4} xs={4}>
        <p className="mb-0 bold pt-2 text-md-right">{currency}</p>
      </Col>
    </Row>
  );
};
export default PriceEveryDayComponent;

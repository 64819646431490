import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GRAPHQL_URL } from "../../utils/constants.js";

const link = new HttpLink({
  uri: `${GRAPHQL_URL}`,
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const authLink = setContext(async (_, { headers }) => {
  let token;
  let localState = localStorage.getItem("persist:business");
  let superAdminSessionToken = localStorage.getItem("SHADOWED_USER_TOKEN");

  if (localState && typeof JSON.parse(localState) === "object") {
    localState = JSON.parse(localState);
    token = localState.accessToken;
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${superAdminSessionToken && superAdminSessionToken.length > 0
            ? superAdminSessionToken
            : token
          }`,
      },
    };
  }
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions,
});

export default class API {
  getSmsLogs(businessId) {
    try {
      let data = client
        .mutate({
          mutation: gql`
          query getSmsLogs{
            sms_logs(order:"reverse:created_at", where: {business_id:${businessId}}) {
                reservation_restaurant_id
                sms_status
                sms_price
                phone
              }
          }
          `,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (error) { }
  }
}

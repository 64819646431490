import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
import Datetime from "react-datetime";
import { useTranslation, withTranslation } from "react-i18next";
import API from "../../../../redux/option/api";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from "../../../../redux/loader/action";
import { createNotification } from "../../../../utils/utils";
import { getHourWork } from "../../../../redux/grid/action";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import { getExtraSunbedPhoto, saveBusinessSettings } from "../../../../redux/option/action";
import moment from "moment";
const api = new API();
const MustArriveBy = (props) => {
  const { t } = useTranslation();
  const [mustBuyArrive, setMustBuyArrive] = useState("");
  const grid = useSelector(({ grid }) => grid);
  const business = useSelector(({ business }) => business);
  const [workHourValue, setWorkHourValue] = useState([]);
  const [showMustArriveBy, setShowMustArriveBy] = useState(false);
  const dispatch = useDispatch();
  const option = useSelector(({ option }) => option);
  //let updatedWorkHours = [];


  // useEffect(() => {
  //   setWorkHourValue(workHourValue)
  // }, [workHourValue]);

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const getName = async () => {
    // let curWorkH = grid.workHourValue;
    // setWorkHourValue(curWorkH);
    const ab = await api.getMustArriveBy(business.filterBusinessList.id);
    dispatch(startLoading())
    //setMustBuyArrive(ab.data.business_week[0].mon_must_arrive_by);
    if (ab && ab.data && ab.data.business_week) {
      setDayWiseState(ab.data.business_week[0]);
    }
    dispatch(stopLoading())
  };

  useEffect(() => {
    getName();
  }, []);

  const setDayWiseState = (mustArriveData) => {
    dispatch(startLoading())
    return new Promise((resolve) => {
      dispatch(getHourWork(resolve));
    }).then((data) => {
      if (data.length > 0) {
        let workingDays = [
          {
            id: 1,
            day: 'Monday',
            name: `${props.t("Monday")}`,
            from: data[0].mon_start,
            to: data[0].mon_end,
            status: data[0].mon,
            break_from: data[0].mon_start_break,
            break_to: data[0].mon_end_break,
            mustBuyArrive: mustArriveData.mon_must_arrive_by
              ? mustArriveData.mon_must_arrive_by
              : (data[0].mon_end) ? (moment(data[0].mon_end, "HH:mm").subtract(60, "minutes").format("HH:mm")) : "",
          },
          {
            id: 2,
            day: 'Tuesday',
            name: `${props.t("Tuesday")}`,
            from: data[0].tue_start,
            to: data[0].tue_end,
            status: data[0].tue,
            break_from: data[0].tue_start_break,
            break_to: data[0].tue_end_break,
            mustBuyArrive: mustArriveData.tue_must_arrive_by
              ? mustArriveData.tue_must_arrive_by
              : (data[0].tue_end) ? (moment(data[0].tue_end, "HH:mm").subtract(60, "minutes").format("HH:mm")) : "",
          },
          {
            id: 3,
            day: 'Wednesday',
            name: `${props.t("Wednesday")}`,
            from: data[0].wed_start,
            to: data[0].wed_end,
            status: data[0].wed,
            break_from: data[0].wed_start_break,
            break_to: data[0].wed_end_break,
            mustBuyArrive: mustArriveData.wed_must_arrive_by
              ? mustArriveData.wed_must_arrive_by
              : (data[0].wed_end) ? (moment(data[0].wed_end, "HH:mm").subtract(60, "minutes").format("HH:mm")) : "",
          },
          {
            id: 4,
            day: 'Thursday',
            name: `${props.t("Thursday")}`,
            from: data[0].thu_start,
            to: data[0].thu_end,
            status: data[0].thu,
            break_from: data[0].thu_start_break,
            break_to: data[0].thu_end_break,
            mustBuyArrive: mustArriveData.thu_must_arrive_by
              ? mustArriveData.thu_must_arrive_by
              : (data[0].thu_end) ? (moment(data[0].thu_end, "HH:mm").subtract(60, "minutes").format("HH:mm")) : "",
          },
          {
            id: 5,
            day: 'Friday',
            name: `${props.t("Friday")}`,
            from: data[0].fri_start,
            to: data[0].fri_end,
            status: data[0].fri,
            break_from: data[0].fri_start_break,
            break_to: data[0].fri_end_break,
            mustBuyArrive: mustArriveData.fri_must_arrive_by
              ? mustArriveData.fri_must_arrive_by
              : (data[0].fri_end) ? (moment(data[0].fri_end, "HH:mm").subtract(60, "minutes").format("HH:mm")) : "",
          },
          {
            id: 6,
            day: 'Saturday',
            name: `${props.t("Saturday")}`,
            from: data[0].sat_start,
            to: data[0].sat_end,
            status: data[0].sat,
            break_from: data[0].sat_start_break,
            break_to: data[0].sat_end_break,
            mustBuyArrive: mustArriveData.sat_must_arrive_by
              ? mustArriveData.sat_must_arrive_by
              : (data[0].sat_end) ? (moment(data[0].sat_end, "HH:mm").subtract(60, "minutes").format("HH:mm")) : "",
          },
          {
            id: 7,
            day: 'Sunday',
            name: `${props.t("Sunday")}`,
            from: data[0].sun_start,
            to: data[0].sun_end,
            status: data[0].sun,
            break_from: data[0].sun_start_break,
            break_to: data[0].sun_end_break,
            mustBuyArrive: mustArriveData.sun_must_arrive_by
              ? mustArriveData.sun_must_arrive_by
              : (data[0].sun_end) ? (moment(data[0].sun_end, "HH:mm").subtract(60, "minutes").format("HH:mm")) : "",
          },
        ];

        // workingDays.forEach(day => {
        //   if(day.mustBuyArrive && day.mustBuyArrive != ""){
        //     day.enabledMustArriveBy = true
        //   }else{
        //     day.enabledMustArriveBy = false
        //   }
        // })
        setWorkHourValue(workingDays);
      } else {
        setWorkHourValue([]);
      }
      dispatch(stopLoading())
    }).catch(() => {
      dispatch(startLoading());
    });
  };

  const _onClose = async (time, workHour) => {
    let aH = parseInt(workHour.mustBuyArrive.split(":")[0]);
    let fh = parseInt(workHour.from.split(":")[0]);
    let th = parseInt(workHour.to.split(":")[0]);
    let hasError = false;
    if (aH && fh && th) {
      if (th > fh) {
        if (aH < fh) {
          hasError = true;
        } else if (aH >= th) {
          hasError = true;
        }
      } else {
        if (aH < fh) {
          if (aH >= th) {
            hasError = true;
          }
        }
      }
    }
    let updatedWorkH = [...workHourValue];
    updatedWorkH.forEach((data) => {
      if (data.name === workHour.name) {
        if (hasError) {
          data.hasError = true;
        } else {
          data.hasError = false;
        }
      }
    });
    setWorkHourValue(updatedWorkH);
  };

  const updateEnabledDisable = (workHour, value) => {
    let updatedWorkH = [...workHourValue];
    updatedWorkH.forEach((data) => {
      if (data.name === workHour.name) {
        if (!value) {
          //data.mustBuyArrive = "";
          data.enabledMustArriveBy = false
        } else {
          data.enabledMustArriveBy = true
        }
      }
    });
    setWorkHourValue(updatedWorkH);
  }

  const _onChange = async (time, workHour) => {
    let updatedWorkH = [...workHourValue];
    updatedWorkH.forEach((data) => {
      if (data.name == workHour.name) {
        data.mustBuyArrive = time.format("HH:mm");
      }
    });
    setWorkHourValue(updatedWorkH);
  };
  const _onSaveHours = async () => {
    let updatedWorkH = [...workHourValue];
    let hasError = false;
    updatedWorkH.forEach((data) => {
      let aH = parseInt(data.mustBuyArrive.split(":")[0]);
      let fh = parseInt(data.from.split(":")[0]);
      let th = parseInt(data.to.split(":")[0]);
      if (aH && fh && th) {
        if (th > fh) {
          if (aH < fh) {
            data.hasError = true;
            hasError = true;
          } else if (aH >= th) {
            data.hasError = true;
            hasError = true;
          }
        } else {
          if (aH < fh) {
            if (aH >= th) {
              data.hasError = true;
              hasError = true;
            }
          }
        }
      }
    });
    if (hasError) {
      setWorkHourValue(updatedWorkH);
      createNotification({
        type: "danger",
        title: "Save Must arrive by",
        message: "Must arrive by should be between from and to working hours",
      });
      return false;
    }
    saveData(workHourValue);
  };


  const saveData = async (workHourValue) => {
    let time = {
      mon_must_arrive_by: workHourValue[0].mustBuyArrive,
      tue_must_arrive_by: workHourValue[1].mustBuyArrive,
      wed_must_arrive_by: workHourValue[2].mustBuyArrive,
      thu_must_arrive_by: workHourValue[3].mustBuyArrive,
      fri_must_arrive_by: workHourValue[4].mustBuyArrive,
      sat_must_arrive_by: workHourValue[5].mustBuyArrive,
      sun_must_arrive_by: workHourValue[6].mustBuyArrive,
    };
    dispatch(startLoading());
    const as = await api.setMustArriveBy(business.filterBusinessList.id, time);
    dispatch(stopLoading());
  }

  useEffect(() => {
    if (option.businessSettings.length > 0) {
      setShowMustArriveBy(option.businessSettings[0].must_arrive_by_required);
    } else {
      setShowMustArriveBy(false);
    }
  }, [option.businessSettings]);

  const getMinHours = (hours) => {
    let to = hours.to.split(":");
    return parseInt(to[0]);
  };

  const getMaxHours = (hours) => {
    let from = hours.from.split(":");
    return parseInt(from[0]);
  };

  const changeMustArriveBy = (value) => {
    let mutation = `{
      id : ${option.businessSettings[0].id}
      must_arrive_by_required: ${value}
    }`;
    dispatch(startLoading());
    return dispatch(saveBusinessSettings(mutation)).then(data => {
      dispatch(stopLoading());
      dispatch(getExtraSunbedPhoto());
      if (!value) {
        let updatedWorkH = [...workHourValue];
        updatedWorkH.forEach(hours => {
          hours.mustBuyArrive = (hours.to) ? (moment(hours.to, "HH:mm").subtract(60, "minutes").format("HH:mm")) : ""
        })
        saveData(updatedWorkH);
      }
    }).catch(err => {
      dispatch(stopLoading());
    });
  }

  return (
    <Card className="addSunbed">
      <CardHeader className="px-4">
        <h5 className="title" style={{ textAlign: "center" }}>
          {props.t("mustArriveBy")}
        </h5>
      </CardHeader>
      <CardBody className="px-4">
        {
          (business.filterBusinessList.type === 'beach' || business.filterBusinessList.type === 'pool') && (
            <Row className="justify-content-center align-items-center pb-3">
              <Col xs="auto">{props.t("mustArriveBy")}</Col>
              <Col xs="auto">
                <FormGroup check className="form-check-radio text-center" style={{ marginTop: 0 }}>
                  <Label check className="photo-required-radio">
                    <Input
                      checked={showMustArriveBy}
                      defaultValue="option1"
                      id="mustArriveRadios1"
                      name="mustArriveRadios"
                      type="radio"
                      onChange={e => { changeMustArriveBy(true) }}
                    />
                    <span className="form-check-sign" />
                    {t('Yes')}
                  </Label>
                  <Label check>
                    <Input
                      checked={!showMustArriveBy}
                      defaultValue="option2"
                      id="mustArriveRadios"
                      name="exampleRadios"
                      type="radio"
                      onChange={e => { changeMustArriveBy(false) }}
                    />
                    <span className="form-check-sign" />
                    {t('No')}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          )
        }

        {
          (showMustArriveBy || (business.filterBusinessList.type !== 'beach' && business.filterBusinessList.type !== 'pool')) && (
            workHourValue.map((workhour) => (
              <Row
                key={workhour.name}
                className=""
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <Col xs={4} md={3} className="pt-1">
                  <p
                    className="bold"
                    style={{
                      marginTop: "14%",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {workhour.name}
                  </p>
                </Col>
                <Col xs={4} md={4} sm={3} lg={2}>
                  <Datetime
                    inputProps={{
                      disabled: workhour.status != "Open" || !workhour.from || !workhour.to,
                    }}
                    dateFormat={false}
                    className={workhour.hasError ? "has-time-error" : ""}
                    timeFormat={"HH:mm"}
                    value={workhour.mustBuyArrive}
                    onChange={(time) => _onChange(time, workhour)}
                    onBlur={(time) => _onClose(time, workhour)}
                    timeConstraints={{
                      minutes: { min: 0, max: 60, step: 30 },
                    }}
                  />
                </Col>
                {/* <Col xs={4} md={4} sm={4} lg={2}>
                  <FormGroup check style={{ marginTop: "0px" }}>
                    <Label check>
                      <Input checked={workhour.enabledMustArriveBy} type="checkbox"
                        onChange={e => updateEnabledDisable(workhour, e.target.checked)}
                      />
                      <span className="form-check-sign" />
                      {t('SET')}
                    </Label>
                  </FormGroup>
                </Col> */}
              </Row>
            ))
          )
        }
        <Row>
          <Col md={12} className="py-1">
            <div className="d-flex justify-content-center">
              <Button onClick={_onSaveHours} className="save-btn">
                {props.t("Save")}
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(MustArriveBy);

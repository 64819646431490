import { rejectPromiseAction, resolvePromiseAction } from '@adobe/redux-saga-promise';
import axios from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { calls } from '../../utils/calls';
import { actionTypes, getPermissionList } from './action';
import API from './api';

// const callss = calls();
const api = new API();

///////////////////
const getAllEmployeesRequest = (business) => {
	return api.getAllEmployeesRequests(business);
};

function* getAllEmployees() {
	try {
		const business = yield select((store) => store.business);
		const result = yield call(getAllEmployeesRequest, business);
		if (result.data) {
			yield put({
				type: actionTypes.RESULT_GET_ALL_EMPLOYEES,
				value: result.data.business_user,
			});
		}
	} catch (e) {
	}
}

const createActionsRequest = (employeeData, business) => {
	return api.createActionsRequests(employeeData, business);
};


function* createActions({ employeeData, permissons }) {
	try {
		const business = yield select((store) => store.business);
		const result = yield call(createActionsRequest, employeeData, business);
		if (result.data) {
			if (result.data.business_userCreate) {
				yield axios(calls.updateUserPermission(
					result.data.business_userCreate.id,
					permissons,
					business?.filterBusinessList?.id))
				const result2 = yield call(getAllEmployeesRequest, business);
				if (result2.data) {
					yield put({
						type: actionTypes.RESULT_GET_ALL_EMPLOYEES,
						value: result2.data.business_user,
					});
				}
			}
		}
	} catch (e) {
	}
}

const updateActionsRequest = (employeeData) => {
	return api.updateActionsRequests(employeeData);
};

function* updateActions({ employeeData }) {
	try {
		const result = yield call(updateActionsRequest, employeeData);
		if (result.data) {
			if (result.data.business_userUpdate) {
				const business = yield select((store) => store.business);
				const result = yield call(getAllEmployeesRequest, business);
				if (result.data) {
					yield put({
						type: actionTypes.RESULT_GET_ALL_EMPLOYEES,
						value: result.data.business_user,
					});
				}
			}
		}
	} catch (e) {
	}
}

const deleteActionsRequest = (id) => {
	return api.deleteActionsRequests(id);
};

function* deleteActions({ id }) {
	try {
		const result = yield call(deleteActionsRequest, id);
		if (result.data) {
			if (result.data.business_userDelete) {
				const business = yield select((store) => store.business);
				const result = yield call(getAllEmployeesRequest, business);
				if (result.data) {
					yield put({
						type: actionTypes.RESULT_GET_ALL_EMPLOYEES,
						value: result.data.business_user,
					});
				}
			}
		}
	} catch (e) {
	}
}

const employeeNameValidationActionRequest = (name, value) => {
	return api.employeeNameValidationActionRequests(name, value);
};
const employeeCodeValidationActionRequest = (name, value, business_id) => {
	return api.employeeCodeValidationActionRequests(name, value, business_id);
};


const employeeEmailValidationActionRequest = (name, value, business_id) => {
	return api.employeeEmailValidationActionRequests(name, value, business_id);
};

function* employeeValidationAction({ resolve, name, value, business_id }) {
	try {
		if (name === "email") {
			const result = yield call(employeeEmailValidationActionRequest, name, value);
			if (result.data) {
				resolve(result.data);
			}
		}
		if (name === 'name') {
			const result = yield call(employeeNameValidationActionRequest, name, value);
			if (result.data) {
				resolve(result.data);
			}
		} else {
			const result = yield call(employeeCodeValidationActionRequest, name, value, business_id);
			if (result.data) {
				resolve(result.data);
			}
		}
	} catch (e) {
	}
}

const getPemissionListApi = (name, value, business_id) => {
	return api.getPermissionsList();
};

function* getPemissionListAction(action) {
	try {
		const result = yield call(getPemissionListApi);
		if (result.data) {
			yield call(resolvePromiseAction, action, result.data)
		} else {
			yield call(rejectPromiseAction, action, result)
		}
	} catch (e) {
		yield call(rejectPromiseAction, action, e)
	}
}

export default function* rootSaga() {
	yield all([
		takeLatest(actionTypes.GET_ALL_EMPLOYEES, getAllEmployees),
		takeLatest(actionTypes.CREATE_ACTION, createActions),
		takeLatest(actionTypes.UPDATE_ACTION, updateActions),
		takeLatest(actionTypes.DELETE_ACTION, deleteActions),
		takeLatest(actionTypes.EMPLOYEE_VALIDATION, employeeValidationAction),
		takeLatest(getPermissionList, getPemissionListAction)
	]);
}

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  saveReservationGuaranteedAction,
  saveReservationTimeGuaranteedAction,
} from "../../../../redux/option/action";
import Switch from "@material-ui/core/Switch";

const GuranteeReservation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [reservationStatus, setReservationStatus] = useState(false);
  const [value, setValue] = useState("");
  const [valueTime, setValueTime] = useState("");

  const [disableSave, setDisableSave] = useState(true);
  const [disableSaveTime, setDisableSaveTime] = useState(true);

  useEffect(() => {
    if (option.businessSettings.length > 0) {
      setReservationStatus(
        option.businessSettings[0].guaranteed_reservation > 0 ? true : false
      );
      setValue(
        option.businessSettings[0].guaranteed_reservation == 0
          ? ""
          : option.businessSettings[0].guaranteed_reservation
      );
      setValueTime(option.businessSettings[0].refund_threshold);
    } else {
      setReservationStatus(false);

      setValue("");
      setValueTime("");
    }
  }, [option.businessSettings]);
  useEffect(() => {
    if (value !== "" && valueTime !== "") {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [value, valueTime]);
  const handleReservationStatus = (event) => {
    if (event.target.checked == true) {
      setReservationStatus(true);
    } else {
      dispatch(saveReservationGuaranteedAction(0));
      setValueTime("");
      setValue("");
      setReservationStatus(false);

      dispatch(saveReservationTimeGuaranteedAction(null, 0));
    }
  };

  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };
  const handleChangeValueTime = (event) => {
    let value = event.target.value;

    if (value && value.split("")[0] == 0) {
      let splitValue = value.split("");
      alert(t("Time_Reservations_value_must_not_be_start_with_zero"));
      delete splitValue[0];
      if (value.split("")[1]) {
        setValueTime(splitValue.join(""));
      } else {
        setValueTime("");
      }
    } else {
      setValueTime(event.target.value);
    }
  };
  const saveReservation = () => {
    if (business.filterBusinessList.currency == "RON" && Number(value) < 25) {
      alert(t("Ron_save_reservation_value_must_be"));
      return;
    } else if (
      business.filterBusinessList.currency == "EURO" &&
      Number(value) < 5
    ) {
      alert(t("Euro_save_reservation_value_must_be"));
      return;
    }
    dispatch(saveReservationGuaranteedAction(value));
    setDisableSave(true);
  };

  const saveReservationTime = () => {
    if (business.filterBusinessList.currency == "RON" && Number(value) < 25) {
      alert(t("Ron_save_reservation_value_must_be"));
      return;
    } else if (
      business.filterBusinessList.currency == "EURO" &&
      Number(value) < 5
    ) {
      alert(t("Euro_save_reservation_value_must_be"));
      return;
    } else if (valueTime > 144) {
      alert(t("Time_Reservations_must_be_greater_144"));
    } else if (valueTime <= 1) {
      alert(t("Time_Reservations_must_be_greater_1"));
    } else {
      dispatch(saveReservationTimeGuaranteedAction(Number(valueTime), value));
      setDisableSaveTime(true);
    }
  };

  return (
    <>
      <Card className="options-card">
        <Row className="p-3">
          <Col md="10">
            <h5 className="option-title-main">
              {t("enalble_grarantee_detail")}
            </h5>
            <p className="option-title-sub mb-3">
              {t("enable_guaranted_content")}
            </p>
          </Col>
          <Col md="2">
            <Switch
              checked={reservationStatus}
              name="reservation"
              onChange={handleReservationStatus}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Col>

          {reservationStatus && (
            <Col md="10">
              {/* <h5 className="option-title-main">
                  {t("time_for_reservation_start")}
                </h5> */}
              <div className="d-flex ">
                <InputGroup className="btn-input-group">
                  <FormControl
                    type="text"
                    id="exampleEmail"
                    name="phone"
                    onChange={handleChangeValue}
                    value={value}
                    placeholder={t("Enter value")}
                  />
                  <div
                    style={{
                      right: "90px",
                      backgroundColor: "transparent",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <span className="bold">
                      {business.filterBusinessList.currency}
                    </span>
                  </div>
                </InputGroup>

                <InputGroup className="ml-3 btn-input-group">
                  <FormControl
                    type="number"
                    min="1"
                    max="144"
                    id="exampleTime"
                    name="phone"
                    onChange={handleChangeValueTime}
                    value={valueTime}
                    placeholder={t("Enter value")}
                  />
                  <div
                    style={{
                      right: "90px",
                      backgroundColor: "transparent",
                      alignItems: "center",
                      padding: "10px",
                    }}
                  >
                    <span className="bold">{t("time_hrs")}</span>
                  </div>
                </InputGroup>
              </div>
              <div className="mt-2">
                <Button
                  className="save-btn-blue btn-primary"
                  onClick={saveReservationTime}
                  disabled={disableSave}
                >
                  {t("Save")}
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </Card>

      <div className="card-divider-line"></div>
    </>
  );
};

export default GuranteeReservation;

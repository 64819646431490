import { createPromiseAction } from "@adobe/redux-saga-promise";
import axios from "axios";
import { calls } from "../../utils/calls.js";
import { createError } from "../../utils/utils.js";

export const actionTypes = {
  LOGIN_VALUE_ACTION: "LOGIN_VALUE_ACTION",
  LOGIN_ACTION: "LOGIN_ACTION",
  RESULT_LOGIN_DATA: "RESULT_LOGIN_DATA",
  RESULT_ADMIN_LOGIN_DATA: "RESULT_ADMIN_LOGIN_DATA",
  REGISTER_ACTION: "REGISTER_ACTION",
  REGISTER_VALUE_ACTION: "REGISTER_VALUE_ACTION",
  GET_COUNTRY_ACTION: "GET_COUNTRY_ACTION",
  RESULT_COUNTRY_DATA: "RESULT_COUNTRY_DATA",
  RESULT_REGISTER_DATA: "RESULT_REGISTER_DATA",
  RESULT_LOGIN_FAIL_DATA: "RESULT_LOGIN_FAIL_DATA",
  RESULT_REGISTER_FAIL_DATA: "RESULT_REGISTER_FAIL_DATA",
  RESULT_REGISTER_SUCCESS_DATA: "RESULT_REGISTER_SUCCESS_DATA",
  BUSINESS_TYPE: "BUSINESS_TYPE",
  SET_BUSINESS_TYPE: "SET_BUSINESS_TYPE",
  // set location name
  SET_LOCATION_NAME: "SET_LOCATION_NAME",

  GET_SMS_COUNT_DATA : "GET_SMS_DATA",

  RESULT_BUSINESS_DATA: "RESULT_BUSINESS_DATA",
  CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
  GET_LANGUAGES: "GET_LANGUAGES",
  RESULT_GET_LANGUAGES: "RESULT_GET_LANGUAGES",

  GET_BUSINESS_TYPE: "GET_BUSINESS_TYPE",
  LOGOUT_ACTION: "LOGOUT_ACTION",
  RESULT_LOG_OUT: "RESULT_LOG_OUT",
  BUSINESS_TYPE_DATA: "BUSINESS_TYPE_DATA",
  GET_BUSINESS_LIST_GET_BY_ID: "GET_BUSINESS_LIST_GET_BY_ID",
  GET_BUSINESS_PROFILE_BY_ID: "GET_BUSINESS_PROFILE_BY_ID",

  FILTER_BUSINESS_LIST_ACTION: "FILTER_BUSINESS_LIST_ACTION",
  CREATE_BUSINESS_PROFILE: "CREATE_BUSINESS_PROFILE",
  RESULT_CREATE_BUSINESS_ACTION: "RESULT_CREATE_BUSINESS_ACTION",
  GET_CITY_LIST: "GET_CITY_LIST",
  RESULT_GET_CITY_LIST: "RESULT_GET_CITY_LIST",
  BUSINESS_NAME: "BUSINESS_NAME",
  SAVE_PLAN_ACTION: "SAVE_PLAN_ACTION",
  CUOPON_CODE_VALID: "CUOPON_CODE_VALID",
  RESULT_VALID_CUOPON: "RESULT_VALID_CUOPON",
  GET_PLAN_INFORMATION: "GET_PLAN_INFORMATION",
  RESULT_GET_COUPON_BY_BUSINESSID: "RESULT_GET_COUPON_BY_BUSINESSID",
  GET_PLAN_DATA: "GET_PLAN_DATA",
  RESULT_GET_PLAN: "RESULT_GET_PLAN",
  RESULT_ALL_COUPONS: "RESULT_ALL_COUPONS",
  RESULT_DELAY_NOTIFICATION: "RESULT_DELAY_NOTIFICATION",
  LOCATION_NAME_UPDATE: "LOCATION_NAME_UPDATE",
  CHANGE_PUBLISH_STATUS: "CHANGE_PUBLISH_STATUS",
  REMOVE_BUSINESS: "REMOVE_BUSINESS",
  RESULT_SAVE_BUSINESS_NAME: "RESULT_SAVE_BUSINESS_NAME",
  SAVE_BUSINESS_NAME: "SAVE_BUSINESS_NAME",
  LOGIN_FROM_ADMIN: "LOGIN_FROM_ADMIN",
};

export function initialize() {
  const init = {};
  return { type: actionTypes.INITIAL_STATE, init };
}

export function loginValueAction(value) {
  return { type: actionTypes.LOGIN_VALUE_ACTION, value };
}

export function loginAction(history, resolve, reject) {
  return { type: actionTypes.LOGIN_ACTION, history, resolve, reject };
}

export function registerValue(value) {
  return { type: actionTypes.REGISTER_VALUE_ACTION, value };
}

export function registerAction(history) {
  return { type: actionTypes.REGISTER_ACTION, history };
}

export function getCountryAction() {
  return { type: actionTypes.GET_COUNTRY_ACTION };
}

// export function businessTypes(value, history) {
//   return { type: actionTypes.BUSINESS_TYPE, value, history };
// }

export const businessTypes = createPromiseAction(
  actionTypes.BUSINESS_TYPE,
  (value, name, history,business_type) => ({ value, name, history,business_type })
);

export function changeLanguages(value) {
  return { type: actionTypes.CHANGE_LANGUAGE, value };
}
export function getLanguages() {
  return { type: actionTypes.GET_LANGUAGES };
}

export function getBusinessType() {
  return { type: actionTypes.GET_BUSINESS_TYPE };
}

export function logoutAction() {
  return { type: actionTypes.LOGOUT_ACTION };
}

// export const  businessTypesData() {
//   return { type: actionTypes.BUSINESS_TYPE_DATA };
// }
export const businessTypesData = createPromiseAction(
  actionTypes.BUSINESS_TYPE_DATA
);

export function filterBusinessList(value) {
  return { type: actionTypes.FILTER_BUSINESS_LIST_ACTION, value };
}

// export function getBusinessListGetById(value) {
//   return { type: actionTypes.GET_BUSINESS_LIST_GET_BY_ID, value };
// }

export const getBusinessListGetById = createPromiseAction(
  actionTypes.GET_BUSINESS_LIST_GET_BY_ID,
  (value) => value
);
export const getBusinessProfileById = createPromiseAction(
  actionTypes.GET_BUSINESS_PROFILE_BY_ID,
  (id) => id
);

export const loginFromAdminAction = createPromiseAction(
  actionTypes.LOGIN_FROM_ADMIN,
  (payload) => payload
);

export function removeBusiness(id) {
  return { type: actionTypes.REMOVE_BUSINESS, id };
}

export const createBsuinessProfile = createPromiseAction(
  actionTypes.CREATE_BUSINESS_PROFILE,
  (value, countryCityValue, id, resolve, reject, callback) => ({
    value,
    countryCityValue,
    id,
    resolve,
    reject,
    callback,
  })
);

export const saveBusinessName = createPromiseAction(
  actionTypes.SAVE_BUSINESS_NAME,
  (values) => values
);

// export function createBsuinessProfile(
// 	value,
// 	countryCityValue,
// 	id,
// 	resolve,
// 	reject,
// 	callback,
// ) {
// 	return {
// 		type: actionTypes.CREATE_BUSINESS_PROFILE,
// 		value,
// 		countryCityValue,
// 		id,
// 		resolve,
// 		reject,
// 		callback,
// 	};
// }

export function getCityList(value) {
  return { type: actionTypes.GET_CITY_LIST, value };
}
export function getSmsData() {
  return {type : actionTypes.GET_SMS_COUNT_DATA};
}
export function businessName(value) {
  return { type: actionTypes.BUSINESS_NAME, value };
}

export function savePlanAction(
  value,
  couponId,
  business_id,
  price,
  resolve,
  reject
) {
  return {
    type: actionTypes.SAVE_PLAN_ACTION,
    value,
    couponId,
    business_id,
    price,
    resolve,
    reject,
  };
}

export function cuoponCodeValid(
  value,
  business_id,
  validateOnly,
  resolve,
  reject
) {
  return {
    type: actionTypes.CUOPON_CODE_VALID,
    value,
    business_id,
    validateOnly,
    resolve,
    reject,
  };
}

export function getPlanInformation(business_id) {
  return { type: actionTypes.GET_PLAN_INFORMATION, business_id };
}

export function getPlanData(business_id, resolve, reject) {
  return { type: actionTypes.GET_PLAN_DATA, business_id, resolve, reject };
}

export function locationNameUpdate(updateLocationName) {
  return { type: actionTypes.LOCATION_NAME_UPDATE, updateLocationName };
}

export function changePublishStatus(businessId, status) {
  return { type: actionTypes.CHANGE_PUBLISH_STATUS, businessId, status };
}

// ---- PAT Work ----

/**
 * Create a Stripe account
 * @param businessId {String}
 * @param dispatch {Function}
 * @returns {Function}
 */
export const createStripeAccount = (businessId, dispatch) => {
  dispatch({ type: "CREATE_STRIPE_ACCOUNT" });
  return new Promise((resove, reject) => {
    axios(calls.createStripeAccount(businessId))
      .then((res) => {
        dispatch({
          type: "CREATE_STRIPE_ACCOUNT_FULFILLED",
          payload: {
            acceptedToS: res.data.data.acceptedToS,
          },
        });
        resove();
      })
      .catch((err) => {
        dispatch({
          type: "CREATE_STRIPE_ACCOUNT_REJECTED",
          payload: {
            error: createError(err),
          },
        });
        reject(new Error(err.response.data.error));
      });
  });
};

/**
 * Create a Stripe account
 * @param businessId {String}
 * @param dispatch {Function}
 * @returns {Function}
 */
export const getStripeTOSStatus = (businessId, dispatch) => {
  dispatch({ type: "GET_STRIPE_TOS_STATUS" });
  return axios(calls.getStripeTOSStatus(businessId))
    .then((res) => {
      dispatch({
        type: "GET_STRIPE_TOS_STATUS_FULFILLED",
        payload: {
          acceptedToS: res.data.data.acceptedToS,
          stripeToSRequired: res.data.data.stripeToSRequired,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: "GET_STRIPE_TOS_STATUS_REJECTED",
        payload: {
          error: createError(err),
        },
      });
    });
};

/**
 * Accept Stripe ToS
 * @param businessId {String}
 * @param dispatch {Function}
 * @returns {Function}
 */
export const agreeStripeTOS = (businessId, dispatch) => {
  dispatch({ type: "AGREE_STRIPE_TOS" });
  return axios(calls.agreeStripeTOS(businessId))
    .then((res) => {
      dispatch({
        type: "AGREE_STRIPE_TOS_FULFILLED",
        payload: {
          acceptedToS: res.data.acceptedToS,
        },
      });
    })
    .catch((err) => {
      dispatch({
        type: "AGREE_STRIPE_TOS_REJECTED",
        payload: {
          error: createError(err),
        },
      });
    });
};

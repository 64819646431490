import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createAction,
  deleteActionRequest,
  employeeValidation,
  getEmployees,
  getPermissionList,
  updateAction,
} from "../../../redux/employee/action";
import "../../../assets/css/custom.scss";
import { calls } from "../../../utils/calls.js";
import axios from "axios";
import beachImage from "../../../assets/img/beach.jpg";
import restaurantImage from "../../../assets/img/restaurant.jpg";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { createNotification } from "../../../utils/utils";

const Employees = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const grid = useSelector(({ grid }) => grid);
  const business = useSelector(({ business }) => business);
  const employee = useSelector(({ employee }) => employee);
  const [employeeData, setEmployeeData] = useState({
    name: "",
    unlock_code: "",
    role: "Owner",
    id: "",
    email: "",
  });
  const [updateStatus, setUpdateStatus] = useState(false);
  const [codeValidation, setCodeValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(true);
  const [nameValidation, setNameValidation] = useState(false);
  const [UniqueEmailValidation, setUniqueEmailValidation] = useState(false);
  const [userPermission, setUserPermission] = useState([{}]);
  const [deleteId, setDeleteId] = useState(0);

  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    dispatch(getPermissionList()).then((data) => {
      setUserPermission(data.permissions);
    });
  }, []);
  useEffect(() => {
    dispatch(getEmployees());
  }, [business.filterBusinessList, dispatch]);
  const [state, setState] = useState({
    modalDemo: false,
    modalDemoPop: false,
  });
  const toggleModalDemo = () => {
    setState({
      ...state,
      modalDemo: !state.modalDemo,
    });
  };
  const toggleModalDemoPop = () => {
    setState({
      ...state,
      modalDemoPop: !state.modalDemoPop,
    });
  };
  const unLockCodeValidation = (name, value) => {
    return new Promise((resolve, reject) => {
      dispatch(
        employeeValidation(
          resolve,
          name ? name.toLocaleLowerCase() : name,
          value,
          business.filterBusinessList.id
        )
      );
    })
      .then((success) => {
        if (name === "email") {
          if (success.business_user.length > 0) {
            setUniqueEmailValidation(true);
          } else {
            setUniqueEmailValidation(false);
          }
        }
      })
      .catch((errors) => {
        if (errors) {
        }
      });
  };
  const handleChange = (name) => (event) => {
    if (name === "email") {
      unLockCodeValidation("email", event.target.value);
    }
    if (name !== "role") {
      unLockCodeValidation(name, event.target.value);
    }
    if (name === "email") {
      let email = event.target.value;
      const regex =
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (email && regex.test(email) === false) {
        setEmailValidation(false);
      } else setEmailValidation(true);
    }
    setEmployeeData({
      ...employeeData,
      [name]: event.target.value
        ? event.target.value.toLocaleLowerCase()
        : event.target.value,
    });
  };
  const saveAction = (event) => {
    const { status, weekHourData } = grid;
    if (!status || weekHourData.length <= 0) {
      setState({
        ...state,
        modalDemo: !state.modalDemo,
      });
      createNotification({
        type: 'danger',
        title: !status ? t('Activity Period') : t('Working Hour Error'),
        message: !status ? t('Activity_Period_Error') : t('Working_Hour_Error'),
      });
      return null;
    }
    if (!emailValidation) return false;
    setEmployeeData({
      ...employeeData,
      user_assign_permissions: selectedRoles,
    });
    if (!codeValidation && !nameValidation && !UniqueEmailValidation) {
      if (updateStatus) {
        updateUserPermission(employeeData.id);
        dispatch(updateAction(employeeData));
      } else {
        dispatch(createAction(employeeData, selectedRoles));
      }
      setState({
        ...state,
        modalDemo: !state.modalDemo,
      });
    }
  };
  const editAction = (id) => (event) => {
    setCodeValidation(false);
    setUniqueEmailValidation(false);
    setNameValidation(false);
    setDeleteId(id);
    setUpdateStatus(true);
    setState({
      ...state,
      modalDemo: !state.modalDemo,
    });
    const filterData = employee.allEmployees.filter((ele) => ele.id === id);
    if (filterData.length > 0) {
      setEmployeeData({
        id: filterData[0].id,
        name: filterData[0].name,
        unlock_code: filterData[0].unlock_code,
        role: filterData[0].role,
        email: filterData[0].email,
        user_assign_permissions: filterData[0].user_assign_permissions,
      });
      let currentUserPErmission = [];
      filterData[0].user_assign_permissions.forEach((e) => {
        currentUserPErmission.push(e.permission_id);
      });
      setSelectedRoles(currentUserPErmission);
    }
  };
  const deleteAction = (id) => (event) => {
    setDeleteId(id);
    setState({
      ...state,
      modalDemoPop: !state.modalDemoPop,
    });
  };
  const deletActionAgain = (event) => {
    dispatch(deleteActionRequest(deleteId));
    setState({
      ...state,
      modalDemoPop: !state.modalDemoPop,
    });
  };
  const addEmployee = () => {
    setUpdateStatus(false);
    setCodeValidation(false);
    setNameValidation(false);
    setUniqueEmailValidation(false);
    setState({
      ...state,
      modalDemo: !state.modalDemo,
    });
    setEmployeeData({ name: "", unlock_code: "", role: "Owner", id: "" });
    ownerpermission();
  };
  const handlerankPermission = (e) => {
    setEmployeeData({ ...employeeData, role: e.target.value });
    handleChange("role");
    if (!employeeData.user_assign_permissions) {
      if (
        e.target.value === "Owner" ||
        e.target.value === "Hostess" ||
        e.target.value === "Manager"
      )
        ownerpermission();
      else if (e.target.value === "Waiter" || e.target.value === "Bartendar")
        waiterpermission();
      else setSelectedRoles([]);
    } else {
      let currentPremissons = [];
      setSelectedRoles(currentPremissons);
    }
  };
  const ownerpermission = () => {
    let aa = [];
    userPermission.forEach((element) => {
      aa.push(element.id);
    });
    setSelectedRoles(aa);
  };
  const waiterpermission = () => {
    let waiterArray = [];
    userPermission.forEach((element) => {
      if (
        parseInt(element.id) === 1 ||
        parseInt(element.id) === 2 ||
        parseInt(element.id) === 4 ||
        parseInt(element.id) === 5
      ) {
        waiterArray.push(element.id);
      }
    });
    setSelectedRoles(waiterArray);
  };
  const changePermission = (currentId) => {
    let newitem = [];
    if (selectedRoles.includes(currentId)) {
      newitem = selectedRoles.filter((item) => item !== currentId);
      setSelectedRoles(newitem);
    } else {
      setSelectedRoles([...selectedRoles, currentId]);
    }
  };
  const updateUserPermission = (id) => {
    axios(
      calls.updateUserPermission(id, selectedRoles, business.businessData.id)
    ).then((data) => {
    });
  };

  return (
    <Fragment>
      <div
        className="content employees-container"
        style={{
          backgroundImage: `url(${business.filterBusinessList.type === "restaurant"
            ? restaurantImage
            : beachImage
            })`,
        }}
      >
        <Row>
          <Col md="12">
            <Card className="employees-card">
              <CardHeader className="px-4">
                <h5 className="title">{t("Employees")}</h5>
              </CardHeader>
              <CardBody>
                <div className="d-flex justify-content-center">
                  <Button className="add-employee" onClick={addEmployee}>
                    {t("Add Employee")}
                  </Button>
                </div>
                <div style={{ overflowX: "scroll", width: "100%" }}>
                  <Table style={{ marginBottom: "20px" }}>
                    <Thead className="text-primary">
                      <Tr className="border-row">
                        <Th className="text-left emplyoee-head p-2">
                          {t("Name")}
                        </Th>
                        <Th className="text-center emplyoee-head">
                          {t("Email")}
                        </Th>
                        <Th className="text-center emplyoee-head">
                          {t("Position")}
                        </Th>
                        <Th className="text-center emplyoee-head">
                          {t("PIN code")}
                        </Th>
                        <Th className="text-center emplyoee-head">
                          {t("Verified?")}
                        </Th>
                        <Th className="text-center emplyoee-head">
                          {t("Date")}
                        </Th>
                        <Th className="text-center emplyoee-head">
                          {t("Actions")}
                        </Th>
                      </Tr>
                    </Thead>
                    {employee.allEmployees.length > 0 ? (
                      <Tbody>
                        {employee.allEmployees.map((item, index) => {
                          return (
                            <Tr key={index} className="border-row">
                              <Td className="text-left emplyoee-detail">
                                {item.name}
                              </Td>
                              <Td className="text-center emplyoee-detail">
                                {item.email}
                              </Td>
                              <Td className="text-center emplyoee-detail">
                                {item.role}
                              </Td>
                              <Td className="text-center emplyoee-detail">
                                {item.unlock_code}
                              </Td>
                              <Td className="text-center emplyoee-detail">
                                {item.verified ? "Yes" : "No"}
                              </Td>
                              <Td className="text-center emplyoee-detail">
                                {item.updated_at.split("T")[0]}
                              </Td>
                              <Td className="text-center emplyoee-detail">
                                <span
                                  onClick={editAction(item.id)}
                                  className="change change-super"
                                  id="tooltip26024663"
                                >
                                  {t("Change")}
                                </span>
                                <span
                                  onClick={deleteAction(item.id)}
                                  className="remove remove-super"
                                  id="tooltip930083782"
                                >
                                  {t("Remove")}
                                </span>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    ) : (
                      <Tbody>
                        <Tr className="bold text-center border-row">
                          <Td className="emplyoee-detail" colSpan="6">
                            {t("There is no data to display")}
                          </Td>
                        </Tr>
                      </Tbody>
                    )}
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Modal size="xl" isOpen={state.modalDemo} toggle={toggleModalDemo}>
            <ModalHeader
              className="justify-content-center"
              toggle={toggleModalDemo}
            >
              <p className="bold mb-0">{t("Employee")}</p>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md="3" className="single-feild">
                  <FormGroup>
                    <Input
                      type="text"
                      name="name"
                      id="exampleName"
                      value={employeeData.name}
                      placeholder={t("Name")}
                      onChange={handleChange("name")}
                    />
                    {nameValidation && (
                      <p className="mb-0 text-danger">{`${employeeData.name} aready taken`}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col md="3" className="single-feild">
                  <FormGroup>
                    <Input
                      type="text"
                      value={employeeData.email}
                      onChange={handleChange("email")}
                      name="email"
                      id="emialaddress"
                      placeholder={t("Email Address")}
                    />
                    {!emailValidation && (
                      <p className="mb-0 text-danger">
                        {t("Please Enter Valid Email")}
                      </p>
                    )}
                    {UniqueEmailValidation && (
                      <p className="mb-0 text-danger">{`${employeeData.email} aready taken`}</p>
                    )}
                  </FormGroup>
                </Col>
                <Col md="3" className="single-feild">
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Label className="mt-1">{t("Choose_rank")}</Label>
                    <Input
                      style={{
                        width: "60%",
                        marginLeft: "5px",
                      }}
                      type="select"
                      name="select"
                      value={employeeData.role}
                      onChange={(e) => handlerankPermission(e)}
                      id="exampleSelect1"
                    >
                      <option value="Owner">{t("Owner")} </option>
                      <option value={t("Manager")}>{t("Manager")}</option>
                      <option>{t("Cashier")}</option>
                      <option>{t("Hostess")}</option>
                      <option>{t("Bartendar")}</option>
                      <option>{t("Waiter")}</option>
                      <option>{t("Kiosk")}</option>
                      {business.businessData.type === "beach" ||
                        business.businessData.type === "pool" ? (
                        <option>{t("Beach boy")}</option>
                      ) : null}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md="3" className="single-feild">
                  <FormGroup>
                    <Input
                      type="number"
                      name="pinCode"
                      id="PIN_code"
                      value={employeeData.unlock_code}
                      placeholder={t("Set unlock code")}
                      onChange={handleChange("unlock_code")}
                    />
                    {codeValidation && (
                      <p className="mb-0 text-danger">
                        {t("Unlock code must be unique")}
                      </p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {/* <h6 className="mt-2">{t("User Permissions")}</h6>
              <Row>
                {userPermission.map((e) =>
                  e.id === 11 &&
                  (business.businessData.type === "beach" ||
                    business.businessData.type === "pool") ? null : (
                    <Col
                      className="single-permission"
                      style={{ minWidth: "250px" }}
                      key={e.id}
                    >
                      <FormGroup check className="mt-3">
                        <Label check>
                          <Input
                            type="checkbox"
                            name={e.permo}
                            onChange={(event) => changePermission(e.id)}
                            checked={
                              selectedRoles.filter((permission) => {
                                return e.id == permission;
                              }).length > 0
                            }
                          />
                          <span className="form-check-sign" />
                          {t(e.permission_code)}
                        </Label>
                      </FormGroup>
                    </Col>
                  )
                )}
              </Row> */}
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleModalDemo}>
                {t("Close")}s
              </Button>
              <Button className="save-btn" color="primary" onClick={saveAction}>
                {t("Save Profile")}
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={state.modalDemoPop} toggle={toggleModalDemoPop}>
            <ModalBody>
              <h5 className="title text-center">
                {t("Are you sure to delete?")}
              </h5>
            </ModalBody>
            <ModalFooter>
              <Button
                className="grid-button "
                style={{ backgroundColor: "red" }}
                onClick={toggleModalDemoPop}
              >
                {t("No")}
              </Button>
              <Button
                className="grid-button "
                style={{ backgroundColor: "7557f9" }}
                onClick={deletActionAgain}
              >
                {t("Yes")}
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </div>
    </Fragment>
  );
};
export default Employees;

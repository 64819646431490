import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createFacility,
  deleteFacility,
  getFacilities,
  saveMoneySelect,
} from "../../../../redux/option/action";

const Facilities = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const defaultFacilites = [
    {
      id: 1,
      status: false,
      facility_id: 0,
      name: "wifi",
    },
    {
      id: 3,
      status: false,
      facility_id: 0,
      name: "Shower",
    },
    {
      id: 4,
      status: false,
      facility_id: 0,
      name: "bar",
    },
    {
      id: 5,
      status: false,
      facility_id: 0,
      name: "Rest",
    },
    {
      id: 6,
      status: false,
      facility_id: 0,
      name: "Card",
    },
    {
      id: 7,
      status: false,
      facility_id: 0,
      name: "Massage",
    },
    {
      id: 8,
      status: false,
      facility_id: 0,
      name: "Fammily",
    },
    {
      id: 9,
      status: false,
      facility_id: 0,
      name: "Jet",
    },
    {
      id: 10,
      status: false,
      facility_id: 0,
      name: "wave",
    },
  ];
  const [facilities, setFacilities] = useState([...defaultFacilites]);
  useEffect(() => {
    dispatch(getFacilities());
  }, [business.filterBusinessList]);
  useEffect(() => {
    updateOptions();
  }, [option.facilities]);

  const updateOptions = () => {
    //  alert("hi");
    if (option.facilities.length > 0) {
      let arr = [...facilities];
      arr.map((item, index) => {
        arr[index]["status"] = false;
      });

      arr.map((item, index) => {
        option.facilities.map((list, i) => {
          if (item.id === list.facility_id) {
            arr[index]["status"] = !arr[index]["status"];
            arr[index]["facility_id"] = list.id;
          }
        });
      });
      setFacilities(arr);
    } else {
      setFacilities([...defaultFacilites]);
    }
  };

  const handleFacilities = (id, state, facilityId) => (event) => {
    updateOptions();
    if (state) {
      dispatch(deleteFacility(facilityId));
    } else {
      dispatch(createFacility(id));
    }
  };
  return (
    <React.Fragment>
      <Card className="options-card p-3">
        <Row>
          <Col md="10">
            <h5 className="option-title-main">{t("Facilities")}</h5>
            <p className="option-title-sub mb-3">{t("select facility")}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
              {facilities.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "inline-grid", textAlign: "center" }}
                  >
                    {item.status ? (
                      <Button
                        color="primary"
                        className="btn-facility"
                        onClick={handleFacilities(
                          item.id,
                          item.status,
                          item.facility_id
                        )}
                      >
                        {t(item.name)}
                      </Button>
                    ) : (
                      <Button
                        outline
                        color="secondary"
                        className="btn-facility"
                        onClick={handleFacilities(
                          item.id,
                          item.status,
                          item.facility_id
                        )}
                      >
                        {t(item.name)}
                      </Button>
                    )}
                    {/* <img
                        src={`/Facilities/Facilities_${item.id}_${
                          item.status ? "on" : "off"
                        }.png`}
                        key={index}
                        className="circle-rounded m-3 cursor"
                        width="60px"
                        height="60px"
                        onClick={handleFacilities(
                          item.id,
                          item.status,
                          item.facility_id
                        )}
                      />
                      {item.name} */}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};
const Facilities2 = () => {
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [optionStatus, setOptionStatus] = useState("");
  const defaultFacilites = [
    {
      id: 1,
      status: false,
      facility_id: 0,
      hideStatus: true,
      name: "wifi",
    },
    {
      id: 3,
      status: false,
      facility_id: 0,
      hideStatus: false,
      name: "shower",
    },
    {
      id: 4,
      status: false,
      facility_id: 0,
      hideStatus: false,
      name: "bar",
    },
    {
      id: 5,
      status: false,
      facility_id: 0,
      hideStatus: false,
      name: "rest",
    },
    {
      id: 6,
      status: false,
      facility_id: 0,
      hideStatus: true,
      name: "credit card",
    },
    {
      id: 7,
      status: false,
      facility_id: 0,
      hideStatus: false,
      name: "massage",
    },
    {
      id: 8,
      status: false,
      facility_id: 0,
      hideStatus: true,
      name: "kids area",
    },
    {
      id: 9,
      status: false,
      facility_id: 0,
      hideStatus: false,
      name: "jet",
    },
    {
      id: 10,
      status: false,
      facility_id: 0,
      hideStatus: false,
      name: "wave",
    },
    {
      id: 13,
      status: false,
      facility_id: 0,
      hideStatus: true,
      name: "private parking",
    },
    {
      id: 14,
      status: false,
      facility_id: 0,
      hideStatus: true,
      name: "public parking",
    },
    {
      id: 15,
      status: false,
      facility_id: 0,
      hideStatus: false,
      name: "smoking area",
    },
  ];
  const [facilities, setFacilities] = useState([...defaultFacilites]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFacilities());
  }, [business.filterBusinessList, dispatch, business.businessName]);
  useEffect(() => {
    if (option.facilities.length > 0) {
      let arr = [...facilities];
      arr.map((item, index) => {
        arr[index]["status"] = false;
      });
      arr.map((item, index) => {
        option.facilities.map((list, i) => {
          if (item.id === list.facility_id) {
            arr[index]["status"] = true;
            arr[index]["facility_id"] = list.id;
          }
        });
      });
      setFacilities(arr);
    } else {
      setFacilities([...defaultFacilites]);
    }
  }, [option.facilities]);
  useEffect(() => {
    if (option.businessSettings.length > 0) {
      setOptionStatus(option.businessSettings[0].money_selected);
    } else {
      setOptionStatus("");
    }
  }, [option.businessSettings]);
  const handleFacilities = (id, state, facilityId) => (event) => {
    if (state) {
      dispatch(deleteFacility(facilityId));
    } else {
      dispatch(createFacility(id));
    }
  };
  const handleOptionChange = (name) => (event) => {
    setOptionStatus(name);
    dispatch(saveMoneySelect(name));
  };
  return (
    <>
      <Card className="options-card p-3">
        <Row>
          <Col md="10">
            <h5 className="option-title-main">{t("Facilities")}</h5>
            <p className="option-title-sub mb-3">{t("select facility")}</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
              {facilities.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.hideStatus && (
                      <div>
                        {item.status ? (
                          <Button
                            color="primary"
                            className="btn-facility"
                            onClick={handleFacilities(
                              item.id,
                              item.status,
                              item.facility_id
                            )}
                          >
                            {t(item.name)}
                          </Button>
                        ) : (
                          <Button
                            outline
                            color="secondary"
                            className="btn-facility"
                            onClick={handleFacilities(
                              item.id,
                              item.status,
                              item.facility_id
                            )}
                          >
                            {t(item.name)}
                          </Button>
                        )}
                        {/* <img
                          src={`/Facilities/Facilities_${item.id}_${
                            item.status ? "on" : "off"
                          }.png`}
                          className="circle-rounded m-3 cursor"
                          width="60px"
                          height="60px"
                          onClick={handleFacilities(
                            item.id,
                            item.status,
                            item.facility_id
                          )}
                        />
                        {item.name} */}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export { Facilities2, Facilities };

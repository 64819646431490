import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";

// core components
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  cuoponCodeValid,
  getPlanData,
  getPlanInformation,
  savePlanAction,
} from "../../../redux/business/action";
import axios from "axios";
import { calls } from "../../../utils/calls.js";

import beachImage from "../../../assets/img/beach.jpg";
import restaurantImage from "../../../assets/img/restaurant.jpg";
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import { startLoading, stopLoading } from "../../../redux/loader/action";
import { createNotification } from "../../../utils/utils";

const Plans = () => {
  const dispatch = useDispatch();
  const business = useSelector(({ business }) => business);
  const [businessValue, setBusinessValue] = useState([]);
  const [priceValue, setPriceValue] = useState({
    beachPoolPrice: 39,
    restaurantBarPrice: 50,
    currentPrice: "",
    totalPrice: "",
  }); //where u have the 0 value declared for plan 2?
  const [value, setValue] = useState({
    plan: "",
    subPlan: "",
    codeValue: "",
    printDirectly: false,
  });
  const [value2, setValue2] = useState({
    plan: "",
    codeValue: "",
    printDirectly: false,
  });
  const [value3, setValue3] = useState({
    plan: "",
    codeValue: "",
    printDirectly: false,
  });
  const [couponValid, setCouponValid] = useState({
    status: false,
    message: "",
  });
  const [planValid, setPlanValid] = useState({ status: false, message: "" });
  const [plan2Value, setPlan2Value] = useState(9);
  const [plan2ValueOther, setPlan2ValueOther] = useState(0);
  const [plan3ValueOther, setPlan3ValueOther] = useState(30);
  const [plan3Value, setPlan3Value] = useState(5);
  const [changeData, setChangeData] = useState({
    plan: "",
    codeValue: "",
    printDirectly: false,
  });
  const [lockButton, setLockButton] = useState(false);
  const [price, setPrice] = useState(0);
  useEffect(() => {
    dispatch(getPlanInformation(business.filterBusinessList.id));
    if (
      business.filterBusinessList.type == "beach" ||
      business.filterBusinessList.type == "pool"
    ) {
      setPriceValue({ ...priceValue, currentPrice: priceValue.beachPoolPrice });
    } else {
      setPriceValue({
        ...priceValue,
        currentPrice: priceValue.restaurantBarPrice,
      });
    }

    setBusinessValue(business.filterBusinessList);
    return new Promise((resolve, reject) => {
      dispatch(getPlanData(business.filterBusinessList.id, resolve, reject));
    })
      .then((success) => {})
      .catch((errors) => {
        if (errors.plan_changes.length > 0) {
          let len = errors.plan_changes.length - 1;
          setChangeData({
            plan: errors.plan_changes[len].plan_config.type,
            subPlan: errors.plan_changes[len].plan_config.subType,
            printDirectly: errors.plan_changes[len].plan_config.allow_print,
            codeValue: "",
          });
          setValue({
            plan: errors.plan_changes[len].plan_config.type,
            subPlan: errors.plan_changes[len].plan_config.subType,
            printDirectly: errors.plan_changes[len].plan_config.allow_print,
            codeValue: "",
          });
        } else {
          setChangeData({ plan: "", codeValue: "", printDirectly: false });
          setValue({ plan: "", codeValue: "", printDirectly: false });
        }
      });
  }, [business.filterBusinessList]);

  useEffect(() => {
    let totalPriceVal = totalPrice(
      business.planData,
      value.plan == "plan1" && value.subPlan == "intremediate"
        ? "plan3"
        : value.plan
    );
    setPrice(totalPriceVal);
  }, [value, business.planData]);

  const { t, i18n } = useTranslation();
  const [couponValues, setCouponValues] = useState([]);
  const [applyStatus, setApplyStatus] = useState(false);
  const [applyStatus2, setApplyStatus2] = useState(false);
  const [applyStatus3, setApplyStatus3] = useState(false);

  const totalPrice = (data, planValueData) => {
    let percentArray = [];
    let tempPriceValue;
    let totalPriceValue;
    if (data.length > 0) {
      data.map((item) => {
        percentArray.push(Number(item.value));
      });
      tempPriceValue = Math.max(...percentArray);
      if (tempPriceValue == 100) {
        if (
          business.filterBusinessList.type == "beach" ||
          business.filterBusinessList.type == "pool"
        ) {
          totalPriceValue = 0;
        } else {
          totalPriceValue = 0;
        }
      } else {
        if (
          business.filterBusinessList.type == "beach" ||
          business.filterBusinessList.type == "pool"
        ) {
          if (value.plan == "plan1" && !value.subPlan) {
            totalPriceValue =
              Math.round(
                (priceValue.currentPrice -
                  priceValue.currentPrice * (tempPriceValue / 100)) *
                  100
              ) / 100;
          } else if (value.plan == "plan1" && value.subPlan == "intremediate") {
            totalPriceValue =
              Math.round(
                (plan2Value - plan2Value * (tempPriceValue / 100)) * 100
              ) / 100;
          } else {
            totalPriceValue =
              Math.round(
                (plan2Value - plan2Value * (tempPriceValue / 100)) * 100
              ) / 100;
          }
        } else {
          if (value.plan == "plan1" && !value.subPlan) {
            totalPriceValue =
              Math.round(
                (priceValue.currentPrice -
                  priceValue.currentPrice * (tempPriceValue / 100)) *
                  100
              ) / 100;
          } else if (value.plan == "plan1" && value.subPlan == "intremediate") {
            totalPriceValue =
              Math.round(
                (plan2ValueOther - plan2ValueOther * (tempPriceValue / 100)) *
                  100
              ) / 100;
          } else {
            totalPriceValue =
              Math.round(
                (plan2ValueOther - plan2ValueOther * (tempPriceValue / 100)) *
                  100
              ) / 100;
          }
        }
      }
    } else {
      if (planValueData == "plan2") {
        if (
          business.filterBusinessList.type == "beach" ||
          business.filterBusinessList.type == "pool"
        ) {
          totalPriceValue = plan2Value;
        } else {
          totalPriceValue = plan2ValueOther;
        }
      } else if (planValueData == "plan3") {
        if (
          business.filterBusinessList.type == "beach" ||
          business.filterBusinessList.type == "pool"
        ) {
          totalPriceValue = plan3Value;
        } else {
          totalPriceValue = plan3ValueOther;
        }
      } else {
        totalPriceValue = priceValue.currentPrice;
      }
    }
    return totalPriceValue;
  };

  //let price = totalPrice(business.planData, value.plan);

  const handleChange = (name) => (event) => {
    if (name == "printDirectly") {
      setValue({ ...value, [name]: event.target.checked });
      if (event.target.checked == changeData.printDirectly) {
        setLockButton(true);
      } else {
        setLockButton(false);
      }
    }
    if (name == "codeValue") {
      if (event.target.value.length > 0) {
        setApplyStatus(true);
      } else {
        setApplyStatus(false);
      }
      setValue({ ...value, [name]: event.target.value });
    }
    if (name == "codeValue2") {
      if (event.target.value.length > 0) {
        setApplyStatus2(true);
      } else {
        setApplyStatus2(false);
      }
      setValue2({ ...value2, [name]: event.target.value });
    }
    if (name == "codeValue3") {
      if (event.target.value.length > 0) {
        setApplyStatus3(true);
      } else {
        setApplyStatus3(false);
      }
      setValue3({ ...value3, [name]: event.target.value });
    }
    if (name == "plan") {
      setValue({ ...value, [name]: event.target.value });
      if (event.target.value == changeData.plan) {
        setLockButton(true);
      } else {
        setLockButton(false);
      }
    }
  };
  const savePlan = (event) => {
    let price = totalPrice([], "plan1");
    let couponId = [];
    dispatch(startLoading());
    business.planData.map((item, index) => {
      couponId.push(item.coupon_id);
    });
    return new Promise((resolve, reject) => {
      setLockButton(true);
      dispatch(
        savePlanAction(
          {
            plan: "plan1",
            codeValue: "plan1",
            subPlan: "",
            printDirectly: "false",
          },
          couponId,
          business.filterBusinessList.id,
          price,
          resolve,
          reject
        )
      );
    })
      .then((success) => {
        //setPlanValid({ status: false, message: '' });
      })
      .then(() => {
        if (business.planDataById && business.planDataById.length > 0) {
          return axios(
            calls.sendEmail({
              sender: "tabo",
              receiver: "tabo",
              subject: "A location has updated their price plan!",
              mailHtml: `
						<div>
							<h1>Location ${business.filterBusinessList.location_name} has updated their price plan</h1>
						</div>
					`,
            })
          );
        }
        setValue({ ...value, plan: "plan1" });
        dispatch(stopLoading());
      })
      .catch((errors) => {
        dispatch(stopLoading());
        if (errors) {
          setValue(changeData);
          //setPlanValid({ status: true, message: errors });
        }
      });
  };

  const savePlan2 = (event) => {
    let price = totalPrice([], "plan2");
    let couponId = [];
    business.planData.map((item, index) => {
      couponId.push(item.coupon_id);
    });
    dispatch(startLoading());
    return new Promise((resolve, reject) => {
      setLockButton(true);
      dispatch(
        savePlanAction(
          {
            plan: "plan2",
            codeValue: "plan2",
            subPlan: "",
            printDirectly: "false",
          },
          couponId,
          business.filterBusinessList.id,
          price,
          resolve,
          reject
        )
      );
    })
      .then((success) => {
        //setPlanValid({ status: false, message: '' });
      })
      .then(() => {
        if (business.planDataById && business.planDataById.length > 0) {
          return axios(
            calls.sendEmail({
              sender: "tabo",
              receiver: "tabo",
              subject: "A location has updated their price plan!",
              mailHtml: `
						<div>
							<h1>Location ${business.filterBusinessList.location_name} has updated their price plan</h1>
						</div>
					`,
            })
          );
        }
        setValue({ ...value, plan: "plan2" });
        dispatch(stopLoading());
      })
      .catch((errors) => {
        dispatch(stopLoading());
        if (errors) {
          setValue2(changeData);
          //setPlanValid({ status: true, message: errors });
        }
      });
  };

  const savePlan3 = (event) => {
    let price = totalPrice([], "plan3");
    let couponId = [];
    dispatch(startLoading());
    business.planData.map((item, index) => {
      couponId.push(item.coupon_id);
    });
    return new Promise((resolve, reject) => {
      setLockButton(true);
      dispatch(
        savePlanAction(
          {
            plan: "plan1",
            subPlan: "intremediate",
            codeValue: "plan3",
            printDirectly: "false",
          },
          couponId,
          business.filterBusinessList.id,
          price,
          resolve,
          reject
        )
      );
    })
      .then((success) => {
        //setPlanValid({ status: false, message: '' });
      })
      .then(() => {
        if (business.planDataById && business.planDataById.length > 0) {
          return axios(
            calls.sendEmail({
              sender: "tabo",
              receiver: "tabo",
              subject: "A location has updated their price plan!",
              mailHtml: `
						<div>
							<h1>Location ${business.filterBusinessList.location_name} has updated their price plan</h1>
						</div>
					`,
            })
          );
        }
        setValue({ ...value, plan: "plan1", subPlan: "intremediate" });
        dispatch(stopLoading());
      })
      .catch((errors) => {
        dispatch(stopLoading());
        if (errors) {
          setValue(changeData);
          //setPlanValid({ status: true, message: errors });
        }
      });
  };

  const couponCodeGenerate = (event) => {
    let values = [];
    if (value.plan !== "plan1" || value.subPlan) {
      createNotification({
        type: "danger",
        title: t("invalid_plan"),
        message: t("invalid_plan_select"),
      });
      return false;
    }
    return new Promise((resolve, reject) => {
      dispatch(
        cuoponCodeValid(
          value.codeValue,
          business.filterBusinessList.id,
          false,
          resolve,
          reject
        )
      );
    })
      .then((success) => {
        values = couponValues;
        values.push(value.codeValue);
        setCouponValues(values);
        setValue({ ...value, codeValue: "" });
        setApplyStatus(false);
        setCouponValid({ status: false, message: "" });
        dispatch(getPlanInformation(business.filterBusinessList.id));
      })
      .catch((errors) => {
        if (errors) {
          setCouponValid({ status: true, message: errors });
        }
      });
  };

  const couponCodeGenerate3 = (event) => {
    if (value.plan !== "plan1" || !value.subPlan) {
      createNotification({
        type: "danger",
        title: t("invalid_plan"),
        message: t("invalid_plan_select"),
      });
      return false;
    }
    let values = [];
    return new Promise((resolve, reject) => {
      dispatch(
        cuoponCodeValid(
          value3.codeValue3,
          business.filterBusinessList.id,
          false,
          resolve,
          reject
        )
      );
    })
      .then((success) => {
        values = couponValues;
        values.push(value.codeValue3);
        setCouponValues(values);
        setValue({ ...value3, codeValue: "" });
        setApplyStatus3(false);
        setCouponValid({ status: false, message: "" });
        dispatch(getPlanInformation(business.filterBusinessList.id));
      })
      .catch((errors) => {
        if (errors) {
          setCouponValid({ status: true, message: errors });
        }
      });
  };

  const couponCodeGenerate2 = (event) => {
    if (value.plan !== "plan2") {
      createNotification({
        type: "danger",
        title: t("invalid_plan"),
        message: t("invalid_plan_select"),
      });
      return false;
    }
    let values = [];
    return new Promise((resolve, reject) => {
      dispatch(
        cuoponCodeValid(
          value2.codeValue,
          business.filterBusinessList.id,
          false,
          resolve,
          reject
        )
      );
    })
      .then((success) => {
        values = couponValues;
        values.push(value.codeValue);
        setCouponValues(values);
        setValue2({ ...value2, codeValue: "" });
        setApplyStatus2(false);
        setCouponValid({ status: false, message: "" });
        dispatch(getPlanInformation(business.filterBusinessList.id));
      })
      .catch((errors) => {
        if (errors) {
          setCouponValid({ status: true, message: errors });
        }
      });
  };

  const onDismiss = () => {
    setPlanValid({ ...planValid, status: !planValid.status });
  };
  return (
    <Fragment>
      <div style={{ position: "absolute", top: "50px", right: "50px" }}>
        <Alert
          color="info"
          className="alert-with-icon"
          style={{ zIndex: "200" }}
          isOpen={planValid.status}
          toggle={onDismiss}
        >
          <span data-notify="icon" className="now-ui-icons ui-1_bell-53"></span>
          <span data-notify="message">{planValid.message}</span>
        </Alert>
      </div>
      <div
        className="content plan-container"
        style={{
          backgroundImage: `url(${
            business.filterBusinessList.type === "beach"
              ? beachImage
              : restaurantImage
          })`,
        }}
      >
        <PanelHeader size="sm" />
        <Row className="title-container xs-hidden">
          <Col md="12" className="mt-4">
            <h5 className="title text-center" style={{ marginTop: "30px" }}>
              {t("Choose your plan for")}{" "}
              {business.businessName ? business.businessName : "Location Name"}
            </h5>
            <p className="bold">{t("topTitle")}</p>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col md="3" style={{ maxWidth: "366px", minWidth: "366px" }}>
            <Card className="card-user">
              <CardBody className="px-4">
                <p className="py-2 bold font-size-16 mb-0 text-primary">
                  {t("Tabo King")}
                </p>

                <p check className="font-size-14 text-center">
                  {t("plan text 2")}
                </p>
                <p check className="font-size-14 text-center">
                  {t("plan text 1")}
                </p>
                <p check className="font-size-14 text-center">
                  <b>{t("plan text 3")}</b>
                </p>
                {business.filterBusinessList && false && (
                  <Fragment>
                    {business.filterBusinessList.country_id == 181 && (
                      <FormGroup check className="pl-0">
                        <Label check>
                          <Input
                            type="checkbox"
                            onChange={handleChange("printDirectly")}
                            checked={value.printDirectly}
                          />{" "}
                          {t("printBillsFromReceptionistApp")}
                          <span className="form-check-sign">
                            <span className="check"></span>
                          </span>
                        </Label>
                      </FormGroup>
                    )}
                  </Fragment>
                )}
                <div className="d-flex py-3 coupon">
                  <div className="coupon-form">
                    <FormGroup>
                      <Input
                        type="text"
                        name="success"
                        id="success"
                        placeholder="code coupon"
                        value={value.codeValue}
                        onChange={handleChange("codeValue")}
                      />
                    </FormGroup>
                    {couponValid.status && (
                      <FormText color="danger">{couponValid.message}</FormText>
                    )}
                  </div>
                  <div className="pl-3 coupon-button">
                    <Button
                      color="primary"
                      className="btn-round m-0"
                      disabled={!applyStatus}
                      onClick={couponCodeGenerate}
                    >
                      {t("Apply")}
                    </Button>
                  </div>
                </div>
                {business.planData.length > 0 &&
                  value.plan === "plan1" &&
                  !value.subPlan && (
                    <div className="px-3">
                      {business.planData.map((item, index) => {
                        return (
                          <Row key={index}>
                            <p className="bold coupon-text">{item.coupon}</p>
                          </Row>
                        );
                      })}
                    </div>
                  )}
                <hr />
                <div className="px-2">
                  {true && (
                    <div className="plan-1-prices">
                      {businessValue.type == "beach" ||
                      businessValue.type == "pool" ? (
                        <p className="py-2 bold font-size-16 mb-0">
                          {value.plan == "plan1" && !value.subPlan ? (
                            <span>
                              {" "}
                              {t("total")}:{" "}
                              <span className="price-style">
                                {price} &nbsp;
                                <span>&#8364;</span> &nbsp;/&nbsp; {t("month")}
                              </span>
                            </span>
                          ) : (
                            <span>
                              {t("total")}:{" "}
                              <span className="price-style">
                                {priceValue.beachPoolPrice} &nbsp;
                                <span>&#8364;</span> &nbsp;/&nbsp;{t("month")}{" "}
                              </span>
                            </span>
                          )}
                        </p>
                      ) : (
                        <p className="py-2 bold font-size-16 mb-0">
                          {value.plan == "plan1" && !value.subPlan ? (
                            <span>
                              {" "}
                              {t("total")}:{" "}
                              <span className="price-style">
                                {price} &nbsp;
                                <span>&#8364;</span> &nbsp;/&nbsp; {t("month")}
                              </span>
                            </span>
                          ) : (
                            <span>
                              {t("total")}:{" "}
                              <span className="price-style">
                                {priceValue.restaurantBarPrice} &nbsp;
                                <span>&#8364;</span> &nbsp;/&nbsp;{t("month")}{" "}
                              </span>
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  )}
                </div>

                <Row className="justify-content-center choose-button">
                  <Col md="6" style={{ textAlignLast: "center" }}>
                    {value.plan === "plan1" && !value.subPlan ? (
                      <Button
                        color="primary"
                        className="btn-round"
                        disabled={true}
                        onClick={savePlan}
                      >
                        Chosen
                      </Button>
                    ) : (
                      <Button outline className="btn-round" onClick={savePlan}>
                        Choose
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className="justify-content-center text-center">
                  <Col>
                    {businessValue.type == "beach" ||
                    businessValue.type == "pool" ? (
                      <span className="font-size-14">
                        <i>{t("addtionalDescription3", { price: 10 })}</i>
                      </span>
                    ) : (
                      <span className="font-size-14">
                        <i>{t("addtionalDescription2", { price: 0 })}</i>
                      </span>
                    )}
                  </Col>
                </Row>
                {(businessValue.type == "restaurant" ||
                  businessValue.type == "bar" ||
                  businessValue.type == "club") && (
                  <Row className="justify-content-center text-center">
                    <Col>
                      <span className="font-size-14">
                        <i>{t("note_vat")}</i>
                      </span>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
          {businessValue.type == "beach" ||
          businessValue.type == "pool" ? null : (
            <Col md="3" style={{ maxWidth: "366px", minWidth: "366px" }}>
              <Card className="card-user">
                <CardBody className="px-4">
                  <p className="py-2 bold font-size-16 mb-0 text-primary">
                    Tabo Guard
                  </p>

                  <p check className="font-size-14 text-center">
                    {t("plan text 2")}
                  </p>
                  <p check className="font-size-14 text-center">
                    {t("plan text 1")}
                  </p>
                  <p check className="font-size-14 text-center">
                    <b>{t("plan text 3")}</b>
                  </p>
                  {business.filterBusinessList && false && (
                    <Fragment>
                      {business.filterBusinessList.country_id == 181 && (
                        <FormGroup check className="pl-0">
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={handleChange("printDirectly")}
                              checked={value.printDirectly}
                            />{" "}
                            {t("printBillsFromReceptionistApp")}
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                          </Label>
                        </FormGroup>
                      )}
                    </Fragment>
                  )}
                  <div className="d-flex py-3 coupon">
                    <div className="coupon-form">
                      <FormGroup>
                        <Input
                          type="text"
                          name="success"
                          id="success"
                          placeholder="code coupon"
                          value3={value3.codeValue}
                          onChange={handleChange("codeValue3")}
                        />
                      </FormGroup>
                      {couponValid.status && (
                        <FormText color="danger">
                          {couponValid.message}
                        </FormText>
                      )}
                    </div>
                    <div className="pl-3 coupon-button">
                      <Button
                        color="primary"
                        className="btn-round m-0"
                        disabled={!applyStatus3}
                        onClick={couponCodeGenerate3}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                  {business.planData.length > 0 &&
                    value.plan == "plan1" &&
                    value.subPlan == "intremediate" && (
                      <div className="px-3">
                        {business.planData.map((item, index) => {
                          return (
                            <Row key={index}>
                              <p className="bold coupon-text">{item.coupon}</p>
                            </Row>
                          );
                        })}
                      </div>
                    )}
                  <hr />
                  <div className="px-2">
                    {true && (
                      <div className="plan-1-prices">
                        {businessValue.type == "beach" ||
                        businessValue.type == "pool" ? (
                          <p className="py-2 bold font-size-16 mb-0">
                            {value.plan == "plan1" &&
                            value.subPlan == "intremediate" ? (
                              <span>
                                {" "}
                                {t("total")}:{" "}
                                <span className="price-style">
                                  {price} &nbsp;
                                  <span>&#8364;</span> &nbsp;/&nbsp;{" "}
                                  {t("month")}
                                </span>
                              </span>
                            ) : (
                              <span>
                                {t("total")}:{" "}
                                <span className="price-style">
                                  {plan3Value} &nbsp;
                                  <span>&#8364;</span> &nbsp;/&nbsp;{t("month")}{" "}
                                </span>
                              </span>
                            )}
                          </p>
                        ) : (
                          <p className="py-2 bold font-size-16 mb-0">
                            {value.plan == "plan1" &&
                            value.subPlan == "intremediate" ? (
                              <span>
                                {" "}
                                {t("total")}:{" "}
                                <span className="price-style">
                                  {price} &nbsp;
                                  <span>&#8364;</span> &nbsp;/&nbsp;{" "}
                                  {t("month")}
                                </span>
                              </span>
                            ) : (
                              <span>
                                {t("total")}:{" "}
                                <span className="price-style">
                                  {plan3ValueOther} &nbsp;
                                  <span>&#8364;</span> &nbsp;/&nbsp;{t("month")}{" "}
                                </span>
                              </span>
                            )}
                          </p>
                        )}
                      </div>
                    )}
                  </div>

                  <Row className="justify-content-center choose-button">
                    <Col md="6" style={{ textAlignLast: "center" }}>
                      {value.plan === "plan1" &&
                      value.subPlan == "intremediate" ? (
                        <Button
                          color="primary"
                          className="btn-round"
                          disabled={true}
                          onClick={savePlan3}
                        >
                          Chosen
                        </Button>
                      ) : (
                        <Button
                          outline
                          className="btn-round"
                          onClick={savePlan3}
                        >
                          Choose
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row className="justify-content-center text-center">
                    <Col>
                      {businessValue.type == "beach" ||
                      businessValue.type == "pool" ? (
                        <span className="font-size-14">
                          <i>{t("addtionalDescription3", { price: 10 })}</i>
                        </span>
                      ) : (
                        <span className="font-size-14">
                          <i>{t("addtionalDescription2", { price: 0.5 })}</i>
                        </span>
                      )}
                    </Col>
                  </Row>
                  {(businessValue.type == "restaurant" ||
                    businessValue.type == "bar" ||
                    businessValue.type == "club") && (
                    <Row className="justify-content-center text-center">
                      <Col>
                        <span className="font-size-14">
                          <i>{t("note_vat")}</i>
                        </span>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          )}
          <Col md="3" style={{ maxWidth: "366px", minWidth: "366px" }}>
            <Card className="card-user">
              <CardBody className="px-4">
                <Row>
                  <Col md={12}>
                    <p className="py-2 bold font-size-16 mb-0">Tabo Free</p>
                    <p check className="font-size-8 text-center plan-two">
                      {t("plan text 2")}
                    </p>
                    <p check className="font-size-8 text-center">
                      {t("plan text 1")}
                    </p>
                    {business.filterBusinessList && false && (
                      <Fragment>
                        {business.filterBusinessList.country_id == 181 && (
                          <FormGroup check className="pl-0">
                            <Label check>
                              <Input
                                type="checkbox"
                                onChange={handleChange("printDirectly")}
                                checked={value.printDirectly}
                              />{" "}
                              {t("printBillsFromReceptionistApp")}
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                            </Label>
                          </FormGroup>
                        )}
                      </Fragment>
                    )}
                  </Col>
                  <Col md={12}>
                    <div className="d-flex py-3 coupon">
                      <div className="coupon-form">
                        <FormGroup>
                          <Input
                            type="text"
                            name="success"
                            id="success"
                            placeholder="code coupon"
                            value2={value2.codeValue}
                            onChange={handleChange("codeValue2")}
                          />
                        </FormGroup>
                        {couponValid.status && (
                          <FormText color="danger">
                            {couponValid.message}
                          </FormText>
                        )}
                      </div>
                      <div className="pl-3 coupon-button">
                        <Button
                          color="primary"
                          className="btn-round m-0"
                          disabled={!applyStatus2}
                          onClick={couponCodeGenerate2}
                        >
                          {t("Apply")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
                {business.planData.length > 0 && value.plan == "plan2" && (
                  <div className="px-3">
                    {business.planData.map((item, index) => {
                      return (
                        <Row key={index}>
                          <p className="bold coupon-text">{item.coupon}</p>
                        </Row>
                      );
                    })}
                  </div>
                )}
                <hr />
                <div className="px-2">
                  {true && (
                    <div className="plan-2-prices">
                      {businessValue.type == "beach" ||
                      businessValue.type == "pool" ? (
                        <p className="py-2 bold font-size-16 mb-0">
                          {value.plan == "plan1" ? (
                            <span>
                              {t("total")}:{" "}
                              <span className="price-style">
                                {plan2Value} &nbsp;
                                <span>&#8364;</span> &nbsp;/&nbsp;{t("month")}{" "}
                              </span>
                            </span>
                          ) : (
                            <span>
                              {t("total")}:{" "}
                              <span className="price-style">
                                {plan2Value} &nbsp;
                                <span>&#8364;</span> &nbsp;/&nbsp;{t("month")}{" "}
                              </span>
                            </span>
                          )}
                        </p>
                      ) : (
                        <p className="py-2 bold font-size-16 mb-0">
                          {value.plan == "plan1" ? (
                            <span>
                              {" "}
                              {t("total")}:{" "}
                              <span className="price-style">
                                {plan2ValueOther} &nbsp;
                                <span>&#8364;</span> &nbsp;/&nbsp; {t("month")}
                              </span>
                            </span>
                          ) : (
                            <span>
                              {t("total")}:{" "}
                              <span className="price-style">
                                {plan2ValueOther} &nbsp;
                                <span>&#8364;</span> &nbsp;/&nbsp;{t("month")}{" "}
                              </span>
                            </span>
                          )}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <Row className="justify-content-center choose-button plan-2">
                  <Col md="6" style={{ textAlignLast: "center" }}>
                    {value.plan === "plan2" ? (
                      <Button
                        color="primary"
                        className="btn-round"
                        disabled={true}
                        onClick={savePlan2}
                      >
                        Chosen
                      </Button>
                    ) : (
                      <Button outline className="btn-round" onClick={savePlan2}>
                        Choose
                      </Button>
                    )}
                  </Col>
                </Row>
                <Row className="justify-content-center text-center">
                  <Col>
                    {businessValue.type == "beach" ||
                    businessValue.type == "pool" ? (
                      <span className="font-size-14">
                        <i>{t("addtionalDescription3", { price: 10 })}</i>
                      </span>
                    ) : (
                      <span className="font-size-14">
                        <i>{t("addtionalDescription2", { price: 1 })}</i>
                      </span>
                    )}
                  </Col>
                </Row>
                {(businessValue.type == "restaurant" ||
                  businessValue.type == "bar" ||
                  businessValue.type == "club") && (
                  <Row className="justify-content-center text-center">
                    <Col>
                      <span className="font-size-14">
                        <i>{t("note_vat")}</i>
                      </span>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Plans;

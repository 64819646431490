import React, { useState } from "react";
import {
  Card,
  Input,
  Button,
  CardFooter,
  CardHeader,
  InputGroup,
  CardBody,
} from 'reactstrap';
import axios from "axios";
import Swal from "sweetalert2";
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactNotifications } from "react-notifications-component";

import { REST_API_URL } from '../../utils/constants.js';
import { createNotification } from "../../utils/utils";

import 'react-notifications-component/dist/theme.css';
import "react-phone-input-2/lib/bootstrap.css";

import bgImage from '../../assets/img/loginpage.jpg';

const CustomerDeleteAccount = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [country] = useState('ro');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [authToken, setAuthToken] = useState(false);
  const [customer, setCustomer] = useState(null);

  const verifyPhoneNumber = async () => {
    if (!phoneNumber || !code || verifying) return;
    try {
      setVerifying(true);
      let info = {
        code: code,
        number: phoneNumber.replace(`+${code}`, '').replace(' ', '').replace('-', ''),
      };
      const response = await axios.post(`${REST_API_URL}/verifyPhoneNumber`, info);
      const { success, message, error_code, already_registered = false, phone_already_verified = false } = response.data || {};
      if (message === 'CUSTOMER_DEACTIVATED') {
        createNotification({ type: 'info', title: t('Info'), message: t('CUSTOMER_DEACTIVATED') });
      } else if (error_code === "60060") {
        createNotification({ type: 'danger', title: t('Error'), message: t('Invalid_Phone') });
      } else if ((already_registered || phone_already_verified) && !showPassword) {
        setShowPassword(true);
      } else if (!success && message) {
        createNotification({ type: 'danger', title: t('Error'), message });
      }
    } catch (error) {
      setShowPassword(false);
      if (axios.isAxiosError(error)) {
        const { error_code, message } = error.response.data;
        if (error_code === '60060') {
          createNotification({ type: 'danger', title: t('Error'), message: t('Invalid_Phone') });
        } else if (message) {
          createNotification({ type: 'danger', title: t('Error'), message });
        }
      } else if (error?.message) {
        createNotification({ type: 'danger', title: t('Error'), message: error?.message ?? '' });
      }
    } finally {
      setVerifying(false);
    }
  };

  const loginUser = async () => {
    try {
      let info = {
        code, password,
        number: phoneNumber.replace(`+${code}`, '').replace(' ', '').replace('-', ''),
      };
      const response = await axios.post(`${REST_API_URL}/login`, info);
      const { token, customer, message } = response.data;
      if (token) setAuthToken(token);
      if (customer) setCustomer(customer);
      if (message) {
        createNotification({
          message,
          type: token ? 'success' : 'danger',
          title: token ? t('Success') : t('Error'),
        });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { message } = error.response.data;
        if (message === 'WRONG_PASSWORD') {
          createNotification({ type: 'danger', title: t('Error'), message: t('WRONG_PASSWORD') });
        } else if (message === 'CUSTOMER_DEACTIVATED') {
          createNotification({ type: 'info', title: t('Info'), message: t('CUSTOMER_DEACTIVATED') });
        }
      } else if (error?.message) {
        createNotification({ type: 'danger', title: t('Error'), message: error?.message ?? '' });
      }
    }
  };

  const removeAccount = async () => {
    if (!authToken || !customer?.id) return;
    try {
      const response = await axios.delete(`${REST_API_URL}/customer/${customer?.id ?? ''}`, {
        headers: {
          'x-token': authToken,
        }
      });
      const { message } = response?.data || {};
      if (message && message === 'CUSTOMER_DELETED') {
        createNotification({ type: 'success', title: t('Account_Delete_Success'), message: t('Account_Delete_Success_Msg') });
        setTimeout(() => {
          history.replace('/');
        }, 2000);
      } else {
        createNotification({ type: 'danger', title: t('Account_Delete_Error'), message: t('went_wrong') });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { message } = error.response.data;
        if (message === '') {
          createNotification({ type: 'danger', title: t('Account_Delete_Error'), message: message });
        }
      } else {
        createNotification({ type: 'danger', title: t('Account_Delete_Error'), message: error?.message ?? '' });
      }
    }
  };

  const removeAccountConfirmation = async () => {
    Swal.fire({
      title: t('Confirm_Account_Delete_Title'),
      text: t('Confirm_Account_Delete_Msg'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t('Confirm_Delete_Account_Btn'),
      cancelButtonText: t('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        removeAccount();
      }
    });
  };

  return (
    <React.Fragment>
      <ReactNotifications />
      <div className="wrapper wrapper-full-page">
        <div className="full-page section-image" filter-color="yellow">
          <div className="content">
            <div className="d-flex align-items-center justify-content-center">
              <div className="my-4">
                <Card className="p-1 mb-0">
                  <CardHeader>
                    <h5 className="text-red-500">Delete Customer Account</h5>
                  </CardHeader>
                  {
                    authToken ? (
                      <CardBody className="pt-0 flex items-center justify-center">
                        <Button onClick={removeAccountConfirmation} color="danger">{t('delete_account')}</Button>
                      </CardBody>
                    ) : (
                      <CardBody className="pt-0">
                        <InputGroup className={'no-border form-control-lg mt-3'}>
                          <PhoneInput
                            value={phone}
                            country={country}
                            onChange={(value, countryData, e) => {
                              setPhone(value);
                              setCode(countryData.dialCode);
                              setPhoneNumber(e.target.value);
                              setAuthToken('');
                              setCustomer(null);
                              setShowPassword(false);
                            }}
                          />
                        </InputGroup>
                        {
                          showPassword ? (
                            <InputGroup className={'no-border form-control-lg mt-3 input-group-focus'}>
                              <Input
                                type="password"
                                name="password"
                                value={password}
                                style={{ fontSize: '16px' }}
                                placeholder={t('password')}
                                onChange={e => setPassword(e.target.value)}
                              />
                            </InputGroup>
                          ) : null
                        }
                      </CardBody>
                    )
                  }
                  {
                    !authToken ? (
                      <CardFooter>
                        <Button
                          block
                          size="lg"
                          color="success"
                          className="mb-3"
                          onClick={showPassword ? loginUser : verifyPhoneNumber}
                        >
                          {t(showPassword ? 'letsGo' : 'submit')}
                        </Button>
                      </CardFooter>
                    ) : null
                  }
                </Card>
              </div>
            </div>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: 'url(' + bgImage + ')' }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerDeleteAccount;
import React, { PureComponent } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Fuse from "fuse.js";
import axios from "axios";
import { connect } from "react-redux";
import { calls } from "../../../utils/calls.js";
import {
  businessName,
  businessTypes,
  businessTypesData,
  filterBusinessList,
  getBusinessListGetById,
  loginAction,
  loginFromAdminAction,
  removeBusiness,
} from "../../../redux/business/action.js";
import API from "../../../redux/business/api.js";
import { getElements } from "../../../redux/element/action.js";

const api = new API();
class Locations extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      locations: [],
      filteredLocations: [],
      showDeleteAlert: false,
      selectedId: null,
      centered: true
    };

    this.searchOptions = {
      keys: ["name", "city", "status"],
      shouldSort: true,
    };
  }

  componentDidMount() {
    const { business } = this.props;
    localStorage.removeItem("SHADOWED_USER_TOKEN");
    if (business.accessToken) {
      this._getAllBusinesses();
    }
  }

  componentDidUpdate(prevProps) {
    const { business } = this.props;
    if (!prevProps.business.accessToken && business.accessToken) {
      this._getAllBusinesses();
    }
  }

  _getAllBusinesses = () => {
    const { business } = this.props;

    axios(calls.getAllBusinesses(business.accessToken))
      .then((result) => {
        let results = [...result.data].map((item) => {
          item.city = (item.city || {}).name || null;
          return item;
        });
        results = results.sort((a, b) => {
          let dateA = new Date(a.updated_at);
          let dateB = new Date(b.updated_at);
          return dateA - dateB;
        });
        this.setState({
          locations: results,
          filteredLocations: results,
        });
      })
      .catch(() => { });
  };

  onLocationSearchChange = (event) => {
    const { locations } = this.state;
    let searchTerm = event.target.value;

    let fuse = new Fuse(locations, {
      keys: ["location_name", "city.name", "status"],
      shouldSort: true,
      minMatchCharLength: 1,
      findAllMatches: true,
      threshold: 0.2,
      distance: 50,
    });
    let filteredLocations = fuse
      .search(searchTerm)
      .map((result) => result.item);
    if (searchTerm.length === 0) {
      filteredLocations = locations;
    }
    filteredLocations = filteredLocations.sort((a, b) => {
      let dateA = new Date(a.updated_at);
      let dateB = new Date(b.updated_at);
      return dateA - dateB;
    });
    this.setState({ filteredLocations: filteredLocations, searchTerm });
  };

  updateLocationPublishingStatus(location, status) {
    // make a reuqest to update to accept or reject
    axios(calls.updateBusiness(location, { status: status }))
      .then(() => {
        let { filteredLocations } = this.state;
        let updatedClientIndex = filteredLocations.findIndex(
          (client) =>
            client.id === location.id &&
            client.name === location.name &&
            client.representative_email === location.representative_email
        );
        if (updatedClientIndex !== -1) {
          filteredLocations[updatedClientIndex].status = status;
        }
        this.setState({ filteredLocations });
      })
      .then(() => {
        if (status === "suspended" || status === "suspended_temp") {
          const message =
            status === "suspended"
              ? "suspended permanently"
              : "suspended temporarily";
          return axios(
            calls.sendEmail({
              sender: "tabo",
              receiver: location.representative_email,
              subject: `Your account was ${message}!`,
              mailHtml: `
					<div>
						<p>Your account was ${message}!</p>	
					</div>
				`,
            })
          );
        }
      })
      .catch((err) => { });
  }

  removeLocation(id) {
    api
      .removeBusinessActionRequests(id)
      .then((result) => {
        this._getAllBusinesses();
        this.setState({ showDeleteAlert: false, selectedId: null });
      })
      .catch(() => { });
  }

  getShadowUserToken(business) {
    debugger;
    axios(calls.getShadowedUserToken(business.client_id))
      .then((result) => {
        localStorage.setItem("SHADOWED_USER_TOKEN", result.data.token);
        localStorage.setItem("admin_data", JSON.stringify(this.props.business));

        let loginData = result.data
        //loginData.user.super_admin = 1;
        this.props.dispatch(loginFromAdminAction({ history: this.props.history, loginData: loginData })).then(data => {
          this.props.dispatch(businessTypesData()).then(data => {
            this.props.dispatch(filterBusinessList(business));
            this.props.dispatch(getBusinessListGetById(data.data.business[0].id))
            this.props.dispatch(getElements());
            this.props.history.push("/admin/gridPlan");
          })
        })

        // eslint-disable-next-line no-restricted-globals
      })
  }

  render() {
    const { filteredLocations, searchTerm, showDeleteAlert, selectedId, centered } = this.state;
    return (
      <>
        <div className="content" style={{ marginTop: "2rem" }}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader className="px-4">
                  <h5 className="title">All Locations</h5>
                </CardHeader>
                <CardBody>
                  <div className="d-flex justify-content-start">
                    <input
                      value={searchTerm}
                      onChange={this.onLocationSearchChange}
                      placeholder={"Search for a location"}
                    />
                  </div>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">id</th>
                        <th className="text-left">Name</th>
                        <th className="text-center">City</th>
                        <th className="text-center">Status</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    {filteredLocations.length > 0 ? (
                      <tbody>
                        {filteredLocations.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="text-left">{item.id}</td>
                              <td className="text-left">
                                {item.location_name}
                              </td>
                              <td className="text-center">{item.city}</td>
                              <td className="text-center">{item.status}</td>
                              <td className="d-flex flex-column justify-content-center, align-items-end d-md-block text-md-right ">
                                <Button
                                  className="btns-mr-5 mb-1 mb-md-0"
                                  color="success"
                                  id="tooltip26024663"
                                  size="sm"
                                  type="button"
                                  onClick={() => this.getShadowUserToken(item)}
                                >
                                  Go in
                                </Button>
                                <Button
                                  className="btns-mr-5 mb-1 mb-md-0"
                                  color="success"
                                  id="tooltip26024663"
                                  size="sm"
                                  type="button"
                                  onClick={() =>
                                    this.updateLocationPublishingStatus(
                                      item,
                                      "active"
                                    )
                                  }
                                >
                                  Accept
                                </Button>
                                <Button
                                  className="btns-mr-5 mb-1 mb-md-0"
                                  color="success"
                                  id="tooltip26024663"
                                  size="sm"
                                  type="button"
                                  onClick={() =>
                                    this.updateLocationPublishingStatus(
                                      item,
                                      "suspended_temp"
                                    )
                                  }
                                >
                                  Suspend Temporary
                                </Button>
                                <Button
                                  className="btns-mr-5 mb-1 mb-md-0"
                                  color="success"
                                  id="tooltip26024663"
                                  size="sm"
                                  type="button"
                                  onClick={() =>
                                    this.updateLocationPublishingStatus(
                                      item,
                                      "suspended"
                                    )
                                  }
                                >
                                  Suspend
                                </Button>
                                <Button
                                  className="btns-mr-5 mb-1 mb-md-0"
                                  color="success"
                                  id="tooltip26024663"
                                  size="sm"
                                  type="button"
                                  onClick={() => this.setState({ showDeleteAlert: true, selectedId: item.id })}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr className="bold text-center">
                          <td colSpan="6">There is no data to display.</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={showDeleteAlert} centered>
            <ModalBody>Are you sure want to delete this location ?</ModalBody>
            <ModalFooter>
              <div style={{ width: '100%', textAlign: 'end' }}>
                <Button
                  color="primary"
                  onClick={() => this.removeLocation(selectedId)}
                >
                  Yes
                </Button>{' '}
                <Button
                  color="danger"
                  onClick={() => this.setState({ showDeleteAlert: false, selectedId: null })}
                >
                  Close
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  business: store.business,
});

export default connect(mapStateToProps)(Locations);

import React, { Fragment, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Input,
  Button,
} from 'reactstrap';
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import confirm from 'reactstrap-confirm';
import Switch from '@material-ui/core/Switch';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import { ToastContainer, toast } from 'react-toastify';

import CloseDayComponent from './GridPlannerComponent/CloseDayComponent';
import GridComponent from './GridPlannerComponent/GridComponent';
import Datetime from '../../../components/DateTime';
import Zone2 from './GridPlannerComponent/Zone2';
// import Zone from './GridComponent/Zone';

import {
  getZone,
  endDates,
  startDates,
  getZoneName,
  actionTypes,
  // getHourWork,
  saveTimeLine,
  workHourValue,
  saveZoneAction,
  applyWorkTimeTo,
  updateZoneAction,
  getTimeLineAction,
  getActivityPeriod,
  apllySameSchedule,
  applyWorkTimeFrom,
  gridSaveDataAction,
  activityDatePeriod,
  saveWorkHourAction,
  applyWorkTimeToBreak,
  updateWorkHourAction,
  temporaryClosedChange,
  applyWorkTimeFromBreak,
  updateActivityDatePeriodPromise,
} from '../../../redux/grid/action';
import { getExtraSunbedPhoto, saveBusinessSettings } from '../../../redux/option/action';
import { getPrice, checkIfAllPricesAreSet } from '../../../redux/price/action';
import { startLoading, stopLoading } from '../../../redux/loader/action';
import { getElements } from '../../../redux/element/action';
import { createNotification } from '../../../utils/utils';
import { calls } from '../../../utils/calls';

// import { REST_API_URL } from '../../../utils/constants';

import GuranteeReservation from './OptionsComponents/GuranteeReservation';
import { Facilities, Facilities2 } from './OptionsComponents/Facilities';
import ContactInformation from './OptionsComponents/ContactInformation';
// import CovidCertificate from './OptionsComponents/CovidCertificate';
import PhotoRequired from './OptionsComponents/PhotoRerquired';
import AutoAcceptSection from './OptionsComponents/AutoAccept';
import BeachLocation from './OptionsComponents/BeachLocation';
import EstimatedTime from './OptionsComponents/EstimatedTime';
import ExecutiveChef from './OptionsComponents/ExecutiveChef';
import TowelSection from './OptionsComponents/towelSection';
import MustArriveBy from './OptionsComponents/MustArriveBy';
import BusinessLogo from './OptionsComponents/BusinessLogo';
import BeachPhotos from './OptionsComponents/BeachPhotos';
import CallWaiter from './OptionsComponents/callWaiter';
import GuestLimit from './OptionsComponents/GuestLimit';
import { Cuisine } from './OptionsComponents/cuisine';
import { Website } from './OptionsComponents/Website';
import { Cost } from './OptionsComponents/Cost';
import Rules from './OptionsComponents/Rules';

import '../../../assets/css/custom.scss';

const Settings = () => {
  const { t } = useTranslation()
  var intervalOption = [
    { value: 15, label: `15 ${t('minute')}` },
    { value: 30, label: `30 ${t('minute')}` },
    { value: 60, label: `60 ${t('minute')}` },
    { value: 90, label: `90 ${t('minute')}` },
    { value: 120, label: `120 ${t('minute')}` },
    { value: 180, label: `180 ${t('minute')}` },
  ]
  const defaultDays = [
    {
      id: 1,
      name: `Monday`,
      from: '',
      to: '',
      status: 'Open',
      break_from: '',
      break_to: '',
      must_arrive_by: '',
    },
    {
      id: 2,
      name: `Tuesday`,
      from: '',
      to: '',
      status: 'Open',
      break_from: '',
      break_to: '',
      must_arrive_by: '',
    },
    {
      id: 3,
      name: `Wednesday`,
      from: '',
      to: '',
      status: 'Open',
      break_from: '',
      break_to: '',
      must_arrive_by: '',
    },
    {
      id: 4,
      name: `Thursday`,
      from: '',
      to: '',
      status: 'Open',
      break_from: '',
      break_to: '',
      must_arrive_by: '',
    },
    {
      id: 5,
      name: `Friday`,
      from: '',
      to: '',
      status: 'Open',
      break_from: '',
      break_to: '',
      must_arrive_by: '',
    },
    {
      id: 6,
      name: `Saturday`,
      from: '',
      to: '',
      status: 'Open',
      break_from: '',
      break_to: '',
      must_arrive_by: '',
    },
    {
      id: 7,
      name: `Sunday`,
      from: '',
      to: '',
      status: 'Open',
      break_from: '',
      break_to: '',
      must_arrive_by: '',
    },
  ]

  const dispatch = useDispatch();

  const business = useSelector(({ business }) => business)
  const price = useSelector(({ price }) => price)
  const grid = useSelector(({ grid }) => grid)
  // const plan = useSelector(({ plan }) => plan)

  const option = useSelector(({ option }) => option);
  const element = useSelector(({ element }) => element);
  const [applyCheckState, setCheckApplyState] = useState(true);
  const [selectCheckState, setSelectCheckState] = useState(true);
  const [changeValueState, setChangeValueState] = useState(true);
  const [endDateValueStatus, setEndValueDateStatus] = useState(grid.endDate);
  const [startDateValueStatus, setStartValueDateStatus] = useState(grid.startDate);
  const [state3, setState3] = useState(true);
  const [lockButton, setLockButton] = useState(false);
  const [state4, setState4] = useState(true);
  const [dateValue, setDateValue] = useState(defaultDays);
  const [timeLineArrs, setTimeLineArrs] = useState([]);
  const [intervalValue, setIntervalValue] = useState(15);
  const [customerIntervalValue, setCustomerIntervalValue] = useState(15);
  const [zoneName, setZoneName] = useState();
  const [activityDate, setActivityDate] = useState({
    startDate: grid.startDate,
    endDate: grid.endDate,
    startValid: !!grid.startDate,
    endValid: !!grid.endDate,
    status: false,
  });

  useEffect(() => {
    setActivityDate({
      startDate: grid.startDate,
      endDate: grid.endDate,
      startValid: !!grid.startDate,
      endValid: !!grid.endDate,
      status: false,
    });
  }, [grid.startDate, grid.endDate, business.filterBusinessList.id])

  useEffect(() => {
    if (
      business.filterBusinessList.type == 'beach' ||
      business.filterBusinessList.type == 'pool'
    ) {
      dispatch(getZone())
      dispatch(getZoneName())
    }
  }, [business.filterBusinessList, dispatch, grid.hasVipZone, grid.zoneCount])

  useEffect(() => {
    setLockButton(true)
    dispatch(getElements())
  }, [business.filterBusinessList.id]) //

  useEffect(() => {
    setStartValueDateStatus(grid.startDate)
    setEndValueDateStatus(grid.endDate)
    dispatch(getActivityPeriod())
    dispatch(getTimeLineAction())

    // return new Promise((resolve) => {
    //   dispatch(getHourWork(resolve));
    // }).then((data) => {
    // });
  }, [business.filterBusinessList, t]) //
  useEffect(() => {
    if (grid && grid.weekHourData && grid.weekHourData.length > 0) {
      let data = grid.weekHourData

      if (data.length > 0) {
        let workingDay = [
          {
            id: 1,
            name: `Monday`,
            from: data[0].mon_start,
            to: data[0].mon_end,
            status: data[0].mon,
            break_from: data[0].mon_start_break,
            break_to: data[0].mon_end_break,
            must_arrive_by: data[0].mon_must_arrive_by,
          },
          {
            id: 2,
            name: `Tuesday`,
            from: data[0].tue_start,
            to: data[0].tue_end,
            status: data[0].tue,
            break_from: data[0].tue_start_break,
            break_to: data[0].tue_end_break,
            must_arrive_by: data[0].tue_must_arrive_by,
          },
          {
            id: 3,
            name: `Wednesday`,
            from: data[0].wed_start,
            to: data[0].wed_end,
            status: data[0].wed,
            break_from: data[0].wed_start_break,
            break_to: data[0].wed_end_break,
            must_arrive_by: data[0].wed_must_arrive_by,
          },
          {
            id: 4,
            name: `Thursday`,
            from: data[0].thu_start,
            to: data[0].thu_end,
            status: data[0].thu,
            break_from: data[0].thu_start_break,
            break_to: data[0].thu_end_break,
            must_arrive_by: data[0].thu_must_arrive_by,
          },
          {
            id: 5,
            name: `Friday`,
            from: data[0].fri_start,
            to: data[0].fri_end,
            status: data[0].fri,
            break_from: data[0].fri_start_break,
            break_to: data[0].fri_end_break,
            must_arrive_by: data[0].fri_must_arrive_by,
          },
          {
            id: 6,
            name: `Saturday`,
            from: data[0].sat_start,
            to: data[0].sat_end,
            status: data[0].sat,
            break_from: data[0].sat_start_break,
            break_to: data[0].sat_end_break,
            must_arrive_by: data[0].sat_must_arrive_by,
          },
          {
            id: 7,
            name: `Sunday`,
            from: data[0].sun_start,
            to: data[0].sun_end,
            status: data[0].sun,
            break_from: data[0].sun_start_break,
            break_to: data[0].sun_end_break,
            must_arrive_by: data[0].sun_must_arrive_by,
          },
        ]

        if (business.filterBusinessList.type !== 'restaurant') {
          workingDay.forEach((day) => {
            day.must_arrive_by = day.must_arrive_by
              ? day.must_arrive_by
              : moment(day.to, 'HH:mm').subtract(60, 'minutes').format('HH:mm')
          })
        }

        setDateValue(workingDay)

        if (
          data[0].sun_start === data[0].mon_start &&
          data[0].sat_start === data[0].mon_start &&
          data[0].fri_start === data[0].mon_start &&
          data[0].thu_start === data[0].mon_start &&
          data[0].wed_start === data[0].mon_start &&
          data[0].tue_start === data[0].mon_start &&
          data[0].sun_end === data[0].mon_end &&
          data[0].sat_end === data[0].mon_end &&
          data[0].fri_end === data[0].mon_end &&
          data[0].thu_end === data[0].mon_end &&
          data[0].wed_end === data[0].mon_end &&
          data[0].tue_end === data[0].mon_end &&
          data[0].sun_start_break === data[0].mon_start_break &&
          data[0].sat_start_break === data[0].mon_start_break &&
          data[0].fri_start_break === data[0].mon_start_break &&
          data[0].thu_start_break === data[0].mon_start_break &&
          data[0].wed_start_break === data[0].mon_start_break &&
          data[0].tue_start_break === data[0].mon_start_break &&
          data[0].sun_end_break === data[0].mon_end_break &&
          data[0].sat_end_break === data[0].mon_end_break &&
          data[0].wed_end_break === data[0].mon_end_break &&
          data[0].fri_end_break === data[0].mon_end_break &&
          data[0].thu_end_break === data[0].mon_end_break &&
          data[0].tue_end_break === data[0].mon_end_break
        ) {
          setCheckApplyState(true)
          setGridCheck(true)
          dispatch(apllySameSchedule(true))
          setapplySameStartFrom(data[0].mon_start)
          setapplySameEnd(data[0].mon_end)
          setApplySameBreakStartFrom(data[0].mon_start_break)
          setApplySameBreakEndTo(data[0].mon_end_break)
          dispatch(applyWorkTimeFrom(data[0].mon_start))
          dispatch(applyWorkTimeTo(data[0].mon_end))
          dispatch(applyWorkTimeFromBreak(data[0].mon_start_break))
          dispatch(applyWorkTimeToBreak(data[0].mon_end_break))
        } else {
          setGridCheck(true)
          setTimeout(() => {
            setGridCheck(false)
            setCheckApplyState(false)
          }, 100)
        }
      } else {
        setDateValue(defaultDays)
        setapplySameStartFrom('')
        setapplySameEnd('')
        setApplySameBreakStartFrom('')
        setApplySameBreakEndTo('')
        setTimeout(() => {
          setGridCheck(false)
          setCheckApplyState(false)
        }, 100)
      }
      //   setGridCheck(true);
      //   setGridCheck(false);
    }
  }, [business.filterBusinessList, t])

  useEffect(() => {
    dispatch(getPrice())
    dispatch(getExtraSunbedPhoto())
  }, [dispatch, business.filterBusinessList.id]) //

  var currentTime = new Date().getTime()
  const [milidate, setMilidate] = useState(0)
  const [milidate1, setMilidate1] = useState(0)
  const [miliStart, setMiliStart] = useState(0)
  const [miliEnd, setMiliEnd] = useState(0)
  const [applySameStartFrom, setapplySameStartFrom] = useState('')
  const [applySameEnd, setapplySameEnd] = useState('')
  const [applySameBreakStartFrom, setApplySameBreakStartFrom] = useState('')
  const [applySameBreakEndTo, setApplySameBreakEndTo] = useState('')
  // const [visible, setVisible] = useState(false)
  const [gridCheck, setGridCheck] = useState(false)
  const [temporaryClosed, setTemporaryClosed] = useState(
    option.businessSettings?.[0]?.temporary_closed,
  )

  let history = useHistory();

  useEffect(() => {
    if (option && option?.businessSettings) {
      setTemporaryClosed(option.businessSettings?.[0]?.temporary_closed);
      setIntervalValue(option.businessSettings?.[0]?.booking_time_limit);
      setCustomerIntervalValue(option.businessSettings?.[0]?.customer_booking_time_limit);
    }
  }, [option?.businessSettings])

  // const onDismiss = () => {
  //   setVisible(!visible)
  // }

  var yesterday = moment().subtract(1, 'day');
  var endValue;

  const startDate = (date) => {
    setChangeValueState(false)
    endValue = date
    setMiliStart(endValue._d.getTime())
    var a = Math.round(
      (currentTime - endValue._d.getTime()) / (24 * 3600 * 60) / 16.7,
    )
    setMilidate(a + 1)
    endValid(endValue)
    let dateFormat = date.format('DD/MM/YYYY')
    if (dateFormat == startDateValueStatus) {
      setState3(true)
    } else {
      setState3(false)
    }
    dispatch(startDates(date.format('MM/DD/YYYY')))
    setActivityDate({
      ...activityDate,
      startDate: dateFormat,
      startValid: true,
      status: true,
    })
  }

  var futureDaty = moment().subtract(milidate - 1, 'day')

  var endValid = function (current) {
    return current.isAfter(futureDaty)
  }

  var valid = function (current) {
    return current.isAfter(yesterday)
  }

  const endDate = (date) => {
    setLockButton(false)
    setMiliEnd(date._d.getTime())
    let dateFormat = date.format('DD/MM/YYYY')
    if (dateFormat == endDateValueStatus) {
      setState4(true)
    } else {
      setState4(false)
    }
    dispatch(endDates(date.format('MM/DD/YYYY')))
    var a = Math.round(
      (currentTime - date._d.getTime()) / (24 * 3600 * 60) / 16.7,
    )
    setMilidate1(a + 1)
    setActivityDate({ ...activityDate, endDate: dateFormat, endValid: true })
  }
  const [message, setMessage] = useState('')

  const saveZone = (event) => {
    if (
      business.filterBusinessList.type == 'beach' ||
      business.filterBusinessList.type == 'pool'
    ) {
      dispatch(startLoading())
      if (grid.id) {
        return dispatch(updateZoneAction(zoneName))
      } else {
        return dispatch(saveZoneAction(zoneName))
      }
    } else {
      return new Promise((resolve, reject) => {
        resolve({})
      })
    }
  }

  // const openStripeCustomerPortal = () => {
  //   dispatch(startLoading())
  //   axios
  //     .post(
  //       `${REST_API_URL}/stripe/customer-portal`,
  //       {
  //         client_id: business.userId,
  //       })
  //     .then((res) => {
  //       dispatch(stopLoading())
  //       window.location.href = res.data.postalLink
  //     })
  //     .catch((err) => {
  //       dispatch(stopLoading())
  //     })
  // }


  const activityDateAction = (event) => {
    dispatch(startLoading())
    saveZone()
      .then(() => {
        let allDispach = []
        setLockButton(true)
        setState3(true)
        allDispach.push(dispatch(temporaryClosedChange(temporaryClosed)))
        if (
          grid.getActivityPeriod.some(
            (period) => period.business_id === business.filterBusinessList.id,
          )
        ) {
          allDispach.push(
            dispatch({
              type: actionTypes.UPDATE_ACTIVITY_DATE_PERIOD,
              value: activityDate,
            }),
          )
          allDispach.push(
            updateActivityDatePeriodPromise(
              business.filterBusinessList.id,
              activityDate,
              dispatch,
            ).then(() => {
              checkIfAllPricesAreSet(business.filterBusinessList.id, dispatch)
              createNotification({
                type: 'success',
                title: t('Activity Period'),
                message: t('Activity period saved successfully'),
              })
            }),
          )
          if (price.allPrice.length > 0) {
            const sortArray = price.allPrice.sort(
              (a, b) => new Date(a.end_date) - new Date(b.end_date),
            )
            const endDate = sortArray[sortArray.length - 1].end_date
            let endDateValue = new Date(grid.endDate).getTime()
            let currentEndDateValue = new Date(endDate).getTime()
            if (endDateValue <= currentEndDateValue) {
            } else {
            }
          } else {
          }
        } else {
          allDispach.push(
            dispatch(
              activityDatePeriod(
                activityDate,
                () => {
                  checkIfAllPricesAreSet(
                    business.filterBusinessList.id,
                    dispatch,
                  )
                },
                business.filterBusinessList.id,
              ),
            ),
          )
        }
        // if (miliEnd > miliStart) {
        // 	setState4(true);
        // 	setVisible(false);
        // } else {
        // 	setVisible(true);
        // 	setState4(false);
        // }

        Promise.all(allDispach)
          .then(() => {
            dispatch(stopLoading())
          })
          .catch(() => {
            dispatch(stopLoading())
          })
      })
      .catch((e) => {
        dispatch(stopLoading())
      })
  }

  const handleChange = (id, name, value) => {
    setChangeValueState(false)
    let arr = dateValue
    arr.map((item, index) => {
      if (item.id == id) {
        if (name == 'from') {
          arr[index].from = value
        }
        if (name == 'to') {
          arr[index].to = value
          if (
            !option.businessSettings[0].must_arrive_by_required &&
            business.filterBusinessList.type !== 'restaurant'
          ) {
            arr[index].must_arrive_by = moment(value, 'HH:mm')
              .subtract(60, 'minutes')
              .format('HH:mm')
          }
        }
        if (name == 'break_from') {
          arr[index].break_from = value
        }
        if (name == 'break_to') {
          arr[index].break_to = value
        }
        if (name == 'status') {
          if (value == 'Open') {
            arr[index].status = value
          } else {
            arr[index].status = value
            arr[index].from = ''
            arr[index].to = ''
            arr[index].break_from = ''
            arr[index].break_to = ''
            arr[index].must_arrive_by = ''
          }
        }
      }
    })
    setDateValue(arr)
    arr.map((item, index) => {
      if (item.status == 'Open') {
        if (item.from.length == 0) {
          setChangeValueState(true)
          return
        } else {
          if (item.to.length == 0) {
            setChangeValueState(true)
            return
          } else {
            setChangeValueState(false)
          }
        }
      }
    })
    dispatch(workHourValue(arr))
  }

  const saveWorkHour = () => {
    if (gridCheck) {
      let toValue = moment(`${applySameEnd}`, 'HH:mm')
      let fromValue = moment(`${applySameStartFrom}`, 'HH:mm')
      let breakTo = moment(`${applySameBreakEndTo}`, 'HH:mm')
      let breakFrom = moment(`${applySameBreakStartFrom}`, 'HH:mm')

      if (toValue.isBefore(fromValue)) {
        toValue.add(24, "hours");
      }

      if (fromValue.isAfter(breakFrom, "minutes")) {
        createNotification({
          type: 'danger',
          title: t('Invalid break from'),
          message: t('Break from can not before from'),
        })
        return false
      }

      if (toValue.isBefore(breakTo, "minutes") || breakTo.isBefore(breakFrom, "minutes")) {
        createNotification({
          type: 'danger',
          title: t('Invalid break to'),
          message: t('Break to can not before break from'),
        })
        return false
      }
    }
    setChangeValueState(true)
    setCheckApplyState(true)
    setSelectCheckState(true)
    let allPromices = []
    dispatch(startLoading())

    let must_arrive_by = ''

    let days = [
      {
        id: 1,
        name: 'Monday',
        from: grid.from,
        to: grid.to,
        status: 'Open',
        break_from: grid.from_break,
        break_to: grid.to_break,
      },
      {
        id: 2,
        name: 'Tuesday',
        from: grid.from,
        to: grid.to,
        status: 'Open',
        break_from: grid.from_break,
        break_to: grid.to_break,
      },
      {
        id: 3,
        name: 'Wednesday',
        from: grid.from,
        to: grid.to,
        status: 'Open',
        break_from: grid.from_break,
        break_to: grid.to_break,
      },
      {
        id: 4,
        name: 'Thursday',
        from: grid.from,
        to: grid.to,
        status: 'Open',
        break_from: grid.from_break,
        break_to: grid.to_break,
      },
      {
        id: 5,
        name: 'Friday',
        from: grid.from,
        to: grid.to,
        status: 'Open',
        break_from: grid.from_break,
        break_to: grid.to_break,
      },
      {
        id: 6,
        name: 'Saturday',
        from: grid.from,
        to: grid.to,
        status: 'Open',
        break_from: grid.from_break,
        break_to: grid.to_break,
      },
      {
        id: 7,
        name: 'Sunday',
        from: grid.from,
        to: grid.to,
        status: 'Open',
        break_from: grid.from_break,
        break_to: grid.to_break,
      },
    ]

    if (
      !option.businessSettings[0].must_arrive_by_required &&
      business.filterBusinessList.type !== 'restaurant'
    ) {
      must_arrive_by = moment(grid.to, 'HH:mm')
        .subtract(60, 'minutes')
        .format('HH:mm')
      days.forEach((day) => {
        day.must_arrive_by = must_arrive_by
      })
    }

    allPromices.push(SaveGridData(gridCheck ? days : dateValue, false))

    allPromices.push(getTimeLine(gridCheck ? days : dateValue, 15, false))

    if (grid.weekHourData?.length) {
      //Remove as from request by Nicoal on 24-07-21
      // if (option.businessSettings?.[0]) {
      // 	getTimeLine(gridCheck ? days : dateValue, option.businessSettings?.[0]?.booking_time_limit, false);
      // }

      if (gridCheck) {
        setDateValue(days)
        allPromices.push(dispatch(workHourValue(days)))
        allPromices.push(dispatch(updateWorkHourAction(false)))
      } else {
        allPromices.push(dispatch(workHourValue(dateValue)))
        allPromices.push(dispatch(updateWorkHourAction(false)))
      }
    } else {
      if (gridCheck) {
        allPromices.push(dispatch(workHourValue(days)))
        allPromices.push(dispatch(saveWorkHourAction(false)))
      } else {
        allPromices.push(dispatch(workHourValue(dateValue)))
        allPromices.push(dispatch(saveWorkHourAction(false)))
      }
    }
    //allPromices.push(saveIntervalAction());

    Promise.all(allPromices)
      .then((data) => {
        createNotification({
          type: "success",
          title: t("update_work_hours"),
          message: t("update_work_hours_success"),
        });
        dispatch(stopLoading())
      })
      .catch((error) => {
        createNotification({
          type: "danger",
          title: t("update_work_hours"),
          message: t("update_work_hours_fail"),
        });
        dispatch(stopLoading())
      })
    dispatch(stopLoading())
  }

  const SaveGridData = (arrs, showNotification = true) => {
    let timeArrs = []
    let totalArray = []

    arrs.map((item, index) => {
      let timeArr = []
      let arr = []
      if (item.from) {
        arr = [item.from]
      }
      let arr1 = getTimeLineArr(item, 15, timeArr)
      arr = [...arr, ...arr1]
      let time_index = 0
      arr.map((list, index) => {
        if (index < arr.length - 1) {
          let startTime = moment(arr[index], 'HH:mm')
          let endTime = moment(arr[index + 1], 'HH:mm')
          let duration = moment.duration(endTime.diff(startTime)).asHours()
          if (duration != 0.25) {
            time_index = index + 1
          }
        }
      })
      if (time_index != 0) {
        arr.splice(time_index, 0, item.break_from)
      }
      timeArrs.push({
        id: index,
        arrs: arr,
        name: item.name,
      })
    })

    timeArrs.map((item, index) => {
      if (element.resultElements.length > 0) {
        let elementArr = []
        element.resultElements.map((list, i) => {
          item.arrs.map((time, j) => {
            elementArr.push(
              `{ id: ${i}, index: "${list.id}_${time}", x: ${0 + 35 * i}, y: ${0 + 60 * j
              },status:false }`,
            )
          })
        })
        totalArray.push(
          `{id: ${index},day: "${item.name}",grid_arr:[${elementArr.join(
            '',
          )}]}`,
        )
      }
    })

    return dispatch(
      gridSaveDataAction(
        totalArray,
        () => {
          checkIfAllPricesAreSet(business.filterBusinessList.id, dispatch)
        },
        showNotification,
      )
    );
  };

  const getTimeLine = (value, limitTime, showNotification = true) => {
    // Updated Temp solution for limit time.
    if (!limitTime) {
      limitTime = 15
    }
    let timeArrs = []
    value.map((item, index) => {
      let timeArr = []
      let arr = []
      if (item.from) {
        arr = [item.from]
      }
      let arr1 = getTimeLineArr(item, limitTime, timeArr)
      arr = [...arr, ...arr1]
      let time_index = 0
      arr.map((list, index) => {
        if (index < arr.length - 1) {
          let startTime = moment(arr[index], 'HH:mm')
          let endTime = moment(arr[index + 1], 'HH:mm')
          let duration = moment.duration(endTime.diff(startTime)).asHours()
          if (duration != 0.25) {
            time_index = index + 1
          }
        }
      })
      // if (time_index != 0) {
      //   arr.splice(time_index, 0, item.break_from);
      // }
      timeArrs.push({
        id: index,
        arrs: arr,
        name: item.name,
      })
    })
    setTimeLineArrs(timeArrs)
    return dispatch(saveTimeLine(timeArrs, business.filterBusinessList.id, showNotification))
  }

  const getTimeLineArr = (item, limitTime, timeArr) => {
    return repeatTime(
      item.from,
      item.to,
      limitTime,
      timeArr,
      item.break_from,
      item.break_to,
      1,
    )
  }

  const repeatTime = (
    from,
    to,
    limitTime,
    arr,
    break_from,
    break_to,
    status,
  ) => {
    let dataArr = []
    dataArr = arr
    if (status == 1) {
    }
    let output = moment(from, 'hh:mm').format('HH mm')
    let time = moment(
      output.split(' ')[0] + ':' + output.split(' ')[1],
      'HH:mm',
    )
    let updateTime = time.add(limitTime, 'm').format('HH mm')
    let beginningTime = moment(
      `${updateTime.split(' ')[0]}:${updateTime.split(' ')[1]}`,
      'HH:mm',
    )

    let endTime = moment(`${to}`, 'HH:mm')
    if (moment(from, 'hh:mm').isAfter(endTime)) {
      endTime = endTime.add(24, 'h')
    }
    let start_BreakTime = moment(`${break_from}`, 'HH:mm')
    let end_BreakTime = moment(`${break_to}`, 'HH:mm')
    if (beginningTime.isSameOrBefore(endTime)) {
      if (beginningTime.isBefore(start_BreakTime)) {
        arr.push(`${updateTime.split(' ')[0]}:${updateTime.split(' ')[1]}`)
      } else {
        if (beginningTime.isBefore(end_BreakTime)) {
          // arr.push(break_from);
        } else {
          arr.push(`${updateTime.split(' ')[0]}:${updateTime.split(' ')[1]}`)
        }
      }
      repeatTime(
        `${updateTime.split(' ')[0]}:${updateTime.split(' ')[1]}`,
        to,
        limitTime,
        arr,
        break_from,
        break_to,
        0,
      )
      return arr
    } else {
      return arr
    }
  }
  function allAreEqual() {
    return Object.values(dateValue).map((value, _index, arr) => {
      if (
        arr[_index].break_from == '' &&
        arr[_index].break_to == '' &&
        value.break_from !== arr[0].break_from &&
        value.break_to !== arr[0].break_to
      ) {
        return 'break'
      } else if (value.from !== arr[0].from || value.to !== arr[0].to) {
        return 'not_break'
      } else if (
        value.from == arr[0].from &&
        value.to == arr[0].to &&
        value.break_from == arr[0].break_from &&
        value.break_to == arr[0].break_to
      ) {
        return 'true'
      }

      // setGridCheck(false);
    })
  }

  const checkChange = (event) => {
    if (event.target.checked == false) {
      setGridCheck(event.target.checked)

      dispatch(apllySameSchedule(event.target.checked))
    } else {
      let not_brake = allAreEqual().some((dt) => dt === 'not_break')
      let brake = allAreEqual().some((dt) => dt === 'break')
      if (not_brake) {
        createNotification({
          type: 'danger',
          title: t('Working Hour Error'),
          message: t('All Working Hours should be  same value'),
        })
      } else if (brake) {
        createNotification({
          type: 'danger',
          title: t('Break Time Error'),
          message: t('All break times should be on/off  or atleast same value'),
        })
      } else {
        setGridCheck(event.target.checked)

        dispatch(apllySameSchedule(event.target.checked))
      }
    }
  }

  const applyWorkHourFrom = (date) => {
    setChangeValueState(false)
    let dateTime = date.format('HH:mm')
    dispatch(applyWorkTimeFrom(dateTime))
    setapplySameStartFrom(dateTime)
  }

  const onTemporaryClose = (event) => {
    setTemporaryClosed(event.target.checked)
    dispatch(temporaryClosedChange(event.target.checked))
    axios(
      calls.sendEmail({
        sender: 'tabo',
        receiver: 'tabo',
        subject: event.target.checked
          ? 'A location is now temporary closed!'
          : 'A location has resumed their schedule',
        mailHtml: event.target.checked
          ? `
						<div>
							<h1>Location ${business.filterBusinessList.location_name} is now closed temporarily</h1>
						</div>
					`
          : `
						<div>
							<h1>Location ${business.filterBusinessList.location_name} has unchecked "temporary closed"</h1>
						</div>
					`,
      }),
    )
  }
  const applyWorkHourTo = (date) => {
    setChangeValueState(false)
    let dateTime = date.format('HH:mm')
    setapplySameEnd(dateTime)
    dispatch(applyWorkTimeTo(dateTime))
  }

  const applyWorkHourFromBreak = (date) => {
    setChangeValueState(false)
    let dateTime = date.format('HH:mm')
    setApplySameBreakStartFrom(dateTime)
    dispatch(applyWorkTimeFromBreak(dateTime))
  }

  const applyWorkHourToBreak = (date) => {
    setChangeValueState(false)
    let dateTime = date.format('HH:mm')
    setApplySameBreakEndTo(dateTime)
    dispatch(applyWorkTimeToBreak(dateTime))
  }

  const handleChangeCheck = (checkStatus, id) => {
    let arr = [...dateValue]
    arr.map((item, index) => {
      if (item.id == id && !checkStatus) {
        item.break_to = ''
        item.break_from = ''
      }
    })
    setChangeValueState(false)
    dispatch(workHourValue(arr))
    setDateValue(arr)
  }

  const saveIntervalAction = async (count, type) => {
    let booking_time_limit,
      customer_booking_time_limit = 15
    let estimatedTimeChange = false
    if (type == 'business') {
      booking_time_limit = count
      customer_booking_time_limit = customerIntervalValue
    } else if (type == 'customer') {
      booking_time_limit = intervalValue
      customer_booking_time_limit = count
    } else {
      booking_time_limit = intervalValue
      customer_booking_time_limit = customerIntervalValue
    }

    if (customer_booking_time_limit % booking_time_limit != 0) {
      customer_booking_time_limit = booking_time_limit
    }

    let estimated_time =
      option?.businessSettings[0]?.estimated_time || booking_time_limit

    if (
      option?.businessSettings[0]?.estimated_time &&
      option?.businessSettings[0]?.estimated_time % booking_time_limit != 0
    ) {
      estimated_time = booking_time_limit
      estimatedTimeChange = true
    }

    setIntervalValue(booking_time_limit)
    setCustomerIntervalValue(customer_booking_time_limit)
    let mutation = `{
      id: ${option?.businessSettings[0]?.id}
      booking_time_limit: ${booking_time_limit}
      customer_booking_time_limit: ${customer_booking_time_limit}
      estimated_time : ${estimated_time}
    }`

    return dispatch(saveBusinessSettings(mutation)).then((data) => {
      if (estimatedTimeChange) {
        estimateTimeConfirm(booking_time_limit).then((result) => {
          if (result) {
            history.push('/admin/option')
          }
        })
      }
    })
  }

  const estimateTimeConfirm = (reset_value) => {
    return confirm({
      title: <>{t('estimate_alert_confirm_title')}</>,
      message: t('estimate_alert_confirm', { reset_value: reset_value }),
      confirmText: t('estimate_alert_confirm_text'),
      cancelText: t('estimate_alert_cancel_text'),
      confirmColor: 'primary',
      cancelColor: 'primary',
    })
  }

  return (
    <Fragment>
      <div
        className="content grid-planner-container options-container"
        style={{
          backgroundColor: '#fff',
          display: 'block',
        }}
      >
        <div className="content options-container">
          <Row className="grid-planner-row">
            <Col md="12">
              <div className="page-heading">
                <i
                  className="now-ui-icons ui-1_settings-gear-63"
                  style={{ fontSize: '24px' }}
                ></i>
                <h5>{t('Settings')}</h5>
              </div>
            </Col>
            <Col md="12" className="mx-auto">
              <Row
                className={
                  business.filterBusinessList.type == 'club'
                    ? 'club-cards club-height'
                    : 'club-cards'
                }
              >
                <Col md="12">
                  <Row>
                    <Col md={6} className="mx-auto expanded-cols">
                      <div>
                        <Card className="options-card">
                          <Row className="p-3">
                            <Col md="12">
                              <h5 className="option-title-main mb-3">
                                {t('Activity Period')}
                              </h5>
                            </Col>
                            <Col md="12">
                              <Row>
                                <Col
                                  style={{
                                    minWidth: '130px',
                                    maxWidth: '130px',
                                  }}
                                  className="px-3"
                                >
                                  <p className="mb-0 bold date-name">
                                    {t('From')}
                                  </p>
                                  <Datetime
                                    name="start"
                                    value={grid.startDate}
                                    timeFormat={false}
                                    inputProps={{
                                      placeholder: grid.startDate
                                        ? grid.startDate
                                        : `${t('Start Date')}`,
                                      readOnly: true,
                                    }}
                                    isValidDate={valid}
                                    onChange={startDate}
                                    closeOnSelect
                                    timeConstraints={{
                                      minutes: { min: 0, max: 60, step: 30 },
                                    }}
                                  />
                                </Col>

                                <Col
                                  style={{
                                    minWidth: '130px',
                                    maxWidth: '130px',
                                  }}
                                  className="px-3"
                                >
                                  <p className="mb-0 bold date-name">
                                    {t('to')}
                                  </p>
                                  {grid.status ? (
                                    <Datetime
                                      name="end"
                                      closeOnTab={false}
                                      isValidDate={endValid}
                                      timeFormat={false}
                                      onChange={endDate}
                                      value={grid.endDate}
                                      closeOnSelect
                                      timeConstraints={{
                                        minutes: { min: 0, max: 60, step: 30 },
                                      }}
                                      inputProps={{
                                        placeholder: grid.endDate
                                          ? grid.endDate
                                          : `${t('End Date')}`,
                                        readOnly: true,
                                      }}
                                    />
                                  ) : (
                                    <Input
                                      type="text"
                                      name="data"
                                      placeholder={
                                        grid.endDate
                                          ? grid.endDate
                                          : `${t('End Date')}`
                                      }
                                      disabled={true}
                                    />
                                  )}
                                </Col>

                                <Col className="px-0 py-3 temporarily-closed mobile-temporarily-closed">
                                  <div
                                    style={{
                                      whiteSpace: 'nowrap',
                                      paddingLeft: '10px',
                                    }}
                                  >
                                    <Switch
                                      checked={temporaryClosed}
                                      name="temporarily-closed"
                                      onChange={onTemporaryClose}
                                      color="primary"
                                      inputProps={{
                                        'aria-label': 'primary checkbox',
                                      }}
                                    />
                                    <span>{t('Temporary closed')}</span>
                                  </div>
                                </Col>
                              </Row>
                            </Col>

                            <Col md="12" className="mt-1">
                              <div className="d-flex">
                                <Button
                                  onClick={activityDateAction}
                                  disabled={lockButton}
                                  className="save-btn-secondary mt-0 mb-0"
                                >
                                  {t('Save')}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                        <div className="card-divider-line"></div>
                      </div>
                    </Col>
                    <Col md={6} className="mx-auto expanded-cols">
                      <ContactInformation />
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="mx-auto expanded-cols">
                  <div>
                    {(business.filterBusinessList.type === 'restaurant' || business.filterBusinessList.type === 'bar') ? (
                      <Fragment>
                        <Card className="options-card">
                          <Row className="p-3">
                            <Col md="12">
                              <h5 className="option-title-main">
                                {t('Slot Intervals')}
                              </h5>
                            </Col>
                            <Col md="12">
                              <Row className="align-items-center mb-2">
                                <Col md={9} xs={8}>
                                  <h5 className="title-working-hours">
                                    {t('Interval between bookings')}
                                  </h5>
                                </Col>
                                <Col
                                  md={3}
                                  xs={4}
                                  style={{
                                    'max-width': '140px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                  }}
                                >
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    placeholder="Single Select"
                                    name="singleSelect"
                                    value={intervalValue}
                                    defaultValue={intervalValue}
                                    clearable={false}
                                    options={intervalOption}
                                    isSearchable={false}
                                    onChange={(value) => {
                                      saveIntervalAction(value.value, 'business')
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row className="align-items-center mb-1">
                                <Col md={9} xs={8}>
                                  <h5 className="title-working-hours">
                                    {t('Interval between bookings for customer')}
                                  </h5>
                                </Col>
                                <Col
                                  md={3}
                                  xs={4}
                                  style={{
                                    'max-width': '140px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                  }}
                                >
                                  <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    placeholder="Single Select"
                                    name="singleSelect"
                                    value={customerIntervalValue}
                                    defaultValue={customerIntervalValue}
                                    clearable={false}
                                    options={intervalOption.filter((interval) => {
                                      return interval.value % intervalValue == 0
                                    })}
                                    onChange={(value) => {
                                      saveIntervalAction(value.value, 'customer')
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                        <div className="card-divider-line" />
                      </Fragment>
                    ) : null}
                    <Card className="options-card">
                      <Row className="p-3">
                        <Col md="12">
                          <h5 className="option-title-main">
                            {t('Working Hours')}
                          </h5>
                        </Col>
                        <Col md="12">
                          <Row>
                            <Col md={8}>
                              <Switch
                                checked={gridCheck}
                                name="same-schedule"
                                onChange={checkChange}
                                color="primary"
                                inputProps={{
                                  'aria-label': 'primary checkbox',
                                }}
                              />{' '}
                              {t('Apply same schedule for all days')}
                            </Col>

                            {gridCheck ? (
                              <Col md="12">
                                <Row className="mt-3">
                                  <Col md={12} className="mx-auto">
                                    <Row className="pb-3">
                                      <Col
                                        md={2}
                                        sm={6}
                                        xs={6}
                                        className="pr-2 pl-3 mb-2 mobile-input"
                                        style={{
                                          minWidth: '95px',
                                          maxWidth: '95px',
                                        }}
                                      >
                                        <p className="mb-0 from-time">
                                          {t('From')}
                                        </p>
                                        <Datetime
                                          dateFormat={false}
                                          timeFormat={'HH:mm'}
                                          value={applySameStartFrom}
                                          timeConstraints={{
                                            minutes: {
                                              min: 0,
                                              max: 60,
                                              step: 30,
                                            },
                                          }}
                                          inputProps={{
                                            placeholder: grid.from
                                              ? grid.from
                                              : `${t('From')}`,
                                            readOnly: true,
                                          }}
                                          onChange={applyWorkHourFrom}
                                        // onFocus={applyWorkHourFromOpen}
                                        />
                                      </Col>
                                      <Col
                                        md={2}
                                        sm={6}
                                        xs={6}
                                        className="px-2 mb-2 mobile-input"
                                        style={{
                                          minWidth: '95px',
                                          maxWidth: '95px',
                                        }}
                                      >
                                        <p className="mb-0 to-time">
                                          {t('to')}
                                        </p>
                                        <Datetime
                                          dateFormat={false}
                                          timeFormat={'HH:mm'}
                                          value={applySameEnd}
                                          timeConstraints={{
                                            minutes: {
                                              min: 0,
                                              max: 60,
                                              step: 30,
                                            },
                                          }}
                                          inputProps={{
                                            placeholder: grid.to
                                              ? grid.to
                                              : `${t('to')}`,
                                            readOnly: true,
                                          }}
                                          onChange={applyWorkHourTo}
                                        // onFocus={applyWorkHourToOpen}
                                        />
                                      </Col>
                                      {(business.filterBusinessList.type ==
                                        'restaurant' ||
                                        business.filterBusinessList.type ==
                                        'bar') && (
                                          <React.Fragment>
                                            <Col
                                              md={3}
                                              xs={6}
                                              className="px-2 mobile-input"
                                              style={{ minWidth: '160px' }}
                                            >
                                              <p className="mb-0 from-time">
                                                {t('Break Time From')}
                                              </p>
                                              <Datetime
                                                dateFormat={false}
                                                timeFormat={'HH:mm'}
                                                timeConstraints={{
                                                  minutes: {
                                                    min: 0,
                                                    max: 60,
                                                    step: 30,
                                                  },
                                                }}
                                                value={applySameBreakStartFrom}
                                                inputProps={{
                                                  placeholder: applySameBreakStartFrom
                                                    ? applySameBreakStartFrom
                                                    : `${t('From')}`,
                                                  readOnly: true,
                                                }}
                                                onChange={applyWorkHourFromBreak}
                                              // onFocus={applyWorkHourFromOpen}
                                              />
                                            </Col>
                                            <Col
                                              md={3}
                                              xs={6}
                                              className="px-2 mobile-input"
                                              style={{ minWidth: '160px' }}
                                            >
                                              <p className="mb-0 from-time">
                                                {t('Break Time To')}
                                              </p>
                                              <Datetime
                                                dateFormat={false}
                                                timeFormat={'HH:mm'}
                                                value={applySameBreakEndTo}
                                                timeConstraints={{
                                                  minutes: {
                                                    min: 0,
                                                    max: 60,
                                                    step: 30,
                                                  },
                                                }}
                                                inputProps={{
                                                  placeholder: applySameBreakEndTo
                                                    ? applySameBreakEndTo
                                                    : `${t('to')}`,
                                                  readOnly: true,
                                                }}
                                                onChange={applyWorkHourToBreak}
                                              // onFocus={applyWorkHourToOpen}
                                              />
                                            </Col>
                                          </React.Fragment>
                                        )}
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            ) : (
                              <>
                                <Col md="12">
                                  {(business.filterBusinessList.type ===
                                    'bar' ||
                                    business.filterBusinessList.type ===
                                    'restaurant') && (
                                      <div className="working-hours-header mt-2">
                                        <div className="working-day">
                                          {t('Day')}
                                        </div>
                                        <div className="work-break-time">
                                          {t('Break time')}
                                        </div>
                                      </div>
                                    )}
                                </Col>
                                <Col md={12} className="pt-3">
                                  {dateValue.map((item, index) => {
                                    return (
                                      <GridComponent
                                        key={index}
                                        parentMethod={handleChange}
                                        props={item}
                                        type={business.filterBusinessList.type}
                                        changeCheck={handleChangeCheck}
                                      />
                                    )
                                  })}
                                </Col>
                              </>
                            )}
                          </Row>
                        </Col>

                        <Col md="12">
                          <div className="d-flex">
                            <Button
                              className="save-btn-secondary"
                              disabled={changeValueState}
                              onClick={saveWorkHour}
                            >
                              {t('Save')}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                    <div className="card-divider-line"></div>
                  </div>

                  {(business.filterBusinessList.type == 'restaurant' ||
                    business.filterBusinessList.type == 'bar' ||
                    business.filterBusinessList.type == 'club') && (
                      <GuranteeReservation />
                    )}
                  <PhotoRequired />
                  {(business.filterBusinessList.type == 'beach' ||
                    business.filterBusinessList.type == 'pool') && (
                      <CallWaiter />
                    )}

                  <div className="card-divider-line"></div>
                  {(business.filterBusinessList.type == 'restaurant' ||
                    business.filterBusinessList.type == 'bar' ||
                    business.filterBusinessList.type == 'club') && (
                      <ExecutiveChef />
                    )}

                  {/* <CovidCertificate /> */}

                  {business.filterBusinessList.type != 'restaurant' && <Cost />}

                  {(business.filterBusinessList.type === 'restaurant' ||
                    business.filterBusinessList.type === 'bar' ||
                    business.filterBusinessList.type === 'club') && <Zone2 />}
                  <BusinessLogo></BusinessLogo>
                </Col>

                <Col md="6" className="mx-auto expanded-cols">
                  {(business.filterBusinessList.type == 'beach' ||
                    business.filterBusinessList.type == 'pool' ||
                    business.filterBusinessList.type == 'club') && (
                      <>
                        <MustArriveBy
                          gridCheck={gridCheck}
                          from={applySameStartFrom}
                          to={applySameEnd}
                          changeValueState={changeValueState}
                        />
                        <div className="card-divider-line"></div>
                      </>
                    )}

                  {(business.filterBusinessList.type === 'restaurant' ||
                    business.filterBusinessList.type === 'bar') && (
                      <Fragment>
                        <Card className="options-card">
                          <Row className="p-3">
                            <Col md="12">
                              <h5 className="option-title-main mb-0">
                                {t('Closed days by calendar')}
                              </h5>
                            </Col>
                            <Col md="12">
                              <CloseDayComponent
                                businessId={business.filterBusinessList.id}
                              />
                            </Col>
                          </Row>
                        </Card>
                        <div className="card-divider-line"></div>
                      </Fragment>
                    )}

                  <Website />
                  {(
                    business.filterBusinessList.type == 'restaurant' ||
                    business.filterBusinessList.type == 'bar' ||
                    business.filterBusinessList.type == 'club'
                  ) ? (
                    <GuestLimit />
                  ) : null}

                  <Rules />
                  <AutoAcceptSection />

                  {business.filterBusinessList.type == 'beach' ||
                    business.filterBusinessList.type == 'pool' ? (
                    <>
                      <TowelSection />
                      <div className="card-divider-line"></div>
                      <Facilities />
                    </>
                  ) : (
                    <Facilities2 />
                  )}

                  {business.filterBusinessList.type != 'beach'
                    && business.filterBusinessList.type != 'pool' ? (
                    <>
                      <div className="card-divider-line"></div>
                      <EstimatedTime />
                      <Cost />
                    </>
                  ) : null}
                  <div className="card-divider-line mobile-card-divider-line"></div>
                </Col>
              </Row>

              <BeachPhotos />

              {/* <div className="card-divider-line"></div>
              <Card className="options-card pb-3 pt-3">
                <Row className="p-3">
                  <Col>
                    <div className="d-flex flex-sm-column flex-md-row align-items-center" style={{ gap: "12px" }}>
                      <h5 className="option-title-main mb-0">{t('Stripe Customer Portal')}</h5>
                      <Button
                        onClick={openStripeCustomerPortal}
                        className="save-btn">
                        {t('Customer Portal')}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card> */}
              <div className="card-divider-line"></div>

              {(business.filterBusinessList.type == 'restaurant' ||
                business.filterBusinessList.type == 'bar' ||
                business.filterBusinessList.type == 'club') && <Cuisine />}

              <BeachLocation />
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
export default Settings

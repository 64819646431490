import { ApolloClient, gql, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GRAPHQL_URL } from '../../utils/constants.js';
import { createNotification } from '../../utils/utils.js';
import axios from "axios";
import { calls } from '../../utils/calls.js';
//import swal from "sweetalert";

const link = new HttpLink({
	uri: `${GRAPHQL_URL}`,
});
const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
};
const authLink = setContext(async (_, { headers }) => {
	let token;
	let localState = localStorage.getItem('persist:business');
	let superAdminSessionToken = localStorage.getItem("SHADOWED_USER_TOKEN");

	if (localState && typeof JSON.parse(localState) === 'object') {
		localState = JSON.parse(localState);
		token = localState.accessToken;
		return {
			headers: {
				...headers,
				authorization: `Bearer ${superAdminSessionToken && superAdminSessionToken.length > 0 ? superAdminSessionToken : token}`,
			}
		};
	}
});
const client = new ApolloClient({
	link: authLink.concat(link),
	cache: new InMemoryCache(),
	defaultOptions
});
export default class API {
	getStructureElementRequests(id) {
		try {
			let data = client.mutate({
				mutation: gql`
					query business_element{
						business_element(where:{
							zone_id:${id}
						}){
							id
							element_id
							element{
								type
								structure
							}
							seat_type {
								id
								name
							}
						}
					}
				`,
			})
				.then(data => {
					return data;
				})
				.catch(err => {
					return err;
				});
			return data;
		} catch (err) {
		}
	}

	createPricePlanRequests(seat_type, priceData, business, price, filterResult) {
		let arr = [];
		priceData.map((list) => {
			arr.push(`{
				price: {
					monday:${list.priceData[0].price ? list.priceData[0].price : null}
					tuesday:${list.priceData[1].price ? list.priceData[1].price : null}
					wednesday:${list.priceData[2].price ? list.priceData[2].price : null}
					thursday:${list.priceData[3].price ? list.priceData[3].price : null}
					friday:${list.priceData[4].price ? list.priceData[4].price : null}
					saturday:${list.priceData[5].price ? list.priceData[5].price : null}
					sunday:${list.priceData[6].price ? list.priceData[6].price : null}
				}
				type:"${seat_type.name}",
				seat_types_id:${seat_type.id}
				seat_count:null
				zone_id:${price.currentZoneId}
				element_id:null
				business_id:${business?.filterBusinessList?.id}
				start_date:"${list.startDate}"
				end_date:"${list.endDate}"
			}`);
		});
		try {
			let data = client.mutate({
				mutation: gql`
					mutation CreateCustomPrice{
						CreateCustomPrice(input:{
							list:[${arr.join('')}]
						}){
							result
						}
					}
				`,
			})
				.then(data => {
					createNotification({ type: 'success', title: 'Update', message: 'Successfully updated !' });
					//swal("Successfully updated !");
					return data;
				})
				.catch(err => {
					createNotification({ type: 'danger', title: 'Update', message: 'Not updated !' });
					//swal("Not updated");
					return err;
				});
			return data;
		} catch (err) {
		}
	}

	getPriceRequests(price, business) {
		try {
			let data = client.mutate({
				mutation: gql`
					query price{
						price(where:{
							business_id:${business?.filterBusinessList?.id
						? business.filterBusinessList.id
						: business.filterBusinessList}
						}){
							id
							price
							start_date
							end_date
							business_id
							type
							zone_id
							seat_types_id
							seat_type {
								id
								name
							}
						}
					}
				`,
			})
				.then(data => {
					return data;
				})
				.catch(err => {
					return err;
				});
			return data;
		} catch (err) {
		}
	}

	resetActionRequests(business) {
		try {
			let data = client.mutate({
				mutation: gql`
					mutation DeleteCustomPrice{
						DeleteCustomPrice(input:{
							business_id:${business?.filterBusinessList?.id}
						}) {
							result
						}
					}

				`,
			})
				.then(data => {
					createNotification({ type: 'success', title: 'Update', message: 'Successfully updated !' });
					//swal("Successfully updated !");
					return data;
				})
				.catch(err => {
					createNotification({ type: 'danger', title: 'Update', message: 'Not updated !' });
					//swal("Not updated");
					return err;
				});
			return data;
		} catch (err) {
		}
	}

	deletePricePlanInput(period_id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await client.mutate({
					mutation: gql`
						mutation DeleteCustomPrice{
							DeleteCustomPrice(input:{
								period_id:${period_id}
							}) {
								result
							}
						}
					`,
				})
				resolve(response);
			} catch (error) {
				reject(error);
			}
		})
	}

	deletePrice(id, business_id) {
		return axios(calls.deletePrice({
			seat_types_id: id,
			business_id: business_id
		}));
	}
}
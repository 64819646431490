import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import Slider from "react-slick";

// import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import beachImage from "../../../assets/img/beach.jpg";
import restaurantImage from "../../../assets/img/restaurant.jpg";
import iconInfo from "../../../assets/img/icon-info-thin.svg";
import moment from "moment";
import Switch from "react-switch";
import PlanPriceContainer from "../../../container/planPrice.container";
import httpService from "../../../services/http";

import "react-tabs/style/react-tabs.css";
import "react-multi-carousel/lib/styles.css";
import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";
import "../../../assets/css/custom.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  cuoponCodeValid,
  getPlanData,
  getPlanInformation,
} from "../../../redux/business/action";

import {
  getActivePlan,
  getPlans,
  inactiveOldPlan,
  setSelectedPlan,
} from "../../../redux/plan/action";
import { startLoading, stopLoading } from "../../../redux/loader/action.js";
import { createNotification } from "../../../utils/utils";
import { PlanPriceData } from "../../../description/PlanPriceData";
import VirtualizedSelect from "react-virtualized-select";
import Info from "../../../assets/img/info.png";

const Totalaftercoupon = (props) => {
  return (
    <div>
      <span className="lbl-total">t('Total'):</span>: €
      {Math.round(
        (props.data.cost / (props.data.exclude_sms ? 2 : 1) -
          (props.data.cost / (props.data.exclude_sms ? 2 : 1)) *
          (props.couponpercentage / 100)) *
        100
      ) / 100}{" "}
      / Month
    </div>
  );
};

const Plans2 = () => {
  const dispatch = useDispatch();
  const { planList } = PlanPriceContainer();
  const business = useSelector(({ business }) => business);
  const price = useSelector(({ price }) => price);
  const plan = useSelector(({ plan }) => plan);
  const [plans, setPlans] = useState([]);
  const [clonedPlan, setclonedPlan] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [SMSPrices, setSMSPrices] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [planSelected, setplanSelected] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState({});
  const [planHeaderHeightStyle, setPlanHeaderHeightStyle] = useState({});
  const [couponPercentage, setCouponPercentage] = useState(0);

  // const tooltipRef = Array.from({ length: PlanPriceData.length }, () => useRef(null));
  const [couponValid, setCouponValid] = useState({
    status: false,
    message: "",
  });

  useEffect(() => {
    if (SMSPrices.length > 0) {
      const defaultPrice = SMSPrices.find(
        (p) => p?.label && p?.label.includes("United States")
      );
      setSelectedPrice(defaultPrice);
    }
  }, [SMSPrices]);

  useEffect(() => {
    getSMSPrice();
  }, []);

  const getSMSPrice = async () => {
    try {
      const { data = [] } = await httpService.get("/smsPrice");
      setSMSPrices(
        data.map((d) => ({
          ...d,
          label: `${d.country} ($${d?.price} / sms)`,
          value: d.id,
        }))
      );
    } catch (error) { }
  };

  useEffect(() => {
    return plan?.plans?.map((data) => {
      return {};
    });
  }, []);

  useEffect(() => {
    const arr = document.querySelectorAll(".plan-header");
    if (arr.length) {
      let maxHeight = 0;
      arr.forEach((ele) => {
        if (ele.clientHeight > maxHeight) {
          maxHeight = ele.clientHeight;
        }
      });
      setPlanHeaderHeightStyle({ height: `${maxHeight}px` });
    }
  }, [document, planList]);

  useEffect(() => {
    dispatch(getPlanData());
    dispatch(getPlanInformation(business.filterBusinessList.id));
    dispatch(startLoading());
    dispatch(getPlans()).then((data) => {
      dispatch(getActivePlan(business?.filterBusinessList.id)).then((data) => {
        dispatch(stopLoading());
      });
    });
  }, [business?.filterBusinessList, planSelected]);
  const { t } = useTranslation();

  const selectPlan = (id) => {
    const data = plan?.plans?.find((plan) => plan?.id === id);
    let selectedPlan = { ...data };
    const oldPlan = { ...plan.selectedPlan };
    const noOfDays = moment().daysInMonth() - parseInt(moment().format("DD"));
    if (noOfDays <= 0) {
      createNotification({
        type: "danger",
        title: t("plan_error_infinity"),
        message: t("plan_error_infinity"),
      });
      return false;
    }
    selectedPlan.reservation = Math.round(selectedPlan.reservation / noOfDays);
    selectedPlan.plan_id = selectedPlan.id;
    selectedPlan.business_id = business?.filterBusinessList.id;
    selectedPlan.exclude_sms = selectedPlan.exclude_sms ? true : false;
    dispatch(startLoading());
    dispatch(setSelectedPlan(selectedPlan)).then(
      (data) => {
        if (oldPlan && oldPlan.id) {
          dispatch(inactiveOldPlan(oldPlan.id)).then(
            (data) => {
              dispatch(getActivePlan(business?.filterBusinessList.id)).then(
                (data) => {
                  dispatch(stopLoading());
                },
                (err) => {
                  dispatch(stopLoading());
                }
              );
            },
            (err) => {
              createNotification({
                type: "danger",
                title: t("invalid_plan"),
                message: err.message,
              });
            }
          );
        }
        if (selectedPlan.couponCode) {
          selectedPlan.coupon = selectedPlan.couponCode;
          applyCoupon(selectedPlan, true);
        }
        setplanSelected(!planSelected);
        dispatch(stopLoading());
      },
      (err) => {
        createNotification({
          type: "danger",
          title: t("invalid_plan"),
          message: err.message,
        });
        dispatch(stopLoading());
      }
    );
  };

  useEffect(() => {
    if (business.planData.length > 0) {
      let percentArray = [];
      business.planData.map((item) => {
        percentArray.push(Number(item.value));
      });
      let tempPriceValue = Math.max(...percentArray);
      setCouponPercentage(tempPriceValue);
    } else {
      setCouponPercentage(0);
    }
  }, [business.planData]);

  useEffect(() => {
    let plansClone = [
      ...plan.plans.filter((planData) => {
        return planData.plan_for.indexOf(business.filterBusinessList.type) >= 0;
      }),
    ];
    plansClone.forEach((data) => {
      if (plan.selectedPlan && data.id == plan.selectedPlan.plan_id) {
        data.exclude_sms = plan.selectedPlan.exclude_sms;
      }
      data.cost_per_reservation =
        data.config[business.filterBusinessList.type + "_cost_per_reservation"];
      data.cost = data.config[business.filterBusinessList.type + "_cost"];
      data.price_type =
        data.config[business.filterBusinessList.type + "_price_type"];
    });
    setPlans(plansClone);
  }, [plan.plans, plan.selectedPlan, planSelected]);

  const setCouponCode = (e, id) => {
    const plansClone = [...plans];
    plansClone.forEach((data) => {
      if (data.id == id) {
        data.coupon = e.target.value;
      }
    });

    setPlans(plansClone);
  };

  const excludeSms = (e, plan) => {
    const plansClone = [...plans];
    plansClone.forEach((data) => {
      if (data.id == plan.id) {
        data.exclude_sms = !e;
      }
    });
    setPlans(plansClone);
  };

  const applyCoupon = (id, applied = false) => {
    const appliedPlan = plan?.plans?.find((plan) => plan?.id === id);
    let validateOnly = false;
    if (!appliedPlan.coupon) {
      createNotification({
        type: "danger",
        title: t("invalid_coupon"),
        message: t("invalid_coupon_select"),
      });
      return false;
    }

    if (
      !applied &&
      (!plan.selectedPlan || plan.selectedPlan.plan_id != appliedPlan.id)
    ) {
      validateOnly = true;
    }

    return new Promise((resolve, reject) => {
      dispatch(
        cuoponCodeValid(
          appliedPlan.coupon,
          business.filterBusinessList.id,
          validateOnly,
          resolve,
          reject
        )
      );
    })
      .then((success) => {
        if (!validateOnly) {
          dispatch(getPlanInformation(business.filterBusinessList.id));
        } else {
          const plansClone = [...plans];
          plansClone.forEach((data) => {
            if (data.id == appliedPlan.id) {
              data.couponPercentage = success.data.CreateBusinessCoupon.value;
              data.couponName = success.data.CreateBusinessCoupon.name;
              data.couponCode = appliedPlan.coupon;
            }
          });
          setPlans(plansClone);
        }
      })
      .catch((errors) => {
        if (errors) {
          setCouponValid({ status: true, message: errors });
        }
      });
  };
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  const getCost = (id) => plans?.find((obj) => obj?.id === id)?.cost;
  return (
    <>
      {business.superAdmin != 1 &&
        !price.allPricesAreSet &&
        (business.filterBusinessList?.type == "beach" ||
          business.filterBusinessList?.type == "pool") && (
          <div className="plan-periods">
            {/* <Alert
          color="info"
          className="alert-with-icon"
          style={{ zIndex: "200" }}
          isOpen={planValid.status}
          toggle={onDismiss}
        >
          <span data-notify="icon" className="now-ui-icons ui-1_bell-53"></span>
          <span data-notify="message">{planValid.message}</span>
        </Alert> */}
            {/* <PanelHeader size="sm" /> */}
          </div>
        )}
      {/* <PlanPriceContainer/> */}
      <div
        className="content"
        style={{
          backgroundImage: `url(${business.filterBusinessList.type === "beach"
            ? beachImage
            : restaurantImage
            })`,
        }}
      >
        <Row className="title-container xs-hidden">
          <Col md="12">
            <h5
              className="title text-center text-white"
              style={{ marginTop: "30px" }}
            >
              {t("Choose your plan")}{" "}
              {/* {business.businessName ? business.businessName : "Location Name"} */}
            </h5>
          </Col>
        </Row>
        {/* <div className="pt-12 font-DMSans px-3">
                <Slider {...sliderSettings}>
                  {data.map(renderDesktopPlan)}
                </Slider>
              </div> */}
        <div className="pt-10 font-DMSans px-3 pb-12">
          <div className="2xl:max-w-[1300px] xl:max-w-6xl lg:max-w-4xl max-w-[400px] mx-auto">
            <Slider {...sliderSettings}>
              {planList &&
                planList?.length &&
                planList?.map((planData, index) => {
                  const { data } = planData;
                  const translateKey = t(`${planData?.key}`, { returnObjects: true });
                  return data?.map((planDt, index) => {
                    return (
                      <div
                        key={`${index}`}
                        className="md:mx-5 xs:mx-2 h-full !w-auto !block"
                      >
                        <div className="flex flex-col h-full border-2 border-solid border-[#0b1b353d] bg-white rounded-xl text-center mb-0">
                          <div
                            className="plan-header"
                            style={{ ...planHeaderHeightStyle }}
                          >
                            <div className="pt-4 flex relative items-center px-4 w-full justify-center">
                              <div className="mr-2 w-[15%] absolute left-3">
                                <img
                                  src={planDt?.plan_image}
                                  alt={planDt?.plan_title}
                                  className="w-full"
                                />
                              </div>
                              <div className="flex items-center max-w-[75%]">
                                <h3 className="text-[#6844F9] font-bold md:text-2xl text-lg mb-0">
                                  {planDt?.plan_title}
                                </h3>
                                {planDt?.info ? (
                                  <button
                                    data-tip
                                    data-for={`global-${index}`}
                                    className="hover:bg-transparent ml-2"
                                    id={`planInfo-${index}`}
                                  >
                                    <img
                                      src={Info}
                                      alt={"Plan Info"}
                                      className="w-4"
                                    />
                                    <UncontrolledTooltip
                                      target={`planInfo-${index}`}
                                    >
                                      {translateKey[planDt?.info]}
                                    </UncontrolledTooltip>
                                  </button>
                                ) : null}
                              </div>
                            </div>
                            <div
                              className={`mt-3 flex items-center justify-center`}
                            >
                              <div className={`flex justify-center ml-12`}>
                                <h2
                                  className={`text-[40px] leading-[32px] md:leading-[60px] font-bold mb-0`}
                                >
                                  {/* {getCost(planDt?.id)} */}
                                  {planDt?.plan_price}
                                  {planDt?.plan_currency_symbol ? (
                                    <span>{planDt?.plan_currency_symbol}</span>
                                  ) : null}
                                </h2>
                              </div>
                              <div className="text-lg mt-3">/ {t("month")}</div>
                            </div>
                            {planDt?.top_features?.map((feature) => (
                              <div
                                className={`flex items-center justify-center gap-2 flex-wrap ${feature.class}`}
                              >
                                {translateKey[feature.key]}
                              </div>
                            ))}
                          </div>
                          <div className="px-6 my-3">
                            {planDt?.plan_data?.map((p, ind) => (
                              <div
                                key={`${ind}`}
                                className="flex items-baseline pb-2 text-left text-[#0B1B35] text-sm"
                              >
                                {/* <img src={p.is_available ? CheckIcon : Cross} alt="icon" /> */}
                                <span
                                  className={`${!p.is_available
                                    ? "line-through text-gray-400"
                                    : "no-underline"
                                    }`}
                                >
                                  {translateKey[p.description]}
                                </span>
                              </div>
                            ))}
                          </div>
                          {planDt?.note_information && (
                            <div className="px-6 mt-auto mb-[10px]">
                              <Fragment>
                                <div className="mb-2">
                                  <div
                                    className={`flex items-baseline pb-2.5 text-left text-red-500 text-md md:text-sm flex-wrap italic`}
                                  >
                                    {translateKey[planDt?.note_information.key]}
                                  </div>
                                </div>
                                {SMSPrices.length ? (
                                  <div className="price-select-dropdown">
                                    <VirtualizedSelect
                                      searchable
                                      clearable={false}
                                      options={SMSPrices}
                                      value={
                                        selectedPrice?.[planDt?.id] ||
                                        selectedPrice
                                      }
                                      classNamePrefix="select"
                                      name={`${index}.${planDt?.plan_title}`}
                                      onChange={(newValue) =>
                                        setSelectedPrice((prev) => ({
                                          ...prev,
                                          [planDt?.id]: newValue,
                                        }))
                                      }
                                    />
                                  </div>
                                ) : null}
                              </Fragment>
                            </div>
                          )}
                          <div className="px-6 mt-auto pt-2 mb-4">
                            <div className="coupon-section flex items-center">
                              <Input
                                type="text"
                                name="coupon"
                                placeholder={t("coupon_code")}
                                value={data.coupon}
                                className="!rounded-xl !h-10 !px-4"
                                onChange={(e) => {
                                  setCouponCode(e, planDt?.id);
                                }}
                              ></Input>
                              <button
                                className="border rounded-xl py-2 px-4 ml-2 h-10"
                                onClick={(e) => {
                                  applyCoupon(planDt?.id);
                                }}
                              >
                                {t("OK")}
                              </button>
                            </div>
                            <div>
                              {plan.selectedPlan &&
                                plan.selectedPlan.plan_id == planDt?.id ? (
                                <button
                                  className="bg-danger border-2 border-danger text-white rounded-xl w-full py-2 px-2 mt-2"
                                  onClick={() => selectPlan(planDt?.id)}
                                  disabled={true}
                                >
                                  {t("in use")}
                                </button>
                              ) : (
                                <button
                                  onClick={() => selectPlan(planDt?.id)}
                                  className={`border-2 rounded-xl w-full py-2 px-2 mt-2`}
                                  style={{
                                    borderColor: `${planDt?.border_color}`,
                                  }}
                                >
                                  {t("GET")} {t(planDt.plan_title)}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  });
                })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plans2;

import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// core components
import PageNavbar from '../components/Navbars/PageNavbar.js';
import routes from '../routes.js';
import '../i18n';
import { useSelector } from 'react-redux';
import { ReactNotifications } from "react-notifications-component";
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";


const Page = (props) => {
	const business = useSelector(({ business }) => business);
	const loader = useSelector(({ loader }) => loader);
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.layout === '/') {
				return props.location.pathname === '/' ? (
					business.accessToken ? (
						business.superAdmin ? (<Redirect to="/super_admin" />) : (<Redirect to="/admin/dahsboard" />)

					) : (
						<Redirect to="/login" />
					)
				) : (
					<Route
						exact
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};
	return (
		<Fragment>
			<ReactNotifications />
			<PageNavbar {...props} />
			<div className="wrapper wrapper-full-page">
				<LoadingOverlay
					active={loader.loading}
					spinner={<BeatLoader size="30" color="#fff" />}
				>
					<div className="full-page section-image" filter-color="yellow">
						<Switch>{getRoutes(routes)}</Switch>
					</div>
				</LoadingOverlay>
			</div>
		</Fragment>
	);
};

export default Page;

import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createZonesAction,
  getZone,
  deleteZoneAction,
} from "../../../../redux/grid/action";
import { startLoading, stopLoading } from "../../../../redux/loader/action";
import Select from "react-select";
import AddIcon from "../../../../assets/img/add-button.svg";
import { createNotification } from "../../../../utils/utils";
import pencilIcon from "../../../../assets/img/pencil.svg";
import Label from "reactstrap/lib/Label";
import Switch from "@material-ui/core/Switch";

const Zone2 = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const grid = useSelector(({ grid }) => grid);
  const business = useSelector(({ business }) => business);
  const [zones, setZones] = useState([]);
  const [disableSave, setDisableSave] = useState(true);
  const colorOptions = [
    { value: "#efb5b3", label: "Orange" },
    { value: "#d0e9ad", label: "Red" },
    { value: "#a1ecdb", label: "Yellow" },
    { value: "#95b8ed", label: "Blue" },
    { value: "#d9a7e6", label: "Green" },
    { value: "#e1c7c8", label: "Dust Storm" },
  ];
  useEffect(() => {
    dispatch(getZone());
  }, [business.filterBusinessList, dispatch, grid.hasVipZone, grid.zoneCount]);

  useEffect(() => {
    if (grid.zones && grid.zones.length > 0) {
      setZones(grid.zones);
    } else {
      setZones([
        {
          business_id: business.filterBusinessList.id,
          color: getDefaultZoneColor(),
        },
      ]);
    }
  }, [grid.zones]);

  const saveZone = (event) => {
    let names = zones.map(function (v) {
      return v.name;
    });
    if (names.indexOf(undefined) > -1 || names.indexOf("") > -1) {
      createNotification({
        type: "danger",
        title: t("zone error"),
        message: t("blank name"),
      });
      return false;
    }
    const isDuplicateName = zones.some(function (v) {
      return (
        names.filter(function (w) {
          return w === v.name;
        }).length > 1
      );
    });
    if (isDuplicateName) {
      createNotification({
        type: "danger",
        title: t("zone error"),
        message: t("duplicate name"),
      });
      return false;
    }

    let colors = zones.map(function (v) {
      return v.color;
    });

    if (colors.indexOf(undefined) > -1 || colors.indexOf("") > -1) {
      createNotification({
        type: "danger",
        title: t("zone error"),
        message: t("blank color"),
      });
      return false;
    }

    const isDuplicateColor = zones.some(function (v) {
      return (
        colors.filter(function (w) {
          return w === v.color;
        }).length > 1
      );
    });
    if (isDuplicateColor) {
      createNotification({
        type: "danger",
        title: t("zone error"),
        message: t("duplicate color"),
      });
      return false;
    }
    dispatch(startLoading());
    dispatch(createZonesAction(zones))
      .then((data) => {
        setDisableSave(true);
        dispatch(stopLoading());
        dispatch(getZone());
        createNotification({
          type: "success",
          title: t("zone success"),
          message: t("zone save successfully"),
        });
      })
      .catch((e) => {
        dispatch(stopLoading());
      });
  };

  const handleNameChange = (event, index) => {
    setDisableSave(false);
    let zonesC = [...zones];
    zonesC[index].name = event.target.value;
    setZones(zonesC);
  };

  const handleColorChange = (event, index) => {
    setDisableSave(false);
    let zonesC = [...zones];
    if (index > -1 && event && event.value && zonesC[index]) {
      zonesC[index].color = event.value;
      setZones(zonesC);
    }
  };

  const handleCheckboxChange = (event, index) => {
    setDisableSave(false);
    let zonesC = [...zones];
    if (event.target.checked) {
      zonesC[index].show_to_customer = true;
    } else {
      zonesC[index].show_to_customer = false;
    }

    setZones(zonesC);
  };

  const AddZone = () => {
    setDisableSave(false);
    let zonesC = [...zones];
    zonesC.push({
      business_id: business.filterBusinessList.id,
      color: getDefaultZoneColor(),
    });
    setZones(zonesC);
  };

  const removeZone = (index) => {
    setDisableSave(false);
    let zonesC = [...zones];
    let tempZon = zonesC;
    if (zonesC[index].id) {
      dispatch(startLoading());
      dispatch(deleteZoneAction(zonesC[index].id))
        .then((data) => {
          dispatch(stopLoading());
          zonesC.splice(index, 1);
          createNotification({
            type: "success",
            title: t("zone delete success"),
            message: t("zone delete success"),
          });
          setZones(zonesC);
        })
        .catch((e) => {
          dispatch(stopLoading());
          createNotification({
            type: "danger",
            title: t("zone delete error"),
            message: t("zone delete error"),
          });
        });
    } else {
      zonesC.splice(index, 1);
      setZones(zonesC);
    }
    setDisableSave(true);
  };

  const getDefaultZoneColor = () => {
    const usedColors = zones.map(z => z.color);
    const usUsedColors = colorOptions.filter(c => usedColors.length && !usedColors.includes(c.value));
    return usUsedColors.length > 0 ? usUsedColors[0].value : colorOptions[0].value;
  };

  // Doesn't work like it should. Fix it before you enable it.
  return (
    <>
      {/* <div className="card-divider-line"></div> */}
      {business.filterBusinessList.type != "restaurant" && (
        <div className="card-divider-line"></div>
      )}
      <Card className="options-card">
        <Row className="p-3">
          <Col md="12">
            <h5 className="option-title-main">{t("Zone")}</h5>
          </Col>
          <Col md="12">
            <Row className="align-items-center">
              <Col md={4} className="pt-2 zone-header">
                {t("zone name")}
              </Col>
              <Col
                md={4}
                className="pt-2 zone-header"
                style={{ textAlign: "center" }}
              >
                {t("zone color")}
              </Col>
              <Col
                md={4}
                className="pt-2 zone-header"
                style={{ textAlign: "center" }}
              >
                {t("show in customer app")}
              </Col>
            </Row>
          </Col>
          <Col md="12">
            {zones
              .filter((zone) => !zone.deleted)
              .map((zone, index) => (
                <Row
                  key={index}
                  className="align-items-center"
                >
                  <Col sm={4} className="pt-2 zone-section">
                    <FormGroup>
                      <Input
                        type="text"
                        value={t(zone.name)}
                        onChange={(e) => handleNameChange(e, index)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4} className="pt-2 zone-section">
                    <div style={{ textAlign: "center" }}>
                      <span id={`PopoverFocus_${index}`} class="popover-focus">
                        <span
                          className="color-box"
                          style={{
                            backgroundColor: zone.color ? zone.color : getDefaultZoneColor(),
                          }}
                        >
                          &nbsp;
                        </span>
                        <img
                          src={pencilIcon}
                          alt="react-logo"
                          style={{ width: "20px" }}
                        />
                      </span>
                      <UncontrolledPopover
                        placement="bottom"
                        target={`PopoverFocus_${index}`}
                        trigger="legacy"
                      >
                        <PopoverBody>
                          <div>
                            {colorOptions.map((color) => (
                              <span
                                onClick={() => handleColorChange(color, index)}
                                key={color.value}
                                className={`color-box ${color.value == zone.color ? "selected" : ""
                                  }`}
                                style={{ backgroundColor: color.value }}
                              >
                                &nbsp;
                              </span>
                            ))}
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </div>
                  </Col>
                  <Col sm={4} className="zone-section">
                    <div
                      className="d-flex justify-content-center"
                      style={{ gap: "12px" }}
                    >
                      <div>
                        <Switch
                          checked={zone.show_to_customer ? true : false}
                          name="show-to-customer"
                          onChange={(e) => handleCheckboxChange(e, index)}
                          color="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      </div>
                      <div className="mt-2">
                        {zones.length > 1 ? (
                          <Button
                            close
                            onClick={() => {
                              removeZone(index);
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
          </Col>
          <Col md="12">
            <div className="d-flex justify-content-between mt-1">
              <Button className="save-btn-blue btn-primary" onClick={AddZone}>
                {t("Add More")}
              </Button>
              <Button
                className="save-btn-blue btn-primary"
                disabled={disableSave}
                onClick={saveZone}
              >
                {t("Save")}
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default Zone2;

import axios from "axios";
import { REST_API_URL } from "../utils/constants";
axios.defaults.baseURL = REST_API_URL;
const httpService = {
  get: (url) => {
    return axios.get(url);
  },

  post: (url, data) => {
    return axios.post(url, data);
  },
};

export default httpService;

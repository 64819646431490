import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Nav,
  Row,
  Alert,
  NavItem,
  NavLink,
  CardBody,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import PriceDayComponenet from "./PriceComponent/PriceDayComponent";
import ElementType from "./PriceComponent/ElementType";

import {
  isData,
  getPrice,
  modalAction,
  resetPeriod,
  savedStatus,
  tempZoneName,
  deleteAction,
  getStructure,
  createPricePlan,
  currentZoneName,
  elementTypesAction,
  ayncCreatePricePlan,
  checkIfAllPricesAreSet,
} from "../../../redux/price/action";
import {
  getZones,
  getElements,
  getAllElement,
} from "../../../redux/element/action";
import { getActivityPeriod, getHourWork } from "../../../redux/grid/action";
import { startLoading, stopLoading } from "../../../redux/loader/action";
import { getExtraSunbedPhoto } from "../../../redux/option/action";

const PricePlanner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const element = useSelector(({ element }) => element);
  const grid = useSelector(({ grid }) => grid);
  const price = useSelector(({ price }) => price);
  const business = useSelector(({ business }) => business);
  const { loading } = useSelector(({ loader }) => loader);
  const [filterZoneData, setFilterZoneData] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [selectStatus, setSelectStatus] = useState("");
  const [state, setState] = useState({
    openedCollapses: ["collapseOne"],
    hTabs: "ht1",
    vTabs: "vt1",
    vTabsIcons: "vti1",
    pageSubcategories: "ps1",
  });
  const [currentId, setCurrentId] = useState("");
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [selectedSeat, setSelectedSeat] = useState({});
  const [availableSeats, setAvailableSeats] = useState([]);
  const option = useSelector(({ option }) => option);

  useEffect(() => {
    dispatch(startLoading());
    return new Promise((resolve) => {
      dispatch(getHourWork(resolve));
    }).then((data) => { }).finally(() => {
      setFetching(false);
    });
  }, []);
  useEffect(() => {
    dispatch(getPrice());
    priceSetup();
    dispatch(getElements());
    dispatch(getAllElement());
    dispatch(getZones());
    dispatch(getActivityPeriod()).then((result) => {
      setTimeout(() => {
        dispatch(stopLoading(), 2000);
      });
    });
    dispatch(getExtraSunbedPhoto());
  }, [price.allPricesAreSet]);

  useEffect(() => {
    if (element.idVIP) {
      dispatch(getStructure(element.idVIP));
      setCurrentId(element.idVIP);
      setState({ hTabs: "ht1" });
      dispatch(currentZoneName("VIP"));
    } else {
      if (element.id1) {
        setState({ hTabs: "ht2" });
        dispatch(currentZoneName("HT1"));
        dispatch(getStructure(element.id1));
        setCurrentId(element.id1);
      }
    }
  }, [element, price.resetPeriodStatus]);

  useEffect(() => {
    if (price.allPrice.length > 0) {
      const result = price.allPrice.filter(
        (ele) =>
          ele.zone_id == price.currentZoneId &&
          ele.seat_types_id == price.elementType.id
      );
      if (result.length > 0) {
        setFilterZoneData(result);
      } else {
        setFilterZoneData([]);
      }
    }
  }, [price.currentZoneId, price.elementType]);

  useEffect(() => {
    if (price.structureElement.length > 0) {
      let tempSelectedSeats = [];
      price.structureElement.forEach((element) => {
        if (element.seat_type) {
          const index = tempSelectedSeats
            .map((object) => object.id)
            .indexOf(element.seat_type.id);
          if (index < 0) {
            tempSelectedSeats.push(element.seat_type);
          }
        }
      });

      if (
        option.businessSettings.length > 0 &&
        option.businessSettings[0].extra_sunbeds
      ) {
        tempSelectedSeats.push({
          id: 14,
          name: "addtionalSunbed",
        });
      }
      setAvailableSeats(tempSelectedSeats);
    }
  }, [price.structureElement]);

  const priceSetup = () => {
    if (price.allPrice.length > 0) {
      let tempSelectedSeats = [];
      price.allPrice.forEach((priceData) => {
        const index = tempSelectedSeats
          .map((object) => object.id)
          .indexOf(priceData.seat_type.id);
        if (index < 0) {
          tempSelectedSeats.push(priceData.seat_type);
        }
      });
      setSelectedSeats(tempSelectedSeats);
      setSelectedSeat(
        price.elementType ? price.elementType : tempSelectedSeats[0]
      );
      filterTypeAction(
        price.elementType ? price.elementType : tempSelectedSeats[0]
      );
    }
  };
  const vipAction = () => {
    setSelectStatus("zone");
    dispatch(tempZoneName("VIP", element.idVIP));
    if (!price.isSaved) {
      dispatch(modalAction(true));
    } else {
      setCurrentId(element.idVIP);
      setState({ hTabs: "ht1" });
      dispatch(getStructure(element.idVIP));
    }
  };
  const ht1Action = () => {
    setSelectStatus("zone");
    dispatch(tempZoneName("HT1", element.id1));
    if (!price.isSaved) {
      dispatch(modalAction(true));
    } else {
      dispatch(currentZoneName("HT1"));
      setState({ hTabs: "ht2" });
      setCurrentId(element.id1);
      dispatch(getStructure(element.id1));
      if (price.allPrice.length > 0) {
        const result = price.allPrice.filter(
          (ele) => ele.zone_id == element.id1 && ele.type == price.elementType
        );
        if (result.length > 0) {
          setFilterZoneData(result);
        } else {
          setFilterZoneData([]);
        }
      }
    }
  };
  const ht2Action = () => {
    setSelectStatus("zone");
    dispatch(tempZoneName("HT2", element.id2));
    if (!price.isSaved) {
      dispatch(modalAction(true));
    } else {
      dispatch(currentZoneName("HT2"));
      setState({ hTabs: "ht3" });
      setCurrentId(element.id2);
      dispatch(getStructure(element.id2));
      if (price.allPrice.length > 0) {
        const result = price.allPrice.filter(
          (ele) => ele.zone_id == element.id2 && ele.type == price.elementType
        );
        if (result.length > 0) {
          setFilterZoneData(result);
        } else {
          setFilterZoneData([]);
        }
      }
    }
  };
  const ht3Action = () => {
    setSelectStatus("zone");
    dispatch(tempZoneName("HT3", element.id3));
    if (!price.isSaved) {
      dispatch(modalAction(true));
    } else {
      dispatch(currentZoneName("HT3"));
      setState({ hTabs: "ht4" });
      setCurrentId(element.id3);
      dispatch(getStructure(element.id3));
      if (price.allPrice.length > 0) {
        const result = price.allPrice.filter(
          (ele) => ele.zone_id == element.id3 && ele.type == price.elementType
        );
        if (result.length > 0) {
          setFilterZoneData(result);
        } else {
          setFilterZoneData([]);
        }
      }
    }
  };
  const ht4Action = () => {
    setSelectStatus("zone");
    dispatch(tempZoneName("HT4", element.id4));
    if (!price.isSaved) {
      dispatch(modalAction(true));
    } else {
      dispatch(currentZoneName("HT4"));
      setState({ hTabs: "ht5" });
      setCurrentId(element.id4);
      dispatch(getStructure(element.id4));
      if (price.allPrice.length > 0) {
        const result = price.allPrice.filter(
          (ele) => ele.zone_id == element.id4 && ele.type == price.elementType
        );
        if (result.length > 0) {
          setFilterZoneData(result);
        } else {
          setFilterZoneData([]);
        }
      }
    }
  };

  const filterTypeAction = (seat) => {
    if (!seat) {
      return false;
    }

    setSelectedSeat(seat);
    if (price.allPrice.length > 0) {
      const result = price.allPrice.filter(
        (ele) =>
          ele.zone_id == price.currentZoneId && ele.seat_types_id == seat.id
      );
      if (result.length > 0) {
        setFilterZoneData(result);
      } else {
        setFilterZoneData([]);
      }
    }
  };

  const resetAction = () => {
    setFilterZoneData([]);
    dispatch(deleteAction());
    dispatch(resetPeriod(false));
  };
  const cancelHandle = () => {
    dispatch(modalAction(false));
  };
  const agreeHandle = (range) => {
    dispatch(isData(true));
    dispatch(createPricePlan(price.elementType, range));
    dispatch(savedStatus(true));
    dispatch(modalAction(false));
    dispatch(elementTypesAction(price.tempElementType));
    dispatch(currentZoneName(price.tempZoneName));
    if (price.allPrice.length > 0) {
      if (selectStatus == "zone") {
        const result = price.allPrice.filter(
          (ele) =>
            ele.zone_id == price.tempZoneId && ele.type == price.elementType
        );
        if (result.length > 0) {
          setFilterZoneData(result);
        } else {
          setFilterZoneData([]);
        }
      } else {
        const result = price.allPrice.filter(
          (ele) =>
            ele.zone_id == price.currentZoneId &&
            ele.type == price.tempElementType
        );
        if (result.length > 0) {
          setFilterZoneData(result);
        } else {
          setFilterZoneData([]);
        }
      }
    }
  };
  const savePricePlanActionHandle = (range) => {
    dispatch(savedStatus(true));
    return new Promise((resolve) => {
      dispatch(ayncCreatePricePlan(price.elementType, range, resolve));
    }).then((success) => {
      checkIfAllPricesAreSet(business.filterBusinessList.id, dispatch);
      if (success.length > 0) {
        const result = success.filter(
          (ele) =>
            ele.zone_id == price.currentZoneId &&
            ele.seat_types_id == price.elementType.id
        );
        if (result.length > 0) {
          dispatch(isData(true));
          setFilterZoneData(result);
        } else {
          setFilterZoneData([]);
        }
      }
    }).catch((err) => {
    });
  };
  if (grid && grid.weekHourData.length > 0) {
    return (
      <Fragment>
        <div
          className="content"
          style={{
            backgroundColor: "#fff",
            // backgroundImage: `url(${business.filterBusinessList.type === "restaurant"
            //   ? restaurantImage
            //   : beachImage
            //   })`,
            display: "block",
          }}
        >
          <div
            style={{
              height: "100%",
              overflow: "hidden",
            }}
            className={window.innerWidth < 1220 ? "content" : ""}
          >
            <div className="px-4 pt-4">
              <h5 className="title" style={{ textAlign: "center" }}>
                {t("Price planner")}
              </h5>
            </div>
            {element.hasVipZone ||
              element.zone1 ||
              element.zone2 ||
              element.zone3 ||
              element.zone4 ? (
              <CardBody className="px-4">
                <Row>
                  <Col md={12} style={{ paddingBottom: "120px" }}>
                    <Nav pills className="nav-pills-primary">
                      {element.hasVipZone && element.zone2 && (
                        <NavItem>
                          <NavLink
                            className={
                              price.currentZonName === "VIP"
                                ? "active cursor"
                                : "cursor"
                            }
                            onClick={vipAction}
                          >
                            VIP
                          </NavLink>
                        </NavItem>
                      )}
                      {element.zone1 && element.zone2 && (
                        <NavItem>
                          <NavLink
                            className={
                              price.currentZonName == "HT1"
                                ? "active cursor"
                                : "cursor"
                            }
                            onClick={ht1Action}
                          >
                            {element.zone1}
                          </NavLink>
                        </NavItem>
                      )}
                      {element.zone2 && (
                        <NavItem>
                          <NavLink
                            className={
                              price.currentZonName === "HT2"
                                ? "active cursor"
                                : "cursor"
                            }
                            onClick={ht2Action}
                          >
                            {element.zone2}
                          </NavLink>
                        </NavItem>
                      )}
                      {element.zone3 && (
                        <NavItem>
                          <NavLink
                            className={
                              price.currentZonName === "HT3"
                                ? "active cursor"
                                : "cursor"
                            }
                            onClick={ht3Action}
                          >
                            {element.zone3}
                          </NavLink>
                        </NavItem>
                      )}
                      {element.zone4 && (
                        <NavItem>
                          <NavLink
                            className={
                              price.currentZonName === "HT4"
                                ? "active cursor"
                                : "cursor"
                            }
                            onClick={ht4Action}
                          >
                            {t(element.zone4)}
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                    <ElementType
                      fetchingData={fetching}
                      filterType={filterTypeAction}
                      availableSeats={
                        availableSeats ? availableSeats : []
                      }
                      selectedSeats={selectedSeats ? selectedSeats : []}
                    />
                    {filterZoneData && (
                      <PriceDayComponenet
                        startDate={grid.startDate}
                        endDate={grid.endDate}
                        currency={business.filterBusinessList.currency}
                        filterData={filterZoneData}
                        cancelActionss={cancelHandle}
                        agreeActionss={agreeHandle}
                        savePricePlanAction={savePricePlanActionHandle}
                        seletedSeat={selectedSeat}
                      />
                    )}
                  </Col>
                </Row>
              </CardBody>
            ) : (
              <CardBody>
                {!loading ? <Alert color="danger">{t("You have to set zone.")}</Alert> : null}
              </CardBody>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div
          className="content grid-planner-container"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="content options-container">
            <h1
              style={{
                fontSize: "24px",
                color: "#1E2F65",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("Please buy plan first and then set grid elements first")}
            </h1>
          </div>
        </div>
      </Fragment>
    );
  }
};
export default PricePlanner;

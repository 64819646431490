import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import GenerateMultipleInvoices from './GenerateMultipleInvoices';
import html2pdf from 'html2pdf.js';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next'
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';

const InvoiceModal = ({ invoice, showInvoice, setShowInvoiceState, mutliple, invoices, InvoiceComponent, multipleInvoice }, ref) => {
    const componentRef = useRef();
    const { t } = useTranslation();
    const handlePrint = useReactToPrint({
        content: () => componentRef?.current,
    });

    const { innerWidth: width, innerHeight: height } = window;


    useEffect(() => {
        mutliple && showInvoice && handleMultiplePdfDownload()
    }, [mutliple, showInvoice])

    const pdfJSX = () => {
        return (
            <InvoiceComponent invoice={invoice}></InvoiceComponent>
        )
    }

    const mutlipleInvoiceDownload = (invoice) => {
        return (<InvoiceComponent invoice={invoice}></InvoiceComponent>)
    }
    const handleMultiplePdfDownload = () => {
        invoices.map(val => {
            const printElement = ReactDOMServer.renderToString(mutlipleInvoiceDownload(val));
            html2pdf().from(printElement).save(val?.invoice_number);
            setShowInvoiceState()
        }
        )
    }

    const handleDownloadPdf = () => {
        const printElement = ReactDOMServer.renderToString(pdfJSX());
        html2pdf().from(printElement).save(invoice?.invoice_number !== null ? invoice?.invoice_number : "allInvoice");
    }

    return (
        !mutliple && <Modal isOpen={showInvoice} centered size="lg" height={`${Number(width) <= Number(process.env.REACT_APP_MOBILE_VIEW_WIDTH) ? "800px" : "1000px"}`}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                    style={{ padding: "10px", margin: "10px", background: "#fff", color: "#000", border: "1px solid #000", borderRadius: "30px" }}
                    onClick={setShowInvoiceState}
                >
                    <CloseIcon />
                </Button>
                {<Button style={{ padding: "10px", margin: "10px", boxShadow: "0 3px 8px 0 rgb(0 0 0 / 40%)" }} color="primary" onClick={() => { showInvoice && !mutliple && handleDownloadPdf() }}><GetAppIcon /></Button>}
            </div>
            {Number(width) <= Number(process.env.REACT_APP_MOBILE_VIEW_WIDTH) ? <div style={{ height: "700px", display: "flex", alignItems: "center", marginLeft: "12px" }}>You cannot preview this invoice. Please download</div> : <ModalBody style={{ padding: 0 }}>
                <>
                    {showInvoice && !mutliple &&
                        <>
                            <InvoiceComponent invoice={invoice} ref={componentRef} ></InvoiceComponent>
                        </>
                    }
                </>
            </ModalBody>}
        </Modal>
    );
};
export default InvoiceModal;
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import Switch from "@material-ui/core/Switch";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getExtraSunbedPhoto,
  saveExtraSunbedPhoto,
} from "../../../../redux/option/action";
import { checkIfAllPricesAreSet } from "../../../../redux/price/action.js";
import { startLoading, stopLoading } from "../../../../redux/loader/action";

const PhotoRequired = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [photoRequired, setPhotoRequired] = useState(false);
  const [umbrellaRequired, setUmbrellaRequired] = useState(false);
  const [extraSunbed, setExtraSunbed] = useState(0);
  useEffect(() => {
    dispatch(getExtraSunbedPhoto());
  }, [business.filterBusinessList, dispatch]);
  useEffect(() => {
    if (option.businessSettings.length > 0) {
      setPhotoRequired(option.businessSettings[0].photo_required);
      setExtraSunbed(option.businessSettings[0].extra_sunbeds);
      setUmbrellaRequired(option.businessSettings[0].umbrella_requrired);
    } else {
      setPhotoRequired(false);
      setExtraSunbed(0);
    }
  }, [option.businessSettings]);

  const handlePhoto = (event) => {
    let issetPhoto = event.target.checked;
    setPhotoRequired(issetPhoto);
    dispatch(startLoading());
    dispatch(saveExtraSunbedPhoto(issetPhoto, extraSunbed, umbrellaRequired))
      .then((data) => {
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };

  const handleUmbrella = (value) => (event) => {
    let issetPhoto = value === "Yes" ? true : false;
    setUmbrellaRequired(issetPhoto);
    dispatch(startLoading());
    dispatch(saveExtraSunbedPhoto(false, extraSunbed, false))
      .then((data) => {
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };
  const handleChangeSunbedCount = (count) => (event) => {
    setExtraSunbed(count);
    dispatch(startLoading());
    dispatch(saveExtraSunbedPhoto(photoRequired, count, umbrellaRequired))
      .then((data) => {
        checkIfAllPricesAreSet(business.filterBusinessList.id, dispatch);
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };

  const extraBedCount = [0, 1, 2, 3, 4];
  return (
    <React.Fragment>
      <Row>
        <Col md={12}>
          <Card className="options-card">
            <Row className="p-3">
              <Col md="10">
                <h5 className="option-title-main">{t("Photo required")}</h5>
                <p className="option-title-sub">
                  {t("Photo_required_description")}
                </p>
              </Col>
              <Col md="2">
                <Switch
                  checked={photoRequired}
                  name="photoRequired"
                  onChange={handlePhoto}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Col>
            </Row>
          </Card>
          <div className="card-divider-line"></div>

          {(business.filterBusinessList.type === "beach" ||
            business.filterBusinessList.type === "pool") && (
              <>
                <Card className="options-card p-3">
                  <Row>
                    <Col md="10">
                      <h5 className="option-title-main">
                        {t("accept_baldiquin")}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="d-flex" style={{ gap: "12px" }}>
                        {extraBedCount.map((value, index) => (
                          <FormGroup
                            key={index}
                            check
                            className="form-check-button"
                          >
                            <Label check for={`count-${value}`}>
                              <Input
                                checked={extraSunbed === value}
                                id={`count-${value}`}
                                name="extra-bed"
                                type="radio"
                                onClick={handleChangeSunbedCount(value)}
                              />
                              <span className="form-button extra-bed">
                                {value} <span className="form-check-sign" />
                              </span>
                            </Label>
                          </FormGroup>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Card>
                <div className="card-divider-line"></div>
              </>
            )}
        </Col>
      </Row>

      {/*This next section is disabled and will need to be used later for launching in France and Italy*/}
      {false &&
        (business.filterBusinessList.type === "beach" ||
          business.filterBusinessList.type === "pool") && (
          <Card className="addSunbed">
            <CardHeader className="px-4">
              <h5 className="title">{t("Rent_umbrella")}</h5>
            </CardHeader>
            <CardBody className="px-4">
              <Row>
                <Col className="pb-3">
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <Input
                        checked={umbrellaRequired}
                        id="exampleRadios111"
                        name="umbrella"
                        type="radio"
                        onChange={handleUmbrella("Yes")}
                      />
                      <span className="form-check-sign" />
                      {t("Yes")}
                    </Label>
                  </FormGroup>
                  <FormGroup check className="form-check-radio">
                    <Label check>
                      <Input
                        checked={!umbrellaRequired}
                        id="exampleRadios1112"
                        name="umbrella"
                        type="radio"
                        onChange={handleUmbrella("No")}
                      />
                      <span className="form-check-sign" />
                      {t("No")}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
    </React.Fragment>
  );
};
export default PhotoRequired;

import { ApolloClient, gql, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GRAPHQL_URL } from "../../utils/constants.js";
import { createNotification } from "../../utils/utils.js";
//import swal from "sweetalert";

const link = new HttpLink({
  uri: `${GRAPHQL_URL}`,
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};
const authLink = setContext(async (_, { headers }) => {
  let token;
  let localState = localStorage.getItem("persist:business");
  let superAdminSessionToken = localStorage.getItem("SHADOWED_USER_TOKEN");

  if (localState && typeof JSON.parse(localState) === "object") {
    localState = JSON.parse(localState);
    token = localState.accessToken;
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${superAdminSessionToken && superAdminSessionToken.length > 0
          ? superAdminSessionToken
          : token
          }`,
      },
    };
  }
});
const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  defaultOptions,
});
export default class API {
  getRuleRequests(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_rules{
						business_rules(where:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							id
							rules
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  saveRuleRequests(business, option) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_rulesCreate{
						business_rulesCreate(business_rules:{
							rules:{
								en:"${option.en}",
								ro:"${option.ro}",
								fr:"${option.fr}",
								es:"${option.es}",
								it:"${option.it}",
								el:"${option.el}",
								enGoogleTranslated:"${option.enGoogleTranslated}",
								roGoogleTranslated:"${option.roGoogleTranslated}",
								frGoogleTranslated:"${option.frGoogleTranslated}",
								esGoogleTranslated:"${option.esGoogleTranslated}",
								itGoogleTranslated:"${option.itGoogleTranslated}",
								elGoogleTranslated:"${option.elGoogleTranslated}",
								mainLang:"${option.mainLang}",
								currentIng:"${option.currentIng}"
							}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							id
							rules
							business_id
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Login",
            message: "Login successful saveRuleRequests",
          });
          //swal("Login successful saveRuleRequests");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Login",
            message: "Not updated",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateRuleRequests(business, option) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_rulesUpdate{
						business_rulesUpdate(business_rules:{
							id:${option.ruleId}
							rules:{
								en:"${option.en}",
								ro:"${option.ro}",
								fr:"${option.fr}",
								es:"${option.es}",
								it:"${option.it}",
								el:"${option.el}",
								enGoogleTranslated:"${option.enGoogleTranslated}",
								roGoogleTranslated:"${option.roGoogleTranslated}",
								frGoogleTranslated:"${option.frGoogleTranslated}",
								esGoogleTranslated:"${option.esGoogleTranslated}",
								itGoogleTranslated:"${option.itGoogleTranslated}",
								elGoogleTranslated:"${option.elGoogleTranslated}",
								mainLang:"${option.mainLang}",
								currentIng:"${option.currentIng}"
							}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							id
							rules
							business_id
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  getFacilitiesActionRequests(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_facilities{
						business_facilities(where:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							id
							facility_id
							business_id
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  deleteFacilitiesActionRequests(id, business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_facilitiesDelete{
						business_facilitiesDelete(id:${id})
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  createFacilitiesActionRequests(id, business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_facilitiesCreate{
						business_facilitiesCreate(business_facilities:{
							facility_id:${id}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							business_id
							facility_id
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  getExtraSunbedPhotoActionRequests(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_settings{
						business_settings(where:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							umbrella_requrired
							estimated_time
							booking_time_limit
							guaranteed_reservation
              refund_threshold
							money_selected
							temporary_closed
              covid_certificate
              contact_phone
              cuisine
              executive_chef
              website
              address
              customer_booking_time_limit
              max_guest_allowed
              logo
              allowed_logo_landing_page
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  createExtraSunbedPhotoActions(
    option,
    photoRequired,
    extraSunbed,
    umbrellarRequired,
    covidCetificate,
    contact_phone,
    business
  ) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsCreate{
						business_settingsCreate(business_settings:{
							photo_required:${photoRequired}
							extra_sunbeds:${extraSunbed}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            },
							umbrella_requrired:${umbrellarRequired},
              covid_certificate:${covidCetificate},
              contact_phone: "${contact_phone}"
						}){
							id
							latitude
							longitude
							photo_required
              must_arrive_by_required
							extra_sunbeds
							business_id
							umbrella_requrired
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed,
              covid_certificate,
              contact_phone
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateExtraSunbedPhotoActions(
    option,
    photoRequired,
    extraSunbed,
    umbrellarRequired,
    covidCetificate,
    contact_phone
  ) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:{
							id:${option.businessSettings[0].id}
							photo_required:${photoRequired}
							extra_sunbeds:${extraSunbed}
							umbrella_requrired:${umbrellarRequired},
              covid_certificate : ${covidCetificate}
              contact_phone: "${contact_phone}"
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							umbrella_requrired
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed,
              covid_certificate,
              contact_phone
              max_guest_allowed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateWaiterCallActions(option, waiter_calls) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:{
							id:${option.businessSettings[0].id}
							waiter_calls:${waiter_calls}
							
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							umbrella_requrired
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed,
              covid_certificate,
              contact_phone
              max_guest_allowed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }


  // updateTowelIncludedActions

  updateAutoAcceptedActions(option, auto_accept) {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await client.mutate({
          mutation: gql`
          mutation business_settingsUpdate{
            business_settingsUpdate(business_settings:{
              id:${option.businessSettings[0].id}
              auto_accept:${auto_accept}
            }){
              id
              latitude
              longitude
              photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
              extra_sunbeds
              business_id
              umbrella_requrired
              guaranteed_reservation
              estimated_time
              booking_time_limit
              customer_booking_time_limit
              money_selected
              temporary_closed,
              covid_certificate,
              contact_phone
              max_guest_allowed
            }
          }
        `,
        });
        resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  }

  // updateTowelIncludedActions

  updateTowelIncludedActions(option, towel_included) {
    try {
      let data = client
        .mutate({
          mutation: gql`
        mutation business_settingsUpdate{
          business_settingsUpdate(business_settings:{
            id:${option.businessSettings[0].id}
            towel_included:${towel_included}
          }){
            id
            latitude
            longitude
            photo_required
            waiter_calls
            auto_accept
            towel_included
            must_arrive_by_required
            extra_sunbeds
            business_id
            umbrella_requrired
            guaranteed_reservation
            estimated_time
            booking_time_limit
            customer_booking_time_limit
            money_selected
            temporary_closed,
            covid_certificate,
            contact_phone
            max_guest_allowed
          }
        }
      `,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  //updateContactActions

  updateContactsActions(option, contact_phone) {
    try {
      let data = client
        .mutate({
          mutation: gql`
        mutation business_settingsUpdate{
          business_settingsUpdate(business_settings:{
            id:${option.businessSettings[0].id}
            contact_phone: "${contact_phone}"
            
          }){
            id
            latitude
            longitude
            photo_required
            waiter_calls
            auto_accept
            towel_included
            must_arrive_by_required
            extra_sunbeds
            business_id
            umbrella_requrired
            guaranteed_reservation
            estimated_time
            booking_time_limit
            customer_booking_time_limit
            money_selected
            temporary_closed,
            covid_certificate,
            contact_phone
            max_guest_allowed
          }
        }
      `,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }
  createBusinessSettingAction(mutationObject) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:${mutationObject}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							umbrella_requrired
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed,
              covid_certificate,
              contact_phone
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateBusinessSettingActions(mutationObject) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:${mutationObject}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							umbrella_requrired
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed,
              covid_certificate,
              contact_phone
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  getBeachLocationActionRequests(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_settings{
						business_settings(where:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							estimated_time
							booking_time_limit
							guaranteed_reservation
							estimated_time
							booking_time_limit
							money_selected
							temporary_closed,
              cuisine
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  createBeachLocationActions(
    option,
    mapPosition,
    business,
    country,
    city,
    address
  ) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsCreate{
						business_settingsCreate(business_settings:{
							latitude:${mapPosition.lat}
							longitude:${mapPosition.lng}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							beach_location_country:"${country}"
							beach_location_city:"${city}"
              address:"${address}"
						}){
							id
							latitude
							longitude
							photo_required
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed,
              address
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateBeachLocationActions(option, mapPosition, country, city, address) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:{
							id:${option.businessSettings[0].id}
							latitude:${mapPosition.lat}
							longitude:${mapPosition.lng}
							beach_location_country:"${country}"
							beach_location_city:"${city}"
              address:"${address}"
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed,
              address
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateBeachTimezone(id, timezone) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation businessUpdate{
						businessUpdate(business:{
							id:${id}
							timezone:"${timezone}"
						}){
							id
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  createLocationInCityLocale(city) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation createCityLocale{
						city_localeCreate(city_locale:{
							google_name:"${city}",
              coming_soon:0,
              ro:"${city}",
              en:"${city}",
						}){
							id
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateCityLocale(id) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation updateCityLocale{
						city_localeUpdate(city_locale:{
							id:${id},
              coming_soon:0
						}){
							id
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  getCityLocale(city) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query getCityLocale{
            city_locale(where:{google_name:"${city}"}){
              id,
              coming_soon
              google_name
              ro
              en
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  getBusinessGalleryRequests(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_gallery{
						business_gallery(where:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							id
							url
							is_main
							business_id
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  imageDeleteActionRequests(id, business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_galleryDelete{
						business_galleryDelete(id:${id})
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  businessElementPhotoRequests(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_element_gallery{
						business_element_gallery(where:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							id
							image
							element_type
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  getBusinessTypeRequests(business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					query business_element{
						business_element(where:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
						}){
							id
							element{
								id
								type
							}
						}
					}
				`,
        })
        .then((data) => {
          return data;
        })
        .catch((err) => {
          return err;
        });
      return data;
    } catch (err) { }
  }

  saveReservationGuarationRequests(value, business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsCreate{
						business_settingsCreate(business_settings:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							guaranteed_reservation:${value}
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateReservationGuarationRequests(value, business, option) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:{
							id:${option.businessSettings[0].id}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							guaranteed_reservation:${value}
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  // save reservationtime

  saveReservationTimeGuarationRequests(value, value2, business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsCreate{
						business_settingsCreate(business_settings:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							refund_threshold:${value}
							guaranteed_reservation:${value2}

						}){
							id
							latitude
							longitude
							photo_required
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
              refund_threshold
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateReservationTimeGuarationRequests(value, value2, business, option) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:{
							id:${option.businessSettings[0].id}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
              refund_threshold:${value}
							guaranteed_reservation:${value2}
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
              refund_threshold
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  // *******
  saveEstimatedRequests(count, business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsCreate{
						business_settingsCreate(business_settings:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							estimated_time:${count}
						}){
							id
							latitude
							longitude
							photo_required
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateEstimatedRequests(count, business, option) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:{
							id:${option.businessSettings[0].id}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							estimated_time:${count}
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  saveIntervalTimeRequests(count, business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsCreate{
						business_settingsCreate(business_settings:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							booking_time_limit:${count}
						}){
							id
							latitude
							longitude
							photo_required
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateIntervalTimeRequests(count, business, option) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:{
							id:${option.businessSettings[0].id}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							booking_time_limit:${count}
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  saveMoneySelectRequests(name, business) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsCreate{
						business_settingsCreate(business_settings:{
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							money_selected:"${name}"
						}){
							id
							latitude
							longitude
							photo_required
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  updateMoneySelectRequests(name, business, option) {
    try {
      let data = client
        .mutate({
          mutation: gql`
					mutation business_settingsUpdate{
						business_settingsUpdate(business_settings:{
							id:${option.businessSettings[0].id}
							business_id:${business?.filterBusinessList?.id
              ? business.filterBusinessList.id
              : business.filterBusinessList
            }
							money_selected:"${name}"
						}){
							id
							latitude
							longitude
							photo_required
              waiter_calls
              auto_accept
              towel_included
              must_arrive_by_required
							extra_sunbeds
							business_id
							guaranteed_reservation
							estimated_time
							booking_time_limit
              customer_booking_time_limit
							money_selected
							temporary_closed
						}
					}
				`,
        })
        .then((data) => {
          createNotification({
            type: "success",
            title: "Update",
            message: "Successfully updated !",
          });
          //swal("Successfully updated !");
          return data;
        })
        .catch((err) => {
          createNotification({
            type: "danger",
            title: "Update",
            message: "Not updated !",
          });
          //swal("Not updated");
          return err;
        });
      return data;
    } catch (err) { }
  }

  async getMustArriveBy(business) {
    try {
      let data = await client.mutate({
        mutation: gql`
					query business_week{
						business_week(where:{
							business_id:${business}
						}){
							id
            mon_must_arrive_by
            tue_must_arrive_by
            wed_must_arrive_by
            thu_must_arrive_by
            fri_must_arrive_by
            sat_must_arrive_by
            sun_must_arrive_by
						}
					}
				`,
      });
      return data;
    } catch (err) {
      return err;
    }
  }

  async getBusinessWekId(business) {
    try {
      let data = await client.mutate({
        mutation: gql`
		query business_week{
			business_week(where:{
				business_id:${business}
			}){
				id
			}
		}
				`,
      });
      return data;
    } catch (err) {
      return err;
    }
  }

  async setMustArriveBy(business, time) {
    try {
      const BusinessWeekId = await this.getBusinessWekId(business);
      let data = await client.mutate({
        mutation: gql`
		mutation business_weekUpdate{
			business_weekUpdate(business_week:{
  					id:${BusinessWeekId.data.business_week[0].id}
				business_id:${business}              
        mon_must_arrive_by:"${time.mon_must_arrive_by.toString()}"
        tue_must_arrive_by:"${time.tue_must_arrive_by.toString()}"
        wed_must_arrive_by:"${time.wed_must_arrive_by.toString()}"
        thu_must_arrive_by:"${time.thu_must_arrive_by.toString()}"
        fri_must_arrive_by:"${time.fri_must_arrive_by.toString()}"
        sat_must_arrive_by:"${time.sat_must_arrive_by.toString()}"
        sun_must_arrive_by:"${time.sun_must_arrive_by.toString()}"

			}){
				mon_must_arrive_by

			}
		}
				`,
      });
      createNotification({
        type: "success",
        title: "Update",
        message: "Successfully updated !",
      });
      //swal("Successfully updated !");
      return data;
    } catch (err) {
      createNotification({
        type: "danger",
        title: "Update",
        message: "Not updated !",
      });
      //swal("Not updated");
      return err;
    }
  }
}

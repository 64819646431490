import React, { Fragment, useState } from "react";
import { Col, Row } from "reactstrap";
import PhotoExtraSunbed from "./OptionComponent/PhotoExtraSunbed";
import BeachLocation from "./OptionComponent/BeachLocation";
import { Facilities, Facilities2 } from "./OptionComponent/Facilities";
import BeachPhotos from "./OptionComponent/BeachPhotos";
import Reservations from "./OptionComponent/Reservations.js";
import EstimatedTime from "./OptionComponent/EstimatedTime";
import Rules from "./OptionComponent/Rules";
import { useSelector } from "react-redux";
import beachImage from "../../../assets/img/beach.jpg";
import restaurantImage from "../../../assets/img/restaurant.jpg";
import MustArriveBy from "./OptionComponent/MustArriveBy";
import CovidCertificate from "./OptionComponent/CovidCertificate";
import ContactInformation from "./OptionComponent/ContactInformation";
import { Cuisine } from "./OptionComponent/cuisine";
import { Website } from "./OptionComponent/website";
import { ExecutiveChef } from "./OptionComponent/executiveChef";
import { Cost } from "./OptionComponent/Cost";
import Grid from "../adminPage/Grid";
import Grid2 from "../adminPage/Grid2";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import { Beforeunload } from "react-beforeunload";

const GridPage = () => {
  const business = useSelector(({ business }) => business);
  const [savedStatus, setSavedStatus] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const { t } = useTranslation();
  const updateSaveStatus = (status) => {
    setSavedStatus(status);
  };
  React.useEffect(() => { }, [business]);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  if (Number(width) <= Number(process.env.REACT_APP_MOBILE_VIEW_WIDTH)) {
    return (
      <Fragment>
        <div
          className="content grid-planner-container"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="content options-container">
            <h1
              style={{
                fontSize: "24px",
                color: "#1E2F65",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {t("This Page is not visible in mobile view")}
            </h1>
          </div>
        </div>
      </Fragment>
    );
  } else
    return (
      <Fragment>
        {!savedStatus && (
          <Beforeunload
            onBeforeunload={() => t("grid changes unsaved warning")}
          />
        )}
        <Prompt
          when={!savedStatus}
          message={(location) => t("grid changes unsaved warning")}
        />
        {business.filterBusinessList.type === "beach" ||
          business.filterBusinessList.type === "pool" ? (
          <Grid></Grid>
        ) : (
          <Grid2
            savedStatus={savedStatus}
            setSavedStatus={updateSaveStatus}
          ></Grid2>
        )}
      </Fragment>
    );
};
export default GridPage;

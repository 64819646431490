import axios from "axios";
import { calls } from "../../utils/calls.js";
import { createError } from "../../utils/utils.js";
//import swal from "sweetalert";

export const actionTypes = {
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
};

export function initialize() {
  const init = {
    loading : false
  };
  return { type: actionTypes.INITIAL_STATE, init };
}

export function startLoading() {
  return { type: actionTypes.START_LOADING };
}

export function stopLoading() {
  return { type: actionTypes.STOP_LOADING };
}

import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import Datetime from "react-datetime";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PanelHeader from "../../components/PanelHeader/PanelHeader.js";
import {
  agreeStripeTOS,
  businessTypesData,
  changePublishStatus,
  createBsuinessProfile,
  createStripeAccount,
  getBusinessListGetById,
  getBusinessProfileById,
  getCityList,
  getCountryAction,
  getStripeTOSStatus,
  saveBusinessName,
} from "../../redux/business/action";
import "../../assets/css/custom.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { REST_API_URL } from "../../utils/constants.js";
import { calls } from "../../utils/calls.js";
import restaurantImage from "../../assets/img/restaurant.jpg";
import beachImage from "../../assets/img/beach.jpg";
import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";
import VirtualizedSelect from "react-virtualized-select";
import { startLoading, stopLoading } from "../../redux/loader/action.js";
import { createNotification } from "../../utils/utils";
import { getExtraSunbedPhoto } from "../../redux/option/action.js";
import AlertHeader from "../../components/PanelHeader/AlertHeader";
import briefcaseIcon from "../../../src/assets/img/briefcaseIcon.svg";

const currencyList = [
  { name: "EUR" },
  { name: "USD" },
  { name: "RON" },
  { name: "GBP" },
  { name: "CHF" },
  { name: "NOK" },
  { name: "SEK" },
  { name: "DKK" },
];

const BusinessProfile = ({ match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const business = useSelector(({ business }) => business);
  const option = useSelector(({ option }) => option);
  const [values, setValues] = useState([]);
  const [disableSave, setDisableSave] = useState(true);

  const [id_card_file_name, setid_card_file_name] = useState("");
  const [additional_document_file_name, setadditional_document_file_name] =
    useState("");
  const [identification_file_name, setidentification_file_name] = useState("");
  const [show, setShow] = useState(false);
  const [stateFileUpload, setStateFileUpload] = useState({
    idCardUpload: "",
    additionalDocument: "",
  });
  const [fileName, setFileName] = useState({
    idCard: business.businessData.id_card_file_name,
    additionalDocument: business.businessData.additional_document_file_name,
    companyIdentification: business.businessData.identification_file_name,
  });
  const [countryCityValue, setCountryCityValue] = useState({
    birthdatData: "",
    countryId: 181,
    cityId: 1,
    countryStatus: false,
    cityStatus: false,
    birthdatDataStatus: false,
  });
  const [state, setState] = useState(false);
  const [useableList, setUseableList] = useState([]);
  const [publishStatus, setPublishStatus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [checkResultData, setCheckResultData] = useState({
    businessProfileStatus: false,
    planDataLength: 0,
    businessSettings: 0,
    businessYear: 0,
    businessRules: 0,
    price: 0,
    businessElement: 0,
    businessUser: 0,
    businessZone: 0,
    businessPhotos: 0,
    businessWeek: 0,
    businessFacilities: 0,
  });
  const [cities, setCities] = useState([]);
  const [acceptedToS, setAcceptedToS] = useState();

  const getPublishStatus = async (businessId) => {
    await axios
      .post(`${REST_API_URL}/dashboard/checkPublish`, {
        id: businessId,
      })
      .then(function (response) {
        if (response.data.status) {
          changeStatusAction("pending");
        } else {
          handleShow();
        }
        setCheckResultData(response.data);
        setPublishStatus(response.data.status);
      });
  };

  useEffect(() => {
    if (match.params.id) dispatch(getBusinessProfileById(match.params.id));
  }, [match.params.id]);
  useEffect(() => {
    setAcceptedToS(business.acceptedToS);
  }, [business.acceptedToS]);

  useEffect(() => {
    dispatch(getExtraSunbedPhoto());
  }, [dispatch, business.filterBusinessList.id]); //

  useEffect(() => {
    if (business.businessLists.length > 0) {
      const filterResult = business.businessLists.filter(
        (ele) => ele.id == match.params.id
      );
      setSelectedStatus(filterResult[0] ? filterResult[0].status : '');
    }
  }, []);

  useEffect(() => {
    dispatch(getCountryAction());
    setValues(business.filterBusinessList);

    if (business.businessLists) {
      const filterList = business.businessLists.filter(
        (ele) => ele.representative_email != null
      );
      if (filterList) {
        setUseableList(filterList);
      }
    }
  }, [match.params.id]);

  useEffect(() => {
    dispatch(getCityList(countryCityValue.countryId));
  }, [countryCityValue.countryId]);

  // useEffect(() => {
  //   if (business.businessData.country_id) {
  //     dispatch(getCityList(business.businessData.country_id));
  //   }
  // }, [disableSave]);

  useEffect(() => {
    setFileName({
      idCard: business.businessData.id_card_file_name,
      additionalDocument: business.businessData.additional_document_file_name,
      companyIdentification: business.businessData.identification_file_name,
    });
    setid_card_file_name(business.businessData.id_card_file_name);
    setadditional_document_file_name(
      business.businessData.additional_document_file_name
    );
    setidentification_file_name(business.businessData.identification_file_name);
  }, []);

  useEffect(() => {
    const type =
      business.filterBusinessList.type == "beach" ||
        business.filterBusinessList.type == "pool"
        ? "beach"
        : "restaurant";

    setBusinessType(type);
  }, [business.filterBusinessList]);

  useEffect(() => {
    setCountryCityValue({
      ...countryCityValue,
      birthdatData: business.businessData.representative_birth_date,
    });
  }, [business.businessData]);

  useEffect(() => {
    setCities(
      business.cityLists.map((city) => ({
        label: city.name,
        value: city.id,
      }))
    );
  }, [business]);

  const idCard = React.createRef();
  const additionalDocument = React.createRef();
  const companyIdentification = React.createRef();

  const handleFileInput = (e, type) => {
    if (type === "idCard") {
      idCard.current.click(e);
    }
    if (type === "additionalDocument") {
      additionalDocument.current.click(e);
    }
    if (type === "companyIdentification") {
      companyIdentification.current.click(e);
    }
  };

  const addIdCardFile = (e, type) => {
    setStateFileUpload({ ...stateFileUpload, [type]: e.target.files });
    let fileNames;
    for (let i = 0; i < e.target.files.length; i++) {
      fileNames = e.target.files[i].name;
    }
    setFileName({ ...fileName, [type]: fileNames });
    docsUpload(type, e.target.files[0]);
  };

  const docsUpload = (type, file) => {
    const formData = new FormData();
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    formData.append("businessId", business.filterBusinessList.id);
    if (type === "idCard") {
      formData.append("idCard", file);
      formData.append("type", "idCard");
    }
    if (type === "additionalDocument") {
      formData.append("additionalDocument", file);
      formData.append("type", "additionalDocument");
    }
    if (type === "companyIdentification") {
      formData.append("companyIdentification", file);
      formData.append("type", "companyIdentification");
    }

    axios
      .post(`${REST_API_URL}/file-docs/upload`, formData, config)
      .then((res) => {
        const { filename } = res.data;

        if (type === "idCard") {
          setid_card_file_name(filename);
        }
        if (type === "additionalDocument") {
          setadditional_document_file_name(filename);
        }
        if (type === "companyIdentification") {
          setidentification_file_name(filename);
        }

        dispatch({
          type: "BUSINESS_DOCUMENT_UPLOADED",
          payload: {
            businessId: business.filterBusinessList.id,
            filename: filename,
            type: type,
          },
        });
      });
  };
  const handleChangeData = (date) => {
    const valueOfInput = date.format("MM/DD/YYYY");
    setCountryCityValue({ ...countryCityValue, birthdatData: valueOfInput });
    setDisableSave(false);
  };

  const demoData = (id) => () => {
    setState(true);
    const arrFilter = business.businessLists.filter((ele) => ele.id == id);
    setValues(arrFilter[0]);
    setCountryCityValue({
      ...countryCityValue,
      birthdatData: arrFilter[0].representative_birth_date,
    });

    setFileName({
      idCard: arrFilter[0].id_card_file_name,
      additionalDocument: arrFilter[0].additional_document_file_name,
      companyIdentification: arrFilter[0].identification_file_name,
    });
  };

  const handleClick = (event) => {
    dispatch(getCityList(event.target.value));
  };

  const changeStatusAction = (status) => {
    setSelectedStatus(status);
    dispatch(changePublishStatus(business.filterBusinessList.id, status));
  };
  const formik = useFormik({
    initialValues: {
      location_name: state
        ? business.businessData.location_name
        : values.location_name
          ? values.location_name
          : "",
      representative_first_name: values.representative_first_name
        ? values.representative_first_name
        : "",
      representative_last_name: values.representative_last_name
        ? values.representative_last_name
        : "",
      name: values.name ? values.name : "",
      representative_phone: values.representative_phone
        ? values.representative_phone
        : "",
      representative_email: values.representative_email
        ? values.representative_email
        : "",
      address: values.address ? values.address : "",
      country_id: values.country_id ? values.country_id : 181,
      city_id: values.city_id ? values.city_id : 50,
      zipcode: values.zipcode ? values.zipcode : "",
      reg_com_number: values.reg_com_number ? values.reg_com_number : "",
      capital_social: values.capital_social ? values.capital_social : "",
      cui_number: values.cui_number ? values.cui_number : "",
      vat: values.vat ? values.vat : "",
      vat_number: values.vat_number ? values.vat_number : "",
      bank_name: values.bank_name ? values.bank_name : "",
      bank_account: values.bank_account ? values.bank_account : "",
      bank_routing_number: values.bank_routing_number
        ? values.bank_routing_number
        : "",
      bank_account_holder_name: values.bank_account_holder_name
        ? values.bank_account_holder_name
        : "",
      description: values.description ? values.description : "",
      currency: values.currency ? values.currency : "EUR",
      id_card_file_name: values.id_card_file_name
        ? values.id_card_file_name
        : "",
      additional_document_file_name: values.additional_document_file_name
        ? values.additional_document_file_name
        : "",
      identification_file_name: values.identification_file_name
        ? values.identification_file_name
        : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      location_name: Yup.string().required("Required"),
      representative_first_name: Yup.string().required("Required"),
      representative_last_name: Yup.string().required("Required"),
      name: Yup.string().required("Required"),
      representative_phone: Yup.string().required("Required"),
      representative_email: Yup.string()
        .email("Invalid email")
        .required("Required"),
      address:
        businessType === "beach"
          ? Yup.string().required("Required")
          : Yup.string().optional(),
      country_id:
        businessType === "beach"
          ? Yup.string().required("Required")
          : Yup.string().optional(),
      city_id:
        businessType === "beach"
          ? Yup.string().required("Required")
          : Yup.string().optional(),
      zipcode:
        businessType === "beach"
          ? Yup.string().required("Required")
          : Yup.string().optional(),
      reg_com_number: Yup.string().required("Required"),
      capital_social: Yup.string().required("Required"),
      cui_number: Yup.string().required("Required"),
      vat: Yup.string().required("Required"),
      vat_number: Yup.string().required("Required"),
      bank_name:
        businessType == "beach" ||
          (option &&
            option.businessSettings &&
            option.businessSettings.length > 0 &&
            option.businessSettings[0].guaranteed_reservation)
          ? Yup.string().required("Required")
          : Yup.string().optional(),
      bank_account:
        businessType == "beach" ||
          (option &&
            option.businessSettings &&
            option.businessSettings.length > 0 &&
            option.businessSettings[0].guaranteed_reservation)
          ? Yup.string().required("Required")
          : Yup.string().optional(),
      bank_routing_number:
        businessType == "beach" ||
          (option &&
            option.businessSettings &&
            option.businessSettings.length > 0 &&
            option.businessSettings[0].guaranteed_reservation)
          ? Yup.string().required("Required")
          : Yup.string().optional(),
      bank_account_holder_name:
        businessType == "beach" ||
          (option &&
            option.businessSettings &&
            option.businessSettings.length > 0 &&
            option.businessSettings[0].guaranteed_reservation)
          ? Yup.string().required("Required")
          : Yup.string().optional(),
    }),
    onSubmit: (values) => {
      values.id_card_file_name = id_card_file_name || existentIdCardFilename || '';
      values.additional_document_file_name = additional_document_file_name || existentAdditionalDocumentFilename || '';
      values.identification_file_name = identification_file_name || companyIdentificationFilename || '';
      dispatch(startLoading());
      dispatch(createBsuinessProfile(values, countryCityValue, match.params.id))
        .then((data) => {
          //_handleSubmitCallback()
          axios(
            calls.sendEmail({
              sender: "tabo",
              receiver: "tabo",
              subject: "A location has updated their business profile!",
              mailHtml: `
						<div>
							<h1>Location ${business.filterBusinessList.location_name} has updated their profile</h1>
						</div>
					`,
            })
          )
            .then((data) => {
              dispatch(stopLoading());
            })
            .catch((e) => {
              dispatch(stopLoading());
            });
        })
        .catch((e) => {
          dispatch(stopLoading());
        });
      setDisableSave(true);
    },
  });

  const checkStripeDisable = () => {
    return (
      !business.filterBusinessList.id_card_file_name ||
      !business.filterBusinessList.country_id ||
      !business.filterBusinessList.city_id ||
      !business.filterBusinessList.zipcode ||
      !business.filterBusinessList.identification_file_name ||
      !business.filterBusinessList.additional_document_file_name ||
      !business.filterBusinessList.currency ||
      !business.filterBusinessList.representative_first_name ||
      !business.filterBusinessList.representative_last_name ||
      !business.filterBusinessList.bank_account ||
      !business.filterBusinessList.location_name ||
      !business.filterBusinessList.representative_phone ||
      !business.filterBusinessList.cui_number ||
      !business.filterBusinessList.reg_com_number ||
      !business.filterBusinessList.vat_number
    );
  };

  const _handleSaveBusinessName = (name) => {
    dispatch(startLoading());
    dispatch(
      saveBusinessName({
        location_name: name,
        id: match.params.id,
      })
    )
      .then((data) => {
        dispatch(stopLoading());
        createNotification({
          type: "success",
          title: "Update",
          message: "Successfully updated name",
        });
      })
      .catch((e) => {
        dispatch(stopLoading());
        createNotification({
          type: "danger",
          title: "Update",
          message: "Error while update name",
        });
      });
  };

  const _handleSubmitCallback = () => {
    dispatch(startLoading());
    createStripeAccount(match.params.id, dispatch)
      .then(() => {
        dispatch(businessTypesData()).then(() => {
          dispatch(getBusinessListGetById(business.filterBusinessList.id));
          getStripeTOSStatus(business?.filterBusinessList?.id, dispatch);
          dispatch(stopLoading());
        });
      })
      .catch((err) => {
        dispatch(stopLoading());
        createNotification({
          type: "danger",
          title: "Create stripe account",
          message: err.message,
        });
      });
  };

  const _agreeStripeTOS = () => {
    agreeStripeTOS(match.params.id, dispatch);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const publishAction = () => {
    if (selectedStatus === "inactive") {
      getPublishStatus(business.filterBusinessList.id)
        .then(() => {
          return axios(
            calls.sendEmail({
              sender: "tabo",
              receiver: "tabo",
              subject: "A location has published their profile!",
              mailHtml: `
						<div>
							<h1>Location ${business.filterBusinessList.location_name} has published their profile</h1>
						</div>
					`,
            })
          );
        })
        .catch((err) => { });
    }
  };

  const changeCountry = (option) => {
    setCountryCityValue({ ...countryCityValue, countryId: option.id });
    setDisableSave(false);
    dispatch(getCityList(option.id));
  };

  const changeCity = (option) => {
    formik.setFieldValue("city_id", option.value);
    setDisableSave(false);
  };

  const existentIdCardFilename = business.businessLists.find(
    (b) => b.id === business.filterBusinessList.id
  )?.id_card_file_name;
  const existentAdditionalDocumentFilename = business.businessLists.find(
    (b) => b.id === business.filterBusinessList.id
  )?.additional_document_file_name;
  const companyIdentificationFilename = business.businessLists.find(
    (b) => b.id === business.filterBusinessList.id
  )?.identification_file_name;

  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
    setDisableSave(false);
  };

  return (
    <Fragment>
      <AlertHeader />
      <div
        className="content business-container"
        style={{ backgroundColor: "#fff" }}
      >
        {/* <PanelHeader size="sm" /> */}
        <div className="content business-profile-container"
          style={{ marginTop: "100px" }}
        >
          <div className="d-flex business-profile-head">
            <div className="page-heading mb-0">
              <img src={briefcaseIcon} />
              <h5>{t("Business Profile")}</h5>
            </div>
            <div className="d-flex" style={{ gap: "12px" }}>
              <div>
                <Button
                  color="primary"
                  className="save-btn-blue"
                  onClick={publishAction}
                >
                  {selectedStatus === "inactive"
                    ? t("PUBLISH")
                    : selectedStatus === "pending"
                      ? t("PENDING APPROVAL")
                      : selectedStatus === "active"
                        ? t("PUBLISHED")
                        : t("SUSPENDED")}
                </Button>
              </div>
              <div>
                {useableList.length && (
                  <div className="d-flex">
                    {!business.filterBusinessList.representative_email && (
                      <UncontrolledDropdown tyle={{ background: "#609" }}>
                        <DropdownToggle
                          caret
                          tyle={{ background: "#609" }}
                          color="info"
                          className="m-0 btn-profile-autofill"
                        >
                          {t("Use an already added company")}
                        </DropdownToggle>
                        <DropdownMenu style={{ backgroundColor: "#fff", maxHeight: "250px", overflowY: "auto" }}>
                          {useableList &&
                            useableList.map((item, index) => {
                              return (
                                <DropdownItem
                                  key={index}
                                  onClick={demoData(item.id)}
                                >
                                  {item.location_name}
                                </DropdownItem>
                              );
                            })}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <Row className="business-profile-row mx-auto">
            <Col md="12">
              <Form id="business-profile-form" onSubmit={formik.handleSubmit}>
                <Card className="business-profile-card">
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <h6 className="profile-title">{t("Location Name")}</h6>
                      </Col>
                      <Col md="6" lg="4">
                        <div className="d-flex" style={{ gap: "10px" }}>
                          <div className="w-100">
                            <FormGroup>
                              <label className="text-input-label">
                                {t("Location Name")}
                              </label>
                              <Input
                                placeholder={t("Location Name")}
                                type="text"
                                name="location_name"
                                value={formik.values.location_name}
                                onChange={handleChange}
                              />
                              {formik.errors.location_name &&
                                formik.touched.location_name && (
                                  <FormText color="danger">
                                    {formik.errors.location_name}
                                  </FormText>
                                )}
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup>
                              <label style={{ display: "block" }}>&nbsp;</label>
                              <Button
                                disabled={
                                  !formik.values.location_name ||
                                  business.filterBusinessList.stripe_approved
                                }
                                type="button"
                                onClick={(e) => {
                                  _handleSaveBusinessName(
                                    formik.values.location_name
                                  );
                                }}
                                className="m-0 save-btn-blue"
                                color="primary"
                              >
                                {t("Save")}
                              </Button>
                            </FormGroup>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="card-divider-line mt-3 mb-3"></div>

                    <Row>
                      <Col md="6">
                        <Row className="g-3">
                          <Col md="12">
                            <h6 className="profile-title">
                              {t("User Profile")}
                            </h6>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="text-input-label">
                                {t("First_Name")}
                              </label>
                              <Input
                                placeholder={t("First_Name")}
                                type="text"
                                name="representative_first_name"
                                value={formik.values.representative_first_name}
                                onChange={handleChange}
                              />
                              {formik.errors.representative_first_name &&
                                formik.touched.representative_first_name && (
                                  <FormText color="danger">
                                    {formik.errors.representative_first_name}
                                  </FormText>
                                )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="text-input-label">
                                {t("Last_Name")}
                              </label>
                              <Input
                                placeholder={t("Last_Name")}
                                type="text"
                                name="representative_last_name"
                                value={formik.values.representative_last_name}
                                onChange={handleChange}
                              />
                              {formik.errors.representative_last_name &&
                                formik.touched.representative_last_name && (
                                  <FormText color="danger">
                                    {formik.errors.representative_last_name}
                                  </FormText>
                                )}
                            </FormGroup>
                          </Col>
                          {businessType == "beach" ||
                            (option &&
                              option.businessSettings.length > 0 &&
                              option.businessSettings[0]
                                .guaranteed_reservation) ||
                            business.businessData.representative_birth_date !==
                            undefined ? (
                            <Col md="6">
                              <FormGroup>
                                <label className="text-input-label">
                                  {t("Birth Day")}
                                </label>
                                <Datetime
                                  name="birthdatData"
                                  timeFormat={false}
                                  onChange={handleChangeData}
                                  // dateFromat="YYYY-MM-dd"
                                  value={countryCityValue.birthdatData}
                                  inputProps={{
                                    placeholder: `${t("Birth Day")}`,
                                  }}
                                />
                              </FormGroup>
                            </Col>
                          ) : null}
                          <Col md="6">
                            <FormGroup>
                              <label
                                htmlFor="exampleInputEmail1"
                                className="text-input-label"
                              >
                                {t("Email")}
                              </label>
                              <Input
                                placeholder={t("Email")}
                                type="email"
                                name="representative_email"
                                value={formik.values.representative_email}
                                onChange={handleChange}
                              />
                              {formik.errors.representative_email &&
                                formik.touched.representative_email && (
                                  <FormText color="danger">
                                    {formik.errors.representative_email}
                                  </FormText>
                                )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label
                                htmlFor="exampleInputEmail1"
                                className="text-input-label"
                              >
                                {t("Phone")}
                              </label>
                              <Input
                                placeholder={t("Phone")}
                                type="text"
                                name="representative_phone"
                                value={formik.values.representative_phone}
                                onChange={handleChange}
                              />
                              {formik.errors.representative_phone &&
                                formik.touched.representative_phone && (
                                  <FormText color="danger">
                                    {formik.errors.representative_phone}
                                  </FormText>
                                )}
                            </FormGroup>
                          </Col>

                          {businessType == "beach" ||
                            (option &&
                              option.businessSettings.length > 0 &&
                              option.businessSettings[0]
                                .guaranteed_reservation) ||
                            values.zipcode ? (
                            <>
                              <Col md="6">
                                <FormGroup>
                                  <label className="text-input-label">
                                    {t("Postal Code")}
                                  </label>
                                  <Input
                                    placeholder={t("ZIP Code")}
                                    type="number"
                                    name="zipcode"
                                    value={formik.values.zipcode}
                                    onChange={handleChange}
                                  />
                                  {formik.errors.zipcode &&
                                    formik.touched.zipcode && (
                                      <FormText color="danger">
                                        {formik.errors.zipcode}
                                      </FormText>
                                    )}
                                </FormGroup>
                              </Col>
                            </>
                          ) : null}

                          {businessType == "beach" ||
                            (option &&
                              option.businessSettings.length > 0 &&
                              option.businessSettings[0]
                                .guaranteed_reservation) ||
                            values.country_id ? (
                            <>
                              <Col className="" md="6">
                                {business.countryData && (
                                  <>
                                    <FormGroup>
                                      <Label
                                        className="text-input-label"
                                        for="disabled1"
                                      >
                                        {t("Country")}
                                      </Label>

                                      <VirtualizedSelect
                                        form="business-profile-form"
                                        style={{
                                          borderRadius: 6,
                                          fontSize: 12,
                                          borderColor: "#E5E5E5",
                                        }}
                                        name="country_id"
                                        valueKey="id"
                                        value={countryCityValue.countryId}
                                        onChange={changeCountry}
                                        clearable={false}
                                        labelKey="name"
                                        options={business.countryData}
                                      />
                                    </FormGroup>
                                  </>
                                )}
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          {businessType == "beach" ||
                            (option &&
                              option.businessSettings.length > 0 &&
                              option.businessSettings[0]
                                .guaranteed_reservation) ||
                            values.city_id ? (
                            <Col className="" md="6">
                              <FormGroup>
                                <Label
                                  className="text-input-label"
                                  for="disabled1"
                                >
                                  {t("City")}
                                </Label>
                                <VirtualizedSelect
                                  form="business-profile-form"
                                  style={{
                                    borderRadius: 6,
                                    fontSize: 12,
                                    borderColor: "#E5E5E5",
                                  }}
                                  name="city_id"
                                  value={formik.values.city_id}
                                  onChange={changeCity}
                                  clearable={false}
                                  options={cities}
                                />
                              </FormGroup>
                            </Col>
                          ) : (
                            ""
                          )}
                          {businessType == "beach" ||
                            (option &&
                              option.businessSettings.length > 0 &&
                              option.businessSettings[0]
                                .guaranteed_reservation) ||
                            values.address ? (
                            <Col md="12">
                              <FormGroup>
                                <label className="text-input-label">
                                  {t("Address")}
                                </label>
                                <Input
                                  placeholder={t("Home Address")}
                                  type="text"
                                  name="address"
                                  value={formik.values.address}
                                  onChange={handleChange}
                                />
                                {formik.errors.address &&
                                  formik.touched.address && (
                                    <FormText color="danger">
                                      {formik.errors.address}
                                    </FormText>
                                  )}
                              </FormGroup>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col md="6">
                            <FormGroup className="form-file-upload form-file-simple">
                              <Label
                                className="text-input-label"
                                for="disabled1"
                              >
                                {t("ID card")}
                              </Label>
                              <Input
                                type="text"
                                disabled={
                                  business.filterBusinessList.stripe_approved
                                }
                                className="inputFileVisible upload-field"
                                placeholder={t("ID card")}
                                onClick={(e) => handleFileInput(e, "idCard")}
                                value={
                                  fileName.idCard || existentIdCardFilename
                                }
                              />
                              <input
                                type="file"
                                className="inputFileHidden"
                                disabled={
                                  business.filterBusinessList.stripe_approved
                                }
                                style={{ zIndex: -1 }}
                                ref={idCard}
                                onChange={(e) => addIdCardFile(e, "idCard")}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="form-file-upload form-file-simple">
                              <Label
                                className="text-input-label"
                                for="disabled1"
                              >
                                {t("Additional Document")}
                              </Label>
                              <Input
                                type="text"
                                className="inputFileVisible upload-field"
                                disabled={
                                  business.filterBusinessList.stripe_approved
                                }
                                placeholder={t("Additional Document")}
                                onClick={(e) =>
                                  handleFileInput(e, "additionalDocument")
                                }
                                value={
                                  fileName.additionalDocument ||
                                  existentAdditionalDocumentFilename
                                }
                              />
                              <input
                                type="file"
                                className="inputFileHidden"
                                disabled={
                                  business.filterBusinessList.stripe_approved
                                }
                                style={{ zIndex: -1 }}
                                ref={additionalDocument}
                                onChange={(e) =>
                                  addIdCardFile(e, "additionalDocument")
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup className="form-file-upload form-file-simple">
                              <Label
                                className="text-input-label"
                                for="disabled1"
                              >
                                {t("Company Identification")}
                              </Label>
                              <Input
                                type="text"
                                className="inputFileVisible upload-field"
                                disabled={
                                  business.filterBusinessList.stripe_approved
                                }
                                placeholder={t("Company Identification")}
                                onClick={(e) =>
                                  handleFileInput(e, "companyIdentification")
                                }
                                value={
                                  fileName.companyIdentification ||
                                  companyIdentificationFilename
                                }
                              />
                              <input
                                type="file"
                                className="inputFileHidden"
                                disabled={
                                  business.filterBusinessList.stripe_approved
                                }
                                style={{ zIndex: -1 }}
                                ref={companyIdentification}
                                onChange={(e) =>
                                  addIdCardFile(e, "companyIdentification")
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      <Col md="6">
                        <Row>
                          <Col md="12">
                            <h6 className="profile-title">{t("Company")}</h6>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="text-input-label">
                                {t("Company Name")}
                              </label>
                              <Input
                                placeholder={t("Company Name")}
                                type="text"
                                name="name"
                                value={formik.values.name}
                                onChange={handleChange}
                              />
                              {formik.errors.name && formik.touched.name && (
                                <FormText color="danger">
                                  {formik.errors.name}
                                </FormText>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="text-input-label">
                                {t("Commerical Number")}
                              </label>
                              <Input
                                placeholder={t("Commerical Number")}
                                type="text"
                                name="reg_com_number"
                                value={formik.values.reg_com_number}
                                onChange={handleChange}
                              />
                              {formik.errors.reg_com_number &&
                                formik.touched.reg_com_number && (
                                  <FormText color="danger">
                                    {formik.errors.reg_com_number}
                                  </FormText>
                                )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="text-input-label">
                                {t("Woriking Capital")}
                              </label>
                              <Input
                                placeholder={t("Woriking Capital")}
                                type="text"
                                name="capital_social"
                                value={formik.values.capital_social}
                                onChange={handleChange}
                              />
                              {formik.errors.capital_social &&
                                formik.touched.capital_social && (
                                  <FormText color="danger">
                                    {formik.errors.capital_social}
                                  </FormText>
                                )}
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label className="text-input-label">
                                {t("Fiscal Number")}
                              </label>
                              <Input
                                placeholder={t("Fiscal Number")}
                                type="text"
                                name="cui_number"
                                value={formik.values.cui_number}
                                onChange={handleChange}
                              />
                              {formik.errors.cui_number &&
                                formik.touched.cui_number && (
                                  <FormText color="danger">
                                    {formik.errors.cui_number}
                                  </FormText>
                                )}
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <label className="text-input-label">
                                {t("VAT %")}
                              </label>
                              <Input
                                placeholder={t("VAT %")}
                                type="number"
                                name="vat"
                                value={formik.values.vat}
                                onChange={handleChange}
                              />
                              {formik.errors.vat && formik.touched.vat && (
                                <FormText color="danger">
                                  {formik.errors.vat}
                                </FormText>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <label className="text-input-label">
                                {t("Vat Number")}
                              </label>
                              <Input
                                placeholder={t("Vat Number")}
                                type="text"
                                name="vat_number"
                                value={formik.values.vat_number}
                                onChange={handleChange}
                              />
                              {formik.errors.vat_number &&
                                formik.touched.vat_number && (
                                  <FormText color="danger">
                                    {formik.errors.vat_number}
                                  </FormText>
                                )}
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup>
                              <Label
                                className="text-input-label"
                                for="disabled1"
                              >
                                {t("Select Currency")}
                              </Label>
                              <Input
                                type="select"
                                id="disabled1"
                                name="currency"
                                value={formik.values.currency}
                                onChange={handleChange}
                              >
                                {currencyList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.name}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>

                        {businessType == "beach" ||
                          (option &&
                            option.businessSettings.length > 0 &&
                            option.businessSettings[0].guaranteed_reservation) ||
                          formik.values.bank_name ||
                          formik.values.bank_account ||
                          formik.values.bank_routing_number ||
                          formik.values.bank_account_holder_name ? (
                          <>
                            <div className="card-divider-line mt-3 mb-3"></div>
                            <Row>
                              <Col md="12">
                                <h6 className="profile-title">
                                  {t("Company Bank Details")}
                                </h6>
                              </Col>

                              <Col
                                md="6"
                                className={
                                  !formik.values.bank_name &&
                                    businessType != "beach"
                                    ? "error-bank"
                                    : ""
                                }
                              >
                                <FormGroup>
                                  <label className="text-input-label">
                                    {t("Bank Name")}
                                  </label>
                                  <Input
                                    placeholder={t("Bank Name")}
                                    type="text"
                                    name="bank_name"
                                    value={formik.values.bank_name}
                                    onChange={handleChange}
                                  />
                                  {formik.errors.bank_name &&
                                    formik.touched.bank_name && (
                                      <FormText color="danger">
                                        {formik.errors.bank_name}
                                      </FormText>
                                    )}
                                </FormGroup>
                              </Col>
                              <Col
                                md="6"
                                className={
                                  !formik.values.bank_account &&
                                    businessType != "beach"
                                    ? "error-bank"
                                    : ""
                                }
                              >
                                <FormGroup>
                                  <label className="text-input-label">
                                    {t("Bank Account")}
                                  </label>
                                  <Input
                                    placeholder={t("Bank Account")}
                                    type="text"
                                    name="bank_account"
                                    value={formik.values.bank_account}
                                    onChange={handleChange}
                                  />
                                  {formik.errors.bank_account &&
                                    formik.touched.bank_account && (
                                      <FormText color="danger">
                                        {formik.errors.bank_account}
                                      </FormText>
                                    )}
                                </FormGroup>
                              </Col>
                              <Col
                                md="6"
                                className={
                                  !formik.values.bank_routing_number &&
                                    businessType != "beach"
                                    ? "error-bank"
                                    : ""
                                }
                              >
                                <FormGroup>
                                  <label className="text-input-label">
                                    {t("Routing Number")}
                                  </label>
                                  <Input
                                    placeholder={t("Routing Number")}
                                    type="text"
                                    name="bank_routing_number"
                                    value={formik.values.bank_routing_number}
                                    onChange={handleChange}
                                  />
                                  {formik.errors.bank_routing_number &&
                                    formik.touched.bank_routing_number && (
                                      <FormText color="danger">
                                        {formik.errors.bank_routing_number}
                                      </FormText>
                                    )}
                                </FormGroup>
                              </Col>
                              <Col
                                md="6"
                                className={
                                  !formik.values.bank_account_holder_name &&
                                    businessType != "beach"
                                    ? "error-bank"
                                    : ""
                                }
                              >
                                <FormGroup>
                                  <label className="text-input-label">
                                    {t("Account holder name")}
                                  </label>
                                  <Input
                                    placeholder={t("Account holder name")}
                                    type="text"
                                    name="bank_account_holder_name"
                                    value={
                                      formik.values.bank_account_holder_name
                                    }
                                    onChange={handleChange}
                                  />
                                  {formik.errors.bank_account_holder_name &&
                                    formik.touched.bank_account_holder_name && (
                                      <FormText color="danger">
                                        {formik.errors.bank_account_holder_name}
                                      </FormText>
                                    )}
                                </FormGroup>
                              </Col>
                              {/* <Row>
												<Col md="12">
													<FormGroup>
														<label>{ t('Description') }</label>
														<Input
															cols="80"
															placeholder={ t('Here can be your description') }
															rows="4"
															type="textarea"
															name="description"
															value={ formik.values.description }
															onChange={ handleChange }
														/>
                            { formik.errors.description && formik.touched.description && (
                              <FormText color="danger">
                                { formik.errors.description }
                              </FormText>
                            ) }
													</FormGroup>
												</Col>
											</Row> */}
                            </Row>
                          </>
                        ) : null}

                        {/* {businessType == "beach" ||
                        (option &&
                          option.businessSettings.length > 0 &&
                          option.businessSettings[0].guaranteed_reservation) ? (
                          <Row className="mt-4">
                            <Col md="12">
                              <h6>{t("acceptStripeToS")}</h6>
                            </Col>
                            <Col md="12">
                              <div style={{ marginLeft: "1.25rem" }}>
                                <FormGroup
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 0,
                                  }}
                                >
                                  <Input
                                    style={{ width: 16, height: 16 }}
                                    type="checkbox"
                                    disabled={acceptedToS}
                                    onChange={() =>
                                      setAcceptedToS(!acceptedToS)
                                    }
                                    checked={acceptedToS}
                                  />
                                  <span style={{ marginTop: 4, marginLeft: 4 }}>
                                    {" "}
                                    {t("stripeToS")}
                                  </span>
                                </FormGroup>
                              </div>
                              <hr />
                            </Col>
                          </Row>
                        ) : null} */}
                      </Col>
                    </Row>
                  </CardBody>
                  <div className="d-flex justify-content-end p-4">
                    <Button
                      disabled={
                        business.savingStripeAccount ||
                        business.filterBusinessList.stripe_approved ||
                        disableSave
                      }
                      style={{ background: "#609" }}
                      type="submit"
                      className="m-0 save-btn-blue save-light-button"
                      color="success"
                    >
                      {t("Save")}
                    </Button>
                    {businessType == "beach" ||
                      (option &&
                        option.businessSettings.length > 0 &&
                        option.businessSettings[0].guaranteed_reservation) ? (
                      <>
                        &nbsp; &nbsp;
                        <Button
                          disabled={
                            business.filterBusinessList.stripe_account_id ||
                            business.savingStripeAccount ||
                            !existentIdCardFilename ||
                            !business.filterBusinessList.country_id ||
                            !business.filterBusinessList.city_id ||
                            !business.filterBusinessList.zipcode ||
                            !existentAdditionalDocumentFilename ||
                            !companyIdentificationFilename ||
                            !business.filterBusinessList.currency ||
                            !business.filterBusinessList
                              .representative_first_name ||
                            !business.filterBusinessList
                              .representative_last_name ||
                            !business.filterBusinessList.bank_account ||
                            !business.filterBusinessList.location_name ||
                            !business.filterBusinessList.representative_phone ||
                            !business.filterBusinessList.cui_number ||
                            !business.filterBusinessList.reg_com_number ||
                            !business.filterBusinessList.vat_number
                          }
                          style={{ background: "#609" }}
                          type="button"
                          onClick={_handleSubmitCallback}
                          className="m-0 save-btn-blue save-light-button"
                          color="success"
                        >
                          {business.filterBusinessList.stripe_account_id
                            ? t("update stripe account")
                            : t("create stripe account")}
                        </Button>
                      </>
                    ) : null}
                  </div>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <div>
              {business.filterBusinessList.type == "beach" ||
                business.filterBusinessList.type == "pool" ? (
                <div>
                  {!checkResultData.businessProfileStatus && (
                    <p className="mb-0">
                      {t("You have to set Business Profile.")}
                    </p>
                  )}
                  {checkResultData.businessPlan == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Plan.")}
                    </p>
                  )}
                  {checkResultData.businessYear == 0 && (
                    <p className="mb-0">
                      {t("You have to set Activity Period.")}
                    </p>
                  )}
                  {checkResultData.businessWeek == 0 && (
                    <p className="mb-0">{t("You have to set Working Hour.")}</p>
                  )}
                  {checkResultData.businessElement == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Grid.")}
                    </p>
                  )}
                  {!checkResultData.businessZoneStatus && (
                    <p className="mb-0">
                      {t("You have to set Business Zone.")}
                    </p>
                  )}
                  {!checkResultData.businessLocationStatus && (
                    <p className="mb-0">
                      {t("You have to set Business Location.")}
                    </p>
                  )}
                  {checkResultData.businessPhotos == 0 && (
                    <p className="mb-0">
                      {t("You have to set Location Photos.")}
                    </p>
                  )}
                  {checkResultData.businessRules == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Rule.")}
                    </p>
                  )}
                  {checkResultData.businessFacilities == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Facilities.")}
                    </p>
                  )}
                  {checkResultData.price == 0 && (
                    <p className="mb-0">
                      {t("You have to set Price Planner.")}
                    </p>
                  )}
                  {checkResultData.businessUser == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Employee.")}
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  {!checkResultData.businessProfileStatus && (
                    <p className="mb-0">
                      {t("You have to set Business Profile.")}
                    </p>
                  )}
                  {checkResultData.businessPlan == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Plan.")}
                    </p>
                  )}
                  {checkResultData.businessYear == 0 && (
                    <p className="mb-0">
                      {t("You have to set Activity Period.")}
                    </p>
                  )}
                  {checkResultData.businessSettings == 0 && (
                    <p className="mb-0">{t("You have to set Slot Intervals.")}</p>
                  )}
                  {checkResultData.businessWeek == 0 && (
                    <p className="mb-0">{t("You have to set Working Hour.")}</p>
                  )}
                  {checkResultData.businessElement == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Grid.")}
                    </p>
                  )}
                  {!checkResultData.businessLocationStatus && (
                    <p className="mb-0">
                      {t("You have to set Business Location.")}
                    </p>
                  )}
                  {checkResultData.businessPhotos == 0 && (
                    <p className="mb-0">
                      {t("You have to set Location Photos.")}
                    </p>
                  )}
                  {checkResultData.businessRules == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Rule.")}
                    </p>
                  )}
                  {checkResultData.businessFacilities == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Facilities.")}
                    </p>
                  )}
                  {checkResultData.businessUser == 0 && (
                    <p className="mb-0">
                      {t("You have to set Business Employee.")}
                    </p>
                  )}
                </div>
              )}
              <Button color="success" onClick={handleClose}>
                {t("OK")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

export default BusinessProfile;

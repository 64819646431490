import { actionTypes } from './action';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const reducer = persistReducer(
	{
		storage,
		key: 'plan',
	},
	(state = initState, action) => {
		// const newState = { ...state };
		switch (action.type) {
			case actionTypes.INITIAL_STATE:
				return { ...state };
			case actionTypes.SET_PLANS:
				return {
					...state,
					plans: action.value,
				};
			case actionTypes.SET_ACTIVE_PLAN:
				return {
					...state,
					selectedPlan: action.value,
				};
			default:
				return state;
		}
	}
);

export const initState = {
	initials: null,
	plans: [],
	selectedPlan: null,
};
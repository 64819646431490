import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { saveEstimatedTime } from "../../../../redux/option/action";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const EstimatedTime = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const option = useSelector(({ option }) => option);


  const allOptions = [30, 45, 60, 90, 120, 180];

  const [estimatedTime, setEstimatedTime] = useState(30);
  const [sunbedCounts, setSunBedCounts] = useState(allOptions);

  useEffect(() => {
    if (option.businessSettings.length > 0) {
      const businessSettings = option.businessSettings?.[0] ?? {};
      setEstimatedTime(businessSettings.estimated_time);
      if (businessSettings?.booking_time_limit) {
        setSunBedCounts(
          allOptions.filter((data) => {
            return data % businessSettings?.booking_time_limit == 0;
          })
        );
      }
    } else {
      setEstimatedTime(30);
    }
  }, [option.businessSettings.length > 0 && option.businessSettings[0]]);

  const _changeSunbedCount = (e) => {
    const value = parseInt(e.target.value);

    setEstimatedTime(value);
    dispatch(saveEstimatedTime(value));
  };

  const _renderSunbedCount = () => {
    return sunbedCounts.map((sunbedCount) => (
      <DropdownItem
        key={sunbedCount}
        value={sunbedCount}
        onClick={_changeSunbedCount}
      >
        {sunbedCount} {t("minute")}
      </DropdownItem>
    ));
  };

  return (
    <>
      <Card className="options-card p-3 pb-4">
        <Row>
          <Col md="10">
            <h5 className="option-title-main">{t("estimated_time")}</h5>
            <p className="option-title-sub">
              {t("estimated_time_description")}
            </p>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div className="d-flex flex-wrap" style={{ gap: "12px" }}>
              {sunbedCounts.map((sunbedCount, i) => (
                <FormGroup check className="form-check-button">
                  <Label check for={`${sunbedCount}min`}>
                    <Input
                      checked={estimatedTime === sunbedCount}
                      value={sunbedCount}
                      id={`${sunbedCount}min`}
                      name={`${sunbedCount}min`}
                      type="radio"
                      onChange={_changeSunbedCount}
                    />
                    <span className="form-button">
                      {sunbedCount} {t("minute")}{" "}
                      <span className="form-check-sign" />
                    </span>
                  </Label>
                </FormGroup>
              ))}
            </div>
          </Col>
        </Row>
      </Card>

      <div className="card-divider-line"></div>
    </>
  );
};

export default EstimatedTime;

import { actionTypes } from './action';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const reducer = (
	{
		storage,
		key: 'loader',
	},
	(state = initState, action) => {
		switch(action.type) {
			case actionTypes.START_LOADING:
				return { ...state,loading:true };
			case actionTypes.STOP_LOADING:
					return { ...state,loading:false };
			default:
				return state;
		}
	}
);

export const initState = {
	loading : false
};
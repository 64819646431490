import moment from 'moment';
import React, { forwardRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Invoice from './Invoice';
const GenerateMultipleInvoices = ({ invoices }, ref) => {
    return (
        <div ref={ref}>{
            invoices.map(invoice => (
                <Invoice invoice={invoice} ></Invoice>
            ))
        }</div>
    );
};
export default forwardRef(GenerateMultipleInvoices);
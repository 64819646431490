import React from "react";
import Datetime from "react-datetime";

export default function DateTime(props) {
  return (
    <Datetime
      dateFormat={props.dateFormat}
      timeFormat={props.timeFormat}
      value={props.value}
      timeConstraints={props.timeConstraints}
      inputProps={props.inputProps}
      onChange={props.onChange}
      onFocus={props.onFocus}
    />
  );
}

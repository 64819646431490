import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import PanelHeader from "../../../components/PanelHeader/PanelHeader";
import {
  createElementInformation,
  getAllElement,
  getElements,
  getZones,
  lastElementAction,
  lastMoveElementAction,
  previousElementAction,
  saveElements,
  saveZoomAreaValue,
  zoomRateAction,
} from "../../../redux/element/action";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Layer, Rect, Stage, Transformer } from "react-konva";
import Konva from "konva";
import { URLImage } from "./components/URLImage";
import {
  findNewElementPosition,
  getElementDistance,
  getElementItem,
  moveElement,
} from "./utils/utils";
import SetNumber from "./GridComponent/SetNumber";
import SetZone from "./GridComponent/SetZone";
import { checkIfAllPricesAreSet } from "../../../redux/price/action.js";
import beachImage from "../../../assets/img/beach.jpg";
import restaurantImage from "../../../assets/img/restaurant.jpg";
import { createNotification } from "../../../utils/utils";
import { startLoading, stopLoading } from "../../../redux/loader/action";
import { getZone } from "../../../redux/grid/action";
import { useBeforeunload } from "react-beforeunload";
import confirm from "reactstrap-confirm";
import fullscreenIcon from "../../../assets/img/fullscreen.svg";

const Grid2 = ({ savedStatus, setSavedStatus }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storeElement = useSelector(({ element }) => element);
  storeElement.zoomRate = 1;
  const rate = useSelector(({ element }) => element.zoomRate);
  const gridStore = useSelector(({ grid }) => grid);
  const business = useSelector(({ business }) => business);
  const [changeStatus, setChangeStatus] = useState(false);
  const [activeDragElement, setActiveDragElement] = useState(null);
  const [elements, setElements] = useState([]);

  const [movedLastElements, setMovedLastElements] = useState([]);
  const [elementPaddingWidth, setElementPaddingWidth] = useState(10);
  const [elementPaddingHeight, setElementPaddingHeight] = useState(0);
  const [elementArrangeType, setElementArrangeType] = useState("horizontal");
  const [elementRotate, setElementRotate] = useState(0);
  const [elementIcon, setElementIcon] = useState(0);
  const [isMulti, setIsMulti] = useState(false);
  const [isResizable, setIsResizable] = useState(false);

  const [totalStatus, setTotalStatus] = useState(true);
  const [zoomRate, setZoomRate] = useState(1);
  const [value, setValue] = useState(80);
  const [zoomAreaValue, setZoomAreaValue] = useState(1);
  //const [savedStatus, setSavedStatus] = useState(true);
  const [zoomInStatus, setZoomInStatus] = useState("");
  const [states, setStates] = useState({
    modalDemoRule: false,
  });
  const [zoneStatus, setZoneStatus] = useState(false);
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState({});
  const [fixed_class, setfixed_class] = useState("fixed-item");
  const [isFixed, setIsFixed] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  useEffect(() => {
    dispatch(getAllElement());
  }, [business.filterBusinessList]);

  useEffect(() => {
    dispatch(getZone());
  }, [business.filterBusinessList]);

  useEffect(() => {
    if (storeElement.resultElements && storeElement.allElement) {
      let arr = [];
      let lastElementArr = [];
      const devine = 1;

      storeElement.resultElements.map((item, index) => {
        const filterResult = storeElement.allElement.filter((ele) => {
          return ele.id === item.element_id;
        });
        if (filterResult.length > 0) {
          arr.push({
            id: `${item.id}`,
            elementId: item.element_id,
            h: filterResult[0].height,
            w: filterResult[0].width,
            moved: false,
            number: item.position.number,
            src: `/element/${item.element_id}.png`,
            static: false,
            x: item.position.x * zoomRate,
            y: item.position.y * zoomRate,
            r: item.rotate_angle ? item.rotate_angle : 0,
            zone_id: item.zone_id,
            is_extra_element: item.position.is_extra_element,
            is_resizable: item.position.is_resizable,
            scaleX: item.position.scaleX,
            scaleY: item.position.scaleY,
          });
        }
      });

      arr = arr.filter((el) => {
        return el.zone_id === selectedZone.id;
      });

      setElements(arr);
      dispatch(saveElements(arr));
      if (storeElement.lastMoved2) {
        var filterArray2 = arr.filter(
          (ele) => ele.id == storeElement.lastMoved2
        );
        if (filterArray2[0] == undefined) {
        } else {
          lastElementArr.push(filterArray2[0]);
        }
      }
      if (storeElement.lastMoved1) {
        var filterArray1 = arr.filter(
          (ele) => ele.id == storeElement.lastMoved1
        );
        if (filterArray1[0] == undefined) {
        } else {
          lastElementArr.push(filterArray1[0]);
        }
      }
      if (lastElementArr.length > 0) {
        dispatch(lastMoveElementAction(lastElementArr));
        let getDistance = getElementDistance(
          lastElementArr,
          elements,
          lastElementArr[lastElementArr.length - 1]
        );
        setElementPaddingWidth(getDistance[0]);
        setElementPaddingHeight(getDistance[2]);
        setElementRotate(getDistance[1]);
        setElementArrangeType(getDistance[3]);
        setActiveElementNumber(storeElement.lastMoved1);
      }
      const zoneFilterResult = storeElement.resultElements.filter(
        (ele) => ele.zone_id == null
      );
      if (zoneFilterResult.length > 0) {
        setZoneStatus(true);
      } else {
        setZoneStatus(false);
      }
    }
  }, [storeElement.resultElements, storeElement.allElement]);

  useEffect(() => {
    if (gridStore.zones && gridStore.zones.length > 0) {
      setZones(gridStore.zones);
      setSelectedZone(gridStore.zones[0]);
    }
  }, [gridStore.zones]);
  useEffect(() => {
    if (selectedZone) {
      setMovedLastElements([]);
      setElementArrangeType("horizontal");
      dispatch(startLoading());
      dispatch(getElements()).then((data) => {
        dispatch(stopLoading());
      });
    }
  }, [selectedZone]);

  ////////////////////
  const dragElement = useRef();
  const stageRef = useRef();
  const layerRef = useRef();
  const trRef = useRef();
  const [activeElementNumber, setActiveElementNumber] = useState(0);
  const [state, setState] = useState({
    openedCollapses: ["collapseOne"],
    hTabs: "ht1",
    vTabs: "vt1",
    vTabsIcons: "vti1",
    pageSubcategories: "ps1",
  });
  const placeholder = () => {
    if (!activeDragElement) {
      return null;
    }
    activeDragElement.placeholder = true;
    return <URLImage draggable="true" element={activeDragElement} />;
  };

  const lastMoveElement = (element, actionType) => {
    let arr = [];
    arr = movedLastElements;
    if (actionType == "onDrop") {
      if (arr.length == 0) {
        arr.push(element);
      }
      if (arr.length == 1) {
        let elementIndex = -1;
        arr.map((item, index) => {
          if (item.id === element.id) {
            elementIndex = index;
          }
        });
        if (elementIndex != -1) {
          arr.push(element);
          arr.splice(elementIndex, 1);
        } else {
          arr.push(element);
        }
      }
      if (arr.length == 2) {
        let elementIndex = -1;
        arr.map((item, index) => {
          if (item.id === element.id) {
            elementIndex = index;
          }
        });
        if (elementIndex != -1) {
          arr.push(element);
          arr.splice(elementIndex, 1);
        } else {
          arr.push(element);
          arr.splice(0, 1);
        }
      }
    }
    if (actionType == "onDragEnd" || actionType == "transFormEnd") {
      if (arr.length > 0) {
        arr.map((item, index) => {
          if (item.id == element.id) {
            arr[index].r = element.r;
            arr[index].x = element.x;
            arr[index].y = element.y;
          }
        });
      }
    }
    if (arr.length == 1) {
      dispatch(lastElementAction(arr[0].id));
    }
    if (arr.length == 2) {
      dispatch(lastElementAction(arr[1].id));
      dispatch(previousElementAction(arr[0].id));
    }
    setMovedLastElements(arr);
    dispatch(lastMoveElementAction(arr));
    return arr;
  };
  const [selectedId, selectShape] = useState(null);
  const [nodesArray, setNodes] = useState([]);
  const selectionRectRef = useRef();
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });
  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
      trRef.current.nodes([]);
      setNodes([]);
      // layerRef.current.remove(selectionRectangle);
    }
  };
  const [selectStatus, setSelectStatus] = useState(false);
  const updateSelectionRect = () => {
    const node = selectionRectRef.current;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(0, 161, 255, 0.3)",
    });
    node.getLayer().batchDraw();
  };

  const oldPos = React.useRef(null);
  const onMouseDown = (e) => {
    const isElement = e.target.findAncestor(".elements-container");
    const isTransformer = e.target.findAncestor("Transformer");
    if (isElement || isTransformer) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.visible = true;
    selection.current.x1 = pos.x;
    selection.current.y1 = pos.y;
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };
  const onMouseMove = (e) => {
    if (!selection.current.visible) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };
  const onMouseUp = () => {
    oldPos.current = null;
    if (!selection.current.visible) {
      return;
    }
    const selBox = selectionRectRef.current.getClientRect();
    const elements = [];
    layerRef.current.find(".rectangle").forEach((elementNode) => {
      const elBox = elementNode.getClientRect();
      elBox.x *= zoomRate;
      elBox.y *= zoomRate;
      if (Konva.Util.haveIntersection(selBox, elBox)) {
        elements.push(elementNode);
        setSelectStatus(true);
      }
    });
    if (trRef.current) {
      trRef.current.nodes(elements);
      if (trRef && trRef.current) {
        if (trRef.current._nodes.length > 1) {
          setIsMulti(true);
          setIsResizable(false);
        } else {
          setIsMulti(false);
          setIsResizable(
            trRef.current._nodes[0] && trRef.current._nodes[0].attrs.resizable
          );
          setElementIcon(
            trRef.current._nodes[0]
              ? trRef.current._nodes[0].attrs.width
              : elementIcon
          );
        }
      }
    }
    selection.current.visible = false;
    Konva.listenClickTap = false;
    updateSelectionRect();
  };

  //////////////////////
  useEffect(() => {
    if (trRef && trRef.current) {
      var buttons = {
        rotater: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><title>box-configurator-rotate</title><circle cx="8" cy="8" r="8" style="fill:#fff"/><path d="M0.9,0.5c0.1,0,0.3,0.1,0.3,0.3L1.1,2.9c1-1.4,2.6-2.4,4.5-2.4c2.9,0,5.3,2.4,5.3,5.3c0,2.9-2.4,5.3-5.3,5.3c-1.4,0-2.6-0.5-3.6-1.4c-0.1-0.1-0.1-0.3,0-0.4L2.3,9c0.1-0.1,0.3-0.1,0.4,0c0.7,0.7,1.7,1.1,2.8,1.1c2.3,0,4.2-1.9,4.2-4.2S7.8,1.7,5.5,1.7c-1.7,0-3.2,1-3.8,2.5l2.7-0.1c0.1,0,0.3,0.1,0.3,0.3v0.6c0,0.1-0.1,0.3-0.3,0.3H0.3C0.1,5.2,0,5.1,0,4.9V0.8c0-0.1,0.1-0.3,0.3-0.3H0.9z"/></svg>',
          shape: trRef.current.findOne(".rotater"),
        },
        top_left: {
          path: '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><title>box-configurator-delete</title><circle cx="16" cy="16" r="16" style="fill:#fff"/><path d="M10.24,1.08v.66a.39.39,0,0,1-.36.36H1.12a.39.39,0,0,1-.36-.36V1.08A.39.39,0,0,1,1.12.72H3.64L3.82.3A.52.52,0,0,1,4.24,0h2.4a.61.61,0,0,1,.48.3L7.3.72H9.82C10.06.78,10.24.9,10.24,1.08ZM1.42,2.82h8.1V9.91a1.05,1.05,0,0,1-1,1H2.44a1.05,1.05,0,0,1-1-1ZM3.1,9.19a.39.39,0,0,0,.36.36.39.39,0,0,0,.36-.36V4.44a.39.39,0,0,0-.36-.36.39.39,0,0,0-.36.36Zm2,0a.36.36,0,0,0,.72,0V4.44a.36.36,0,1,0-.72,0Zm2,0a.36.36,0,0,0,.72,0V4.44a.36.36,0,0,0-.72,0Z"/></svg>',
          shape: trRef.current.findOne(".top-left"),
        },
      };
      layerRef.current.find(".rotater-icon").destroy();
      for (var button in buttons) {
        var shape = buttons[button].shape;
        var selector = button.replace("_", "-");
        var icon = new Konva.Path({
          fill: "white",
          data: buttons[button].path,
          name: selector + "-icon",
        });
        var deleteButton = new Konva.Circle({
          radius: 12,
          fill: "transparent",
        });
        icon.position(shape.position());
        // if (selector == "rotater") {
        //   trRef.current.add(icon);
        //   icon.x(shape.x() + elementIcon / 14 - 12);
        //   icon.y(shape.y() - 5.25);
        // }
        if (selector == "top-left") {
          deleteButton.x(shape.x() - 16);
          deleteButton.y(shape.y() - 16);
          icon.x(shape.x() - 22.25);
          icon.y(shape.y() - 21.25);
        }
        if (selector == "top-left") {
          trRef.current.add(icon);
          trRef.current.add(deleteButton);
          shape.listening(false);
          deleteButton.on("click", (e) => {
            var array = [...elements];
            var anotherOne = trRef.current._nodes;
            var filteredArray = array.filter(function (array_el) {
              return (
                anotherOne.filter(function (anotherOne_el) {
                  return anotherOne_el.attrs.id == array_el.id;
                }).length == 0
              );
            });
            setElements(filteredArray);
            // setChangeStatus(true)
            setSelectStatus(false);
            const { status, weekHourData } = gridStore;
            if (!status || weekHourData.length <= 0) {
              return null;
            }
            dispatch(saveElements(filteredArray));
            // clearInterval(myVar);
            if (totalStatus) {
              dispatch(startLoading());
              dispatch(createElementInformation(selectedZone.id))
                .then((success) => {
                  if (success) {
                    setTotalStatus(true);
                  }
                  dispatch(stopLoading());
                })
                .catch((errors) => {
                  setTotalStatus(false);
                });
            }
          });
        }
      }
    }
  }, [elementIcon, selectStatus]);
  const doubleClickAction = (id, isExtra, isResizable) => (e) => {
    setSavedStatus(false);
    selectShape(null);
    e.preventDefault();
    // register event position
    stageRef.current.setPointersPositions(e);
    if (elementPaddingHeight > 1000) {
      setElementPaddingHeight(10);
    }
    const droppedElement = findNewElementPosition(
      elements,
      storeElement.allElement,
      stageRef.current.getPointerPosition(),
      id,
      activeElementNumber,
      elementPaddingWidth,
      elementRotate,
      elementPaddingHeight,
      elementArrangeType,
      selectedZone,
      isExtra,
      isResizable,
      business?.businessData?.type ?? ""
    );

    if (droppedElement.x > stageRef.current.attrs.width) {
      droppedElement.x = 10;
    }

    if (droppedElement.y > stageRef.current.attrs.height) {
      droppedElement.y = 10;
    }
    droppedElement.zone_id = selectedZone.id;
    setElements(elements.concat([droppedElement]));
    setChangeStatus(true);
    // clearInterval(myVar);
    dispatch(saveElements(elements.concat([droppedElement])));
    setActiveElementNumber(droppedElement.id);
    setActiveDragElement(null);
    const getLastElements = lastMoveElement(droppedElement, "onDrop");
    const getDistance = getElementDistance(
      getLastElements,
      elements,
      droppedElement
    );
    setElementPaddingWidth(getDistance[0]);
    setElementPaddingHeight(getDistance[2]);
    setElementRotate(getDistance[1]);
    setElementArrangeType(getDistance[3]);
  };
  const grid = 15 * storeElement.zoomRate;
  const saveElementsAction = () => {
    const { status, weekHourData } = gridStore;
    if (!status || weekHourData.length <= 0) {
      createNotification({
        type: 'danger',
        title: !status ? t('Activity Period') : t('Working Hour Error'),
        message: !status ? t('Activity_Period_Error') : t('Working_Hour_Error'),
      });
      return null;
    }
    dispatch(startLoading());
    setSavedStatus(true);
    return dispatch(createElementInformation(selectedZone.id))
      .then((success) => {
        if (success) {
          checkIfAllPricesAreSet(business.filterBusinessList.id, dispatch);
        }
        dispatch(stopLoading());
      })
      .catch((errors) => {
        dispatch(stopLoading());
      });
  };
  const toggleModalDemoRule = () => {
    setStates({ ...states, modalDemoRule: !states.modalDemoRule });
  };
  const agreeSave = () => {
    setStates({ ...states, modalDemoRule: !states.modalDemoRule });
    saveElementsAction();
  };
  const canceleSave = () => {
    setStates({ ...states, modalDemoRule: !states.modalDemoRule });
    setSavedStatus(true);
  };

  const changeZone = async (zone) => {
    if (!savedStatus) {
      let result = await confirm({
        title: t("is_save_title"),
        message: t("is_save"),
      });
      if (!result) {
        setSelectedZone(zone);
      } else {
        saveElementsAction().then((data) => {
          setSelectedZone(zone);
        });
      }
    } else {
      setSelectedZone(zone);
    }
    setSelectStatus(false);
  };

  const listenScrollEvent = (e) => {
    setTimeout(() => {
      if (window.scrollY > 10) {
        setfixed_class("fixed-item fixed");
        setIsFixed(true);
      } else {
        setfixed_class("fixed-item");
        setIsFixed(false);
      }
    }, 100);
  };

  const toggleFullScreen = () => {
    if (!fullscreen) {
      setFullscreen(true);
      setfixed_class("fixed-item fullscreen-nav");
      setIsFixed(true);
    } else {
      setfixed_class("fixed-item");
      setIsFixed(false);
      setFullscreen(false);
    }
  };

  useEffect(() => {
    layerRef.current.scale({
      x: zoomRate,
      y: zoomRate,
    });
    layerRef.current.draw();
    window.addEventListener("scroll", listenScrollEvent);
  }, []);
  return (
    <Fragment>
      <div
        className="content grid-planner-container options-container"
        style={{
          backgroundColor: '#fff',
          display: 'block',
        }}
      >
        <div className="content grid-page">
          <div className="px-4 pt-4">
            <div className={`${fixed_class} ${fullscreen ? "fullscreen-nav" : ""}`}>
              {zones && zones.length > 1 ? (
                <Row
                  className="justify-content-center align-items-center zone-row"
                  style={{ minWidth: "200px" }}
                >
                  <Col style={{ minWidth: "200px" }} xs="12">
                    <h5 className="text-muted font-weight-bold mb-0 font-size-16 text-md-center">
                      {t("select zone")}
                    </h5>
                    <div className="text-center">
                      <span
                        id={`PopoverFocus`}
                        style={{
                          color:
                            selectedZone.color &&
                              selectedZone.color != "#fff"
                              ? selectedZone.color
                              : "#333",
                          fontWeight: "bold",
                        }}
                      >
                        <span
                          className="color-box"
                          style={{
                            backgroundColor: selectedZone.color
                              ? selectedZone.color
                              : "#fff",
                          }}
                        >
                          &nbsp;
                        </span>
                        {t(selectedZone.name)}
                      </span>
                      <UncontrolledPopover
                        placement="bottom"
                        target={`PopoverFocus`}
                        trigger="legacy"
                      >
                        <PopoverBody>
                          <div>
                            {zones.map((zone) => (
                              <span
                                key={zone.id}
                                className="color-box-block"
                                onClick={() => changeZone(zone)}
                              >
                                <span
                                  key={zone.color}
                                  className={`color-box ${zone.color == selectedZone.color
                                    ? "selected"
                                    : ""
                                    }`}
                                  style={{ backgroundColor: zone.color }}
                                >
                                  &nbsp;
                                </span>
                                {t(zone.name)}
                              </span>
                            ))}
                          </div>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </div>
                  </Col>
                </Row>
              ) : null}

              <Row
                className="justify-content-center align-items-center"
                style={{ minWidth: "700px" }}
              >
                <Row className="justify-content-start align-items-center">
                  <Col>
                    <h5 className="text-muted mb-0 font-size-16 text-center">
                      {t("1 seat")}
                    </h5>
                    <Row className="py-2 px-3 justify-content-center align-items-center">
                      <div className="d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center">
                        {storeElement.allElement.length > 0 &&
                          storeElement.allElement
                            .filter((item) => {
                              return (
                                item.type == "table" &&
                                item.static &&
                                !item.resizable
                              );
                            })
                            .sort((a, b) => {
                              return a.structure.seats && b.structure.seats
                                ? a.structure.seats > b.structure.seats
                                  ? 1
                                  : -1
                                : 1;
                            })
                            .map((item, index) => {
                              return (
                                <React.Fragment>
                                  <div className="p-1" key={index}>
                                    <div className="table-layout">
                                      <img
                                        alt="table"
                                        width={item.width / 1.3}
                                        src={`/element/${item.id}.png`}
                                        className="cursor"
                                        onDoubleClick={doubleClickAction(
                                          item.id,
                                          item.static,
                                          item.resizable
                                        )}
                                        draggable="true"
                                        onDragStart={(e) => {
                                          if (trRef && trRef.current) {
                                            trRef.current.nodes([]);
                                          }
                                          dragElement.current = {
                                            id: item.id,
                                            is_extra_element: item.static,
                                            is_resizable: item.resizable,
                                          };
                                          //e.target.src;
                                        }}
                                      />
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                      </div>
                    </Row>
                  </Col>
                  <Col>
                    <h5 className="text-muted mb-0 font-size-16 text-center">
                      {t("Resizable element")}
                    </h5>
                    <Row className="py-2 px-3 justify-content-center align-items-center">
                      <div className="d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center">
                        {storeElement.allElement.length > 0 &&
                          storeElement.allElement
                            .filter((item) => {
                              return (
                                (item.type == "table" ||
                                  item.type == "bar") &&
                                item.resizable
                              );
                            })
                            .sort((a, b) => {
                              return a.structure.seats && b.structure.seats
                                ? a.structure.seats > b.structure.seats
                                  ? 1
                                  : -1
                                : 1;
                            })
                            .map((item, index) => {
                              return (
                                <React.Fragment>
                                  <div className="p-1" key={index}>
                                    <div className="table-layout">
                                      <img
                                        alt="table"
                                        src={`/element/${item.id}.png`}
                                        width={item.width / 2}
                                        className="cursor mr-2"
                                        onDoubleClick={doubleClickAction(
                                          item.id,
                                          item.static,
                                          item.resizable
                                        )}
                                        draggable="true"
                                        onDragStart={(e) => {
                                          if (trRef && trRef.current) {
                                            trRef.current.nodes([]);
                                          }
                                          dragElement.current = {
                                            id: item.id,
                                            is_extra_element: item.static,
                                            is_resizable: item.resizable,
                                          };
                                          //e.target.src;
                                        }}
                                      />
                                      <div className="text-center mr-2">
                                        {t(item.type)}
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                      </div>
                    </Row>
                  </Col>
                </Row>
                <Col style={{ maxWidth: "550px" }}>
                  <h5 className="text-muted mb-0 font-size-16 text-center">
                    {t("Tables")}
                  </h5>
                  <Row className="py-2 px-3 justify-content-center align-items-center">
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-center align-items-center">
                      {storeElement.allElement.length > 0 &&
                        storeElement.allElement
                          .filter((item) => {
                            return (
                              item.type == "table" &&
                              !item.static &&
                              !item.resizable
                            );
                          })
                          .sort((a, b) => {
                            return a.structure.seats && b.structure.seats
                              ? a.structure.seats > b.structure.seats
                                ? 1
                                : -1
                              : 1;
                          })
                          .map((item, index) => {
                            return (
                              <React.Fragment>
                                <div className="p-1" key={index}>
                                  <div className="table-layout">
                                    <img
                                      alt="table"
                                      width={item.width / 1.3}
                                      src={`/element/${item.id}.png`}
                                      className="cursor mr-2 mr-md-5"
                                      onDoubleClick={doubleClickAction(
                                        item.id,
                                        item.static,
                                        item.resizable
                                      )}
                                      draggable="true"
                                      onDragStart={(e) => {
                                        if (trRef && trRef.current) {
                                          trRef.current.nodes([]);
                                        }
                                        dragElement.current = {
                                          id: item.id,
                                          is_extra_element: item.static,
                                          is_resizable: item.resizable,
                                        };
                                        //e.target.src;
                                      }}
                                    />
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                    </div>
                  </Row>
                </Col>
              </Row>
              <Row
                className="justify-content-center align-items-center row action-row"
                style={{ minWidth: "200px" }}
              >
                <Col md={12}>
                  <Nav
                    pills
                    className="grid-page-pills nav-pills-primary justify-content-center align-item-center"
                  >
                    {state.hTabs === "ht2" && (
                      <NavItem>
                        <NavLink
                          className={
                            state.hTabs === "ht1"
                              ? "active cursor"
                              : "cursor"
                          }
                          onClick={() => {
                            setState({ hTabs: "ht1" });
                            if (zoneStatus && state.hTabs == "ht3") {
                              setState({ hTabs: "ht3" });
                              alert(
                                "Please review the Set Zones section and set zones for the elements remaining"
                              );
                            } else {
                              setState({ hTabs: "ht1" });
                            }
                          }}
                        >
                          {t("create/edit/delete")}
                        </NavLink>
                      </NavItem>
                    )}
                    {state.hTabs === "ht1" && (
                      <NavItem
                        style={{
                          margin: "auto",
                          marginRight: "5px",
                        }}
                      >
                        <Button
                          className={
                            state.hTabs === "ht2"
                              ? "active cursor nav-link"
                              : "cursor nav-link"
                          }
                          onClick={() => {
                            if (savedStatus) {
                              setState({ hTabs: "ht2" });
                            } else {
                              setStates({
                                modalDemoRule: true,
                              });
                            }
                            if (zoneStatus && state.hTabs == "ht3") {
                              setState({ hTabs: "ht3" });
                              alert(
                                "Please review the Set Zones section and set zones for the elements remaining"
                              );
                            } else {
                              setState({ hTabs: "ht2" });
                            }
                          }}
                        >
                          {t("Set numbers")}
                        </Button>
                      </NavItem>
                    )}

                    {state.hTabs === "ht1" && (
                      <NavItem
                        style={{
                          margin: "auto",
                          marginRight: "5px",
                        }}
                      >
                        <Button
                          className="save-light-button"
                          disabled={savedStatus}
                          onClick={saveElementsAction}
                        >
                          {t("Save")}
                        </Button>
                      </NavItem>
                    )}
                  </Nav>
                </Col>
              </Row>
              {fullscreen ? (
                <button className="btn-close" onClick={toggleFullScreen}>
                  X
                </button>
              ) : (
                <button
                  className="btn-fullscreen"
                  onClick={toggleFullScreen}
                >
                  <img src={fullscreenIcon} alt="react-logo" />
                </button>
              )}
            </div>
            <div className={`${fullscreen ? "fullscreen fullscreen-view" : ""}`}>
              <Row>
                <Col>
                  <TabContent activeTab={state.hTabs}>
                    <TabPane tabId="ht1" className="pt-0">
                      <Row>
                        <Col md={12}>
                          <div
                            style={{ background: selectedZone.color }}
                            onDrop={(e) => {
                              selectShape(null);
                              e.preventDefault();
                              // register event position
                              stageRef.current.setPointersPositions(e);
                              const droppedElement =
                                findNewElementPosition(
                                  elements,
                                  storeElement.allElement,
                                  stageRef.current.getPointerPosition(),
                                  dragElement.current.id,
                                  activeElementNumber,
                                  elementPaddingWidth,
                                  elementRotate,
                                  elementPaddingHeight,
                                  elementArrangeType,
                                  selectedZone,
                                  dragElement.current.is_extra_element,
                                  dragElement.current.is_resizable,
                                  business?.businessData?.type ?? ""
                                );
                              setElements(
                                elements.concat([droppedElement])
                              );
                              setChangeStatus(true);
                              dispatch(
                                saveElements(
                                  elements.concat([droppedElement])
                                )
                              );
                              setActiveElementNumber(droppedElement.id);
                              setActiveDragElement(null);
                              const getLastElements = lastMoveElement(
                                droppedElement,
                                "onDrop"
                              );
                              const getDistance = getElementDistance(
                                getLastElements,
                                elements,
                                droppedElement
                              );
                              setElementPaddingWidth(getDistance[0]);
                              setElementPaddingHeight(getDistance[2]);
                              setElementRotate(getDistance[1]);
                              setElementArrangeType(getDistance[3]);
                            }}
                            onDragLeave={(e) => {
                              setActiveDragElement(null);
                            }}
                            onDragOver={(e) => {
                              setSavedStatus(false);
                              selectShape(null);
                              e.preventDefault();
                              stageRef.current.setPointersPositions(e);
                              const element = findNewElementPosition(
                                elements,
                                storeElement.allElement,
                                stageRef.current.getPointerPosition(),
                                dragElement.current.id,
                                activeElementNumber,
                                elementPaddingWidth,
                                elementRotate,
                                elementPaddingHeight,
                                elementArrangeType,
                                selectedZone,
                                dragElement.current.is_extra_element,
                                dragElement.current.is_resizable,
                                business?.businessData?.type ?? ""
                              );
                              const placeholder = {
                                w: element.w,
                                h: element.h,
                                x: element.x,
                                y: element.y,
                                r: element.r,
                                scaleX: element.scaleX,
                                scaleY: element.scaleY,
                                placeholder: true,
                                id: element.id,
                              };
                              setActiveDragElement(placeholder);
                            }}
                            className="create-element-style custom-shadow-class"
                          >
                            <Stage
                              width={window.innerWidth * zoomAreaValue}
                              height={1200 * zoomAreaValue}
                              ref={stageRef}
                              onMouseDown={onMouseDown}
                              onMouseUp={onMouseUp}
                              onMouseMove={onMouseMove}
                              onTouchStart={checkDeselect}
                            >
                              <Layer ref={layerRef}>
                                {placeholder()}
                                {elements.map((element, index) => {
                                  return (
                                    <URLImage
                                      key={index}
                                      elementIndex={index}
                                      onDragStart={(e, id) => {
                                        const element = getElementItem(
                                          elements,
                                          id
                                        );
                                        const placeholder = {
                                          w: element.w,
                                          h: element.h,
                                          x: element.x,
                                          y: element.y,
                                          r: element.r,
                                          scaleX: element.scaleX,
                                          scaleY: element.scaleY,
                                          placeholder: true,
                                          id: id,
                                        };
                                        if (element) {
                                          setActiveDragElement(
                                            placeholder
                                          );
                                        } else {
                                        }
                                      }}
                                      onDragMove={(e, id) => {
                                        setSavedStatus(false);
                                        if (trRef && trRef.current) {
                                          trRef.current.nodes([]);
                                        }
                                        let element = getElementItem(
                                          elements,
                                          id
                                        );
                                        const items = moveElement(
                                          elements,
                                          element,
                                          Math.round(e.target.x()) > 0
                                            ? Math.round(
                                              e.target.x() / grid
                                            ) * grid
                                            : 10,
                                          Math.round(e.target.y()) > 0
                                            ? Math.round(
                                              e.target.y() / grid
                                            ) * grid
                                            : 10,
                                          true,
                                          true,
                                          "horizontal"
                                        );
                                        const placeholder = {
                                          w: element.w,
                                          h: element.h,
                                          x: element.x,
                                          y: element.y,
                                          r: element.r,
                                          scaleX: element.scaleX,
                                          scaleY: element.scaleY,
                                          // src:element.src,
                                          placeholder: true,
                                          id: id,
                                        };
                                        if (element && !isMulti) {
                                          setActiveDragElement(
                                            placeholder
                                          );
                                        } else {
                                        }
                                      }}
                                      onDragEnd={(e, id) => {
                                        setSavedStatus(false);
                                        let element = getElementItem(
                                          elements,
                                          id
                                        );
                                        setActiveElementNumber(
                                          element.id
                                        );
                                        const items = moveElement(
                                          elements,
                                          element,
                                          Math.round(e.target.x()) > 0
                                            ? Math.round(
                                              e.target.x() / grid
                                            ) * grid
                                            : 10,
                                          Math.round(e.target.y()) > 0
                                            ? Math.round(
                                              e.target.y() / grid
                                            ) * grid
                                            : 10,
                                          true,
                                          true,
                                          "horizontal"
                                        );
                                        const getLastElements =
                                          lastMoveElement(
                                            element,
                                            "onDragEnd"
                                          );
                                        const getDistance =
                                          getElementDistance(
                                            getLastElements,
                                            elements,
                                            element
                                          );
                                        setElementPaddingWidth(
                                          getDistance[0]
                                        );
                                        setElementPaddingHeight(
                                          getDistance[2]
                                        );
                                        setElementArrangeType(
                                          getDistance[3]
                                        );
                                        setElementRotate(getDistance[1]);
                                        setChangeStatus(true);
                                        setElements([]);
                                        setTimeout(() => {
                                          setElements(items);
                                          dispatch(saveElements(items));
                                        }, 10);
                                        setActiveDragElement(null);
                                        if (trRef && trRef.current) {
                                          trRef.current.detach();
                                        }
                                      }}
                                      onTransFormEnd={(e, id) => {
                                        setSavedStatus(false);
                                        let element = getElementItem(
                                          elements,
                                          id
                                        );
                                        let arr = [...elements];
                                        arr.map((item, index) => {
                                          if (item.id === id) {
                                            arr[index].x = Math.round(
                                              e.target.x()
                                            );
                                            arr[index].y = Math.round(
                                              e.target.y()
                                            );
                                            arr[index].r = Math.round(
                                              e.target.rotation()
                                            );
                                            arr[index].scaleX =
                                              e.target.scaleX();
                                            arr[index].scaleY =
                                              e.target.scaleY();
                                          }
                                        });
                                        setElements(arr);
                                        dispatch(saveElements(arr));
                                        setChangeStatus(true);
                                        const getLastElements =
                                          lastMoveElement(
                                            element,
                                            "transFormEnd"
                                          );
                                        const getDistance =
                                          getElementDistance(
                                            getLastElements,
                                            elements,
                                            element
                                          );
                                        setElementPaddingWidth(
                                          getDistance[0]
                                        );
                                        setElementPaddingHeight(
                                          getDistance[2]
                                        );
                                        setElementRotate(getDistance[1]);
                                        setElementArrangeType(
                                          getDistance[3]
                                        );
                                      }}
                                      draggable="true"
                                      element={element}
                                      isSelected={
                                        element.id === selectedId
                                      }
                                      onSelect={(e) => {
                                        if (e.current !== undefined) {
                                          let temp = nodesArray;
                                          e.current.type = "table";
                                          if (
                                            !nodesArray.includes(
                                              e.current
                                            )
                                          ) {
                                            temp.push(e.current);
                                          }
                                          setNodes(temp);
                                          trRef.current.nodes(nodesArray);
                                          trRef.current.nodes(nodesArray);
                                          trRef.current
                                            .getLayer()
                                            .batchDraw();
                                        }
                                        selectShape(element.id);
                                      }}
                                    />
                                  );
                                })}
                                {selectStatus &&
                                  (!isMulti && isResizable ? (
                                    <Transformer
                                      borderDash={[4, 3]}
                                      name="transformer"
                                      anchorCornerRadius={5}
                                      anchorStrokeWidth={15}
                                      borderStrokeWidth={1}
                                      padding={16}
                                      keepRatio={false}
                                      rotateEnabled={false}
                                      anchorFill="#29A9E5"
                                      rotationSnaps={[0, 90, 180, 270]}
                                      rotateAnchorOffset={0}
                                      ref={trRef}
                                    />
                                  ) : (
                                    <Transformer
                                      borderDash={[4, 3]}
                                      name="transformer"
                                      anchorCornerRadius={5}
                                      anchorStrokeWidth={15}
                                      borderStrokeWidth={1}
                                      padding={16}
                                      keepRatio={false}
                                      rotateEnabled={false}
                                      enabledAnchors={["top-left"]}
                                      anchorFill="#29A9E5"
                                      rotationSnaps={[0, 90, 180, 270]}
                                      rotateAnchorOffset={0}
                                      ref={trRef}
                                    />
                                  ))}
                                <Rect
                                  fill="rgba(0,0,255,0.5)"
                                  ref={selectionRectRef}
                                />
                              </Layer>
                            </Stage>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="ht2">
                      <SetNumber
                        zoomRate={zoomRate}
                        zoomAreaValue={zoomAreaValue}
                        selectedZone={selectedZone}
                      />
                    </TabPane>
                    <TabPane tabId="ht3">
                      <SetZone
                        zoomRate={zoomRate}
                        zoomAreaValue={zoomAreaValue}
                      />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </div>
            {/* {state.hTabs === "ht1" && (
              <div className="d-flex justify-content-center">
                <Button
                  color="success"
                  disabled={savedStatus}
                  onClick={saveElementsAction}
                >
                  {t("Save")}
                </Button>
              </div>
            )} */}
          </div>
          <Modal isOpen={states.modalDemoRule} toggle={toggleModalDemoRule}>
            <ModalBody>
              <p className="bold">{t("is_save")}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                className="button"
                style={{ backgroundColor: "red" }}
                onClick={canceleSave}
              >
                {t("No")}
              </Button>
              <Button
                className="button"
                style={{ backgroundColor: "#7557f9" }}
                onClick={agreeSave}
              >
                {t("Yes")}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </Fragment>
  );
};
export default Grid2;

import free from "../assets/img/FREE.svg";
import incongnito from "../assets/img/INCOGNITO.svg";
import king from "../assets/img/KING.svg";

export const PlanPriceForBeach = [
  {
    id: 1,
    key:"BeachPlan",
    data: [
      {
        id: 3,
        primary_text_color: "#fff",
        primary_border_color: "#57A609",
        plan_title: "INCOGNITO",
        border_color: "#57a60a",
        plan_image: incongnito,
        plan_price: 59,
        plan_currency_symbol: "€",
        info: "infoBeach2",
        top_features: [
          {
            key: "FreeTrial",
            class: "text-red-500 italic",
          },
        ],
        plan_data: [
          {
            description: "Visibility",
            is_available: false,
          },
          {
            description: "Accept",
            is_available: false,
          },
          {
            description: "SetNotifications",
            is_available: true,
          },
          {
            description: "StoreReservations",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "DayOrRange",
            is_available: true,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "ProtocolDiscount",
            is_available: true,
          },
          {
            description: "Statistics",
            is_available: true,
          },
          {
            description: "MaxUsersUnlim",
            is_available: true,
          },
        ],
      },
      {
        id: 1,
        primary_text_color: "#FA5865",
        primary_border_color: "#FA5865",
        plan_title: "KING",
        border_color: "#fa5865",
        plan_image: king,
        plan_price: 49,
        plan_currency_symbol: "€",
        info: "infoBeach1",
        top_features: [
          {
            key: "CoastPerReservation",
            class: "color-black italic",
          },
        ],
        plan_data: [
          {
            description: "Visibility",
            is_available: true,
          },
          {
            description: "Accept",
            is_available: true,
          },
          {
            description: "SetNotifications",
            is_available: true,
          },
          {
            description: "StoreReservations",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "DayOrRange",
            is_available: true,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "ProtocolDiscount",
            is_available: true,
          },
          {
            description: "Statistics",
            is_available: true,
          },
          {
            description: "MaxUsers5",
            is_available: true,
          },
        ],
      },
      {
        id: 5,
        primary_text_color: "#4E4E57",
        primary_border_color: "#0b1b3559",
        plan_title: "FREE",
        plan_image: free,
        plan_price: 0,
        border_color: "#660ff2",
        plan_currency_symbol: "€",
        info: "infoBeach3",
        top_features: [
          {
            key: "CoastPerReservation",
            class: "color-black italic",
          },
        ],
        plan_data: [
          {
            description: "Visibility",
            is_available: true,
          },
          {
            description: "Accept",
            is_available: true,
          },
          {
            description: "SetNotifications",
            is_available: false,
          },
          {
            description: "StoreReservations",
            is_available: false,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "DayOrRange",
            is_available: false,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "ProtocolDiscount",
            is_available: false,
          },
          {
            description: "Statistics2",
            is_available: true,
          },
          {
            description: "MaxUsers2",
            is_available: true,
          },
        ],
      },
    ],
  },
];

export const PlanPriceForPool = [
  {
    id: 2,
    key:"Pool",
    data: [
      {
        id: 3,
        primary_text_color: "#fff",
        primary_border_color: "#57A609",
        plan_title: "INCOGNITO",
        border_color: "#57a60a",
        plan_image: incongnito,
        plan_price: 59,
        plan_currency_symbol: "€",
        info: "infoPool2",
        top_features: [
          {
            key: "FreeTrial",
            class: "text-red-500 italic",
          },
        ],
        plan_data: [
          {
            description: "Visibility",
            is_available: false,
          },
          {
            description: "Accept",
            is_available: false,
          },
          {
            description: "SetNotifications",
            is_available: true,
          },
          {
            description: "StoreReservations",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "DayOrRange",
            is_available: true,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "ProtocolDiscount",
            is_available: true,
          },
          {
            description: "Statistics",
            is_available: true,
          },
          {
            description: "MaxUsersUnlim",
            is_available: true,
          },
        ],
      },
      {
        id: 1,
        primary_text_color: "#FA5865",
        primary_border_color: "#FA5865",
        plan_title: "KING",
        border_color: "#fa5865",
        plan_image: king,
        plan_price: 49,
        plan_currency_symbol: "€",
        info: "infoPool1",
        top_features: [
          {
            key: "CoastPerReservation",
            class: "color-black italic",
          },
        ],
        plan_data: [
          {
            description: "Visibility",
            is_available: true,
          },
          {
            description: "Accept",
            is_available: true,
          },
          {
            description: "SetNotifications",
            is_available: true,
          },
          {
            description: "StoreReservations",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "DayOrRange",
            is_available: true,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "ProtocolDiscount",
            is_available: true,
          },
          {
            description: "Statistics",
            is_available: true,
          },
          {
            description: "MaxUsers5",
            is_available: true,
          },
        ],
      },
      {
        id: 5,
        primary_text_color: "#4E4E57",
        primary_border_color: "#0b1b3559",
        plan_title: "FREE",
        plan_image: free,
        plan_price: 0,
        border_color: "#660ff2",
        plan_currency_symbol: "€",
        info: "infoPool3",
        top_features: [
          {
            key: "CoastPerReservation",
            class: "color-black italic",
          },
        ],
        plan_data: [
          {
            description: "Visibility",
            is_available: true,
          },
          {
            description: "Accept",
            is_available: true,
          },
          {
            description: "SetNotifications",
            is_available: false,
          },
          {
            description: "StoreReservations",
            is_available: false,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "DayOrRange",
            is_available: false,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "ProtocolDiscount",
            is_available: false,
          },
          {
            description: "Statistics2",
            is_available: true,
          },
          {
            description: "MaxUsers2",
            is_available: true,
          },
        ],
      },
    ],
  },
];

export const PlanPriceForRestaurant = [
  {
    id: 3,
    key:"RestaurantPlan",
    data: [
      {
        id: 3,
        primary_text_color: "#57A609",
        primary_border_color: "#57A609",
        plan_title: "INCOGNITO",
        plan_image: incongnito,
        border_color: "#57a60a",
        plan_price: 99,
        plan_currency_symbol: "€",
        info: "infoRestaurant2",
        top_features: [
          {
            key: "FreeTrial",
            class: "text-red-500 italic",
          },
          {
            key: "IncludedSMS",
            class: "text-red-500 italic",
          },
        ],
        note_information: {
          key: "InfoSMS",
          class: "text-red-500 italic",
        },
        plan_data: [
          {
            description: "AddAgenda",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "SetNotifications",
            is_available: true,
          },
          {
            description: "ServerNotifications",
            is_available: true,
          },
          {
            description: "BirthdayNotifications",
            is_available: true,
          },
          {
            description: "ManualorBulkSMS",
            is_available: true,
          },
          {
            description: "StoreReservations",
            is_available: true,
          },
          {
            description: "ShowName",
            is_available: true,
          },
          {
            description: "NumberOfReservations",
            is_available: true,
          },
          {
            description: "ColitionBlinking",
            is_available: true,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "LateEditTime",
            is_available: true,
          },
          {
            description: "HistoryNotifications",
            is_available: true,
          },
          {
            description: "HistoryActions",
            is_available: true,
          },
          {
            description: "MonitorReservations",
            is_available: true,
          },
          {
            description: "Statistics",
            is_available: true,
          },
          {
            description: "MaxUsersUnlim",
            is_available: true,
          },
        ],
      },
      {
        id: 1,
        primary_text_color: "#FA5865",
        primary_border_color: "#0b1b3559",
        plan_title: "KING",
        plan_image: king,
        plan_price: 49,
        border_color: "#fa5865",
        plan_currency_symbol: "€",
        info: "infoRestaurant1",
        top_features: [
          {
            key: "CoastPerReservation",
            class: "color-black italic",
          },
          {
            key: "FreeTrial",
            class: "text-red-500 italic",
          },
          {
            key: "IncludedSMS",
            class: "text-red-500 italic",
          },
        ],
        note_information: {
          key: "InfoSMS",
          class: "text-red-500 italic",
        },
        plan_data: [
          {
            description: "Visibility",
            is_available: true,
          },
          {
            description: "Accept",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "AddAgenda",
            is_available: true,
          },
          {
            description: "SetNotifications",
            is_available: true,
          },
          {
            description: "ServerNotifications",
            is_available: true,
          },
          {
            description: "BirthdayNotifications",
            is_available: true,
          },
          {
            description: "ManualorBulkSMS",
            is_available: true,
          },
          {
            description: "StoreReservations",
            is_available: true,
          },
          {
            description: "ShowName",
            is_available: true,
          },
          {
            description: "NumberOfReservations",
            is_available: true,
          },
          {
            description: "ColitionBlinking",
            is_available: true,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "LateEditTime",
            is_available: true,
          },
          {
            description: "HistoryNotifications",
            is_available: true,
          },
          {
            description: "HistoryActions",
            is_available: true,
          },
          {
            description: "MonitorReservations",
            is_available: true,
          },
          {
            description: "Statistics",
            is_available: true,
          },
          {
            description: "MaxUsers5",
            is_available: true,
          },
        ],
      },
      {
        id: 5,
        primary_text_color: "#4E4E57",
        primary_border_color: "#0b1b3559",
        plan_title: "FREE",
        border_color: "#660ff2",
        plan_image: free,
        plan_price: 0,
        plan_currency_symbol: "€",
        info: "infoRestaurant3",
        top_features: [
          {
            key: "CoastPerReservation",
            class: "color-black italic",
          },
        ],
        plan_data: [
          {
            description: "Visibility",
            is_available: true,
          },
          {
            description: "Accept",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "AddAgenda",
            is_available: false,
          },
          {
            description: "SetNotifications",
            is_available: false,
          },
          {
            description: "ServerNotifications",
            is_available: false,
          },
          {
            description: "BirthdayNotifications",
            is_available: false,
          },
          {
            description: "ManualorBulkSMS",
            is_available: false,
          },
          {
            description: "StoreReservations",
            is_available: false,
          },
          {
            description: "ShowName",
            is_available: true,
          },
          {
            description: "NumberOfReservations",
            is_available: true,
          },
          {
            description: "ColitionBlinking",
            is_available: false,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "LateEditTime",
            is_available: true,
          },
          {
            description: "HistoryNotifications",
            is_available: true,
          },
          {
            description: "HistoryActions",
            is_available: true,
          },
          {
            description: "MonitorReservations",
            is_available: true,
          },
          {
            description: "Statistics2",
            is_available: true,
          },
          {
            description: "MaxUsers2",
            is_available: true,
          },
        ],
      },
    ],
  },
];

export const PlanPriceForClub = [
  {
    id: 3,
    key:"ClubPlan",
    data: [
      {
        id: 3,
        primary_text_color: "#57A609",
        primary_border_color: "#57A609",
        plan_title: "INCOGNITO",
        plan_image: incongnito,
        border_color: "#57a60a",
        plan_price: 99,
        plan_currency_symbol: "€",
        info: "infoClub2",
        top_features: [
          {
            key: "FreeTrial",
            class: "text-red-500 italic",
          },
          {
            key: "IncludedSMS",
            class: "text-red-500 italic",
          },
        ],
        note_information: {
          key: "InfoSMS",
          class: "text-red-500 italic",
        },
        plan_data: [
          {
            description: "AddAgenda",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "SetNotifications",
            is_available: true,
          },
          {
            description: "ServerNotifications",
            is_available: true,
          },
          {
            description: "BirthdayNotifications",
            is_available: true,
          },
          {
            description: "ManualorBulkSMS",
            is_available: true,
          },
          {
            description: "StoreReservations",
            is_available: true,
          },
          {
            description: "ShowName",
            is_available: true,
          },
          {
            description: "NumberOfReservations",
            is_available: true,
          },
          {
            description: "ColitionBlinking",
            is_available: true,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "LateEditTime",
            is_available: true,
          },
          {
            description: "HistoryNotifications",
            is_available: true,
          },
          {
            description: "HistoryActions",
            is_available: true,
          },
          {
            description: "MonitorReservations",
            is_available: true,
          },
          {
            description: "Statistics",
            is_available: true,
          },
          {
            description: "MaxUsersUnlim",
            is_available: true,
          },
        ],
      },
      {
        id: 1,
        primary_text_color: "#FA5865",
        primary_border_color: "#0b1b3559",
        plan_title: "KING",
        plan_image: king,
        plan_price: 49,
        border_color: "#fa5865",
        plan_currency_symbol: "€",
        info: "infoClub1",
        top_features: [
          {
            key: "CoastPerReservation",
            class: "color-black italic",
          },
          {
            key: "FreeTrial",
            class: "text-red-500 italic",
          },
          {
            key: "IncludedSMS",
            class: "text-red-500 italic",
          },
        ],
        note_information: {
          key: "InfoSMS",
          class: "text-red-500 italic",
        },
        plan_data: [
          {
            description: "Visibility",
            is_available: true,
          },
          {
            description: "Accept",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "AddAgenda",
            is_available: true,
          },
          {
            description: "SetNotifications",
            is_available: true,
          },
          {
            description: "ServerNotifications",
            is_available: true,
          },
          {
            description: "BirthdayNotifications",
            is_available: true,
          },
          {
            description: "ManualorBulkSMS",
            is_available: true,
          },
          {
            description: "StoreReservations",
            is_available: true,
          },
          {
            description: "ShowName",
            is_available: true,
          },
          {
            description: "NumberOfReservations",
            is_available: true,
          },
          {
            description: "ColitionBlinking",
            is_available: true,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "LateEditTime",
            is_available: true,
          },
          {
            description: "HistoryNotifications",
            is_available: true,
          },
          {
            description: "HistoryActions",
            is_available: true,
          },
          {
            description: "MonitorReservations",
            is_available: true,
          },
          {
            description: "Statistics",
            is_available: true,
          },
          {
            description: "MaxUsers5",
            is_available: true,
          },
        ],
      },
      {
        id: 5,
        primary_text_color: "#4E4E57",
        primary_border_color: "#0b1b3559",
        plan_title: "FREE",
        border_color: "#660ff2",
        plan_image: free,
        plan_price: 0,
        plan_currency_symbol: "€",
        info: "infoClub3",
        top_features: [
          {
            key: "CoastPerReservation",
            class: "color-black italic",
          },
        ],
        plan_data: [
          {
            description: "Visibility",
            is_available: true,
          },
          {
            description: "Accept",
            is_available: true,
          },
          {
            description: "GenerateQR",
            is_available: true,
          },
          {
            description: "AddAgenda",
            is_available: false,
          },
          {
            description: "SetNotifications",
            is_available: false,
          },
          {
            description: "ServerNotifications",
            is_available: false,
          },
          {
            description: "BirthdayNotifications",
            is_available: false,
          },
          {
            description: "ManualorBulkSMS",
            is_available: false,
          },
          {
            description: "StoreReservations",
            is_available: false,
          },
          {
            description: "ShowName",
            is_available: true,
          },
          {
            description: "NumberOfReservations",
            is_available: true,
          },
          {
            description: "ColitionBlinking",
            is_available: false,
          },
          {
            description: "ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "LateEditTime",
            is_available: true,
          },
          {
            description: "HistoryNotifications",
            is_available: true,
          },
          {
            description: "HistoryActions",
            is_available: true,
          },
          {
            description: "MonitorReservations",
            is_available: true,
          },
          {
            description: "Statistics2",
            is_available: true,
          },
          {
            description: "MaxUsers2",
            is_available: true,
          },
        ],
      },
    ],
  },
];

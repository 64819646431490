import React, { PureComponent } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import axios from 'axios';
import { calls } from '../../../utils/calls.js';
import API from '../../../redux/business/api.js';

class RequestRegister extends PureComponent {
	api = new API();

	constructor(props) {
		super(props);
		this.state = {
			pendingClients: [],
		};
	}

	componentDidMount() {
		axios(calls.getPendingClients())
			.then((result) => {
				let results = [...result.data].map((item) => {
					item.country = (item.country || {}).name;
					item.fullName = `${item.first_name || ''} ${item.last_name || ''}`;
					item.interested_in = (item.interested_in || []).map(interest => interest.value).join(', ');
					return item;
				});
				this.setState({ pendingClients: results });
			})
			.catch(() => { });
	}

	updateRegistrationStatus = (item, status) => () => {
		// make a reuqest to update to accept or reject
		axios(calls.updateClientStatus(item, status))
			.then(() => {
				this.setState(prevState => ({
					pendingClients: prevState.pendingClients.filter(pendingClient => pendingClient.id !== item.id),
				}));
			})
			.then(() => {
				if (status === 'Accept') {
					this.api.sendUserAcceptedEmail(item.id);
				} else {
					this.api.sendUserRejectedEmail(item.id);
				}
			}).catch(err => { });
	};

	render() {
		const { pendingClients } = this.state;
		return (
			<>
				<div className="content" style={{ marginTop: '2rem' }}>
					<Row>
						<Col md="12">
							<Card>
								<CardHeader className="px-4">
									<h5 className="title">Register Requests</h5>
								</CardHeader>
								<CardBody>
									<Table responsive>
										<thead className="text-primary">
											<tr>
												<th className="text-left">Full Name</th>
												<th className="text-left">Email</th>
												<th className="text-left">Position</th>
												<th className="text-left">Phone</th>
												<th className="text-left">Country</th>
												<th className="text-left">Interests</th>
												<th className="text-right">Actions</th>
											</tr>
										</thead>
										{pendingClients.length > 0 ? (
											<tbody>
												{pendingClients.map((item, index) => {
													return (
														<tr key={index}>
															<td className="text-left">{item.fullName}</td>
															<td className="text-left">{item.email}</td>
															<td className="text-left">{item.position}</td>
															<td className="text-left">{item.phone}</td>
															<td className="text-left">{item.country}</td>
															<td className="text-left">{item.interested_in}</td>
															<td className="text-right">
																<Button
																	className="btn-icon btns-mr-5"
																	color="success"
																	id="tooltip26024663"
																	size="sm"
																	type="button"
																	onClick={this.updateRegistrationStatus(item, 'Accept')}>
																	<i className="now-ui-icons ui-1_check" />
																</Button>
																<Button
																	className="btn-icon btns-mr-5"
																	color="success"
																	id="tooltip26024663"
																	size="sm"
																	type="button"
																	onClick={this.updateRegistrationStatus(item, 'Reject')}>
																	<i className="now-ui-icons ui-1_simple-remove" />
																</Button>
															</td>
														</tr>
													);
												})}
											</tbody>
										) : (
											<tbody>
												<tr className="bold text-center">
													<td colSpan="6">There is no data to display.</td>
												</tr>
											</tbody>
										)}
									</Table>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
};

export default RequestRegister;
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const LocationMenu = () => {
	const history = useHistory();
	const business = useSelector(({ business }) => business);
	const nextPage = () => {
		history.push(`/admin/business/${business?.filterBusinessList?.id ? business?.filterBusinessList?.id : business?.businessLists?.[0]?.id}`);
	};
	return (
		<a
			onClick={nextPage}
			className="simple-text logo-normal cursor pl-2"
			target="_blank"
		>
			{business.businessName ? business?.businessName : business?.businessLists?.[0] ? business?.businessLists?.[0]?.id : ''}
		</a>
	);
};
export default LocationMenu;

import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Form,
  Card,
  Modal,
  Input,
  Button,
  CardBody,
  FormGroup,
  CardTitle,
  ModalBody,
  CardHeader,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import sortBy from "lodash/sortBy";
import { Add } from "@material-ui/icons";
import Rating from '@material-ui/lab/Rating';
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";
import { useDispatch, useSelector } from "react-redux";
import VirtualizedSelect from "react-virtualized-select";

import ReactTable from '../../../components/ReactTable/ReactTable';
import DateTime from "../../../components/DateTime";

import { startLoading, stopLoading } from "../../../redux/loader/action";
import { createNotification } from "../../../utils/utils";
import countryData from "../../../locales/country.json";
import { calls } from "../../../utils/calls.js";
import API from "../../../redux/auth/api";

import beachImage from "../../../assets/img/beach.jpg";
import restaurantImage from "../../../assets/img/restaurant.jpg";

import "../../../assets/css/custom.scss";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Container } from "react-bootstrap";

const api = new API();

const Guest = () => {
  const { t } = useTranslation();
  const grid = useSelector(({ grid }) => grid);
  const business = useSelector((state) => state?.business ?? {});
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [openCreateEditModal, setOpenCreateEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState({});
  const [fetchingReservations, setFetchingReservations] = useState(false);
  const [allUserReservations, setAllUserReservations] = useState([]);
  const [prefixCountry, setPrefixCountry] = useState([]);
  const [cities, setCities] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    getData()
  }, [business.filterBusinessList]);

  useEffect(() => {
    updateCountryData();
  }, [business.businessData]);

  useEffect(() => {
    setPrefixCountry(countryData.map(c => ({
      ...c,
      value: `${c.dial_code} ${c.name}`,
      label: `${c.flag} ${c.dial_code}`,
    })));
  }, []);

  const updateCountryData = () => {
    const { businessData = {}, countryData: businessCountryData = [] } = business;
    if (businessData && businessCountryData) {
      const { country_id = "" } = businessData;
      const { id: cId } = businessCountryData.find(c => c.id == country_id) || {};
      setEditData({ ...editData, country_id: cId });
      getAllCity(cId);
    }
  };

  const getData = async () => {
    try {
      dispatch(startLoading())
      const { data: results = [] } = await axios(calls.getAllGuestList(business.accessToken, business.filterBusinessList.id))
      const finalList = sortBy(results.map((guest) => {
        guest.phone_number = `${guest.prefix} ${guest.phone}`;
        let bDate = moment(guest.birth_date, "YYYY-MM-DD");
        guest.birth_date_timestamp = 0;
        if (bDate.isValid()) {
          bDate.set("year", moment().format("YYYY"));
          guest.birth_date_timestamp = bDate.valueOf();
          guest.birth_date = moment(guest.birth_date).format("DD MMM YYYY");
        }
        guest.actions = (
          // we've added some custom button actions
          <div className="actions-right">
            <Button
              onClick={() => onShowDetailPress(guest)}
              className="btn-icon btn-round"
              color="info"
              size="sm"
            >
              <i className="fa fa-eye" />
            </Button>{' '}
            <Button
              onClick={() => {
                setEditData({
                  ...guest,
                  birth_date: moment(guest?.birth_date).format("DD-MM-YYYY")
                });
                getAllCity(guest?.country_id);
                setOpenCreateEditModal(true);
              }}
              className="btn-icon btn-round"
              color="warning"
              size="sm"
            >
              <i className="fa fa-edit" />
            </Button>{' '}
            {/* use this button to remove the data row */}
            <Button
              onClick={() => {
                setDeleteData(guest);
                setOpenDeleteModal(true)
              }}
              className="btn-icon btn-round"
              color="danger"
              size="sm"
            >
              <i className="fa fa-times" />
            </Button>{' '}
          </div>
        );
        return guest;
      }), (u) => {
        let days;
        if (u?.birth_date) {
          days = getDaysDifferenceFromCurrentDay(u.birth_date);
        }
        return days;
      });
      setData(finalList);
    } catch (error) {
    } finally {
      dispatch(stopLoading())
    }
  }

  const getDaysDifferenceFromCurrentDay = (date) => {
    const oDate = new Date(date);
    const day = oDate.getDate();
    const month = oDate.getMonth();
    const today = new Date(moment().format("DD MMM YYYY"));
    const bday = new Date(today.getFullYear(), month, day);
    if (today.getTime() > bday.getTime()) {
      bday.setFullYear(bday.getFullYear() + 1);
    }
    const diff = bday.getTime() - today.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days;
  }

  const deleteGuest = () => {
    dispatch(startLoading())
    axios(calls.deleteGuest(business.accessToken, deleteData.id)).then(() => {
      getData();
    }).catch(() => {

    }).finally(() => {
      setDeleteData({})
      setOpenDeleteModal(false)
      dispatch(stopLoading())
    })
  }

  const onblur = () => {
    // if (editData.prefix.replace(/\+/g, "").length && editData.phone && !editData.id) {
    //   searchCustomerByNumber(editData.prefix, editData.phone)
    // }
  }
  const searchCustomerByNumber = (prefix, phone) => {
    dispatch(startLoading())
    axios(calls.searchCustomerByNumber(business.accessToken, prefix, phone)).then((result) => {
      let results = result.data;
      if (results.length) {
        setEditData({
          ...editData,
          alias: `${results[0].first_name} ${results[0].last_name}`,
          email: results[0].email,
          birth_date: results[0].birthday,
          city: results[0]?.city?.name,
        })
      }
    }).catch((e) => {
      createNotification({
        type: "danger",
        title: t("Guest"),
        message: e.response?.data?.error || e.message
      });
    }).finally(() => {
      dispatch(stopLoading())
    })
  }

  const saveData = () => {
    if (!validateData()) {
      return;
    }
    dispatch(startLoading())
    if (editData.id) {
      update()
    } else {
      create()
    }
  }

  const validateData = () => {
    const errors = []
    if (!editData.prefix || editData?.prefix?.replace(/\+/g, "").length <= 0) {
      errors.push(t("prefixRequired"))
    }
    if (!editData.phone) {
      errors.push(t("phoneRequired"))
    }
    if (!editData.alias) {
      errors.push(t("aliasRequired"))
    }
    if (errors.length) {
      setOpenCreateEditModal(false)
      createNotification({
        type: "danger",
        title: t("invalid Guest data"),
        message: errors.join(),
      });
      return false;
    }
    const { status, weekHourData } = grid;
    if (!status || weekHourData.length <= 0) {
      setOpenCreateEditModal(false)
      createNotification({
        type: 'danger',
        title: !status ? t('Activity Period') : t('Working Hour Error'),
        message: !status ? t('Activity_Period_Error') : t('Working_Hour_Error'),
      });
      return false;
    }
    return true;
  }

  const update = () => {
    editData["birth_date"] = moment(editData?.birth_date, "DD-MM-YYYY").format("MM-DD-YYYY");
    axios(calls.updateGuest(business.accessToke, editData.id, editData)).then(() => {
      getData();
      createNotification({
        type: "success",
        title: t("Guest"),
        message: t("Guest saved successfully"),
      });
    }).catch((e) => {
      createNotification({
        type: "danger",
        title: t("Guest"),
        message: e.response?.data?.error || e.message,
      });
    }).finally(() => {
      clearEditData();
      setOpenCreateEditModal(false)
      dispatch(stopLoading())
    })
  }

  const create = () => {
    editData["birth_date"] = moment(editData?.birth_date, "DD-MM-YYYY").format("MM-DD-YYYY");
    axios(calls.createGuest(business.accessToke, { ...editData, business_id: business.filterBusinessList.id })).then(() => {
      getData();
      createNotification({
        type: "success",
        title: t("Guest"),
        message: t("Guest saved successfully"),
      });
    }).catch((e) => {
      createNotification({
        type: "danger",
        title: t("Guest"),
        message: e.response?.data?.error || e.message,
      });
    }).finally(() => {
      clearEditData();
      setOpenCreateEditModal(false)
      dispatch(stopLoading())
    })
  }

  const clearEditData = () => {
    let countryId = null;
    const { countryData: businessCountryData = [], businessData = {} } = business;
    if (business?.businessData && businessCountryData) {
      const { country_id = "" } = businessData;
      const { id: cId } = businessCountryData.find(c => c.id == country_id) || {};
      countryId = cId;
      getAllCity(cId);
    }
    setEditData({ country_id: countryId });
  };

  const onPrefixChange = (e) => {
    setEditData({ ...editData, prefix: e?.dial_code ?? "" });
  }

  const onCountryChange = async (e) => {
    setEditData({
      ...editData,
      country_id: e?.id ?? ""
    });
    if (e?.id) {
      getAllCity(e.id);
    }
  }

  const getAllCity = async (countryId) => {
    try {
      const { data = {} } = await api.getCityListActionRequests({ value: countryId });
      const { city: cityList } = data;
      setCities(cityList.map((city) => ({
        label: city.name,
        value: city.id,
      })));
    } catch (error) { }
  };

  const onShowDetailPress = (guestData) => {
    setSelectedUserData(guestData);
    setShowInfoModal(true);
    getUserReservatinFeedbackHisotry(guestData.id);
  };

  const closeInfoModal = () => {
    setShowInfoModal(false);
    setSelectedUserData({});
    setAllUserReservations([]);
  };

  const getUserReservatinFeedbackHisotry = async (customer_alias_id) => {
    try {
      setFetchingReservations(true);
      const response = await api.getUserReservatinFeedbackHisotry({
        customer_alias_id,
        business_id: business.filterBusinessList.id,
      });
      const { reservation_restaurant = [] } = response?.data ?? {};
      setAllUserReservations(reservation_restaurant);
    } catch (error) { } finally {
      setFetchingReservations(false);
    }
  };

  const getFormatedData = (date) => {
    return moment(date, "YYYY-MM-DD HH:mm").format("MMM DD, YYYY hh:mm A");
  }

  const renderUserHistory = () => {
    if (fetchingReservations) {
      return (
        <div className="d-flex align-item-center justify-content-center">
          <BeatLoader size="30px" color="#f00" />
        </div>
      );
    }

    if (!fetchingReservations && allUserReservations.length > 0) {
      return allUserReservations.map((booking) => {
        const {
          id,
          rating = 0,
          unique_id = "",
          bill_value = "",
          arrive_time = "",
          selected_day = "",
          feedback_msg = "",
          reservation_status = "",
        } = booking;
        const { currency = "" } = business?.filterBusinessList || {};
        return (
          <div className="history-box" key={`${id}`}>
            <div className="sub-history-box">
              <div className="sub-history-box-left">
                {reservation_status ? <p>{t('Status')}: {reservation_status.toUpperCase()}</p> : null}
                <div className="d-flex rating-view">
                  <Rating
                    max={5}
                    readOnly
                    size="large"
                    precision={0.5}
                    value={rating || 0}
                    name={`rating-${unique_id}`}
                  />
                  <p className="rating-text">({rating || 0})</p>
                </div>
              </div>
              <div className="sub-history-box-right">
                {selected_day && arrive_time ? <p>{t('Date')}: {getFormatedData(`${selected_day} ${arrive_time}`)}</p> : null}
                {unique_id ? <p>{t("reservation_id")}: #{unique_id}</p> : null}
                {bill_value ? <p>{t("BillValue")}: {bill_value} {currency}</p> : null}
              </div>
            </div>
            {feedback_msg ? <p>{feedback_msg}</p> : null}
          </div>
        )
      });
    }

    return (
      <div className="d-flex align-item-center justify-content-center text-center bold">
        <h3>{t("NoUserHistoryFound")}</h3>
      </div>
    );
  };

  const renderListData = () => {
    return (
      <ReactTable
        data={data}
        showGeneralSearch
        initialState={{
          pageSize: 50,
          pageIndex: 0,
          // sortBy: [{ id: "id", desc: "desc" }],
          hiddenColumns: ['id']
        }}
        columns={[
          // {
          //   Header: t("Id"),
          //   accessor: 'id',
          // },
          {
            Header: t('Phone'),
            accessor: 'phone_number',
            data_field: 'phone_number',
          },
          {
            Header: t('Name'),
            accessor: 'alias',
            data_field: 'alias',
          },
          {
            Header: t('AvgRate'),
            accessor: 'avg_rate',
            data_field: 'avg_rate',
          },
          {
            Header: t('Reservations'),
            accessor: 'total_reservation',
            data_field: 'total_reservation',
          },
          {
            Header: t('Email'),
            accessor: 'email',
            data_field: 'email',
          },
          {
            Header: t('Birthday'),
            accessor: 'birth_date_timestamp',
            data_field: 'birth_date',
            Cell: info => {
              const { row = {} } = info;
              const { original = {} } = row;
              const { birth_date = "" } = original;
              return (
                <div>{birth_date}</div>
              );
            }
          },
          {
            Header: t('City'),
            accessor: 'city.name',
            data_field: 'city?.name',
          },
          {
            Header: t('Actions'),
            accessor: 'actions',
            sortable: false,
            filterable: false,
          },
        ]}
      />
    );
  }
  const { name: cName = "" } = countryData.find(c => c.dial_code == editData?.prefix) || {};
  const prefixValue = `${editData?.prefix ?? ""}${cName ? ` ${cName}` : ""}`;

  return (
    <Fragment>
      <div
        className="content employees-container Desktop-Guest-View"
        style={{ paddingTop: "100px" }}
      >
        <Row>
          <Col xs={12} md={12}>
            <Card>
              <CardHeader>
                <div className="d-flex" style={{
                  "justifyContent": "space-between",
                  "alignItems": "center"
                }}>
                  <CardTitle tag="h4">{t("all_guest")}</CardTitle>
                  <div>
                    <Button
                      color="primary"
                      className="btn-facility"
                      onClick={() => {
                        setOpenCreateEditModal(true)
                      }}
                    >
                      {t("Create")}
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardBody style={{ overflow: "auto" }}>
                {/* <div style={{ minWidth: "1000px" }}> */}
                {renderListData()}
                {/* </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Container
        style={{ paddingTop: "105px" }}
        className="Mobile-Guest-View"
      >
        <div className="d-flex business-profile-head">
          <div className="page-heading mb-0">
            <i className={"now-ui-icons business_badge"} />
            <h5>{t("all_guest")}</h5>
          </div>
        </div>
        {renderListData()}
        <Button
          color="primary"
          className="floating-create-btn"
          onClick={() => {
            setOpenCreateEditModal(true)
          }}
        >
          <Add fontSize="large" />
        </Button>
      </Container>
      <Modal isOpen={openDeleteModal} toggle={() => { setOpenDeleteModal(!openDeleteModal) }}>
        <ModalBody>
          <h5 className="title text-center">
            {t("Are you sure to delete?")}
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button
            className="grid-button "
            style={{ backgroundColor: "7557f9" }}
            onClick={deleteGuest}
          >
            {t("Yes")}
          </Button>
          <Button
            className="grid-button "
            style={{ backgroundColor: "red" }}
            onClick={() => { setOpenDeleteModal(!openDeleteModal) }}
          >
            {t("No")}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        centered
        size={"lg"}
        isOpen={showInfoModal}
        toggle={closeInfoModal}
      >
        <ModalHeader
          className="justify-content-center"
          style={{ width: "90%" }}
          toggle={closeInfoModal}
        >
          <p className="title text-center bold mb-0">
            {t("FeedbackHistory")} {selectedUserData?.alias ?? ""}
          </p>
        </ModalHeader>
        <ModalBody>
          {renderUserHistory()}
        </ModalBody>
      </Modal>
      <Modal size="xl" isOpen={openCreateEditModal} toggle={() => {
        if (!openCreateEditModal == false) {
          clearEditData();
        }
        setOpenCreateEditModal(!openCreateEditModal)
      }}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => {
            if (!openCreateEditModal == false) {
              clearEditData();
            }
            setOpenCreateEditModal(!openCreateEditModal)
          }}
        >
          <p className="bold mb-0">{t("Guest")}</p>
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md="2">
                <FormGroup>
                  <label className="text-input-label">
                    {t("Prefix")}
                  </label>
                  <VirtualizedSelect
                    id="prefix"
                    name="prefix"
                    value={prefixValue}
                    className="hide-clear"
                    options={prefixCountry}
                    placeholder={t("Prefix")}
                    onChange={onPrefixChange}
                    style={{
                      fontSize: 12,
                      borderRadius: 0,
                      borderColor: "#E5E5E5",
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md="3" className="single-feild">
                <FormGroup>
                  <label className="text-input-label">
                    {t("Phone")}
                  </label>
                  <Input
                    type="number"
                    name="phone"
                    id="phone"
                    value={editData?.phone ?? ""}
                    placeholder={t("Phone")}
                    onChange={(e) => {
                      setEditData({ ...editData, phone: e.target.value })
                    }}
                    onBlur={onblur}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="single-feild">
                <FormGroup>
                  <label className="text-input-label">
                    {t("Name")}
                  </label>
                  <Input
                    type="text"
                    name="alias"
                    id="alias"
                    value={editData?.alias ?? ""}
                    placeholder={t("Name")}
                    onChange={(e) => {
                      setEditData({ ...editData, alias: e.target.value })
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="single-feild">
                <FormGroup>
                  <label className="text-input-label">
                    {t("Email")}
                  </label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={editData?.email ?? ""}
                    placeholder={t("Email")}
                    onChange={(e) => {
                      setEditData({ ...editData, email: e.target.value })
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="single-feild">
                <FormGroup>
                  <label className="text-input-label">
                    {t("Country")}
                  </label>
                  <VirtualizedSelect
                    id="country"
                    inputProps={{
                      type: "text",
                      autoComplete: "new-password",
                    }}
                    onFocus={(e) => {
                      e.target.setAttribute("autoComplete", "new-password")
                    }}
                    name="country"
                    style={{
                      borderRadius: 0,
                      fontSize: 12,
                      borderColor: "#E5E5E5",
                    }}
                    placeholder={t("Country")}
                    value={editData?.country_id ?? ""}
                    onChange={onCountryChange}
                    clearable={false}
                    options={business?.countryData ? business?.countryData.map(c => ({
                      ...c,
                      label: c?.name ?? "",
                      value: c?.id ?? "",
                    })) ?? [] : []}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="single-feild">
                <FormGroup>
                  <label className="text-input-label">
                    {t("City")}
                  </label>
                  <VirtualizedSelect
                    id="city"
                    name="city"
                    style={{
                      borderRadius: 0,
                      fontSize: 12,
                      borderColor: "#E5E5E5",
                    }}
                    inputProps={{
                      type: "text",
                      autoComplete: "new-password",
                    }}
                    onFocus={(e) => {
                      e.target.setAttribute("autoComplete", "new-password")
                    }}
                    placeholder={t("City")}
                    value={editData?.city_id ?? ""}
                    onChange={(option) =>
                      setEditData({
                        ...editData,
                        city_id: option.value,
                      })
                    }
                    clearable={false}
                    options={cities}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="single-feild">
                <FormGroup>
                  <label className="text-input-label">
                    {t("Birth Day")}
                  </label>
                  <DateTime
                    name="Brthday"
                    timeFormat={false}
                    onChange={(e) => {
                      setEditData({
                        ...editData,
                        birth_date: e.format("DD-MM-YYYY")
                      })
                    }}
                    dateFromat="DD-MM-YYYY"
                    value={editData?.birth_date ? moment(editData?.birth_date ?? "", "DD-MM-YYYY").format("DD MMM YYYY") : null}
                    inputProps={{
                      placeholder: `${t("Birth Day")}`,
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              clearEditData();
              setOpenCreateEditModal(!openCreateEditModal);
            }}
          >
            {t("Close")}
          </Button>
          <Button className="save-btn" color="primary" onClick={saveData}>
            {t("Save Profile")}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
export default Guest;

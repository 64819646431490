import { ApolloClient, gql, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GRAPHQL_URL } from '../../utils/constants.js';
import { createNotification } from '../../utils/utils.js';
//import swal from "sweetalert";

const link = new HttpLink({
	uri: `${GRAPHQL_URL}`,
});
const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
};
const authLink = setContext(async (_, { headers }) => {
	let token;
	let localState = localStorage.getItem('persist:business');
	let superAdminSessionToken = localStorage.getItem("SHADOWED_USER_TOKEN");

	if (localState && typeof JSON.parse(localState) === 'object') {
		localState = JSON.parse(localState);
		token = localState.accessToken;
		return {
			headers: {
				...headers,
				authorization: `Bearer ${superAdminSessionToken && superAdminSessionToken.length > 0 ? superAdminSessionToken : token}`,
			}
		};
	}
});
const client = new ApolloClient({
	link: authLink.concat(link),
	cache: new InMemoryCache(),
	defaultOptions
});
export default class API {

	getPlans() {
		try {
			let data = client.mutate({
				mutation: gql`
					query getPlans{
						plans (where:{status:"active"}){
							id
							plan_for
							plan
							reservation
							cost
							status
							customer_reservation
							promoto_location
							create_reservation_from_business_app
							allow_table_mark
							marketing_email
							cost_per_reservation
							color_class,
							config
						}
					}
				`,
			})
				.then(data => {
					return data;
				})
				.catch(err => {
					return err;
				});
			return data;
		} catch (err) { }
	}


	getActivePlan(businessId) {
		try {
			let data = client.mutate({
				mutation: gql`
					query getplan_changes{
						plan_changes (where:{status:"active", business_id : ${businessId}}){
							id
							plan_id
							plan_config
							status
							sms_balance
							sms_added
							plan_end_date
							business_id
							exclude_sms
						}
					}
				`,
			})
				.then(data => {
					return data;
				})
				.catch(err => {
					return err;
				});
			return data;
		} catch (err) { }
	}

	inactiveOldPlan(id) {
		try {
			let data = client.mutate({
				mutation: gql`
					mutation updatePlan{
						plan_changesUpdate (plan_changes:{id:${id},status:"inactive"}){
							id
							plan_id
							plan_config
							status
							sms_balance
							sms_added
							plan_end_date
							business_id
							exclude_sms
						}
					}
				`,
			})
				.then(data => {
					return data;
				})
				.catch(err => {
					return err;
				});
			return data;
		} catch (err) { }
	}

	createPlanChanges(plan) {
		try {
			let data = client.mutate({
				mutation: gql`
					mutation createPlan{
						plan_changesCreate (plan_changes:
							{
								plan_id: ${plan.id},
								status:"active",
								plan_config:{
									cost: ${plan.cost},
									cost_per_reservation: ${plan.cost_per_reservation},
									price_type : "${plan.price_type}"
								},
								sms_balance:${plan.reservation * 2},
								sms_added : ${plan.reservation * 2},
								business_id : ${plan.business_id},
								exclude_sms :${plan.exclude_sms}
							}){
							id
							plan_id
							plan_config
							status
							sms_balance
							sms_added
							plan_end_date
							business_id
							exclude_sms
						}
					}
				`,
			})
				.then(data => {
					return data;
				})
				.catch(err => {
					return err;
				});
			return data;
		} catch (err) {
			return err;
		}
	}
}
import {
  rejectPromiseAction,
  resolvePromiseAction,
} from "@adobe/redux-saga-promise";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
  actionTypes,
  saveExtraSunbedPhoto,
  saveWaiterCall,
  saveTowelIncluded,
  saveAutoAccepted,
  saveContactCall,
  createFacility,
  saveBeachLocation,
  saveBusinessSettings,
} from "./action";
import API from "./api";

const api = new API();

///////////////////
const getRuleRequest = (business) => {
  return api.getRuleRequests(business);
};

function* getRule() {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(getRuleRequest, business);
    if (result.data) {
      if (result.data.business_rules.length > 0) {
        yield put({
          type: actionTypes.RESULT_GET_RULE,
          ruleId: result.data.business_rules[0].id,
          el: result.data.business_rules[0].rules.el.replace(
            /(\r\n|\n|\r)/gm,
            "\\n"
          ),
          en: result.data.business_rules[0].rules.en.replace(
            /(\r\n|\n|\r)/gm,
            "\\n"
          ),
          es: result.data.business_rules[0].rules.es.replace(
            /(\r\n|\n|\r)/gm,
            "\\n"
          ),
          fr: result.data.business_rules[0].rules.fr.replace(
            /(\r\n|\n|\r)/gm,
            "\\n"
          ),
          it: result.data.business_rules[0].rules.it.replace(
            /(\r\n|\n|\r)/gm,
            "\\n"
          ),
          ro: result.data.business_rules[0].rules.ro.replace(
            /(\r\n|\n|\r)/gm,
            "\\n"
          ),
          elGoogleTranslated:
            result.data.business_rules[0].rules.elGoogleTranslated,
          enGoogleTranslated:
            result.data.business_rules[0].rules.enGoogleTranslated,
          esGoogleTranslated:
            result.data.business_rules[0].rules.esGoogleTranslated,
          frGoogleTranslated:
            result.data.business_rules[0].rules.frGoogleTranslated,
          itGoogleTranslated:
            result.data.business_rules[0].rules.itGoogleTranslated,
          roGoogleTranslated:
            result.data.business_rules[0].rules.roGoogleTranslated,
          mainLang: result.data.business_rules[0].rules.mainLang,
          currentIng: result.data.business_rules[0].rules.currentIng,
        });
      } else {
        yield put({
          type: actionTypes.RESULT_GET_RULE,
          ruleId: "",
          el: "",
          en: "",
          es: "",
          fr: "",
          it: "",
          ro: "",
          elGoogleTranslated: "false",
          enGoogleTranslated: "false",
          esGoogleTranslated: "false",
          frGoogleTranslated: "false",
          itGoogleTranslated: "false",
          roGoogleTranslated: "false",
          mainLang: "",
          currentIng: "en",
        });
      }
    }
  } catch (e) { }
}

const saveRuleRequest = (business, option) => {
  return api.saveRuleRequests(business, option);
};
const updateRuleRequest = (business, option) => {
  return api.updateRuleRequests(business, option);
};

function* saveRule() {
  try {
    const business = yield select((store) => store.business);
    const option = yield select((store) => store.option);
    if (option.ruleId) {
      const result = yield call(updateRuleRequest, business, option);
      if (result.data) {
        if (result.data.business_rulesUpdate) {
          yield put({
            type: actionTypes.RESULT_GET_RULE,
            ruleId: result.data.business_rulesUpdate.id,
            el: result.data.business_rulesUpdate.rules.el.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            en: result.data.business_rulesUpdate.rules.en.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            es: result.data.business_rulesUpdate.rules.es.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            fr: result.data.business_rulesUpdate.rules.fr.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            it: result.data.business_rulesUpdate.rules.it.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            ro: result.data.business_rulesUpdate.rules.ro.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            elGoogleTranslated:
              result.data.business_rulesUpdate.rules.elGoogleTranslated,
            enGoogleTranslated:
              result.data.business_rulesUpdate.rules.enGoogleTranslated,
            esGoogleTranslated:
              result.data.business_rulesUpdate.rules.esGoogleTranslated,
            frGoogleTranslated:
              result.data.business_rulesUpdate.rules.frGoogleTranslated,
            itGoogleTranslated:
              result.data.business_rulesUpdate.rules.itGoogleTranslated,
            roGoogleTranslated:
              result.data.business_rulesUpdate.rules.roGoogleTranslated,
            mainLang: result.data.business_rulesUpdate.rules.mainLang,
            currentIng: result.data.business_rulesUpdate.rules.currentIng,
          });
        }
      }
    } else {
      const result = yield call(saveRuleRequest, business, option);
      if (result.data) {
        if (result.data.business_rulesCreate) {
          yield put({
            type: actionTypes.RESULT_GET_RULE,
            ruleId: result.data.business_rulesCreate.id,
            el: result.data.business_rulesCreate.rules.el.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            en: result.data.business_rulesCreate.rules.en.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            es: result.data.business_rulesCreate.rules.es.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            fr: result.data.business_rulesCreate.rules.fr.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            it: result.data.business_rulesCreate.rules.it.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            ro: result.data.business_rulesCreate.rules.ro.replace(
              /(\r\n|\n|\r)/gm,
              "\\n"
            ),
            elGoogleTranslated:
              result.data.business_rulesCreate.rules.elGoogleTranslated,
            enGoogleTranslated:
              result.data.business_rulesCreate.rules.enGoogleTranslated,
            esGoogleTranslated:
              result.data.business_rulesCreate.rules.esGoogleTranslated,
            frGoogleTranslated:
              result.data.business_rulesCreate.rules.frGoogleTranslated,
            itGoogleTranslated:
              result.data.business_rulesCreate.rules.itGoogleTranslated,
            roGoogleTranslated:
              result.data.business_rulesCreate.rules.roGoogleTranslated,
            mainLang: result.data.business_rulesCreate.rules.mainLang,
            currentIng: result.data.business_rulesCreate.rules.currentIng,
          });
        }
      }
    }
  } catch (e) { }
}

const getFacilitiesActionRequest = (business) => {
  return api.getFacilitiesActionRequests(business);
};

function* getFacilitiesAction() {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(getFacilitiesActionRequest, business);
    if (result.data) {
      if (result.data.business_facilities.length > 0) {
        yield put({
          type: actionTypes.RESULT_GET_FACILITIES,
          value: result.data.business_facilities,
        });
      } else {
        yield put({
          type: actionTypes.RESULT_GET_FACILITIES,
          value: [],
        });
      }
    }
  } catch (e) { }
}

const createFacilitiesActionRequest = (id, business) => {
  return api.createFacilitiesActionRequests(id, business);
};

function* createFacilitiesAction(action) {
  try {
    const { id } = action.payload;
    const business = yield select((store) => store.business);
    const result1 = yield call(createFacilitiesActionRequest, id, business);
    if (result1.data) {
      const business = yield select((store) => store.business);
      const result = yield call(getFacilitiesActionRequest, business);
      if (result.data) {
        if (result.data.business_facilities.length > 0) {
          yield put({
            type: actionTypes.RESULT_GET_FACILITIES,
            value: result.data.business_facilities,
          });
        }
      }
      yield call(resolvePromiseAction, action, result);
    }
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const deleteFacilitiesActionRequest = (id, business) => {
  return api.deleteFacilitiesActionRequests(id, business);
};

function* deleteFacilitiesAction({ id }) {
  try {
    const business = yield select((store) => store.business);
    const result1 = yield call(deleteFacilitiesActionRequest, id, business);
    if (result1.data) {
      const business = yield select((store) => store.business);
      const result = yield call(getFacilitiesActionRequest, business);
      if (result.data) {
        if (result.data.business_facilities.length > 0) {
          yield put({
            type: actionTypes.RESULT_GET_FACILITIES,
            value: result.data.business_facilities,
          });
        }
      }
    }
  } catch (e) { }
}

const getExtraSunbedPhotoActionRequest = (business) => {
  return api.getExtraSunbedPhotoActionRequests(business);
};

function* getExtraSunbedPhotoAction() {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(getExtraSunbedPhotoActionRequest, business);
    if (result.data) {
      if (result.data.business_settings.length > 0) {
        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: result.data.business_settings,
        });
      } else {
        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: [],
        });
      }
    }
  } catch (e) { }
}

const createExtraSunbedPhotoAction = (
  option,
  photoRequired,
  extraSunbed,
  umbrellarRequired,
  covidCertificate,
  contact_phone,
  business
) => {
  return api.createExtraSunbedPhotoActions(
    option,
    photoRequired,
    extraSunbed,
    umbrellarRequired,
    covidCertificate,
    contact_phone,
    business
  );
};
const updateExtraSunbedPhotoAction = (
  option,
  photoRequired,
  extraSunbed,
  umbrellarRequired,
  covidCertificate,
  contact_phone
) => {
  return api.updateExtraSunbedPhotoActions(
    option,
    photoRequired,
    extraSunbed,
    umbrellarRequired,
    covidCertificate,
    contact_phone
  );
};

function* saveExtraSunbedPhotoAction(action) {
  const {
    photoRequired,
    extraSunbed,
    umbrellarRequired,
    covidCetificate,
    contact_phone,
  } = action.payload;
  try {
    const option = yield select((store) => store.option);
    const business = yield select((store) => store.business);
    let result;
    if (option.businessSettings.length > 0) {
      result = yield call(
        updateExtraSunbedPhotoAction,
        option,
        photoRequired,
        extraSunbed,
        umbrellarRequired,
        typeof covidCetificate != "undefined"
          ? covidCetificate
          : option.businessSettings?.[0]?.covid_certificate ?? false,
        contact_phone ? contact_phone : option.businessSettings?.[0]?.contact_phone ?? ""
      );
    } else {
      result = yield call(
        createExtraSunbedPhotoAction,
        option,
        photoRequired,
        extraSunbed,
        umbrellarRequired,
        typeof covidCetificate != "undefined"
          ? covidCetificate
          : option.businessSettings?.[0]?.covid_certificate ?? false,
        contact_phone
          ? contact_phone
          : option.businessSettings?.[0]?.contact_phone ?? "",
        business
      );
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

// save Waiter call

const updateWaiterCallAction = (option, waiter_calls) => {
  return api.updateWaiterCallActions(option, waiter_calls);
};

function* saveWaiterCallAction(action) {
  const { waiter_calls } = action.payload;
  try {
    const option = yield select((store) => store.option);
    let result;
    result = yield call(updateWaiterCallAction, option, waiter_calls);

    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}
// ***************
// save Auto Accepted

const updateAutoAcceptedAction = (option, auto_accept) => {
  return api.updateAutoAcceptedActions(option, auto_accept);
};

function* saveAutoAcceptedAction(action) {
  const { auto_accept } = action.payload;
  try {
    const option = yield select((store) => store.option);
    const result = yield call(updateAutoAcceptedAction, option, auto_accept);
    if (result && result?.data && result?.data?.business_settingsUpdate) {
      const { id } = result?.data?.business_settingsUpdate ?? {};
      const index = option.businessSettings.findIndex(s => s.id == id);
      if (index > -1) {
        let newSettings = [...option.businessSettings];
        newSettings[index] = { ...newSettings[index], ...result?.data?.business_settingsUpdate };
        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: newSettings,
        });
      }
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

// save towel included

const updateTowelIncludedAction = (option, towel_included) => {
  return api.updateTowelIncludedActions(option, towel_included);
};

function* saveTowelIncludedAction(action) {
  const { towel_included } = action.payload;
  try {
    const option = yield select((store) => store.option);
    const result = yield call(updateTowelIncludedAction, option, towel_included);
    if (result && result?.data && result?.data?.business_settingsUpdate) {
      const { id } = result?.data?.business_settingsUpdate ?? {};
      const index = option.businessSettings.findIndex(s => s.id == id);
      if (index > -1) {
        let newSettings = [...option.businessSettings];
        newSettings[index] = { ...newSettings[index], ...result?.data?.business_settingsUpdate };
        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: newSettings,
        });
      }
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}
// ***************
// save contact

const updateContactAction = (option, contact_phone) => {
  return api.updateContactsActions(option, contact_phone);
};

function* saveContactAction(action) {
  const { contact_phone } = action.payload;
  try {
    const option = yield select((store) => store.option);
    let result = yield call(updateContactAction, option, contact_phone);

    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}
// ***************
// save turn off sms call

const updateBusinessSettingActions = (mutation) => {
  return api.updateBusinessSettingActions(mutation);
};

const createBusinessSettingActions = (mutation) => {
  return api.createBusinessSettingAction(mutation);
};

function* saveBusinessSettingAction(action) {
  const { mutation } = action.payload;
  try {
    const option = yield select((store) => store.option);
    // const business = yield select((store) => store.business);
    let result;
    if (option.businessSettings.length > 0) {
      result = yield call(updateBusinessSettingActions, mutation);
      if (result && result?.data && result?.data?.business_settingsUpdate) {
        const { id } = result?.data?.business_settingsUpdate ?? {};
        const index = option.businessSettings.findIndex(s => s.id == id);
        if (index > -1) {
          let newSettings = [...option.businessSettings];
          newSettings[index] = { ...newSettings[index], ...result?.data?.business_settingsUpdate };
          yield put({
            type: actionTypes.RESULT_GET_SETTING,
            value: newSettings,
          });
        }
      }
    } else {
      result = yield call(createBusinessSettingActions, mutation);
    }
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const getBeachLocationActionRequest = (business) => {
  return api.getBeachLocationActionRequests(business);
};

function* getBeachLocationAction() {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(getBeachLocationActionRequest, business);
    if (result.data) {
      if (result.data.business_settings.length > 0) {
        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: result.data.business_settings,
        });
      } else {
        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: [],
        });
      }
    }
  } catch (e) { }
}

const createBeachLocationAction = (
  option,
  mapPosition,
  business,
  country,
  city,
  address
) => {
  return api.createBeachLocationActions(
    option,
    mapPosition,
    business,
    country,
    city,
    address
  );
};
const updateBeachLocationAction = (
  option,
  mapPosition,
  country,
  city,
  address
) => {
  return api.updateBeachLocationActions(
    option,
    mapPosition,
    country,
    city,
    address
  );
};

const updateBeachTimezoneAction = (id, timezone) => {
  return api.updateBeachTimezone(id, timezone);
};

const updateLocationInCityLocale = (city, id) => {
  if (id) {
    return api.updateCityLocale(id);
  } else {
    return api.createLocationInCityLocale(city);
  }
};

const getCityLocale = (city) => {
  return api.getCityLocale(city);
};

function* saveBeachLocationAction(action) {
  try {
    const { mapPosition, country, city, address, timezone } = action.payload;
    const option = yield select((store) => store.option);
    const business = yield select((store) => store.business);
    let result = {};
    if (option.businessSettings.length > 0) {
      result = yield call(
        updateBeachLocationAction,
        option,
        mapPosition,
        country,
        city,
        address
      );
    } else {
      result = yield call(
        createBeachLocationAction,
        option,
        mapPosition,
        business,
        country,
        city,
        address
      );
    }

    const result1 = yield call(
      updateBeachTimezoneAction,
      business?.filterBusinessList?.id,
      timezone
    );
    try {
      const data = yield call(getCityLocale, city);
      if (data.data.city_locale && data.data.city_locale.length > 0) {
        yield call(
          updateLocationInCityLocale,
          city,
          data.data.city_locale[0].id
        );
      } else {
        yield call(updateLocationInCityLocale, city);
      }
    } catch (e) { }
    yield call(resolvePromiseAction, action, result);
  } catch (e) {
    yield call(rejectPromiseAction, action, e);
  }
}

const getBusinessGalleryRequest = (business) => {
  return api.getBusinessGalleryRequests(business);
};

function* getBusinessGallery() {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(getBusinessGalleryRequest, business);
    if (result.data) {
      if (result.data.business_gallery.length > 0) {
        yield put({
          type: actionTypes.RESULT_GET_BUSINESS_GALLERY,
          value: result.data.business_gallery,
        });
      } else {
        yield put({
          type: actionTypes.RESULT_GET_BUSINESS_GALLERY,
          value: [],
        });
      }
    }
  } catch (e) { }
}

const imageDeleteActionRequest = (id, business) => {
  return api.imageDeleteActionRequests(id, business);
};

function* imageDeleteAction({ id }) {
  try {
    const business = yield select((store) => store.business);
    const result1 = yield call(imageDeleteActionRequest, id, business);
    if (result1.data) {
      const business = yield select((store) => store.business);
      const result = yield call(getBusinessGalleryRequest, business);
      if (result.data) {
        if (result.data.business_gallery.length > 0) {
          yield put({
            type: actionTypes.RESULT_GET_BUSINESS_GALLERY,
            value: result.data.business_gallery,
          });
        } else {
          yield put({
            type: actionTypes.RESULT_GET_BUSINESS_GALLERY,
            value: [],
          });
        }
      }
    }
  } catch (e) { }
}

const businessElementPhotoRequest = (business) => {
  return api.businessElementPhotoRequests(business);
};

function* businessElementPhoto() {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(businessElementPhotoRequest, business);
    if (result.data) {
      if (result.data.business_element_gallery.length > 0) {
        yield put({
          type: actionTypes.RESULT_GET_BUSINESS_ELEMENT_PHOTO,
          value: result.data.business_element_gallery,
        });
      } else {
        yield put({
          type: actionTypes.RESULT_GET_BUSINESS_ELEMENT_PHOTO,
          value: [],
        });
      }
    }
  } catch (e) { }
}

const getBusinessTypeRequest = (business) => {
  return api.getBusinessTypeRequests(business);
};

function* getBusinessType() {
  try {
    const business = yield select((store) => store.business);
    const result = yield call(getBusinessTypeRequest, business);
    if (result.data) {
      if (result.data.business_element.length > 0) {
        yield put({
          type: actionTypes.GET_BUSINESS_ELEMENT_TYPE,
          value: result.data.business_element,
        });
      } else {
        yield put({
          type: actionTypes.GET_BUSINESS_ELEMENT_TYPE,
          value: [],
        });
      }
    }
  } catch (e) { }
}

const saveReservationGuarationRequest = (value, business) => {
  return api.saveReservationGuarationRequests(value, business);
};
const updateReservationGuarationRequest = (value, business, option) => {
  return api.updateReservationGuarationRequests(value, business, option);
};
//
function* saveReservationGuaration({ value }) {
  try {
    const option = yield select((store) => store.option);
    const business = yield select((store) => store.business);
    if (option.businessSettings.length > 0) {
      const result = yield call(
        updateReservationGuarationRequest,
        value,
        business,
        option
      );
      if (result.data && result.data.business_settingsUpdate) {
        let settings = { ...(option.businessSettings?.[0] ?? {}) };
        settings.guaranteed_reservation =
          result.data.business_settingsUpdate.guaranteed_reservation;

        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: [settings],
        });
      }
    } else {
      const result = yield call(
        saveReservationGuarationRequest,
        value,
        business
      );
      if (result.data && result.data.business_settingsUpdate) {
        let settings = { ...(option.businessSettings?.[0] ?? {}) };
        settings.guaranteed_reservation =
          result.data.business_settingsUpdate.guaranteed_reservation;

        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: [settings],
        });
      }
    }
  } catch (e) { }
}
//reservation time
const saveReservationTimeGuarationRequest = (value, value2, business) => {
  return api.saveReservationTimeGuarationRequests(value, value2, business);
};
const updateReservationTimeGuarationRequest = (
  value,
  value2,
  business,
  option
) => {
  return api.updateReservationTimeGuarationRequests(
    value,
    value2,
    business,
    option
  );
};
//
function* saveReservationTimeGuaration({ value, value2 }) {
  try {
    const option = yield select((store) => store.option);
    const business = yield select((store) => store.business);
    if (option.businessSettings.length > 0) {
      const result = yield call(
        updateReservationTimeGuarationRequest,
        value,
        value2,
        business,
        option
      );
      if (result.data && result.data.business_settingsUpdate) {
        let settings = { ...(option.businessSettings?.[0] ?? {}) };
        settings.refund_threshold =
          result.data.business_settingsUpdate.refund_threshold;

        settings.guaranteed_reservation =
          result.data.business_settingsUpdate.guaranteed_reservation;

        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: [settings],
        });
      }
    } else {
      const result = yield call(
        saveReservationTimeGuarationRequest,
        value,
        value2,
        business
      );
      if (result.data && result.data.business_settingsUpdate) {
        let settings = { ...(option.businessSettings?.[0] ?? {}) };
        settings.refund_threshold =
          result.data.business_settingsUpdate.refund_threshold;
        settings.guaranteed_reservation =
          result.data.business_settingsUpdate.guaranteed_reservation;

        yield put({
          type: actionTypes.RESULT_GET_SETTING,
          value: [settings],
        });
      }
    }
  } catch (e) { }
}

// **********

const saveEstimatedRequest = (count, business) => {
  return api.saveEstimatedRequests(count, business);
};
const updateEstimatedRequest = (count, business, option) => {
  return api.updateEstimatedRequests(count, business, option);
};

function* saveEstimatedSaga({ count }) {
  try {
    const option = yield select((store) => store.option);
    const business = yield select((store) => store.business);
    if (option.businessSettings.length > 0) {
      const result = yield call(
        updateEstimatedRequest,
        count,
        business,
        option
      );
    } else {
      const result = yield call(saveEstimatedRequest, count, business);
    }
  } catch (e) { }
}

const saveIntervalTimeRequest = (count, business) => {
  return api.saveIntervalTimeRequests(count, business);
};
const updateIntervalTimeRequest = (count, business, option) => {
  return api.updateIntervalTimeRequests(count, business, option);
};

function* saveIntervalTimeSaga({ count }) {
  try {
    const option = yield select((store) => store.option);
    const business = yield select((store) => store.business);
    if (option.businessSettings.length > 0) {
      const result = yield call(
        updateIntervalTimeRequest,
        count,
        business,
        option
      );
    } else {
      const result = yield call(saveIntervalTimeRequest, count, business);
    }
  } catch (e) { }
}

////

const getMustArriveByRequest = (business) => {
  return api.getMustArriveBy(business);
};

function* getMustArriveByData() {
  try {
    const business = yield select((store) => store.business);
    if (business) {
      const result = yield call(getMustArriveByRequest, business);
    }
  } catch (e) { }
}

///

const saveMoneySelectRequest = (name, business) => {
  return api.saveMoneySelectRequests(name, business);
};
const updateMoneySelectRequest = (name, business, option) => {
  return api.updateMoneySelectRequests(name, business, option);
};

function* saveMoneySelectSaga({ name }) {
  try {
    const option = yield select((store) => store.option);
    const business = yield select((store) => store.business);
    if (option.businessSettings.length > 0) {
      const result = yield call(
        updateMoneySelectRequest,
        name,
        business,
        option
      );
    } else {
      const result = yield call(saveMoneySelectRequest, name, business);
    }
  } catch (e) { }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actionTypes.GET_RULE, getRule),
    takeLatest(actionTypes.GET_MUST_ARRIVE_BY, getMustArriveByData),

    takeLatest(actionTypes.SAVE_RULE, saveRule),
    takeLatest(actionTypes.GET_FACILITIES, getFacilitiesAction),
    takeLatest(createFacility, createFacilitiesAction),
    takeLatest(actionTypes.DELETE_FACILITIES, deleteFacilitiesAction),
    takeLatest(actionTypes.GET_EXTRA_SUNBED_PHOTO, getExtraSunbedPhotoAction),
    takeLatest(saveExtraSunbedPhoto, saveExtraSunbedPhotoAction),
    takeLatest(saveWaiterCall, saveWaiterCallAction),
    takeLatest(saveAutoAccepted, saveAutoAcceptedAction),
    takeLatest(saveTowelIncluded, saveTowelIncludedAction),
    takeLatest(saveContactCall, saveContactAction),

    takeLatest(actionTypes.GET_BEACH_LOCATION, getBeachLocationAction),
    takeLatest(saveBeachLocation, saveBeachLocationAction),
    takeLatest(actionTypes.GET_BUSINESS_GALLERY, getBusinessGallery),
    takeLatest(actionTypes.IMAGE_DELETE_ACTION, imageDeleteAction),
    takeLatest(actionTypes.BUSINESS_ELEMENT_PHOTO, businessElementPhoto),
    takeLatest(actionTypes.GET_BUSINESS_TYPE, getBusinessType),
    takeLatest(
      actionTypes.SAVE_RESERVATION_GRARANTEED,
      saveReservationGuaration
    ),
    takeLatest(
      actionTypes.SAVE_RESERVATION_TIME_GRARANTEED,
      saveReservationTimeGuaration
    ),

    takeLatest(actionTypes.SAVE_ESTIMATED_TIME, saveEstimatedSaga),
    takeLatest(actionTypes.SAVE_INTERVAL_TIME, saveIntervalTimeSaga),
    takeLatest(actionTypes.SAVE_MONEY_SELECT, saveMoneySelectSaga),
    takeLatest(saveBusinessSettings, saveBusinessSettingAction),
  ]);
}

import { all, call, put, takeLatest } from "redux-saga/effects";
import { getSmsLogs, actionTypes } from "./action";
import API from "./api";

const api = new API();

const getSmsLogsRequest = (businessId) => {
  return api.getSmsLogs(businessId);
};

function* getSmsLogsAction(action) {
  try {
    const { businessId } = action.payload;
    const result = yield call(getSmsLogsRequest, businessId);
    if (result.data) {
      yield put({
        type: actionTypes.SET_SMS_LOGS,
        payload: result.data,
      });
    }
  } catch (error) { }
}

export default function* rootSaga() {
  yield all([takeLatest(getSmsLogs, getSmsLogsAction)]);
}

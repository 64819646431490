import { createPromiseAction } from "@adobe/redux-saga-promise";
import axios from "axios";
import { calls } from "../../utils/calls.js";
import { createError } from "../../utils/utils.js";
//import swal from "sweetalert";

export const actionTypes = {
  GET_PLANS: "GET_PLAN",
  GET_ACTIVE_PLAN: "GET_ACTIVE_PLAN",
  SET_PLANS : "SET_PLANS",
  SET_ACTIVE_PLAN : "SET_ACTIVE_PLAN",
  INACTIVE_OLD_PLAN: "INACTIVE_OLD_PLAN",
  SET_SELECTED_PLAN:"SET_SELECTED_PLAN",
  GET_ADDITIONAL_PLANS:"GET_ADDITIONAL_PLANS",
  SET_ADDITIONAL_PLANS:"SET_ADDITIONAL_PLANS",
  SELECT_ADDITIONAL_PLAN : "SELECT_ADDITIONAL_PLAN"
};

export function initialize() {
  const init = {};
  return { type: actionTypes.INITIAL_STATE, init };
}

export const getPlans =  createPromiseAction(actionTypes.GET_PLANS, () => ({}));

export const getActivePlan =  createPromiseAction(actionTypes.GET_ACTIVE_PLAN, (businessId) => ({businessId}));

export const inactiveOldPlan =  createPromiseAction(actionTypes.INACTIVE_OLD_PLAN, (id) => ({id}));

export const setSelectedPlan =  createPromiseAction(actionTypes.SET_SELECTED_PLAN, (plan) => ({plan}));

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import { InputGroup, FormControl } from "react-bootstrap";
import { getExtraSunbedPhoto, saveBusinessSettings, saveExtraSunbedPhoto } from "../../../../redux/option/action";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from "../../../../redux/loader/action";
import { createNotification } from "../../../../utils/utils";

const GuestLimit = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const option = useSelector(({ option }) => option);
  const [maxGuestAllowed, setMaxGuestAllowed] = useState("");
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if (option.businessSettings.length > 0) {
      setMaxGuestAllowed(
        option.businessSettings[0].max_guest_allowed
          ? option.businessSettings[0].max_guest_allowed
          : ""
      );
    } else {
      setMaxGuestAllowed("");
    }
  }, [option.businessSettings]);

  const updateStateValue = (event) => {
    setDisableSave(false);
    if (!event.target.value) {
      setDisableSave(true);
    }
    setMaxGuestAllowed(event.target.value);
  };
  const saveSetting = (event) => {
    let mutation = `{
      id : ${option.businessSettings[0].id}
      max_guest_allowed: ${maxGuestAllowed}
    }`;
    dispatch(startLoading());
    return dispatch(saveBusinessSettings(mutation)).then(data => {
      dispatch(stopLoading());
      dispatch(getExtraSunbedPhoto());
    }).catch(err => {
      dispatch(stopLoading());
    });
  };

  return (
    <>
      <Card className="options-card">
        <Row className="p-3">
          <Col md="10">
            <h5 className="option-title-main">{t('max_guest_limit_title')}</h5>
            <p className="option-title-sub mb-3">{t('max_guest_limit_description')}</p>
          </Col>
          <Col md="10">
            <div>
              <InputGroup className="mb-3 btn-input-group">
                <FormControl
                  type="number"
                  id="guest_limit"
                  name="guest_limit"
                  onChange={updateStateValue}
                  value={maxGuestAllowed}
                  placeholder={t("max_guest_limit")}
                />
                <InputGroup.Append>
                  <Button variant="primary" onClick={saveSetting} disabled={disableSave}>
                    {t("Save")}
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </Card>

      <div className="card-divider-line"></div>
    </>
  );
};

export default GuestLimit;

/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { PureComponent } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import NotificationAlert from "react-notification-alert";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "../routes.js";
// import CustomModal from "../components/Templates/CustomModal.jsx";
// import { Button, Col, FormGroup } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { agreeStripeTOS } from "../redux/business/action.js";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
//import {createNotification} from '../utils/utils'
//import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
var ps;

class Admin extends PureComponent {
  state = {
    sidebarMini: true,
    backgroundColor: "blue",
    spinnedLoading: true,
    sidebarToggle: React.createRef(),
  };
  notificationAlert = React.createRef();
  mainPanel = React.createRef();

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.mainPanel.current);
    }
    axios.defaults.headers.common["X-TOKEN"] = this.props.token;
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    // if (e.history.action === "PUSH") {
    //   document.documentElement.scrollTop = 0;
    //   document.scrollingElement.scrollTop = 0;
    //   this.mainPanel.current.scrollTop = 0;
    // }
  }

  minimizeSidebar = () => {
    var message = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      message += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      message += "activated...";
    }
    document.body.classList.toggle("sidebar-mini");
    var options = {};
    options = {
      place: "tr",
      message: message,
      type: "info",
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7,
    };
    // this.notificationAlert.current.notificationAlert(options);
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        if (prop.subMenu) {
          return this.getRoutes(prop.subMenu);
        }
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact || false}
          />
        );
      } else if (prop.layout === "/admin" && prop.path === "/wizard") {
      } else {
        return null;
      }
      return null;
    });
  };

  getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  _agreeStripeTOS = () => {
    const { business, dispatch } = this.props;

    agreeStripeTOS(business?.filterBusinessList?.id, dispatch);
  };
  openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.state.sidebarToggle.current.classList.toggle("toggled");
  };
  render() {
    const { business, loader, t } = this.props;

    return (
      <LoadingOverlay
        active={loader.loading}
        spinner={<BeatLoader size="30" color="#fff" />}
      >
        <div className="wrapper">
          <ReactNotifications />
          <NotificationAlert ref={this.notificationAlert} />
          <Sidebar
            {...this.props}
            routes={routes.filter((route) => route.layout === "/admin")}
            minimizeSidebar={this.minimizeSidebar}
            ref={this.state.sidebarToggle}
            openSidebar={this.openSidebar}
            backgroundColor={this.state.backgroundColor}
          />
          <div className="main-panel" ref={this.mainPanel}>
            <AdminNavbar
              {...this.props}
              ref={this.state.sidebarToggle}
              openSidebar={this.openSidebar}
              brandText={this.getActiveRoute(routes)}
            />
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="/admin" to="/admin/settings" />
            </Switch>
          </div>
          {/* <FixedPlugin
          handleMiniClick={this.minimizeSidebar}
          sidebarMini={this.state.sidebarMini}
          bgColor={this.state.backgroundColor}
          handleColorClick={this.handleColorClick}
        /> */}
          {/*<CustomModal*/}
          {/*	open={ business.stripeToSRequired && !business.acceptedToS }*/}
          {/*	body={ (*/}
          {/*		<>*/}
          {/*			<div>*/}
          {/*				<h6>{ t('acceptStripeToS') }</h6>*/}
          {/*			</div>*/}
          {/*			<Col>*/}
          {/*				<FormGroup style={ {*/}
          {/*					display: 'flex',*/}
          {/*					alignItems: 'center',*/}
          {/*					marginBottom: 0,*/}
          {/*				} }>*/}
          {/*					<p>*/}
          {/*						{ t('acceptStripeToS') }{ ' ' }*/}
          {/*						<a*/}
          {/*							href={ 'https://stripe.com/en-ro/legal' }*/}
          {/*							style={ { marginTop: 4, marginLeft: 4, } }>*/}
          {/*							https://stripe.com/en-ro/legal*/}
          {/*						</a>*/}
          {/*					</p>*/}
          {/*				</FormGroup>*/}
          {/*				<hr/>*/}
          {/*			</Col>*/}
          {/*		</>*/}
          {/*	) }*/}
          {/*	actions={ (*/}
          {/*		<Button*/}
          {/*			color="success"*/}
          {/*			onClick={ this._agreeStripeTOS }>*/}
          {/*			{ t('accept') }*/}
          {/*		</Button>*/}
          {/*	) }*/}
          {/*/>*/}
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (store) => ({
  business: store.business,
  loader: store.loader,
  token: store.business.accessToken,
});

export default withTranslation()(connect(mapStateToProps)(Admin));

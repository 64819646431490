import React, { useState } from 'react';

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';

// core components
import nowLogo from '../../assets/img/tabo-logo.png';
import { useTranslation } from 'react-i18next';
import bgImage from '../../assets/img/bg.jpg';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { REST_API_URL } from '../../utils/constants.js';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading, stopLoading } from '../../redux/loader/action';
import { createNotification } from '../../utils/utils';

const ConfirmPassword = ({ match }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [state, setState] = useState({
		passwordFocus: false,
		confirmPasswordFocus: false,
	});
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [compareStatus, setCompareStatus] = useState(true);
	const [errorStatus, setErrorStatus] = useState(true);
	const confirmPasswordAction = () => {
		if (password === confirmPassword) {
			setCompareStatus(true);
			dispatch(startLoading())
			axios.post(`${ REST_API_URL }/confirmPassword`, { password: password, linkId: match.params.id })
				.then((response) => {
					if (response) {
						if (response.data.status == 'success') {
							createNotification({type:'success',title:t('confirm password'), message:t('reset password success')});
							history.push('/login');
						} else {
							setErrorStatus(false);
						}
					}
					dispatch(stopLoading())
				}).catch((error) => {
					createNotification({type:'danger',title:'confirm password', message:error.response.data.status});
					dispatch(stopLoading())
			});
		} else {
			setCompareStatus(false);
		}
	};
	return (
		<>
			<div className="content">
				<div className="login-page">
					<Container>
						<Col xs={ 12 } md={ 8 } lg={ 4 } className="ml-auto mr-auto">
							<Form>
								<Card className="card-login card-plain">
									<CardHeader>
										<div className="logo-container">
											<img src={ nowLogo } alt="smart-beach-logo"/>
										</div>
									</CardHeader>
									<CardBody>
										<InputGroup
											className={
												'no-border form-control-lg ' +
												( state.passwordFocus ? 'input-group-focus' : '' )
											}
										>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="now-ui-icons ui-1_lock-circle-open"/>
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type="password"
												placeholder="New password"
												onChange={
													(e) => { setPassword(e.target.value); }
												}
												onFocus={ (e) =>
													setState({ ...state, passwordFocus: true })
												}
												onBlur={ (e) =>
													setState({ ...state, passwordFocus: false })
												}
											/>
										</InputGroup>
										<InputGroup
											className={
												'no-border form-control-lg ' +
												( state.confirmPasswordFocus ? 'input-group-focus' : '' )
											}
										>
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="now-ui-icons ui-1_lock-circle-open"/>
												</InputGroupText>
											</InputGroupAddon>
											<Input
												type="password"
												placeholder="Confirms password"
												onChange={
													(e) => { setConfirmPassword(e.target.value); }
												}
												onFocus={ (e) =>
													setState({ ...state, confirmPasswordFocus: true })
												}
												onBlur={ (e) =>
													setState({ ...state, confirmPasswordFocus: false })
												}
											/>
										</InputGroup>
										{ !compareStatus && (
											<p className="text-danger mb-0">password is not match</p>
										) }
										{ !errorStatus && (
											<p className="text-danger mb-0">Your link is wrong.</p>
										) }
									</CardBody>
									<CardFooter>
										<Button
											block
											color="primary"
											size="lg"
											className="mb-3 btn-round"
											onClick={ confirmPasswordAction }
										>
											Confirm
										</Button>
									</CardFooter>
								</Card>
							</Form>
						</Col>
					</Container>
				</div>
			</div>
			<div
				className="full-page-background"
				style={ { backgroundImage: 'url(' + bgImage + ')' } }
			/>
		</>
	);
};

export default ConfirmPassword;

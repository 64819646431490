import { createPromiseAction } from "@adobe/redux-saga-promise";

export const actionTypes = {
  SET_SELECTED_AMOUNT: "SET_SELECTED_AMOUNT",
  SET_CHANGE_AMOUNT: "SET_CHANGE_AMOUNT",
  SET_IS_AUTORECHARGE: "SET_IS_AUTORECHARGE",
  SET_TOPUP_HISTORY: "SET_TOPUP_HISTORY",
  SET_SMS_BALANCE: "SET_SMS_BALANCE",
  GET_SMS_LOGS: "GET_SMS_LOGS",
  SET_SMS_LOGS: "SET_SMS_LOGS",
};

export function initialize() {
  const init = {};
  return { type: actionTypes.INITIAL_STATE, init };
}

export const setSelectedAmount = (amount) => {
  return { type: actionTypes.SET_SELECTED_AMOUNT, amount };
};

export const setChangeAmount = (amount) => {
  return { type: actionTypes.SET_CHANGE_AMOUNT, amount };
};

export const setIsAutoRecharge = (isAutoRecharge) => {
  return { type: actionTypes.SET_IS_AUTORECHARGE, isAutoRecharge };
};

export const setTopupHistory = (history) => {
  return { type: actionTypes.SET_TOPUP_HISTORY, history };
};

export const setSmsbalance = (balance) => {
  return { type: actionTypes.SET_SMS_BALANCE, balance };
};

export const getSmsLogs = createPromiseAction(
  actionTypes.GET_SMS_LOGS,
  (businessId) => ({ businessId })
);

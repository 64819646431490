import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { historyHeader } from "../../../description/sms.description";
import ReactTable from "../../../components/ReactTable/ReactTable";
import axios from "axios";
import { calls } from "../../../utils/calls";
import { setTopupHistory } from "../../../redux/sms/action";

const SmsHistory = () => {
  const { businessData, userId } = useSelector(({ business }) => business);

  const { topupHistory } = useSelector(({ sms }) => sms);
  const dispatch = useDispatch();
  const { id } = businessData;

  useEffect(() => {
    if (businessData) {
      getTopupHistory();
    }
  }, [businessData]);

  const getTopupHistory = async () => {
    try {
      const res = await axios(
        calls.getTopupHistory(userId, { business_id: id })
      );
      if (res) {
        let data;
        data = res?.data?.filter(
          (historyData) => historyData?.business_id === id
        );
        dispatch(setTopupHistory(data));
      }
    } catch (error) {
    }
  };

  const renderHistoryData = () => {
    return (
      <ReactTable
        data={topupHistory}
        showGeneralSearch
        isSearchable={false}
        // showOnMobileView={true}
        columns={historyHeader}
        isMsgDisplay={true}
        isPrint={false}
        hasLastKeySortable={true}
      />
    );
  };
  return (
    <div className="content bg-white">
      <div className="max-w-[1300px] mx-auto px-5">
        <h4 className="font-semibold my-4">History</h4>
        <div className="overflow-y-auto pb-4 history-table">
          {renderHistoryData()}
        </div>
      </div>
    </div>
  );
};

export default SmsHistory;

import React, { Fragment, PureComponent } from "react";
import {
  Col,
  Row,
  Card,
  Input,
  Button,
  CardBody,
  CardHeader,
  CardFooter,
  Label,
} from "reactstrap";
import axios from "axios";
import QRCode from 'qrcode.react';
import domtoimage from "dom-to-image";
import { connect } from "react-redux";
// import * as htmlToImage from "html-to-image";
import { withTranslation } from "react-i18next";
// import { Generator } from "contrast-color-generator";
import ColorContrastChecker from "color-contrast-checker";

import { startLoading, stopLoading } from "../../../redux/loader/action";
import { createNotification } from "../../../utils/utils";
import { calls } from "../../../utils/calls.js";

class UploadMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      uploadedFile: {},
      previewFileUrl: "",
      uploadingDocument: false,
      bgColorQRCode: "#FFFFFF",
      fgColorQRCode: "#000000",
      logoUrl: null,
      error: "",
      borderAdded: true,
      borderColor: "",
      logoWidth: "",
      logoHeight: "",
      contrastError: false,
      QRcanvas: "",
      showCanvas: false,
      fetching: true,
    };

    this.inputRef = React.createRef();
    this.editInputRef = React.createRef();
    this.formData = new FormData();
  }

  componentDidMount() {
    this.getMenu();
  }

  componentDidUpdate(prevProps) {
    //this.getMenu();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.business && nextProps.business.filterBusinessList) {
      if (
        !this.props.business ||
        !this.props.business.filterBusinessList ||
        this.props.business.filterBusinessList.id !=
        nextProps.business.filterBusinessList.id
      ) {
        this.getMenu(nextProps.business.filterBusinessList.id);
      }
    }
  }

  getMenu = async (id = null) => {
    const { dispatch, business } = this.props;
    dispatch(startLoading());
    try {
      if (!id) id = business.filterBusinessList.id;
      const res = await axios(calls.getMenu(id));
      const { url = "", bg_color = "", fg_color = "", qr_code_border = "", logo_url = "" } = res?.data?.data ?? {};
      this.setState({
        previewFileUrl: url,
        bgColorQRCode: bg_color,
        fgColorQRCode: fg_color,
        //logoUrl: logo_url,
        borderAdded: qr_code_border,
        borderColor: fg_color,
        showCanvas: false,
      });

      if (logo_url) {
        const data = await this.getBase64FromUrl(logo_url);
        this.setState({ logoUrl: data }, () => {
          setTimeout(this.convertToCanvas(), 1000);
        });
      } else {
        this.setState({ logoUrl: null }, () => {
          setTimeout(this.convertToCanvas(), 1000);
        });
      }
    } catch (error) { } finally {
      dispatch(stopLoading());
      this.setState({ fetching: false });
    }
  };

  UpdateQRCode = (e, delete_logo_url) => {
    const { t } = this.props;
    // var ccc = new ColorContrastChecker();
    // if (ccc.isLevelAA(this.state.bgColorQRCode, this.state.fgColorQRCode, 14) === false) {
    //     createNotification({
    //         type: "danger",
    //         title: t("Invalid Contrast Color"),
    //         message: t("Please Select Valid Contrast Color."),
    //     });
    //     return false;
    // }
    // if (this.state.contrastError) return false
    const { dispatch } = this.props;
    // e.preventDefault();
    // e.persist();
    if (delete_logo_url) this.formData.append("delete_logo_url", true);
    if (this.state.borderAdded === true)
      this.formData.append("qr_code_border", Boolean(this.state.borderAdded));
    this.formData.append("bg_color", this.state.bgColorQRCode);

    this.formData.append("fg_color", this.state.fgColorQRCode);
    this.formData.append(
      "businessId",
      this.props.business?.filterBusinessList?.id
    );
    dispatch(startLoading());
    axios(
      calls.updateMenuQrcode(
        this.formData,
        this.props.business?.filterBusinessList?.id
      )
    )
      .then((res) => {
        // this.setState({
        //     previewFileUrl: res.data.data.previewUrl,
        //     logoUrl: res.data.data.logo_url,
        //     showCanvas: false
        // }, () => {
        //     setTimeout(this.convertToCanvas(), 1000);
        // });
        // createNotification({
        //     type: "success",
        //     title: t("Update_QR_Code"),
        //     message: t("Update_QR_Code_Success"),
        // });
        this.formData = new FormData();
      })
      .catch((err) => {
        this.setState({
          error: err,
        });
        createNotification({
          type: "danger",
          title: t("Update_QR_Code"),
          message: t("Update_QR_Code_Error")
        });
      })
      .finally(() => {
        this.setState({
          uploadingDocument: false,
        });
        dispatch(stopLoading());
        this.getMenu();
      });
  };
  handleUpload = (e) => {
    const { t, dispatch } = this.props;
    e.preventDefault();
    e.persist();
    this.setState(
      {
        uploadedFile: e.target.files[0],
        uploadingDocument: true,
      },
      () => {
        let data = new FormData();
        data.append("menu", e.target.files[0]);
        data.append("businessId", this.props.business?.filterBusinessList?.id);
        dispatch(startLoading());
        axios(
          calls.uploadMenu(data, this.props.business?.filterBusinessList?.id)
        )
          .then((res) => {
            this.setState({
              previewFileUrl: res.data.data.previewUrl,
            });
            createNotification({
              type: "success",
              title: t("Upload_Menu"),
              message: t("Upload_Menu_Success"),
            });
          })
          .catch((err) => {
            this.setState({
              error: err,
            });
            createNotification({
              type: "danger",
              title: t("Upload_Menu"),
              message: t("Upload_Menu_Error"),
            });
          })
          .finally(() => {
            this.setState({
              uploadingDocument: false,
            });
            dispatch(stopLoading());
            this.getMenu();
          });
      }
    );
  };
  clickInput = () => {
    this.inputRef.click();
  };

  openInNewTab = () => {
    const { previewFileUrl } = this.state;

    const newWindow = window.open(
      previewFileUrl,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) {
      newWindow.opener = null;
    }
  };
  logoImageHandle = (e) => {
    this.formData.delete("logo");
    this.formData.append("logo", e.target.files[0]);
    this.setState(
      {
        logoUrl: URL.createObjectURL(e.target.files[0]),
      },
      () => {
        this.UpdateQRCode();
      }
    );
  };
  onImgLoad = ({ target: img }) => {
    if (img) {
      let imageWidth = img.width;
      let imageHeight = img.height;
      const imgHW = 30;
      if (img.width > imgHW) {
        imageWidth = imgHW;
        imageHeight = (imageHeight * imgHW) / img.width;
      } else if (img.height > imgHW) {
        imageWidth = (imageWidth * imgHW) / img.height;
        imageHeight = imgHW;
      }
      this.setState(
        {
          logoWidth: imageWidth,
          logoHeight: imageHeight,
          showCanvas: false,
        },
        () => {
          if (this.state.previewFileUrl) {
            setTimeout(this.convertToCanvas, 1000);
          }
        }
      );
    }
  };
  validateContrast = () => {
    const { bgColorQRCode, fgColorQRCode } = this.state;
    var ccc = new ColorContrastChecker();
    if (ccc.isLevelAA(bgColorQRCode, fgColorQRCode, 14) === false) {
      this.setState({
        contrastError: true,
      });
    } else {
      this.setState({
        contrastError: false,
      });
    }
  };
  bgColorSelect = (e) => {
    const { bgColorQRCode } = this.state;
    const { fgColorQRCode } = this.state;
    this.setState({
      bgColorQRCode: e.target.value,
      showCanvas: false,
    });
    //this.validateContrast();
  };

  bgblur = (e) => {
    this.UpdateQRCode();
  };
  Addborder = (e) => {
    const { fgColorQRCode } = this.state;
    this.setState({
      borderAdded: e.target.checked,
      borderColor: fgColorQRCode,
    });
  };
  fgColorSelect = (e) => {
    this.setState({
      fgColorQRCode: e.target.value,
      borderColor: e.target.value,
      showCanvas: false,
    });
    //this.validateContrast();
  };
  DownloadQrCode = () => {
    const { dispatch } = this.props;
    dispatch(startLoading());
    this.setState(
      {
        showCanvas: false,
      },
      () => {
        setTimeout(() => {
          domtoimage
            .toSvg(document.getElementById("my-node-QRCode"))
            .then((dataUrl) => {
              var link = document.createElement("a");
              link.download = "PoweredByTABO.svg";
              link.href = dataUrl;
              link.click();
              dispatch(stopLoading());
              this.convertToCanvas();
            });
        }, 1000);
      }
    );
  };
  convertToCanvas = async () => {
    let canvasQR = "";
    const el = document.getElementById("my-node-QRCode");
    if (el) {
      domtoimage.toPng(el).then((dataURL) => {
        // canvasQR = canvas
        // canvas.width = 290;
        // canvas.height = 335;
        // const dataURL = canvas.toDataURL("image/png");
        this.setState(
          {
            QRcanvas: dataURL,
          },
          () => {
            this.setState({
              showCanvas: true,
            });
          }
        );
      });
    }
  };
  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };
  removeDocument = async () => {
    const { t, dispatch, business } = this.props;
    dispatch(startLoading());
    try {
      await axios(calls.deleteMenu(business?.filterBusinessList?.id));
      this.setState({
        previewFileUrl: "",
        uploadedFile: {},
      });
      dispatch(stopLoading());
      createNotification({
        type: "success",
        title: t("Remove_Menu"),
        message: t("Remove_Menu_Success"),
      });
    } catch (error) {
      dispatch(stopLoading());
      createNotification({
        type: "danger",
        title: t("Remove_Menu"),
        message: t("Remove_Menu_Error"),
      });
    }
  };

  _assignRef = (name) => (ref) => (this[name] = ref);

  getFileNameFromUrl = (url = "") => {
    let filename = '';
    try {
      filename = new URL(url).pathname.split('/').pop();
    } catch (e) {
      console.error(e);
    }
    return filename;
  }

  renderUploaderView = () => {
    const { t } = this.props;
    const { business } = this.props;
    const { fetching, previewFileUrl, contrastError, bgColorQRCode, fgColorQRCode } = this.state;

    if (fetching) {
      return null;
    }

    if (!previewFileUrl) {
      return (
        <Col md={6} sm={12}>
          <div class="file-upload">
            <Button className="btn-round file-upload-btn" onClick={this.clickInput}>
              {t("Add")}
            </Button>
            <div className="image-upload-wrap">
              <input
                ref={this._assignRef("inputRef")}
                accept="application/pdf"
                type="file"
                className="file-upload-input"
                multiple={false}
                onInput={this.handleUpload}
              />
              <div className="drag-text">
                <h3>{t("Drag and Drop File")}</h3>
              </div>
            </div>
          </div>
        </Col>
      );
    } else {
      return (
        <Col md={6} sm={12}>
          <Card>
            <CardHeader>
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
                <div className="menu-file-name" onClick={(e) => this.openInNewTab()}>
                  <p>{this.getFileNameFromUrl(previewFileUrl)}</p>
                </div>
                <div>
                  <Button onClick={() => this.editInputRef?.click()} color="primary" className="mr-4 custom-shadow-class">
                    <i className="fa fa-edit" />
                    <div style={{ display: "none", visibility: "hidden" }}>
                      <input
                        type="file"
                        multiple={false}
                        accept="application/pdf"
                        onInput={this.handleUpload}
                        ref={this._assignRef("editInputRef")}
                      />
                    </div>
                  </Button>
                  <Button className="custom-shadow-class" color="danger" onClick={this.removeDocument}>
                    <i className="fa fa-trash" />
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}>
                <div
                  className="bordered-qr"
                  id="my-node-QRCode"
                  style={{
                    backgroundColor: this.state.bgColorQRCode,
                    borderColor: this.state.fgColorQRCode,
                  }}
                >
                  <QRCode
                    size={150}
                    ecLevel="H"
                    logoOpacity={1}
                    renderAs={"svg"}
                    value={previewFileUrl}
                    bgColor={bgColorQRCode}
                    fgColor={fgColorQRCode}
                    logoWidth={this.state.logoWidth}
                    quietZone={this.state.borderAdded ? 10 : 0}
                    imageSettings={{
                      src: this.state.logoUrl,
                      width: this.state.logoWidth,
                      height: this.state.logoHeight,
                    }}
                  />
                  <p
                    className="powertabo"
                    style={{ color: this.state.fgColorQRCode }}
                  >
                    Powered by TABO
                  </p>
                </div>
                {
                  contrastError ? (
                    <p className="error-tip" style={{ height: "13px" }}>
                      {t("Invalid Contrast Color")}
                    </p>
                  ) : null
                }
              </div>
            </CardBody>
            <CardFooter>
              <div className="d-flex align-items-center justify-content-around mb-2 qr-buttons">
                <div className="d-flex align-items-center justify-content-center" style={{ position: "relative" }}>
                  <input
                    type="color"
                    id="bgcolor"
                    name="bgcolor"
                    onBlur={this.bgblur}
                    value={bgColorQRCode}
                    onChange={this.bgColorSelect}
                    className="btn custom-shadow-class"
                    style={{ paddingTop: "18px", paddingBottom: "18px", background: bgColorQRCode }}
                  />
                  <i className="fa fa-fill-drip" style={{ color: bgColorQRCode.toLowerCase() == "#FFFFFF".toLowerCase() ? "#000" : "#fff", position: "absolute", pointerEvents: "none" }} />
                </div>
                <div className="d-flex align-items-center justify-content-center" style={{ position: "relative" }}>
                  <input
                    type="color"
                    id="fgcolor"
                    name="fgcolor"
                    onBlur={this.bgblur}
                    value={fgColorQRCode}
                    onChange={this.fgColorSelect}
                    className="btn custom-shadow-class"
                    style={{ paddingTop: "18px", paddingBottom: "18px", background: fgColorQRCode }}

                  />
                  <i className="fa fa-paint-brush" style={{ color: fgColorQRCode.toLowerCase() == "#FFFFFF".toLowerCase() ? "#000" : "#fff", position: "absolute", pointerEvents: "none" }} />
                </div>
                {this.state.logoUrl ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Button
                      color="danger"
                      className="custom-shadow-class"
                      onClick={(e) => this.UpdateQRCode(e, true)}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                    <div style={{ display: "none", visibility: "hidden", height: "0px" }}>
                      <img
                        alt="logo"
                        onLoad={this.onImgLoad}
                        className="LogoPreview"
                        src={this.state.logoUrl}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <Button
                      color="primary"
                      className="custom-shadow-class"
                      onClick={(e) => this.logofile.click()}
                    >
                      <i className="fa fa-image" style={{ fontSize: "16px" }} />
                    </Button>
                    <div style={{ display: "none", visibility: "hidden", height: "0px" }}>
                      <input
                        ref={this._assignRef("logofile")}
                        type="file"
                        name="user[image]"
                        className="btn-file-input"
                        multiple={false}
                        onInput={this.logoImageHandle}
                      />
                    </div>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    color="info"
                    className="custom-shadow-class"
                    onClick={(e) => this.DownloadQrCode()}
                  >
                    <i className="fa fa-download" />
                  </Button>
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      );
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Fragment>
        <div
          className="content grid-planner-container options-container"
          style={{
            backgroundColor: '#fff',
            display: 'block',
          }}
        >
          <div className="content options-container" style={{ minHeight: "auto" }}>
            <Row className="grid-planner-row mt-4 menu-container-row">
              <Col md="12">
                <div className="page-heading">
                  <i
                    className="now-ui-icons files_paper"
                    style={{ fontSize: '24px' }}
                  ></i>
                  <h5>{t('Menu')}</h5>
                </div>
              </Col>
              {this.renderUploaderView()}
            </Row>
            {/* <Row className="my-4">
              <Col md="6" className={!previewFileUrl ? "mx-auto" : ""} style={{ minWidth: !previewFileUrl ? "400px" : undefined }}>
                <Card className="card-user" style={{ height: "100%" }}>
                  <CardHeader className="px-4">
                    <h5 className="title text-center mb-0">{t("Menu Uploader")}</h5>
                  </CardHeader>
                  <CardBody
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      style={{ visibility: "hidden", width: 0, height: 0 }}
                      ref={this._assignRef("inputRef")}
                      accept="application/pdf"
                      type="file"
                      multiple={false}
                      onInput={this.handleUpload}
                    />
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Col>
                        {previewFileUrl ? (
                          <div
                            className="uploaded-menu"
                            style={{ height: "300px" }}
                          >
                            <embed
                              className="pdfView"
                              style={{ zIndex: 0, overflow: "hidden" }}
                              src={previewFileUrl + "#toolbar=0&scrollbar=0"}
                              type="application/pdf"
                              frameBorder="0"
                              width="100%"
                              height="100%"
                            />
                            <div
                              onClick={this.openInNewTab}
                              className="open-uploaded-menu"
                            />
                          </div>
                        ) : uploadingDocument ? (
                          <div
                            style={{
                              width: 300,
                              height: 300,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontWeight: 600,
                              fontSize: "1.5rem",
                            }}
                          >
                            {t("Uploading...")}
                          </div>
                        ) : (
                          <img
                            src={`/image_placeholder.jpg`}
                            alt="Menu placeholder"
                            width="300px"
                            height="300px"
                          />
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <div className="d-flex align-items-center justify-content-around mb-2">
                      {previewFileUrl ? (
                        <Fragment>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              color="primary"
                              style={{ height: "35px" }}
                              className="custome-color-picker p-0"
                              onClick={this.clickInput}
                            >
                              <i className="fa fa-edit" />
                            </Button>
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              color="danger"
                              style={{ height: "35px" }}
                              onClick={this.removeDocument}
                              className="custome-color-picker p-0"
                            >
                              <i className="fa fa-trash" />
                            </Button>
                          </div>
                        </Fragment>
                      ) : (
                        <div className="d-flex align-items-center justify-content-center">
                          <Button
                            color="primary"
                            onClick={this.clickInput}
                            style={{ height: "35px" }}
                            className="custome-color-picker p-0"
                          >
                            <i className="fa fa-upload" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              {previewFileUrl ? (
                <Col md="6">
                  <Card style={{ height: "100%", }}>
                    <CardHeader className="px-4">
                      <h5 className="title text-center mb-0">
                        {t("QRcode Genetrator")}
                      </h5>
                    </CardHeader>
                    <CardBody>
                      {previewFileUrl ? (
                        <div style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                        }}>
                          <div
                            className="bordered-qr"
                            id="my-node-QRCode"
                            style={{
                              backgroundColor: this.state.bgColorQRCode,
                              borderColor: this.state.fgColorQRCode,
                            }}
                          >
                            <QRCode
                              size={170}
                              ecLevel="H"
                              logoOpacity={1}
                              renderAs={"svg"}
                              bgColor={bgColorQRCode}
                              fgColor={fgColorQRCode}
                              logoWidth={this.state.logoWidth}
                              quietZone={this.state.borderAdded ? 10 : 0}
                              value={`https://tabo.app/location/${business.filterBusinessList.slug}/menu`}
                              imageSettings={{
                                src: this.state.logoUrl,
                                width: this.state.logoWidth,
                                height: this.state.logoHeight,
                              }}
                            />
                            <p
                              className="powertabo"
                              style={{ color: this.state.fgColorQRCode }}
                            >
                              Powered by TABO
                            </p>
                          </div>
                          <p className="error-tip" style={{ height: "13px" }}>
                            {this.state.contrastError
                              ? t("Invalid Contrast Color")
                              : ""}
                          </p>
                        </div>
                      ) : null}
                    </CardBody>
                    <CardFooter>
                      <div className="d-flex align-items-center justify-content-around mb-2 qr-buttons">
                        <div className="d-flex align-items-center justify-content-center" style={{ position: "relative" }}>
                          <Input
                            type="color"
                            id="bgcolor"
                            name="bgcolor"
                            onBlur={this.bgblur}
                            value={bgColorQRCode}
                            onChange={this.bgColorSelect}
                            style={{ background: bgColorQRCode }}
                            className="color-input custome-color-picker"
                          />
                          <i className="fa fa-fill-drip" style={{ color: bgColorQRCode == "#FFFFFF" ? "#000" : "#fff", position: "absolute", pointerEvents: "none" }} />
                        </div>
                        <div className="d-flex align-items-center justify-content-center" style={{ position: "relative" }}>
                          <Input
                            type="color"
                            id="fgcolor"
                            name="fgcolor"
                            onBlur={this.bgblur}
                            value={fgColorQRCode}
                            onChange={this.fgColorSelect}
                            style={{ background: fgColorQRCode }}
                            className="color-input custome-color-picker"
                          />
                          <i className="fa fa-paint-brush" style={{ color: fgColorQRCode == "#FFFFFF" ? "#000" : "#fff", position: "absolute", pointerEvents: "none" }} />
                        </div>
                        {this.state.logoUrl ? (
                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              color="danger"
                              className="custome-color-picker p-0"
                              style={{ height: "35px" }}
                              onClick={(e) => this.UpdateQRCode(e, true)}
                            >
                              <i className="fa fa-trash" />
                            </Button>
                            <div style={{ display: "none", visibility: "hidden", height: "0px" }}>
                              <img
                                alt="logo"
                                onLoad={this.onImgLoad}
                                className="LogoPreview"
                                src={this.state.logoUrl}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              color="primary"
                              style={{ height: "35px" }}
                              className="custome-color-picker p-0 d-flex align-items-center justify-content-center"
                              onClick={(e) => this.logofile.click()}
                            >
                              <i className="fa fa-image" style={{ fontSize: "16px" }} />
                            </Button>
                            <div style={{ display: "none", visibility: "hidden", height: "0px" }}>
                              <input
                                ref={this._assignRef("logofile")}
                                type="file"
                                name="user[image]"
                                className="btn-file-input"
                                multiple={false}
                                onInput={this.logoImageHandle}
                              />
                            </div>
                          </div>
                        )}
                        <div className="d-flex align-items-center justify-content-center">
                          <Button
                            color="info"
                            style={{ height: "35px" }}
                            className="custome-color-picker p-0"
                            onClick={(e) => this.DownloadQrCode()}
                          >
                            <i className="fa fa-download" />
                          </Button>
                        </div>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              ) : null}
            </Row> */}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (store) => ({
  business: store.business,
});

export default withTranslation()(connect(mapStateToProps)(UploadMenu));

/* eslint-disable no-console, react/no-multi-comp */

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe, InjectedStripeProps, StripeProvider, Elements } from 'react-stripe-elements';
import VirtualizedSelect from 'react-virtualized-select';
import { startLoading, stopLoading } from '../../../redux/loader/action';
import { calls } from '../../../utils/calls';

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize,
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': {
                    color: '#aab7c4',
                },
                ...(padding ? { padding } : {}),
            },
            invalid: {
                color: '#9e2146',
            },
        },
    };
};

export const StripePayment = ({ handlePay, isDisabled }) => {
    const { businessData, userId } = useSelector(({ business }) => business);
    const [selectedCard, setSelectedCard] = useState();
    const newCardOption = {
        id: -1,
        label: "New Card"
    };
    const [saveCards, setSavedCards] = useState([{ ...newCardOption }]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (businessData) {
            fetchCards(businessData.id);
        }
    }, [businessData]);

    const fetchCards = async (id) => {
        try {
            dispatch(startLoading())
            const res = await axios(
                calls?.getSavedCardForBusiness(
                    {
                        business_id: id,
                    },
                    userId
                )
            );
            if (res && res.data && res.data.length > 0) {
                const savedCard = [{ ...newCardOption }]
                setSavedCards([...savedCard, ...res.data.map(card => {
                    return {
                        id: card.id,
                        label: `XXXX-XXXX-XXXX-${card.card.last4}`
                    }
                })]);
                setSelectedCard(res.data[0].id)
            } else {
                setSelectedCard(-1)
            }
        } catch (e) {
            setSelectedCard(-1)
        }
        dispatch(stopLoading())

    }
    return (
        <>
            <div className='font-semibold mb-1 mt-4'> Select card</div>
            <VirtualizedSelect
                style={{
                    borderRadius: 6,
                    fontSize: 12,
                    borderColor: "#E5E5E5",
                    maxWidth: "320px",
                }}
                name="savedCard"
                valueKey="id"
                value={selectedCard}
                onChange={(card) => { setSelectedCard(card.id) }}
                clearable={false}
                labelKey="label"
                disabled={saveCards.length == 1}
                options={saveCards}
            />

            {
                selectedCard == -1 ? (
                    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
                        <Elements>
                            <SplitForm handlePay={handlePay} isDisabled={isDisabled} />
                        </Elements>
                    </StripeProvider>
                ) : (
                    <button type="button" className='stripe-payment-button' onClick={() => { handlePay({ card_id: selectedCard }) }} disabled={isDisabled}>Pay with stripe</button>
                )
            }

        </>

    );
};

class _SplitForm extends React.Component {

    handleSubmit = (ev) => {
        ev.preventDefault();
        if (this.props.stripe) {
            this.props.stripe
                .createToken()
                .then((payload) => {
                    this.props.handlePay(payload)
                }).catch(e => { });
        } else { }
    };
    render() {
        return (
            <form onSubmit={this.handleSubmit} className="stripe-form">
                <div>
                    <label>Card number</label>
                    <CardNumberElement
                        {...createOptions(this.props.fontSize)}
                    />
                </div>
                <div>
                    <div className='d-flex'>
                        <div className="d-flex-child">
                            <label>Expiration date</label>
                            <CardExpiryElement
                                {...createOptions(this.props.fontSize)}
                            />
                        </div>
                        <div className="d-flex-child">
                            <label>CVC</label>
                            <CardCVCElement
                                {...createOptions(this.props.fontSize)}
                            />
                        </div>
                    </div>

                </div>

                <button className='stripe-payment-button' disabled={this.props.isDisabled}>Pay with stripe</button>
            </form>
        );
    }
}
export const SplitForm = injectStripe(_SplitForm);
import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row, Button } from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import API from "../../../../redux/option/api";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from "../../../../redux/loader/action";
import { createNotification } from "../../../../utils/utils";
import { getHourWork } from "../../../../redux/grid/action";
import FormGroup from "reactstrap/lib/FormGroup";
import Label from "reactstrap/lib/Label";
import Input from "reactstrap/lib/Input";
import Switch from "@material-ui/core/Switch";
import {
  getExtraSunbedPhoto,
  saveBusinessSettings,
} from "../../../../redux/option/action";
import { apllySameSchedule } from "../../../../redux/grid/action";
import moment from "moment";
import Datetime from "../../../../components/DateTime";
const api = new API();
const MustArriveBy = (props) => {
  const { changeValueState } = props;
  const { t } = useTranslation();
  const [mustBuyArrive, setMustBuyArrive] = useState("");
  const grid = useSelector(({ grid }) => grid);
  const business = useSelector(({ business }) => business);
  const [workHourValue, setWorkHourValue] = useState([]);
  const [dayHourValue, setDayHourValue] = useState("");
  const [mustArriveCheck, setmustArriveCheck] = useState(false);
  const [gridCheck, setGridCheck] = useState(false);

  const [showMustArriveBy, setShowMustArriveBy] = useState(false);
  const dispatch = useDispatch();
  const option = useSelector(({ option }) => option);
  //let updatedWorkHours = [];
  const checkChange = (event) => {
    setGridCheck(event.target.checked);

    dispatch(apllySameSchedule(event.target.checked));
  };
  // useEffect(() => {
  //   setWorkHourValue(workHourValue)
  // }, [workHourValue]);

  // const days = [
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  //   "Sunday",
  // ];

  const getName = async () => {
    // let curWorkH = grid.workHourValue;
    // setWorkHourValue(curWorkH);
    const ab = await api.getMustArriveBy(business.filterBusinessList.id);
    dispatch(startLoading());
    //setMustBuyArrive(ab.data.business_week[0].mon_must_arrive_by);
    if (ab && ab.data && ab.data.business_week) {
      setDayWiseState(ab.data.business_week[0]);
    }
    dispatch(stopLoading());
  };

  useEffect(() => {
    getName();
  }, [changeValueState]);

  const setDayWiseState = (mustArriveData) => {
    dispatch(startLoading());
    return new Promise((resolve) => {
      dispatch(getHourWork(resolve));
    })
      .then((data) => {
        if (data.length > 0) {
          let workingDays = [
            {
              id: 1,
              day: "Monday",
              name: `${props.t("Monday")}`,
              from: data[0].mon_start,
              to: data[0].mon_end,
              status: data[0].mon,
              break_from: data[0].mon_start_break,
              break_to: data[0].mon_end_break,
              mustBuyArrive: mustArriveData.mon_must_arrive_by
                ? mustArriveData.mon_must_arrive_by
                : data[0].mon_end
                ? moment(data[0].mon_end, "HH:mm")
                    .subtract(60, "minutes")
                    .format("HH:mm")
                : "",
            },
            {
              id: 2,
              day: "Tuesday",
              name: `${props.t("Tuesday")}`,
              from: data[0].tue_start,
              to: data[0].tue_end,
              status: data[0].tue,
              break_from: data[0].tue_start_break,
              break_to: data[0].tue_end_break,
              mustBuyArrive: mustArriveData.tue_must_arrive_by
                ? mustArriveData.tue_must_arrive_by
                : data[0].tue_end
                ? moment(data[0].tue_end, "HH:mm")
                    .subtract(60, "minutes")
                    .format("HH:mm")
                : "",
            },
            {
              id: 3,
              day: "Wednesday",
              name: `${props.t("Wednesday")}`,
              from: data[0].wed_start,
              to: data[0].wed_end,
              status: data[0].wed,
              break_from: data[0].wed_start_break,
              break_to: data[0].wed_end_break,
              mustBuyArrive: mustArriveData.wed_must_arrive_by
                ? mustArriveData.wed_must_arrive_by
                : data[0].wed_end
                ? moment(data[0].wed_end, "HH:mm")
                    .subtract(60, "minutes")
                    .format("HH:mm")
                : "",
            },
            {
              id: 4,
              day: "Thursday",
              name: `${props.t("Thursday")}`,
              from: data[0].thu_start,
              to: data[0].thu_end,
              status: data[0].thu,
              break_from: data[0].thu_start_break,
              break_to: data[0].thu_end_break,
              mustBuyArrive: mustArriveData.thu_must_arrive_by
                ? mustArriveData.thu_must_arrive_by
                : data[0].thu_end
                ? moment(data[0].thu_end, "HH:mm")
                    .subtract(60, "minutes")
                    .format("HH:mm")
                : "",
            },
            {
              id: 5,
              day: "Friday",
              name: `${props.t("Friday")}`,
              from: data[0].fri_start,
              to: data[0].fri_end,
              status: data[0].fri,
              break_from: data[0].fri_start_break,
              break_to: data[0].fri_end_break,
              mustBuyArrive: mustArriveData.fri_must_arrive_by
                ? mustArriveData.fri_must_arrive_by
                : data[0].fri_end
                ? moment(data[0].fri_end, "HH:mm")
                    .subtract(60, "minutes")
                    .format("HH:mm")
                : "",
            },
            {
              id: 6,
              day: "Saturday",
              name: `${props.t("Saturday")}`,
              from: data[0].sat_start,
              to: data[0].sat_end,
              status: data[0].sat,
              break_from: data[0].sat_start_break,
              break_to: data[0].sat_end_break,
              mustBuyArrive: mustArriveData.sat_must_arrive_by
                ? mustArriveData.sat_must_arrive_by
                : data[0].sat_end
                ? moment(data[0].sat_end, "HH:mm")
                    .subtract(60, "minutes")
                    .format("HH:mm")
                : "",
            },
            {
              id: 7,
              day: "Sunday",
              name: `${props.t("Sunday")}`,
              from: data[0].sun_start,
              to: data[0].sun_end,
              status: data[0].sun,
              break_from: data[0].sun_start_break,
              break_to: data[0].sun_end_break,
              mustBuyArrive: mustArriveData.sun_must_arrive_by
                ? mustArriveData.sun_must_arrive_by
                : data[0].sun_end
                ? moment(data[0].sun_end, "HH:mm")
                    .subtract(60, "minutes")
                    .format("HH:mm")
                : "",
            },
          ];

          // workingDays.forEach(day => {
          //   if(day.mustBuyArrive && day.mustBuyArrive != ""){
          //     day.enabledMustArriveBy = true
          //   }else{
          //     day.enabledMustArriveBy = false
          //   }
          // })

          setWorkHourValue(workingDays);
          setDayHourValue(workingDays[0].mustBuyArrive);

          // must arrive by logic start
          // *********************

          allAreEqual(workingDays); // 👉️ true

          // *******************
          // ******************
        } else {
          setWorkHourValue([]);
          // setDayHourValue(props.applySameStartFrom);
        }
        dispatch(stopLoading());
      })
      .catch(() => {
        dispatch(startLoading());
      });
  };
  //check mustArriveByValue
  function allAreEqual(obj) {
    return Object.values(obj).every((value, _index, arr) => {
      if (value.mustBuyArrive == arr[0].mustBuyArrive) {
        setGridCheck(true);
        return true;
      }
      setGridCheck(false);

      return false;
    });
  }

  const _onClose = async (time, workHour) => {
    let aH = parseInt(workHour.mustBuyArrive.split(":")[0]);
    let fh = parseInt(workHour.from.split(":")[0]);
    let th = parseInt(workHour.to.split(":")[0]);
    let hasError = false;
    if (aH && fh && th) {
      if (th > fh) {
        if (aH < fh) {
          hasError = true;
        } else if (aH >= th) {
          hasError = true;
        }
      } else {
        if (aH < fh) {
          if (aH >= th) {
            hasError = true;
          }
        }
      }
    }
    let updatedWorkH = [...workHourValue];
    updatedWorkH.forEach((data) => {
      if (data.name === workHour.name) {
        if (hasError) {
          data.hasError = true;
        } else {
          data.hasError = false;
        }
      }
    });
    setWorkHourValue(updatedWorkH);
  };

  const updateEnabledDisable = (workHour, value) => {
    let updatedWorkH = [...workHourValue];
    updatedWorkH.forEach((data) => {
      if (data.name === workHour.name) {
        if (!value) {
          //data.mustBuyArrive = "";
          data.enabledMustArriveBy = false;
        } else {
          data.enabledMustArriveBy = true;
        }
      }
    });
    setWorkHourValue(updatedWorkH);
  };

  const _onChange = async (time, workHour) => {
    let updatedWorkH = [...workHourValue];
    updatedWorkH.forEach((data) => {
      if (data.name == workHour.name) {
        data.mustBuyArrive = time.format("HH:mm");
      }
    });
    setWorkHourValue(updatedWorkH);
  };
  const onDayValueChange = async (time) => {
    let formatTime = time.format("HH:mm");

    setDayHourValue(formatTime);
  };
  const _onSaveHours = async () => {
    let updatedWorkH = [...workHourValue];
    let hasError = false;
    updatedWorkH.forEach((data) => {
      let aH = parseInt(data.mustBuyArrive.split(":")[0]);
      let fh = parseInt(data.from.split(":")[0]);
      let th = parseInt(data.to.split(":")[0]);
      if (aH && fh && th) {
        if (th > fh) {
          if (aH < fh) {
            data.hasError = true;
            hasError = true;
          } else if (aH >= th) {
            data.hasError = true;
            hasError = true;
          }
        } else {
          if (aH < fh) {
            if (aH >= th) {
              data.hasError = true;
              hasError = true;
            }
          }
        }
      }
    });
    if (hasError) {
      setWorkHourValue(updatedWorkH);
      createNotification({
        type: "danger",
        title: "Save Must arrive by",
        message: "Must arrive by should be between from and to working hours",
      });
      return false;
    }
    saveData(workHourValue);
  };

  const saveData = async (workHourValue) => {
    let time = {
      mon_must_arrive_by: workHourValue[0].mustBuyArrive,
      tue_must_arrive_by: workHourValue[1].mustBuyArrive,
      wed_must_arrive_by: workHourValue[2].mustBuyArrive,
      thu_must_arrive_by: workHourValue[3].mustBuyArrive,
      fri_must_arrive_by: workHourValue[4].mustBuyArrive,
      sat_must_arrive_by: workHourValue[5].mustBuyArrive,
      sun_must_arrive_by: workHourValue[6].mustBuyArrive,
    };
    dispatch(startLoading());
    const as = await api.setMustArriveBy(business.filterBusinessList.id, time);
    dispatch(stopLoading());
  };
  const saveAllDayWork = async () => {
    let aH = parseInt(dayHourValue.split(":")[0]);
    let fh = parseInt(props.from.split(":")[0]);
    let th = parseInt(props.to.split(":")[0]);
    let hasError = false;
    if (aH && fh && th) {
      if (th > fh) {
        if (aH < fh) {
          hasError = true;
        } else if (aH >= th) {
          hasError = true;
        }
      } else {
        if (aH < fh) {
          if (aH >= th) {
            hasError = true;
          }
        }
      }
    }

    if (hasError) {
      // setWorkHourValue(updatedWorkH);
      createNotification({
        type: "danger",
        title: "Save Must arrive by",
        message: "Must arrive by should be between from and to working hours",
      });
      return false;
    }
    let time = {
      mon_must_arrive_by: dayHourValue,
      tue_must_arrive_by: dayHourValue,
      wed_must_arrive_by: dayHourValue,
      thu_must_arrive_by: dayHourValue,
      fri_must_arrive_by: dayHourValue,
      sat_must_arrive_by: dayHourValue,
      sun_must_arrive_by: dayHourValue,
    };
    dispatch(startLoading());
    const as = await api.setMustArriveBy(business.filterBusinessList.id, time);
    dispatch(stopLoading());
  };
  useEffect(() => {
    if (option.businessSettings.length > 0) {
      setShowMustArriveBy(option.businessSettings[0].must_arrive_by_required);
    } else {
      setShowMustArriveBy(false);
    }
  }, [option.businessSettings]);

  const getMinHours = (hours) => {
    let to = hours.to.split(":");
    return parseInt(to[0]);
  };

  const getMaxHours = (hours) => {
    let from = hours.from.split(":");
    return parseInt(from[0]);
  };

  const changeMustArriveBy = (event) => {
    let value = event.target.checked;
    let mutation = `{
      id : ${option.businessSettings[0].id}
      must_arrive_by_required: ${value}
    }`;
    dispatch(startLoading());
    return dispatch(saveBusinessSettings(mutation))
      .then((data) => {
        dispatch(stopLoading());
        dispatch(getExtraSunbedPhoto());
        if (!value) {
          let updatedWorkH = [...workHourValue];
          updatedWorkH.forEach((hours) => {
            hours.mustBuyArrive = hours.to
              ? moment(hours.to, "HH:mm")
                  .subtract(60, "minutes")
                  .format("HH:mm")
              : "";
          });
          saveData(updatedWorkH);
        }
      })
      .catch((err) => {
        dispatch(stopLoading());
      });
  };

  return (
    <>
      <Card className="options-card p-3 pb-4">
        <Row className="align-items-baseline justify-content-center">
          <Col md="12">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ gap: "20px" }}
            >
              <div style={{ flex: "0 0 40%" }}>
                <h5 className="option-title-main">{t("mustArriveBy")}</h5>
              </div>
              <div
                style={{
                  flex: "0 0 60%",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Switch
                  checked={showMustArriveBy}
                  name="reservation"
                  onChange={changeMustArriveBy}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Switch
              checked={gridCheck}
              name="same-schedule"
              onChange={checkChange}
              color="primary"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />{" "}
            {t("Apply same schedule for all days")}
          </Col>
        </Row>
        {gridCheck === true ? (
          <div className="d-flex mt-3 mb-2" style={{ gap: "14px" }}>
            <div style={{ flex: "0 0 30%" }}>
              <p
                className="bold"
                style={{
                  // marginTop: "14%",
                  marginBottom: "0px",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {t("All_Days")}
              </p>
            </div>
            <div>
              <Datetime
                inputProps={{
                  disabled: !showMustArriveBy,
                  readOnly: true,
                }}
                dateFormat={false}
                className={`time-input  ${
                  !showMustArriveBy ? "disabled-date-pick" : ""
                }`}
                timeFormat={"HH:mm"}
                value={dayHourValue}
                onChange={onDayValueChange}
                // onBlur={(time) => _onClose(time, workhour)}
                timeConstraints={{
                  minutes: {
                    min: 0,
                    max: 60,
                    step: 30,
                  },
                }}
              />
            </div>
          </div>
        ) : (
          <div className="pt-3">
            <>
              {workHourValue.map((workhour, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-center mb-2"
                  style={{ gap: "14px" }}
                >
                  <div style={{ flex: "0 0 30%" }}>
                    <p
                      className="bold"
                      style={{
                        // marginTop: "14%",
                        marginBottom: "0px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {workhour.name}
                    </p>
                  </div>
                  <div>
                    <Datetime
                      inputProps={{
                        disabled:
                          workhour.status != "Open" ||
                          !workhour.from ||
                          !workhour.to ||
                          !showMustArriveBy,
                        readOnly: true,
                      }}
                      dateFormat={false}
                      className={`time-input ${
                        workhour.hasError ? "has-time-error" : ""
                      } ${!showMustArriveBy ? "disabled-date-pick" : ""}`}
                      timeFormat={"HH:mm"}
                      value={workhour.mustBuyArrive}
                      onChange={(time) => _onChange(time, workhour)}
                      onBlur={(time) => _onClose(time, workhour)}
                      timeConstraints={{
                        minutes: { min: 0, max: 60, step: 30 },
                      }}
                    />
                  </div>
                </div>
              ))}
            </>
          </div>
        )}
        <Row>
          <Col md={12}>
            <div className="d-flex">
              <Button
                onClick={gridCheck === true ? saveAllDayWork : _onSaveHours}
                className="save-btn-secondary"
                disabled={!showMustArriveBy}
              >
                {props.t("Save")}
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default withTranslation()(MustArriveBy);

/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import { connect } from "react-redux";

// reactstrap components
import { Button, Collapse, Nav } from "reactstrap";
import { useSelector } from "react-redux";
// core components
import logo from "../../assets/img/logo-white.svg";
import LocationMenu from "./LocationMenu";
import { useTranslation, withTranslation } from "react-i18next";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      ...this.getCollapseStates(props.routes),
    };
    this.sidebar = React.createRef();
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    // to stop the warning of calling setState of unmounted component
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (
        routes[i].collapse &&
        this.getCollapseInitialState(routes[i].views) &&
        routes[i].show
      ) {
        return true;
      } else if (
        window.location.href.indexOf(routes[i].path) !== -1 &&
        routes[i].show
      ) {
        return true;
      }
    }
    return false;
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { tran } = this.props;
    return routes.map((prop, key) => {
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {tran(prop.name)}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {tran(prop.name)}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      if (prop.show) {
        return (
          <SideBarComponent
            openSidebar={this.props.openSidebar}
            key={key}
            prop={prop}
          />
        );
      }
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  render() {
    if (!this.props.business.businessData && !this.props.business.superAdmin) {
      return (
        <>
          <div className="sidebar" data-color={this.props.backgroundColor}>
            <div className="sidebar-wrapper" ref={this.sidebar}></div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="sidebar" data-color={this.props.backgroundColor}>
          <div className="logo">
            <LocationMenu />
            <div className="navbar-minimize">
              <Button
                outline
                className="btn-round btn-icon"
                color="neutral"
                id="minimizeSidebar"
                onClick={() => this.props.minimizeSidebar()}
              >
                <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
              </Button>
            </div>
          </div>

          <div className="sidebar-wrapper" ref={this.sidebar}>
            {/* <div className="user">
              <div className="photo">
                <img src={avatar} alt="Avatar" />
              </div>
              <div className="info">
                <a
                  href="#pablo"
                  data-toggle="collapse"
                  aria-expanded={this.state.openAvatar}
                  onClick={() =>
                    this.setState({ openAvatar: !this.state.openAvatar })
                  }
                >
                  <span>
                    Ryan Gosling
                    <b className="caret" />
                  </span>
                </a>
                <Collapse isOpen={this.state.openAvatar}>
                  <ul className="nav">
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault}>
                        <span className="sidebar-mini-icon">MP</span>
                        <span className="sidebar-normal">My Profile</span>
                      </a>
                    </li>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault}>
                        <span className="sidebar-mini-icon">EP</span>
                        <span className="sidebar-normal">Edit Profile</span>
                      </a>
                    </li>
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault}>
                        <span className="sidebar-mini-icon">S</span>
                        <span className="sidebar-normal">Settings</span>
                      </a>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </div> */}
            <Nav>{this.createLinks(this.props.routes)}</Nav>
          </div>
        </div>
      </>
    );
  }
}

const SideBarComponent = ({ prop, openSidebar }) => {
  const { t } = useTranslation();
  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };
  const business = useSelector(({ business }) => business);
  if (
    business.filterBusinessList &&
    (business.filterBusinessList.type == "restaurant" ||
      business.filterBusinessList.type == "bar" ||
      business.filterBusinessList.type == "club")
  ) {
    if (prop.path == "/elements" || prop.path == "/price") {
      return <></>;
    } else {
      return (
        <li className={activeRoute(prop.layout + prop.path)}>
          {prop.show && (
            <NavLink to={prop.layout + prop.path} activeClassName="">
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>{t(prop.name)}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{t(prop.name)}</span>
                </>
              )}
              {prop.subMenu ? (
                <ul>
                  {prop?.subMenu?.map((e, key) => {
                    return e.show ? (
                      <SideBarComponent key={key} prop={e} />
                    ) : null;
                  })}
                </ul>
              ) : null}
            </NavLink>
          )}
        </li>
      );
    }
  } else {
    if (
      business.filterBusinessList && (
        business.filterBusinessList.type == "beach" ||
        business.filterBusinessList.type == "pool"
      )
    ) {
      if (prop.path == "/guest") return null;
    }
    return (
      <li
        onClick={openSidebar}
        className={activeRoute(prop.layout + prop.path)}
      >
        {prop.show && (
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{t(prop.name)}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{t(prop.name)}</span>
              </>
            )}
            {prop.subMenu ? (
              <ul>
                {prop?.subMenu?.map((e, key) => {
                  return e.show ? (
                    <SideBarComponent key={key} prop={e} />
                  ) : null;
                })}
              </ul>
            ) : null}
          </NavLink>
        )}
      </li>
    );
  }
};
export { SideBarComponent };

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: "blue",
  minimizeSidebar: () => { },
};

Sidebar.propTypes = {
  // links that are rendered
  routes: PropTypes.arrayOf(PropTypes.object),
  // if you want to show a notification when switching between mini sidebar and normal
  showNotification: PropTypes.bool,
  // background color for the component
  backgroundColor: PropTypes.oneOf([
    "blue",
    "yellow",
    "green",
    "orange",
    "red",
  ]),
  // function that is called upon pressing the button near the logo
  minimizeSidebar: PropTypes.func,
};

const mapStateToProps = (store) => ({
  business: store.business,
});

export default withTranslation()(connect(mapStateToProps)(Sidebar));

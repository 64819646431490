import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// core components
import nowLogo from "../../assets/img/tabo-logo.png";
import { useTranslation } from "react-i18next";
import bgImage from "../../assets/img/loginpage.jpg";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { REST_API_URL } from "../../utils/constants.js";
import { createNotification } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, stopLoading } from "../../redux/loader/action";

const PasswordResetPage1 = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    emailFocus: false,
    lastnameFocus: false,
  });
  const [emailValue, setEmailValue] = useState("");
  const [validStatus, setValidStatus] = useState(false);
  const changeRegister = () => {
    history.push("/register");
  };
  const changeLogin = () => {
    history.push("/login");
  };
  const forgotPasswordAction = () => {
    dispatch(startLoading());
    axios
      .post(
        `${REST_API_URL}/forgotPassword`,
        { email: emailValue },
        {
          headers: {
            "accept-language": i18n.language,
          },
        }
      )
      .then((response) => {
        if (response) {
          if (response.data.status === "fail") {
            setEmailValue("");
            setValidStatus(true);
          } else {
            setValidStatus(false);
            createNotification({
              type: "success",
              title: t("Reset password"),
              message: t("reset password link sucess", { email: emailValue }),
            });
            history.push("/login");
          }
          dispatch(stopLoading());
        }
      })
      .catch((error) => {
        dispatch(stopLoading());
      });
  };

  return (
    <>
      <div className="content">
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form>
                <Card className="card-login card-plain">
                  <CardHeader>
                    <div className="logo-container">
                      <img src={nowLogo} alt="smart-beach-logo" />
                    </div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border form-control-lg " +
                        (state.emailFocus ? "input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder={t("emailAddress")}
                        onChange={(e) => {
                          setEmailValue(e.target.value);
                        }}
                        onFocus={(e) => setState({ emailFocus: true })}
                        onBlur={(e) => setState({ emailFocus: false })}
                      />
                    </InputGroup>
                    {validStatus && (
                      <p className="text-danger">Invalid Email!</p>
                    )}
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      color="primary"
                      size="lg"
                      className="mb-3 btn-round"
                      onClick={forgotPasswordAction}
                    >
                      {t("Send")}
                    </Button>
                    <div className="pull-left">
                      <h6>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={changeRegister}
                          className="link footer-link"
                        >
                          {t("register")}
                        </a>
                      </h6>
                    </div>
                    <div className="pull-right">
                      <h6>
                        <a
                          style={{ cursor: "pointer" }}
                          className="link footer-link"
                          onClick={changeLogin}
                        >
                          {t("login")}
                        </a>
                      </h6>
                    </div>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
      <div
        className="full-page-background"
        style={{ backgroundImage: "url(" + bgImage + ")" }}
      />
    </>
  );
};

export default PasswordResetPage1;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';

import defaultImage from '../../assets/img/image_placeholder.jpg';
import updateIcon from "../../../src/assets/img/image-update.svg";
import removeIcon from "../../../src/assets/img/image-remove.svg";
import defaultAvatar from '../../assets/img/placeholder.jpg';


const BeachImageUpload = ({ avatar, beachImageUpload, imageUrl, imageId, imageDelete, imageUpdate, uploadNewImage }) => {
	const { t } = useTranslation();
	const [state, setState] = useState({
		file: null,
		imagePreviewUrl: imageUrl || avatar ? defaultAvatar : defaultImage,
		imageId: imageId || '',
		id: imageId
	});
	useEffect(() => {
		if (imageUrl) {
			setState({ ...state, imagePreviewUrl: imageUrl, id: imageId });
		} else {
			setState({ ...state, imagePreviewUrl: '', id: imageId });
		}
	}, [imageId, imageUrl]);

	const fileInput = React.createRef();
	const handleImageChange = (e) => {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			setState({
				file: file,
				imagePreviewUrl: '',
				// imagePreviewUrl: reader.result,
			});
			// console.log("file", file);
			const compressOptions = {
				strict: true,
				quality: 0.4,
				resize: "contain",
				mimeType: "image/png",
				checkOrientation: false,
				convertSize: 2 * 1000000,
				convertTypes: ["image/jpeg", "image/png", "image/webp"],
			}
			if (file.size <= 2000000) {
				delete compressOptions["resize"];
				delete compressOptions["mimeType"];
				delete compressOptions["convertTypes"];
				delete compressOptions["checkOrientation"];
				compressOptions["quality"] = 0.8;
			}
			// console.log("compressOptions", compressOptions);
			new Compressor(file, {
				...compressOptions,
				success: (fileResult) => {
					// console.log("fileResult", fileResult);
					// console.log("total compress", ((file?.size - fileResult.size) * 100) / file?.size);
					beachImageUpload(fileResult, reader.result)
				},
				error: (fileError) => {
					// console.log("fileError", fileError);
				}
			})
			// beachImageUpload(file, reader.result);
		};
		if (file) {
			reader.readAsDataURL(file);
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		// this.state.file is the file/image uploaded
		// in this function you can save the image (this.state.file) on form submit
		// you have to call it yourself
	};
	const handleClick = (id) => (event) => {
		fileInput.current.click();
		if (id != 'selectImage') {
			imageUpdate(id);
		}
	};
	const handleRemove = (id) => (event) => {
		imageDelete(id);
		// fileInput.current.value = null;
		// setState({
		//   file: null,
		//   imagePreviewUrl: avatar ? defaultAvatar : defaultImage,
		//   id: ""
		// });
	};
	return (
		<>
			{uploadNewImage ? (
				<div className="fileinput text-center mb-0">
					<input
						type="file"
						onChange={handleImageChange}
						ref={fileInput}
						id={state.id}
					/>
					<div>
						<span className="upload-new" onClick={handleClick('selectImage')}>
							{`${t('Select image')}`}
						</span>
					</div>
				</div>
			) : (
				<div className="fileinput text-center cuisine-image-wrapper">
					<input
						type="file"
						onChange={handleImageChange}
						ref={fileInput}
						id={state.id}
					/>

					{state.imagePreviewUrl ? (
						<div className='cuisine-image'>
							<img src={state.imagePreviewUrl} alt="..." />
						</div>
					) : (
						<label for={state.id} className={'thumbnail' + (avatar ? ' img-circle' : '')}>
							<img src={`/image_placeholder.jpg`} alt="..." width="200px" height="200px" />
						</label>
					)}
					{state.imagePreviewUrl ? (
						<div className="image-preview-links">
							<span className="change-image" onClick={handleClick(state.id)}>
								{/* <img src={updateIcon}></img> */}
								<i className='fa fa-edit' style={{ fontSize: "12px" }} />
							</span>
							<span
								className="remove-image"
								onClick={handleRemove(state.id)}
							>
								<img src={removeIcon}></img>
							</span>
						</div>
					) : (
						null
					)}
				</div>
			)}
		</>
	);
};

BeachImageUpload.propTypes = {
	avatar: PropTypes.bool,
};

export default BeachImageUpload;
